// 钱包的翻译
export default {
  deposit: "เงินฝาก",
  withdrawal:  "การถอนเงิน",
  other:  "อื่น",
  recordDrawing:  "บันทึกการฝากและถอนเงิน",
  network:  "เครือข่าย",
  tokenContract:  "ที่อยู่สัญญาโทเค็น:",
  your:  "ของคุณ",
  depositAddress:  "ที่อยู่การฝากเงิน",
  minimumDeposit:  "จำนวนเงินฝากขั้นต่ำ",
  lowerAmounts:  ",จำนวนเงินที่ต่ำกว่าจะไม่ได้รับเครดิตหรือคืนเงิน",
  sendOnly:  "กรุณาส่งเท่านั้น",
  toAddress:  "ไปยังที่อยู่นี้ คุณต้องได้รับการยืนยัน 1 ครั้งจึงจะฝากเงินเข้าได้",
  yourWalletAddress:  "ที่อยู่กระเป๋าเงินของคุณ",
  enterAddress:  "ป้อนที่อยู่",
  quantity:  "ปริมาณ",
  balance:  "สมดุล",
  enterAmount:  "ใส่จำนวนเงิน",
  whole:  "ทั้งหมด",
  minimumWithdrawalAmount:  "จำนวนเงินถอนขั้นต่ำคือ",
  transactionCosts:  "ค่าธรรมเนียมการทำธุรกรรม: - เวลาที่ใช้ - 0-2 ชั่วโมง ไม่รวมการยืนยันเครือข่าย",
  additionalTime:  "อาจต้องใช้เวลาเพิ่ม",
  sendingAddress:  "ที่อยู่สำหรับส่ง",
  accountArrivalTime:  "เวลาถึง",
  accountStates:  "สถานะ",
  accountStatesTxt_1:  "ถูกปฏิเสธ",
  accountStatesTxt0:  "ที่จะได้รับการตรวจสอบ",
  accountStatesTxt1:  "ตรวจสอบแล้ว",
  accountStatesTxt2:  "มาถึงแล้ว",
  withdrawalAddress:  "ที่อยู่การถอนเงิน",
  serviceCharge:  "ค่าธรรมเนียมการจัดการ",
  pleaseEnterAddress:  "กรุณากรอกที่อยู่",
  extract:  "สารสกัด",
  copySucceeded:  "คัดลอกสำเร็จ",
  enterCorrectBalance:  "กรุณากรอกยอดคงเหลือที่ถูกต้อง",
  runningLow:  "ยอดเงินไม่เพียงพอ",
  withdrawalSucceeded:  "การถอนเงินสำเร็จ",
  otherCoinName:  'ชื่อสกุลเงิน',
  otherAmount:  'จำนวน',
  otherType:  'พิมพ์',
  otherStatus:  'สถานะ',
  otherStatus1:  'ไม่ถูกต้อง',
  otherStatus2:  'ยกเลิก',
  otherStatus3:  'สมบูรณ์',
}
