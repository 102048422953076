// 返佣的翻译
export default {
  rebateProgram: "리베이트 프로그램",
  usersShare:  "사용자가 초대 링크를 공유하면 초대받은 사람은 링크를 통해 연결하고 플랫폼에서 적극적으로 게임을 플레이하며 초대한 사람은 소극적인 수입을 얻습니다. 더 많은 플레이어를 초대할수록 더 많은 수입을 얻을 수 있습니다.",
  usingTheReferralLinks:  "플랫폼에서 생성된 추천 링크를 사용하면 귀하가 초대한 사용자가 어떻게 방문하고 귀하가 베팅에서 어떤 상금을 받는지 기록되고 추적됩니다.",
  enterInvitation:  "초대 페이지로 들어가세요",
  commissionRebate:  "순이익 리베이트",
  inviteeWinsBet:  "초대자가 베팅에서 승리할 때마다 초대자 상금의 3%가 즉시 귀하에게 지급됩니다.",
  platformBetting:  "플랫폼 베팅 자금 리베이트",
  inviteeLoses:  "초대받은 사람이 베팅에서 질 때마다 플랫폼은 베팅 금액의 3%를 가져가서 귀하에게 지불합니다.",
  miningCommission:  "채굴 리베이트",
  miningRebate:  "초대받은 사람이 채굴로 수입을 얻을 때마다 귀하는 그에 상응하는 3%의 채굴 커미션을 받게 됩니다."
}
