// 钱包的翻译
export default {
  deposit:   "보증금",
  withdrawal:  "철수",
  other:  "다른",
  recordDrawing:  "입출금기록",
  network:  "회로망",
  tokenContract:  "토큰 계약 주소:",
  your:  "당신의",
  depositAddress:  "입금주소",
  minimumDeposit:  "최소 입금액",
  lowerAmounts:  ",더 적은 금액은 적립되거나 환불되지 않습니다.",
  sendOnly:  "보내기만 해주세요",
  toAddress:  "이 주소로 입금하려면 확인 1번을 받아야 합니다.",
  yourWalletAddress:  "당신의 지갑 주소",
  enterAddress:  "주소를 입력하세요",
  quantity:  "수량",
  balance:  "균형",
  enterAmount:  "금액을 입력하세요",
  whole:  "모두",
  minimumWithdrawalAmount:  "최소 출금 금액은",
  transactionCosts:  "거래 수수료: - 소요 시간 - 0~2시간. 네트워크 확인은 포함되지 않음,",
  additionalTime:  "시간이 좀 더 걸릴 수 있습니다.",
  sendingAddress:  "보내는 주소",
  accountArrivalTime:  "도착 시간",
  accountStates:  "상태",
  accountStatesTxt_1:  "거부됨",
  accountStatesTxt0:  "검토 예정",
  accountStatesTxt1:  "감사됨",
  accountStatesTxt2:  "도착했다",
  withdrawalAddress:  "출금주소",
  serviceCharge:  "수수료",
  pleaseEnterAddress:  "주소를 입력해주세요",
  extract:  "발췌",
  copySucceeded:  "복사 성공",
  enterCorrectBalance:  "올바른 잔액을 입력해주세요",
  runningLow:  "잔액 불충분",
  withdrawalSucceeded:  "출금 성공",
  otherCoinName:  '통화명',
  otherAmount:  '양',
  otherType:  '유형',
  otherStatus:  '상태',
  otherStatus1:  '유효하지 않은',
  otherStatus2:  '푼',
  otherStatus3:  '완전한',
}
