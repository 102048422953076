// 帮助-关于的翻译
export default {
  aboutQueenG:  "Sobre QueenG",
  p1:  "QueenG é uma plataforma iGaming baseada em BSC. Embora mantenha os hábitos de jogo tradicionais, é completamente descentralizada, garantindo a justiça e a abertura do processo de jogo e proporcionando uma experiência de jogo única para todos os usuários. A interface simples e fácil de usar e o caminho da operação farão com que a experiência faça o seu melhor.",
  p2:  "Suporta vários logins de conexão de carteira blockchain, os ativos são controlados pela própria carteira do usuário e a renda do usuário é liquidada na carteira instantaneamente, o que é absolutamente seguro e confiável.",
  p3:  "A plataforma QueenG está dividida em duas partes: jogos de azar e esportes. Os jogos de azar incluem jogos de cassino tradicionais, como dados e caça-níqueis, e os esportes incluem apostas em vários eventos. Na fase inicial, serão jogos originais, e depois serão conectados a diversos fornecedores e parceiros de jogos, e serão lançados de forma cooperativa dentro da plataforma. .",
  major:  "Quatro principais benefícios dos usuários",
  tip1:  "1) Renda de penhor",
  detail1:  "Os usuários que prometem a liquidez da moeda da plataforma podem desfrutar de dividendos de renda da plataforma.",
  tip2:  "2) Receita de depósito",
  detail2:  "Depois que a plataforma fornece vários produtos agrícolas e moedas digitais de depósito a prazo, você pode obter retornos anualizados correspondentes após o término do período de depósito.",
  tip3:  "3) Receitas mineiras",
  detail3:  "Jogos de usuários são mineração. Quanto mais jogos você joga, mais recompensas de mineração de moeda de plataforma você pode obter.",
  tip4:  "4) Reembolso de receitas",
  detail4:  "Os usuários compartilham links de convite, os convidados se registram e jogam ativamente na plataforma, e os convidadores receberão uma renda passiva.",
}
