// 首页的翻译
export default {
  qGTHasHeenAwarded: "QGT가 수여되었습니다",
  homePage:  "첫 장",
  lasVegas:  "카지노",
  throwTheDice:  "주사위를 던지다",
  roulette:  "룰렛",
  guessTheRiseAndFall:  "상승 또는 하락 추측",
  slotMachine:  "슬롯 머신",
  aloneGame:  "싱글플레이 미니게임",
  arcade:  "아케이드",
  pledge:  "약속",
  commissionBack:  "할인",
  noviceMission:  "초보 작업",
  qGTTokens:  "QGT 토큰",
  helpCenter:  "지원 센터",
  games:  '게임',
  user:  '사용자',
  payout:  '베팅하기',
  gamesTypeAll:  '모두',
  gamesType1:  '카지노',
  gamesType2:  '슬롯 머신',
  gamesType3:  '독립형 미니게임',
  gamesType4:  '아케이드',
  gamesType5:  '스포츠 이벤트',
  gamesType1msg:  '고객은 자신의 기분에 따라 다른 문에 베팅하도록 선택할 수 있습니다.',
  gamesType2msg:  '동전을 넣은 후 레버를 당기면 회전이 시작됩니다.',
  gamesType3msg:  '동전을 넣은 후 레버를 당기면 회전이 시작됩니다.',
  gamesType4msg:  '동전을 넣은 후 레버를 당기면 회전이 시작됩니다.',
  gamesType5msg:  '스포츠 경기가 곧 열립니다.',
  gamesRecordText:  '게임 기록',
  theOriginalGame:  "오리지널 게임",
  miningMode:  "즉, 채굴 모드",
  miningRewards:  "베팅을 많이 할수록 더 많은 보상을 받을 수 있고, 채굴 보상은 실시간으로 정산됩니다",
  qGTCanBeDugToday:  "채굴이 다가오고 있습니다",
  highestEarnedMultiplier:  "스포츠 행사가 다가오고 있습니다",
  gamesPlayed:  "게임을했다",
  gamesPlayedNew:  "요즘 자주 하는 게임",
  highestWin:  "최고의 승리",
  participant:  "참가자",
  canBeDugToday:  "오늘은 파낼 수 있어요",
  highReturn:  "하이 리턴",
  highYield:  "고소득",
  signIn:  "로그인",
  registration:  "등록하다",
  purse:  "지갑",
  search:  "찾다",
  hideOneBalance:  "0 잔액 숨기기",
  displayUSD:  "달러 표시",
  personalCenter:  "개인 센터",
  invite:  "초대 링크 복사",
  inviteCopy:  "초대링크가 복사되었습니다",
  setUp:  "설정",
  Language:  "언어 설정",
  LanguageZh:  "중국어 번체",
  LanguageEn:  "영어",
  logOutAccount:  "계정 탈퇴"

};
