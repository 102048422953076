// 支持者的翻译
export default {
  todayDividend: "预计今日赌城分红",
  supplyYourLP: "#供应您的LP  成为平台伙伴·和平台共分佣金",
  supplyQGT: "供应QGT",
  dividendsExpected: "预计可分红",
  networkCosts: "预计网络费用",
  pledge: "质押",
  pledgedQGT: "已质押QGT",
  quantity: "数量",
  unlock: "解锁",
  dividendsPaid: "已分红",
  settlementDate: "结算日期",
  settlementQGT: "结算QGT",
  dividends: "分红",
  operation: "操作",
  viewTheDetails:  "查看明细",
  pledged: "已质押",
  unlockQuantity: "解锁数量",
  correctBalance: "请输入正确余额",
  correctNumber: "请输入正确数字",
  insufficientPledged: "已质押数量不足",
  unlockSucceeded: "解锁成功"
}
