export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSwitch() {
      this.$emit('change', !this.value);
    }
  }
};