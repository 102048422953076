// 帮助-关于的翻译
export default {
  aboutQueenG: "Tentang QueenG",
  p1:  "QueenG ialah platform iGaming berdasarkan BSC. Sambil mengekalkan tabiat perjudian tradisional, ia tidak berpusat sepenuhnya, memastikan keadilan dan keterbukaan proses permainan, dan menyediakan pengalaman permainan yang unik untuk semua pengguna. Antara muka yang ringkas dan mudah digunakan dan laluan operasi akan menjadikan pengalaman Lakukan yang terbaik.",
  p2:  "Menyokong berbilang log masuk sambungan dompet blockchain, aset dikawal oleh dompet pengguna sendiri, dan pendapatan pengguna diselesaikan ke dompet serta-merta, yang benar-benar selamat dan boleh dipercayai.",
  p3:  "Platform QueenG dibahagikan kepada dua bahagian: perjudian dan sukan. Perjudian termasuk permainan kasino tradisional seperti dadu dan mesin slot, dan sukan termasuk pertaruhan pada pelbagai acara. Pada peringkat awal, ia akan menjadi permainan asli, dan kemudian ia akan menjadi disambungkan kepada pelbagai pembekal dan rakan kongsi permainan, dan akan dilancarkan secara kerjasama dalam platform. .",
  major:  "Empat Manfaat Utama Pengguna",
  tip1:  "1) Mengikrarkan pendapatan",
  detail1:  "Pengguna yang menjanjikan kecairan mata wang platform boleh menikmati dividen pendapatan platform.",
  tip2:  "2) Pendapatan deposit",
  detail2:  "Selepas platform menyediakan pelbagai produk ladang dan mata wang digital deposit masa, anda boleh memperoleh pulangan tahunan yang sepadan selepas tamat tempoh deposit.",
  tip3:  "3) Pendapatan perlombongan",
  detail3:  "Permainan pengguna sedang melombong. Lebih banyak permainan yang anda mainkan, lebih banyak ganjaran perlombongan mata wang platform yang anda boleh perolehi.",
  tip4:  "4) Pendapatan rebat",
  detail4:  "Pengguna berkongsi pautan jemputan, jemputan mendaftar dan bermain permainan secara aktif di platform, dan jemputan akan menerima pendapatan pasif.",
}
