// 登录注册的翻译
export default {
  emailOrUserName:  "thư điện tử hoặc tên người dùng",
  password:  "mật khẩu",
  signIn:  "Đăng nhập",
  registration:  "đăng ký",
  emailOrUsernameNotExist:  "Email hoặc tên người dùng không tồn tại",
  passwordError:  "sai mật khẩu",
  loginSuccessful:  "đăng nhập thành công",
  email:  "E-mail",
  userName:  "tên tài khoản",
  invitationCode:  "Mã mời",
  termsServiceConfirmation:  "Nhấp vào hộp kiểm cho biết bạn đã đọc và xác nhận",
  service:  "các điều khoản và điều kiện",
  mailboxExists:  "Email đã tồn tại",
  userNameExists:  "Tên này đã có người dùng",
  incorrectMailbox:  "Định dạng email không chính xác",
  incorrectPasswords:  "Mật khẩu phải dài không dưới 6 ký tự và phải chứa các chữ cái và dấu thập phân",
  invitationCodeNotExist:  "Mã mời không tồn tại",
  registrationSuccessful:  "đăng ký thành công",
  pleaseCheckEmail:  "Vui lòng kiểm tra email của bạn",
  pleaseEnterEmailAddress:  "Hãy điền địa chỉ email của bạn",
  enterUserNameOrEmail:  "Vui lòng nhập tên người dùng hoặc địa chỉ email của bạn",
  pleaseInputPassword:  "Xin vui lòng nhập mật khẩu",
  verificationCode:  "Mã xác nhận",
  sendVerificationCode:  "Gửi mã xác minh",
  mailSent:  "Tin nhắn đã được gửi",
  enterVerificationCode:  "vui lòng nhập mã xác nhận",
  enterUserName:  "Vui lòng nhập tên người dùng",
  agreeConditions:  "Vui lòng đồng ý với các điều khoản và điều kiện",
  forgetPassword:  'quên mật khẩu',
  forgetEmail:  'E-mail',
  forgetCode:  'Mã xác nhận',
  forgetLogin:  'Đi đến đăng nhập',
  forgetConfirm:  'xác nhận',
  forgetErrorEmail:  'Vui lòng điền địa chỉ email của bạn',
  forgetErrorVerificationCode:  'Vui lòng điền mã xác minh',
  forgetErrorSuccess:  'Đặt lại mật khẩu thành công',

}
