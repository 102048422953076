// 登录注册的翻译
export default {
  emailOrUserName:  "이메일 또는 사용자 이름",
  password:  "비밀번호",
  signIn:  "로그인",
  registration:  "등록하다",
  emailOrUsernameNotExist:  "이메일 또는 사용자 이름이 존재하지 않습니다",
  passwordError:  "잘못된 비밀번호",
  loginSuccessful:  "성공적 로그인",
  email:  "이메일",
  userName:  "사용자 이름",
  invitationCode:  "초대 코드",
  termsServiceConfirmation:  "체크박스를 클릭하면 내용을 읽고 확인했다는 의미입니다.",
  service:  "이용약관",
  mailboxExists:  "이메일이 이미 존재합니다",
  userNameExists:  "사용자 이름이 이미 존재합니다",
  incorrectMailbox:  "이메일 형식이 올바르지 않습니다",
  incorrectPasswords:  "비밀번호는 6자 이상이어야 하며 문자와 소수점을 포함해야 합니다.",
  invitationCodeNotExist:  "초대코드가 존재하지 않습니다",
  registrationSuccessful:  "등록 성공",
  pleaseCheckEmail:  "이메일을 확인해주세요",
  pleaseEnterEmailAddress:  "당신의 이메일 주소를 입력하십시오",
  enterUserNameOrEmail:  "사용자 이름이나 이메일 주소를 입력하세요",
  pleaseInputPassword:  "비밀번호를 입력 해주세요",
  verificationCode:  "확인 코드",
  sendVerificationCode:  "인증코드를 보내주세요",
  mailSent:  "메시지가 전송되었습니다",
  enterVerificationCode:  "인증번호를 입력해주세요",
  enterUserName:  "사용자 이름을 입력하세요",
  agreeConditions:  "이용약관에 동의해주세요",
  forgetPassword:  '비밀번호를 잊어버렸어요',
  forgetEmail:  '이메일',
  forgetCode:  '확인 코드',
  forgetLogin:  '로그인으로 이동',
  forgetConfirm:  '확인하다',
  forgetErrorEmail:  '이메일 주소를 입력해주세요',
  forgetErrorVerificationCode:  '인증번호를 입력해주세요',
  forgetErrorSuccess:  '비밀번호 재설정 성공',

}
