// 支持者的翻译
export default {
  todayDividend:  "Espera-se que o casino pague dividendos hoje",
  supplyYourLP:  "#Forneça seu LP para se tornar um parceiro da plataforma e dividir a comissão com a plataforma",
  supplyQGT:  "Fornecimento QGT",
  dividendsExpected:  "Espera-se que os dividendos sejam distribuídos",
  networkCosts:  "Tarifas de rede estimadas",
  pledge:  "Juramento",
  pledgedQGT:  "QGT prometido",
  quantity:  "quantidade",
  unlock:  "desbloquear",
  dividendsPaid:  "Dividendos distribuídos",
  settlementDate:  "Data de liquidação",
  settlementQGT:  "Liquidação QGT",
  dividends:  "dividendo",
  operation:  "operar",
  viewTheDetails:   "Ver detalhes",
  pledged:  "Prometido",
  unlockQuantity:  "Quantidade desbloqueada",
  correctBalance:  "Por favor, insira o saldo correto",
  correctNumber:  "Por favor, insira o número correto",
  insufficientPledged:  "Quantidade prometida insuficiente",
  unlockSucceeded:  "Desbloqueado com sucesso"
}
