// 设置的翻译
export default {
  routine:  "전통적인",
  emailAddress:  "이메일 주소",
  changeEmail:  "이메일 주소 변경",
  cellPhoneNumber:  "전화 번호",
  areaCode:  "지역 코드",
  preservation:  "구하다",
  originalMailbox:  "원본 이메일 인증코드",
  mailbox:  "원본 이메일",
  sendVerificationCode:  "인증코드를 보내주세요",
  nextStep:  "다음 단계",
  newEmailAddress:  "새 이메일 주소",
  confirmReplacement:  "교체 확인",
  originalPhoneCode:  "원본 휴대폰 인증번호",
  originalPhone:  "원래 휴대폰 번호",
  newPhoneNumber:  "새 휴대폰 번호",
  oldPassword:  "기존 비밀번호",
  newPassword:  "새 비밀번호",
  confirmNewPassword:  "새 비밀번호를 확인하세요",
  oldPasswordError:  "이전 비밀번호가 잘못되었습니다",
  pleaseOldPassword:  "기존 비밀번호를 입력해주세요",
  pleaseNewPassword:  "새 비밀번호를 입력해주세요",
  password:  "비밀번호",
  changePhone:  "휴대폰 번호 변경",
  savePhone:  "전화번호 저장",
  phoneNumber:  "휴대폰번호가 전송되었습니다",
  resend:  "재전송",
  mailSending:  "이메일이 너무 자주 전송됩니다",
  enterCorrectCode:  "올바른 인증번호를 입력해주세요",
  checkEmail:  "이메일을 확인해주세요",
  sendingSucceeded:  "성공적으로 전송되었습니다",
  mailboxSucceeded:  "이메일이 성공적으로 변경되었습니다",
  savingSucceeded:  "성공적으로 저장 되었음",
  enterConfirmationPassword:  "확인 비밀번호를 입력해주세요",
  passwordsTwiceInconsistent:  "두 번 입력한 비밀번호가 일치하지 않습니다",
  passwordSuccessfully:  "비밀번호 재설정 완료"
}
