// 帮助-游戏的翻译
export default {
  title:  "jogo",
  p1:  "QueenG reuniu os jogos mais populares do cenário de cassino e os desenvolveu internamente. Você pode usar criptomoedas TOP para fazer apostas diretamente nos jogos. Atualmente, os usuários podem desfrutar de jogos com design exclusivo, como lançamento de dados, adivinhação de ascensão e queda , e a roda gigante. Também lançaremos jogos de cassino mais populares no futuro!",
  p2:  "As probabilidades correspondentes a cada jogo são diferentes, mas o valor máximo-mínimo da aposta é fixado em: $1000000-$1",
  title1:  "1) Jogue os dados",
  p3:  "Crap é um antigo jogo de dados chinês no qual os jogadores apostam no resultado de um giro de três dados girados em uma gaiola.",
  p4:  "Quando o jogo começa, o jogador seleciona a direção do tamanho da área de apostas ou o número específico de pontos. Após a conclusão das apostas, os dados começarão a girar. Após a conclusão da rotação, a plataforma exibirá os resultados e emitirá recompensas.",
  p5:  "Ao contrário dos dados tradicionais, o jogo de dados GAMES automatiza completamente o processo de apostas, dados aleatórios, compensação de ganhos e processamento de fundos/recompensas através de contratos inteligentes sem qualquer participação de terceiros. Isto é mais rápido do que as apostas de casino tradicionais existentes. Muito mais eficiente e justo .",
  subtitle1:  "1.1) Faça um pedido",
  p6:  "Há uma linha de áreas de apostas abaixo dos dados que são divididas em 6 pequenas células. Nesta linha, você aposta em qual número será exibido após o giro dos dados. Se um dos três dados mostrar o número em que você apostou, então seus Seus ganhos são de 1 a 2. Se dois dos três dados mostrarem o número que você apostou, seus ganhos serão de 1 a 11. Se três dos três dados mostrarem o número que você apostou, seus ganhos serão de 1 a 151.",
  subtitle2:  "1.2) Apostar no empate",
  p7:  "Na área Empate, sua aposta é a soma dos números após a rotação dos três dados. 'Grande' significa que a soma dos números é de 11 a 17; 'Pequena' significa que a soma dos números é de 4 a 10. Se o número após o giro é três. Se os pontos forem iguais, então ele pertence ao 'Leopardo', e tanto o grande quanto o pequeno perdem. As chances do grande e do pequeno são de 2 para 1, e as chances do leopardo são de 25 para 1. ",
  title2:  "2) Adivinhe a ascensão ou queda",
  p8:  "Com base nas condições de mercado das três principais moedas, os usuários podem escolher entre alta ou baixa e prever a ascensão e queda do par de negociação selecionado em 15 segundos. Depois de fazer uma aposta em uma direção, um salto de mercado de 15 segundos a contagem regressiva será inserida. Após 15 segundos, o aumento e a queda do preço serão comparados. Se a estimativa estiver correta, o preço aumentará ou cairá imediatamente. Desconto após a liquidação.",
  p9:  "Sobre fazer apostas:",
  p10:  "Depois de analisar e verificar o mercado, defina o valor da aposta e clique no botão Adivinhar/Adivinhar. Se o resultado do palpite estiver correto, você receberá 2 vezes o bônus correspondente; se o resultado do palpite estiver errado, apenas o valor apostado será será deduzido; se o valor do aumento ou diminuição for zero, haverá empate e a aposta será reembolsada;",
  title3:  "3) Grande plataforma giratória",
  p11:  "A roda gigante é um jogo comum em cassinos. A roda tem 54 áreas que podem ser selecionadas e as quatro cores têm proporções diferentes; o roxo tem 26 blocos e as probabilidades são 2 vezes; o vermelho tem 17 blocos e as probabilidades são 3 vezes; Verde é 10 peças, as probabilidades são 5 vezes; amarelo é 1 peça, as probabilidades são 50 vezes;",
  p12:  "Defina o valor da aposta. Depois de selecionar a cor da aposta, a roda da roleta irá girar. Depois que a roda da roleta parar de girar, a cor da área selecionada será a cor da loteria. Após o sorteio da loteria, a liquidação será concluída imediatamente e o bônus será distribuído;"
}
