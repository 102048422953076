// 首页的翻译
export default {
  qGTHasHeenAwarded:"QGT awarded",
  homePage: "Home",
  lasVegas:"Casino",
  throwTheDice:"Roll the Dice",
  roulette: "Roulette",
  guessTheRiseAndFall:"Guess High or Low",
  slotMachine: "Slot machine",
  aloneGame:"Single-player Mini Games",
  arcade: "Arcade",
  Stake: "Stake",
  commissionBack: "Commission back",
  noviceMission:"Beginner's Tasks",
  qGTTokens: "QGT tokens",
  helpCenter: "Help Center",
  games:"Games",
  user:"Users",
  payout:"Betting",
  gamesTypeAll: 'All',
  gamesType1: 'Casino',
  gamesType2: 'Slot Machine',
  gamesType3: "Single-player Mini Games",
  gamesType4: "Arcade",
  gamesType5: "Sports Events",
  gamesType1msg: "Customers can choose to place bets on any other doors according to their own preferences.",
  gamesType2msg: "After inserting the coin, pull down the lever, and it will start spinning.",
  gamesType3msg: "After inserting the coin, pull down the lever, and it will start spinning.",
  gamesType4msg: "After inserting the coin, pull down the lever, and it will start spinning.",
  gamesType5msg: "Sports events are coming soon.",
  gamesRecordText: "Game Records",
  theOriginalGame: "Original Games",
  miningMode: "Instant Mining Mode",
  miningRewards: "The more you bet, the more rewards you get, mining rewards are settled in real-time.",
  qGTCanBeDugToday: "Mining opportunities are coming soon",
  highestEarnedMultiplier: "Sports events are coming soon",
  gamesPlayed: "Games in progress",
  gamesPlayedNew:"Recently Played Games",
  highestWin: "Highest Winnings",
  participant: "Participants",
  canBeDugToday: "Today's Mineable",
  highReturn: "High Returns",
  highYield: "High Yield",
  signIn: "Log In",
  registration: "Sign Up",
  purse: "Wallet",
  search: "Search",
  hideOneBalance: "Hide 0 balance",
  displayUSD: "Show in USD",
  personalCenter: "Profile",
  invite: "Copy invitation link",
  inviteCopy: "Invitation link copied successfully",
  setUp: "Settings",
  Language: "Language Settings",
  LanguageZh: "Traditional Chinese",
  LanguageEn: "English",
  logOutAccount: "Log Out"
};
