// 设置的翻译
export default {
  routine:  "ธรรมดา",
  emailAddress:  "ที่อยู่อีเมล",
  changeEmail:  "เปลี่ยนที่อยู่อีเมลล์",
  cellPhoneNumber:  "หมายเลขโทรศัพท์",
  areaCode:  "รหัสพื้นที่",
  preservation:  "บันทึก",
  originalMailbox:  "รหัสยืนยันอีเมลต้นฉบับ",
  mailbox:  "อีเมลต้นฉบับ",
  sendVerificationCode:  "ส่งรหัสยืนยัน",
  nextStep:  "ขั้นตอนต่อไป",
  newEmailAddress:  "ที่อยู่อีเมลใหม่",
  confirmReplacement:  "ยืนยันการเปลี่ยน",
  originalPhoneCode:  "รหัสยืนยันโทรศัพท์มือถือเดิม",
  originalPhone:  "หมายเลขโทรศัพท์มือถือเดิม",
  newPhoneNumber:  "หมายเลขโทรศัพท์มือถือใหม่",
  oldPassword:  "รหัสผ่านเก่า",
  newPassword:  "รหัสผ่านใหม่",
  confirmNewPassword:  "ยืนยันรหัสผ่านใหม่",
  oldPasswordError:  "รหัสผ่านเก่าไม่ถูกต้อง",
  pleaseOldPassword:  "กรุณากรอกรหัสผ่านเก่าของคุณ",
  pleaseNewPassword:  "กรุณากรอกรหัสผ่านใหม่",
  password:  "รหัสผ่าน",
  changePhone:  "เปลี่ยนเบอร์มือถือ",
  savePhone:  "บันทึกหมายเลขโทรศัพท์",
  phoneNumber:  "หมายเลขโทรศัพท์มือถือถูกส่งไปแล้ว",
  resend:  "ส่งซ้ำ",
  mailSending:  "อีเมลถูกส่งบ่อยเกินไป",
  enterCorrectCode:  "กรุณากรอกรหัสยืนยันให้ถูกต้อง",
  checkEmail:  "กรุณาตรวจสอบอีเมลของคุณ",
  sendingSucceeded:  "ส่งเรียบร้อยแล้ว",
  mailboxSucceeded:  "เปลี่ยนอีเมลเรียบร้อยแล้ว",
  savingSucceeded:  "บันทึกเรียบร้อยแล้ว",
  enterConfirmationPassword:  "กรุณากรอกรหัสผ่านยืนยัน",
  passwordsTwiceInconsistent:  "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน",
  passwordSuccessfully:  "รีเซ็ตรหัสผ่านเสร็จสมบูรณ์"

}
