// 帮助-Ido质押的翻译
export default {
  title: "质押",
  p1: "用户持有并质押QGT LP，为平台提供流动性，成为平台支持者的同时也可享有平台合伙人权益。每 24小时，平台投注池总利润的20%，将发放给每个质押用户。",
  p2: "该金额会以平台下注池所盈利的数字货币，即下注筹码货币来发放。",
  title1: "1）支持者分红如何运作？",
  p3: "质押池每 24 小时结算一次，前一日平台总利润的 20%将发放给所有支持者。",
  p4: "更重要的是，只需质押一种流动性LP，您就可以在平台所有加密货币中获得巨额收入：USDT、ETH、BTC、BNB、TRX等。",
  p5: "在支持者页面，您可以查看预估的每日收益和质押预计收益。",
  img1: "",
  p6: "在字段中输入数字并查看不同情况下的预计收益。",
  p7: "您质押的 流动性越多，您获得的收益就越大。",
  title2: "2）挖更多 QGT 以增加收入",
  p8: "每天平台 20% 的收益将会在所有支持者之间共享。您可以在支持者页面及个人中心查看分红明细。",
  p9: "但是，QueenG 的支持者越多，您获得的 分红就越少。通过多下注挖矿获取QGT 代币并在平台上质押流动性来提高您的收益！每天以所有可用货币拓展QGT流动性，质押获得丰厚的回报！"
}
