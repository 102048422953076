import axios from "axios";
import store from "../store.js";
import router from "@/router";
import { message } from "./message.js";
const $axios = axios.create({
  baseURL: process.env.VUE_APP_SERVICE,
  headers: {
    "Content-Type": "application/json",
  },
});

// 添加请求拦截器
$axios.interceptors.request.use(function (config) {
  if (store.state.token?.accessToken) {
    config.headers.authToken = store.state.token.accessToken
    config.headers.language = store.state.Lang == 'en' ? 'en-us' : store.state.Lang == 'zh' ? 'zh-tw' : store.state.Lang;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
// 添加响应拦截器
$axios.interceptors.response.use(
  function (response) {
    if (response.status === 200 && response?.data?.code == 0) {
      if (response.data?.count || response.data?.total) {
        return {
          total: response.data?.count || response.data?.total,
          list: response.data.data || response.data.result
        }
      } else {
        return response.data.data || response.data.result;
      }
    } else {
      switch (response?.data?.code) {
        case -1:
          message.error('未登录')
          break;
        case -2:
          message.error('权限不足')
          break;
        case -3:
          message.error('操作失败')
          break;
        case -4:
          message.error('邮箱为空')
          break;
        case -5:
          message.error('邮件业务码为空')
          break;
        case -6:
          message.error('邮件业务码无效')
          break;
        case -7:
          message.error('邮件发送过快')
          break;
        case -8:
          message.error('验证码为空')
          break;
        case -9:
          message.error('密码为空')
          break;
        case -10:
          message.error('确认密码为空')
          break;
        case -11:
          message.error('两次密码不一致')
          break;
        case -12:
          message.error('密码错误')
          break;
        case -13:
          message.error('refreshToken为空')
          break;
        case -14:
          message.error('登录已过期	')
          router.push('/')
          store.commit('logout')
          break;
        case -15:
          message.error('无效的refreshToken')
          break;
        case -16:
          message.error('签名无效')
          break;
        case -17:
          message.error('token校验失败')
          router.push('/')
          store.commit('logout')
          break;
        case -18:
          message.error('登录已过期')
          router.push('/')
          store.commit('logout')
          break;
        case -19:
          message.error('token不能为空')
          router.push('/')
          store.commit('logout')
          break;
        case -20:
          message.error('对比验证码异常')
          break;
        case -21:
          message.error('邮件发送失败')
          break;
        case -22:
          message.error('用户名为空')
          break;
        case -23:
          message.error('邮箱已注册')
          break;
        case -24:
          message.error('用户名已注册')
          break;
        case -25:
          message.error('真实姓名不能为空')
          break;
        case -26:
          message.error('游戏设置不能为空')
          break;
        case -27:
          message.error('该游戏尚未初始化配置')
          break;
        case -28:
          message.error('用户不存在')
          break;
        case -29:
          message.error('邮箱格式无效')
          break;
        case -34:
          $axios.post('/user-auth-service/games/refreshToken', { refreshToken: store.state.refreshToken }).then(res => {
            store.commit('refreshToken', res)
          })
          break;
        default:
          message.error(response.data.message)
          break;
      }
      return Promise.reject(response.data);
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  install(Vue) {
    Vue.config.globalProperties.$http = $axios;
  },
};
export const http = $axios;
