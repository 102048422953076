// 邀请的翻译
export default {
  instantly1: "Get up to",
  instantly2: "3% ",
  instantly3: "High",
  instantly4: "Earnings Commission",
  invitationCode: "Invitation code",
  copy: "Copy",
  share: "Share",
  invited: "Invited",
  commissionReturned: "Commissioned",
  commissionAccumulated: "Total Commission Earned",
  date: "Date",
  commissionRebate: "Net Profit Commission",
  bettingGoldRebate: "Betting Amount Commission",
  miningCommission: "Mining commission",
  total: "Total",
  operation: "Action",
  viewDetails: "View Details",
  goodFriends: "Friends",
  entryTime: "Settlement Time",
  howRebate: "How to Get Friend Commission",
  inviteFriends: "Invite friends",
  shareLink: "Share the link with friends, and friends log in to the platform through the link",
  friendsLogIn: "Friends log in",
  friendsToPlatform: "After logging into the platform, friends can recharge and participate in the game.",
  giveOutRewards: "Distribute rewards",
  friendsPlayGames: "When friends play games on the platform, the inviter receives commission returns",
  tip1: "1. Invite your friends to join the platform, and you can receive up to 3% commission.",
  step1: "Step 1: Enter the invite friends page and share your poster or invitation link with your friends.",
  step2: "Step 2: Friends log in to the platform through the invitation link, and you will receive commission rewards when friends participate in games.",
  step3: "Step 3: The earnings generated by friends playing games on the platform, mining earnings, and the platform's commission from friends' betting amounts will all be returned to you at a certain percentage.",
  tip2: "2. Commission rewards are settled in real-time and credited to your account wallet.",
  tip3: "3. There is no limit to the number of invitations. The more friends you invite, the more commission earnings you will receive. Each friend can enjoy commission earnings permanently. Hurry up and invite more friends!",
  scanningCode1: "Scan the code to receive",
  scanningCode2: "Up to",
  scanningCode3: "3% WCC mining reward",
  longPress: "Hold press to scan the QR code.",
  enteringThePage: "After entering the page, click on register to start playing the game immediately.",
  savePicture: "Save image.",
  rewardRules: "Reward rules",
  copySucceeded: "Copy successful.",
  hiQGT: "Hi, welcome to the FUN3.0 gaming platform",
  airdropBenefits: "",
  clickGetIt: "Register and invite friends to play games to enjoy high commission and mining rewards.",
  receive: "Start registration"
}
