// 帮助-关于的翻译
export default {
  aboutQueenG: "关于QueenG",
  p1: "QueenG是基于BSC 的 iGaming 平台，保留传统的博彩习惯的同时，完全去中心化，保证了游戏过程的公正公开，为所有用户提供独特的游戏体验，简约易用的界面及操作路径，将体验做到极致。",
  p2: "支持多种区块链钱包连接登录，资产由用户自己的钱包控制，用户收益即时结算至钱包，绝对安全可靠。",
  p3: "QueenG平台分为赌博和体育两大块，赌博为投骰子、老虎机等传统赌场游戏，体育为各类赛事赌球；前期为原创游戏，之后接入各游戏供应及合作商，在平台内合作上线。",
  major: "用户四大收益",
  tip1: "1）质押收益",
  detail1: "用户质押平台币流动性，可享受平台收益分红。",
  tip2: "2）存款收益",
  detail2: "平台提供各类farm产品、定期存款数字货币后，存款期满可获得对应年化收益。",
  tip3: "3）挖矿收益",
  detail3: "用户游戏即挖矿，游戏玩得越多，可获得平台币挖矿奖励越多。",
  tip4: "4）返佣收益",
  detail4: "用户分享邀请链接、受邀人注册并在平台上积极玩游戏，邀请人都会获得被动收入。",
 
}
