// 帮助-Qgt代币的翻译
export default {
  title:  "ภาพรวมโทเค็น QGT",
  title1:  "1) การขุด",
  p1:  "การขุดคือระบบการขุดสกุลเงินที่ขุด QGT ทุกวันโดยการวางเดิมพัน QGT เป็นโทเค็นจูงใจสำหรับการแบ่งปันหุ้นของ QueenG และสภาพแวดล้อมการเล่นเกมที่ดี โทเค็น QGT สามารถขุดได้โดยการเล่นเกมและการเดิมพัน การเดิมพันของคุณ ยิ่งคุณเดิมพันมากเท่าไหร่ก็ยิ่งมากขึ้นเท่านั้น QGT คุณขุดได้ การขุด QGT มากขึ้นหมายถึงผลตอบแทนรายวันที่มั่นคง",
  p2:  "⚠️ เพื่อลดภาระในการขุดและรับรองกระบวนการที่ถูกต้อง เงินเดิมพันขั้นต่ำในการขุด QGT คือ $1 (ในสกุลเงินใดก็ได้)",
  title2:  "2) ธุรกรรม",
  p3_1:  "แพลตฟอร์มจะค่อยๆ เปิดตัวธุรกรรมและแหล่งเงินทุนของ QGT ที่ระดับบนสุดต่างๆ เช่น: uniswap, justswap และ ApeSwap",
  p3_2:  "แพลตฟอร์มดังกล่าวจะสนับสนุนการแลกเปลี่ยน QGT โดยตรง และธุรกรรมภายในจะเป็นวิธีที่เร็วและง่ายที่สุดในการซื้อ QGT",
  p4:  "เป็นเจ้าของ QGT ผู้โชคดี หรือแลกเปลี่ยนโทเค็น QGT ของคุณเป็นสกุลเงินดิจิทัลอื่น ๆ",
  title3:  "3) การใช้งาน QGT",
  subtitle1:  "3.1) คำมั่นสัญญา",
  p5:  "โดยการวางเดิมพันสภาพคล่องของ QGT (ในโมดูลผู้สนับสนุน) ผู้ใช้สามารถเพลิดเพลินกับเงินปันผลและรับส่วนหนึ่งของผลกำไรของแพลตฟอร์ม",
  p6:  "ทุก ๆ 24 ชั่วโมง กลุ่มจำนำจะปล่อย 20% ของกำไรรวมของแพลตฟอร์มสำหรับการจ่ายเงินปันผล นั่นคือรายได้รายวันของแพลตฟอร์มในสกุลเงินต่าง ๆ จะถูกแจกจ่ายให้กับผู้ให้บริการสภาพคล่องของ QGT ในรูปแบบของเงินปันผล สภาพคล่องของ QGT ที่คุณให้คำมั่นไว้ ยิ่งมีเพศสัมพันธ์มากขึ้น – ยิ่งคุณได้รับผลกำไรจากเงินปันผลมากขึ้น! กำไรจากรายได้ทั้งหมดจากแพลตฟอร์ม!",
  subtitle2:  "3.2) การทำธุรกรรม",
  p7: "ซื้อและขายโทเค็น QGT จากการแลกเปลี่ยนชั้นนำ และคุณยังสามารถถือโทเค็น QGT เพื่อลงทุนและรับผลประโยชน์ได้ ",
  subtitle3:  "3.3) การพนัน",
  p8:  "โทเค็น QGT สามารถใช้ในทุกเกมในคาสิโน และฟังก์ชันการเดิมพันจะเหมือนกับสกุลเงินอื่น ๆ เดิมพันขั้นต่ำในเกม - 1 QGT",
  title4:  "4) การทำลาย QGT",
  p9:  "QueenG ดำเนินการทำลายโทเค็น QGT รายเดือนเพื่อสนับสนุนการพัฒนาที่ดีของเศรษฐกิจสกุลเงินและระบบนิเวศ การทำลายตามแผนจะเพิ่มมูลค่าของโทเค็นดั้งเดิมของ QGT และรักษาสมดุลระหว่างผู้ถือและนักพัฒนาเพื่อสนับสนุนรูปแบบการกระจายโทเค็นและการเติบโตอย่างต่อเนื่องของการจ่ายเงินเดิมพันสำหรับ ผู้ถือโทเค็น โทเค็นจะถูกเผาทุกเดือน"
}
