// 帮助-服务条款的翻译
export default {
  title: "Terms of Service",
  title1: "1.Introduction",
  p1_1: "These terms and conditions ('Terms') apply to the use of the current website ('Website') and its related or connected services (collectively 'Services'). You should carefully read these Terms as they contain important information about your rights and obligations when using this Website and constitute a legally binding agreement between you - our customer ('Customer') and us ('Website'). By using this Website and/or accessing the Services, you, whether you are a visitor or a registered user with an account ('Account'), agree to be bound by these Terms and any revisions that may be posted from time to time. If you do not accept these Terms, you should refrain from accessing the Services and using the Website.",
  title2: "2. General Terms",
  p2_1: "We reserve the right to revise and modify the Terms (including any documents referenced or linked below). You should regularly visit this page to review the terms and conditions. Revisions will become binding and effective immediately upon publication on this Website. If you object to any such changes, you must immediately cease using the Services. Your continued use of this Website after such publication will indicate your acceptance of the revised terms. Any bets not settled before the effective date of the amended terms will be subject to the existing terms. If QueenG's terms and conditions are updated, the platform will notify you and request your confirmation and agreement.",
  title3: "3. Your obligations",
  p3_1: "You acknowledge that when accessing the Website and using the Services, you will always:",
  p3_2: "3.1. Be at least 18 years old, or have reached the legal age for gambling activities in your applicable jurisdiction. We reserve the right to request proof of age from you at any time.",
  p3_3: "3.2. Have legal capacity to enter into binding legal agreements with us. If you lack legal capacity, you are not permitted to access this Website or use the Services.",
  p3_4: "3.3. Be aware that the right to access and use this Website and any products offered therein may be deemed illegal in certain countries. We cannot verify the legality of the Services in every jurisdiction, so it is your responsibility to ascertain whether your access and use of our Website comply with the applicable laws in your country/region, and you warrant to us that gambling is legal in your place of residence. For various legal or commercial reasons, we do not allow residents of certain jurisdictions to open or use accounts, including but not limited to:",
  p3_5: "Afghanistan, Algeria, Antigua and Barbuda, Australia, Austria, Azerbaijan, Belarus, Bahrain, Belgium, Bhutan, Brunei, Bulgaria, Canada, Central African Republic, Chad, Ivory Coast, Croatia, Cuba, Cyprus, Czech Republic, Democratic People's Republic of Korea (North Korea), Democratic Republic of the Congo, Denmark, Eritrea, Estonia, Finland, France, Georgia, Germany, Greece, Guinea-Bissau, Hungary, Iran, Iraq, Iceland, Israel, Ireland, Italy, Jordan, Kuwait, Latvia, Lebanon, Liberia, Libya, Lithuania, Luxembourg, Malta, Montenegro, Myanmar, Netherlands, Netherlands Antilles (Curaçao, Saint Martin, Bonaire, Sint Eustatius, and Aruba, Saba), Norway, Oman, Palestine, Poland, Portugal, Qatar, Republic of the Congo, Réunion, Romania, Russia, Saint Martin, Saudi Arabia, Serbia, Sierra Leone, Slovakia, Slovenia, Somalia, Spain, Sweden, Switzerland, Syrian Arab Republic, Sudan, Suriname, Trinidad and Tobago, Turkey, Ukraine, United Kingdom, United Arab Emirates, United States (and its territories, military bases, and territories, i.e., outer islands, US Virgin Islands), Vanuatu, Yemen.",
  p3_6: "Or other restricted jurisdictions as reported by us from time to time ('Restricted Jurisdictions'). By using this Website, you confirm that you are not a resident of a Restricted Jurisdiction.",
  p3_7: "3.4. Be responsible for verifying the legality of gambling in a specific jurisdiction when attempting to open an account or use this Website.",
  p3_8: "3.5. Be the authorized user of the payment method you use, and all funds in your account as well as any funds used in the Services are lawful.",
  p3_9: "3.6. Honestly pay all amounts due to us and not attempt to revoke payments already made or take any actions that may result in the revocation of such payments by third parties.",
  p3_10:  "3.7. Understand that when betting under these terms, you may lose some or all of the funds deposited into the Services, and you will be solely responsible for such loss.",
  p3_11:  "3.8. Not use any information that violates the current laws of the country/region at the time of betting.",
  p3_12:  "3.9. Not act on behalf of another party or for any commercial purpose, but solely act on your own behalf as an individual.",
  p3_13:  "3.10. Not attempt to manipulate any markets or elements in the Services in a malicious manner or in a way that adversely affects the integrity of the Services or us.",
  p3_14:  "3.11. Act in good faith at all times during the use of the Services and all bets made using the Services.",
  p3_15:  "3.12. Neither you nor (if applicable) your employees, employers, agents, or family members are registered in our membership program.",
  p3_16:  "3.13. Provide us with true, accurate, and objective data only when using QueenG, both by default and when requested by us.",
  p3_17:  "3.14. Understand the volatility of the cryptocurrency market, and accept the possibility of fluctuations in the value of your currency/crypto while you await withdrawal or while our risk department processes your withdrawal.",
  p3_18:  "3.15. You are mentally sound and not addicted to gambling. If individuals with such problems access the website, the responsibility lies solely with them.",
  p3_19:  "3.16. You agree not to use any third-party software within any segment of QueenG, nor abuse any game/features found on the platform.",
  title4: "4. Restrictions on Use",
  subtitle4_1: "4.1. You may not use the following services:",
  p4_1:  "4.1.1. If you are under 18 years of age (or below the legal age of majority as determined by the laws applicable to you), or if you are legally incapable of entering into a binding legal agreement with us, or if you act as an agent for or otherwise represent someone under 18 years of age (or below the legal age of majority as determined by the laws applicable to you);",
  p4_2:  "4.1.2. If your country/region of residence prohibits its residents or any persons within that country/region from accessing online gambling;",
  p4_3:  "4.1.3. In any way that collects the nicknames, email addresses, and/or other information of other customers (e.g., by sending spam, other types of unsolicited emails, or unauthorized framing or linking to the Services);",
  p4_4:  "4.1.4. To disrupt or excessively affect or impact the activities of other customers or the general operation of the Services;",
  p4_5:  "4.1.5. To advertise unsolicited commercial advertisements, affiliate links, and other forms of solicitation, which may be removed from the Services without prior notice;",
  p4_6:  "4.1.6. In a manner that we reasonably deem as an attempt to: (i) deceive the Services or other customers using the Services in any way; (ii) collude with any other customers using the Services to gain dishonest advantage;",
  p4_7:  "4.1.7. To scrape our odds or infringe upon any of our intellectual property;",
  p4_8:  "4.1.8. For any illegal activities;",
  p4_9:  "4.1.9. Prohibited from creating multiple accounts for the purpose of collusion, fraud, abuse of services, and/or any form of service manipulation, which may lead to account closure.",
  subtitle4_2:  "4.2. You cannot sell or transfer your account to a third party, nor acquire player accounts from third parties.",
  subtitle4_3: "4.3. If you use the Services for unauthorized purposes, we may terminate your account immediately upon written notice to you. In some cases, we may also take legal action against you.",
  subtitle4_4:  "4.4. The usage restrictions for third-party products (such as live, table games, and slot machines) are set by the product providers and are not controlled by QueenG.",
  title5:"5. Registration",
  subtitle5: "You agree that when using the Services, you will always:",
  p5_1:"5.1. We reserve the right to decide whether to accept the registration application of any applicant, without any obligation to inform the specific reasons.",
  p5_2:"5.2. Before using the service, you must personally fill out the registration form and read and accept these terms. We may require you to become a verified customer, which includes undergoing certain checks. You may need to provide valid identification and any other documents deemed necessary. This may include, but is not limited to, photo identification documents (passport, driver's license, or national identity card copies) and recent utility bills listing your name and address as proof of residence. We reserve the right to suspend betting or restrict account options for any account until the required information is received. This procedure is conducted in accordance with applicable gambling regulations and anti-money laundering laws. Additionally,",
  p5_3:"5.3. If you register via email, you must provide accurate contact information, including a valid email address ('registered email address'), and update such information in the future to keep it accurate. You are responsible for updating your contact details in your account. Failure to do so may result in your inability to receive important notifications and information related to your account from us, including changes we make to these terms. We identify and communicate with customers via the email address registered by the customer. It is the customer's responsibility to maintain a valid and unique email account, provide us with the correct email address, and notify the company of any changes to their email address. Each customer is solely responsible for maintaining the security of their registered email address to prevent any third party from using it. The company is not responsible for any damage or loss arising from or claimed to arise from the use of the registered email address for communication between the company and the customer. If you intentionally provide false or inaccurate personal information, we will suspend your account immediately upon written notice.",
  p5_4: "5.4. You may only register one account in the service. If it is found that you have registered multiple accounts with us, the accounts will be closed immediately. This includes the use of proxies, relatives, colleagues, affiliates, associates, affiliates, or representatives operated by you.",
  p5_5: "5.5. To ensure the financial value and confirm your identity, we may require you to provide additional personal information, such as your first and last name, or any third-party information provider we deem necessary. If any additional personal information is to be obtained from third-party sources, we will inform you of the data obtained.",
  p5_6: "5.6. You must keep your service password confidential. If the requested account information is provided correctly, we reserve the right to assume that you have placed bets, made deposits, and withdrawals. We recommend that you change your password regularly and do not disclose it to any third party. Protecting your password is your responsibility, and any risk, cost, and liability for failing to protect your password shall be borne by you. You may log out of the service at the end of each session. If you believe that any of your account information has been misused by a third party, or if your account has been hacked, or if your password has been discovered by a third party, you must notify us immediately. If your registered email address has been hacked, you must notify us, however, we may require you to provide additional information/documents for us to verify your identity. Once we become aware of such an event, we will suspend your account immediately. In the meantime, you are responsible for all activities on your account, including third-party access, whether or not their access is authorized by you.",
  p5_7: "5.7. You may not at any time transmit any content or other information from the service to other customers or any other party via screenshots (or similar methods), nor may you display any such information or content in frames or in any other manner different from how it is displayed when entering the service URL in a browser bar by such customers or third parties.",
  p5_8: "5.8. Upon registration, you will have the possibility to use all available currencies on the website. These will be the currencies for your deposits, withdrawals, and bets, and will be matched within the service as provided in these terms. Certain payment methods may not process all currencies. In such cases, the currency being processed and the conversion calculator available on the page will be displayed.",
  p5_9: "5.9. We are not obliged to open an account for you, our website registration page is merely an invitation for you to treat. Whether to proceed to open an account for you is entirely at our discretion, and if we refuse to open an account for you, we are not obliged to provide you with reasons for such refusal.",
  p5_10: "5.10. Upon receipt of your application, we may contact you to request additional information and/or documents to ensure compliance with our regulatory and legal obligations.",
  p5_11: "5.11. Users registered by mail need to verify their accounts (by mail). If not completed within 30 days, we will delete the account. The company reserves the right to change this deadline.",
  p5_12: "5.12. Only one account is allowed per email address, wallet, etc. If more players wish to use the same account, exceptions can be made after proving the identity of all connected account users. Correct and true names must be stated. The sole purpose of creating multiple accounts is for players to take advantage of our bonus promotions (if any), and players will not be eligible to receive any bonuses obtained using our bonus. In addition, their accounts will be frozen upon identification without further notice.",
  p5_13:"5.13. A player may only have one account. All duplicate player accounts will be deemed 'duplicates' and closed immediately. Any bonuses, bonuses, and rewards obtained from duplicate accounts queried by us may also be recovered. All requested funds you withdraw from your duplicate account will be refunded to us.",
  p5_14: "Authentication",
  p5_15: "5.14. We comply with Curacao laws, regulations, and guidance on preventing money laundering and the financing of terrorism. All suspicious transactions will be investigated. In such cases, the company is not obliged to notify the players or third parties involved in the investigation. Exceptions may be details of investigations that are ongoing or likely to occur, or details that have been or may be transmitted to supervisory authorities. In the event of suspicious activity, the company has the right, in accordance with legal or regulatory requirements, or at the request of supervisory authorities, to suspend, freeze, block, delete, or close player accounts and withhold funds.",
  p5_16: "5.15. All transactions on the website are checked to prevent money laundering and other illegal activities. By agreeing to these terms, you authorize us to conduct personal identity checks according to our requests or those of third parties (including regulatory authorities) to confirm your identity and contact details. In some cases, we may need to contact you and request necessary information to complete the check. If you fail to provide us with the required information, the company reserves the right to freeze or close your account until you provide us with the required information.",
  p5_17: "5.16. If you do not confirm that you are over 18 years of age, the company has the right to freeze your account until you prove your age. If it is proved that you are under 18 years old and you have performed certain actions on this website, the provisions of Article 16 shall apply.",
  p5_18: "5.17. If you violate these terms or we have reason to believe that you have done so, we may terminate your account with or without prior notice to you. In such cases, all your outstanding bets will be canceled, your account will be closed, and we may also confiscate and recover from your account balance any bonus amounts obtained or paid to you, as well as all bonuses, bonuses, and other rewards, which shall be confiscated in this case. Subsequently, upon your request and at our discretion, we will refund the actual currency balance of your account (if any) to you, which we verify as entirely belonging to you.",
  title6: "6. Your account",
  P6_1: "6.1. Accounts may use multiple currencies, in which case all account balances and transactions will be displayed in the currency used for the transaction.",
  P6_2: "6.2. We do not make any judgments regarding the use of the service.",
  P6_3: "6.3. If you fail to comply with these terms, or if we have reason to believe that you have not complied with these terms, or for the purpose of ensuring the integrity or fairness of the service, or if we have any other reasonable grounds to do so, we may close or suspend your account. We may not always be able to notify you in advance. If we close or suspend your account due to your failure to comply with these terms, we may cancel and/or withhold any bets and retain any funds (including deposits) in your account.",
  P6_4: "6.4. We reserve the right to close or suspend any account without further notice and without refunding all funds. However, contractual obligations that have expired will be fulfilled.",
  P6_5: "6.5. We reserve the right to refuse, limit, cancel, or restrict any bets at any time for any reason, including any bets deemed to have been placed fraudulently to circumvent our betting limits and/or our system provisions.",
  P6_6: "6.6. If any amount is mistakenly credited to your account, it remains our property, and when we discover any such error, we will notify you, and the amount will be deducted from your account.",
  P6_7: "6.7. If, for any reason, your account becomes overdrawn, you will owe us the amount overdrawn.",
  P6_8: "6.8. If you discover any errors related to your account, you must notify us immediately. If we determine, in our sole discretion, that you have been unfairly exploiting any bonuses or engaging in any other malicious behavior related to bonuses offered on the website, or promotional activities, we reserve the right to block or terminate your account, in which case we are not obligated to refund any funds that may exist in your account.",
  P6_9: "6.9. Please remember that betting is purely for entertainment and amusement, and once it ceases to be fun, you should stop. Never gamble anything you cannot afford to lose. If you feel you may be losing control over gambling, we offer self-exclusion options. Simply send a message to our customer support department using the registered email address you wish to self-exclude from, and this request will take effect within 24 hours of receipt. In this case, your account will be disabled until further notice, and you will be unable to log in. You can also use the time reminder feature to help you control the time spent in active gaming sessions. Time Alert will allow you to set reminders about the amount of time spent in active gaming sessions and will alert you when your allocated game time ends. Responsible gambling and gambling notifications for more information.",
  P6_10: "6.10. You cannot transfer, sell, or Stake your account to another person. This prohibition includes transferring any assets of value, including but not limited to account ownership, bonuses, deposits, bets, rights, and/or claims related to these assets, whether legal, commercial, or otherwise. The prohibition on the transfer also includes but is not limited to the transfer of ownership burdens, Stakes, transfers, use rights, transactions, brokerage, mortgages, and/or gifts to trustees or any other third parties, companies, natural persons, or legal entities, foundations, and/or any form or form of association.",
  P6_11: "6.11. If you wish to close your account with us, please send an email to our customer support department from your registered email address via the link on the website.",
  P6_12: "6.12. QueenG will keep betting history data for no more than 30 days.",
  P6_13: "6.13. When users request to close their accounts, they accept the fact that their accounts will never be unlocked. It is advisable to withdraw all funds before closing the account.",
  title7: "7. Inactive accounts",
  P7_1: "7.1. QueenG will not block or suspend inactive accounts (accounts that have not been used for a long time).",
  P7_2: "7.2. You will receive your Staking payouts during all inactive periods, and your previous balance and bonuses will be preserved whenever you resume using our platform.",
  title8: "8. Deposit of funds",
  P8_1: "8.1. The platform has a minimum deposit amount for each currency. Sending an amount lower than the minimum will result in the loss of funds and will not be refunded. There is no limit on the maximum deposit amount. QueenG does not charge any deposit fees. Some deposits may require some time associated with network hash rates.",
  P8_2: "8.2. Fees and charges may apply to customer deposits and withdrawals, which can be found on the website. In most cases, we will charge transaction fees for deposits to your QueenG.io account. You are responsible for any wallet fees that may occur when depositing funds to us.",
  P8_3: "8.3. You agree to fully pay any and all amounts and fees owed to us or payment providers in connection with your use of the service. You further agree not to request any refunds or waive, cancel, or otherwise revoke any of your deposits, and in any such event, you will reimburse and indemnify us for any deposits not paid, including any fees incurred during the collection of your deposit, and you agree to forfeit any winnings from bets made using these refunded funds.",
  P8_4: "8.4. Funds from criminal and/or illegal and/or unauthorized activities shall not be deposited into this website.",
  P8_5: "8.5. We reserve the right to confiscate any positive balance on your account if it is related to actions such as resetting bets or belonging to duplicate accounts, conspiracy, fraud, criminal activities, or forgery.",
  P8_6: "8.6. We do not provide refunds for deposits made on QueenG.io unless otherwise specified herein. By depositing funds, you agree not to request any refunds, cancellations, reversals, or other cancellations of any deposits to your account and agree to refund and indemnify us for any deposits unpaid.",
  title9: "9. Withdrawals",
  P9_1: "9.1. Due to the workflow of our anti-fraud system, the waiting time for your withdrawal may be extended to execute all necessary procedures to provide users with a good level of financial security (up to seven (7) days, and potentially longer if needed).",
  P9_2: "9.2. Please note that we cannot guarantee successful processing of withdrawals or refunds if you violate the rules specified in our terms and conditions.",
  P9_3: "9.3. The responsibility for withdrawing funds lies solely with the user, and if the receiving wallet/platform does not accept transactions from smart contracts, any specific coins, or if the user selects the wrong transfer chain, it is at their own risk.",
  P9_4: "9.4. We reserve the right to change withdrawal fees at any time without prior notice.",
  P9_5: "9.5. Processing BFG token withdrawals may take up to 24 hours, and in some cases may be delayed further. QueenG reserves the right to hold BFG withdrawals for verification of their source at any time. QueenG reserves the right to restrict services, deposits, or withdrawals until an investigation is fully determined, or for any other reason at the discretion of QueenG's risk department. QueenG reserves the right to limit transaction counts for a period of time without prior notice.",
  P9_6: "9.6. If your winnings are $50,000 or higher, QueenG reserves the right to pay out an equivalent amount of winnings within up to 30 days in special circumstances.",
  P9_7:"9.7. Regardless of the amount you win in any bet or spin (including any bonus rounds or free spins, as well as free spins purchased on our platform), the amount we are obligated to pay you shall not exceed the equivalent of $300,000 USD in any cryptocurrency, fiat currency, or any other currency ('maximum win limit'). You acknowledge that you understand that certain games have the technical ability to win amounts exceeding the maximum win limit, but you agree that we have the right to deduct any amount over the maximum win limit from your account and only pay you up to the maximum win limit. We reserve this right for any winnings exceeding the maximum win limit. We may determine to reduce the amount to the maximum win limit based on your game history, betting level and account, and other information we analyze.",
  P9_8: "Additionally, the total winnings amount (including amounts exceeding the maximum win limit) may be credited to your account, but the amount paid will not exceed the maximum payout limit. In any case, we reserve the right to make any decision regarding payments exceeding the maximum win limit.",
  P9_9: "QueenG reserves the right to pay out the maximum win limit within up to 90 days.",
  P9_10: "9.8. You acknowledge that if we decide to reduce payouts to the maximum win limit, we will send you an appropriate letter. In this case, upon receipt of the letter, you will not make any claims against the platform, as accepting these terms has confirmed your understanding of the implementation of the maximum win limit on the QueenG platform. You acknowledge that the enforcement of the maximum win limit is the right of QueenG, and does not in any way infringe upon the rights of users of the platform. Any claims made by users after receiving the relevant deduction letter will be considered evidence of users abusing their rights to unreasonably obtain additional funds and may be considered extortion.",
  title10: "10. Payment transactions and processors",
  P10_1:"10.1. You are responsible for paying all amounts owed to us. You must sincerely pay all amounts owed to us, and must not attempt to revoke any payments already made or take any actions that would cause third parties to revoke such payments, to avoid legitimate liabilities. You will indemnify us for any chargebacks, refusals, or revocations of payments made by you, as well as any losses suffered by us as a result thereof.",
  P10_2:"10.2. Before making a deposit, users must ensure the correctness of the token chain they are depositing to and meet the minimum deposit amount, to ensure that the deposit will be credited and reflected in the balance. If there is an error in the deposit/withdrawal, the responsibility lies solely with the user.",
  P10_3:"10.3. All transactions conducted on our website may be checked to prevent money laundering or terrorist financing activities. Suspicious transactions will be reported to the relevant authorities.",
  title11:"11. Errors",
  P11_1:"11.1. If there is an error or malfunction in our system or processes, all bets are void. Once you discover any errors in the service, you are obligated to notify us immediately. In the event of any communication, system errors, glitches, or viruses related to the service or payments made to you due to service defects or errors, we shall not be liable to you or any third party for any direct or indirect costs, expenses, losses, or claims. We reserve the right to cancel all related games/bets and take any other actions to rectify such errors.",
  P11_2:"11.2. We make every effort to ensure that we do not make mistakes when posting betting company lines. However, if bets are accepted due to human error or system issues, the odds are as follows: significant differences from the odds generally available on the market at the time of betting; or are clearly incorrect, as events may have occurred differently at the time of betting. In such cases, we reserve the right to cancel or void the bet or bets placed after the start of the event.",
  P11_3:"11.3. We have the right to recover any overpaid amounts from you and adjust your account to correct any errors. An example of such errors could be incorrect prices or incorrect event results entered by us. If your account funds are insufficient, we may require you to pay us any outstanding amounts related to any erroneous bets or bets. Therefore, we reserve the right to cancel, reduce, or delete any pending plays, regardless of whether funds were placed due to errors.",
  title_12: "12. Sports betting rules, refunds and cancellations",
  P12_1:"12.1. The winner of the match will be determined on the settlement date of the event, and we will not entertain any protests or overturn decisions made for betting purposes.",
  P12_2:"12.2. In cases of betting or odds calculation errors (due to human error, system malfunction, or errors from reference result sources), we will reset/correct the results.",
  P12_3:"12.3. If the match result is overturned by the governing body within the payment period for any reason, all payments will be refunded.",
  P12_4:"12.4. In games where a draw option is provided, all bets on team wins/losses will be lost if a draw occurs. If no draw option is provided, everyone will receive a refund in the event of a match draw. Overtime, if applicable, will be included.",
  P12_5:"12.5. The minimum and maximum bet amounts for all matches will be determined by us, and any changes will not be communicated separately in writing. We also reserve the right to adjust individual account limits.",
  P12_6:"12.6. Customers bear full responsibility for their account transactions. Once a transaction is completed, it cannot be changed. We do not accept any responsibility for customer bets lost or repeated, and we will not entertain requests for differences due to lost or repeated bets. Customers can review their transactions in the 'My Bets' section on the website after each session to ensure that all requested bets have been accepted.",
  P12_7:"12.7. Matches will be considered valid as long as the two teams are correct, regardless of how they are titled on our website.",
  P12_8:"12.8. The start date and time of esports matches displayed on the website are for reference only and are not guaranteed to be correct. If a match is suspended or delayed and not resumed within 72 hours after the actual scheduled start time, the match will be considered void, and bets will be refunded. Any bets regarding whether a team/player advances or wins a championship will remain valid regardless of match suspension or delay.",
  P12_9:"12.9. Official scoring providers or statistics from the respective match or competition's official website will be used to calculate bets. If the statistics from the official scoring provider or official website are unavailable or there are clear indications that they are incorrect, we will wait for the official results to calculate the betting lines. QueenG reserves the right to recalculate bets if there are changes to the results after the end of the match or if there were errors in the calculations before.",
  P12_10:"12.10. If the event date published by us is incorrect, all bets will be handled based on the date announced by the governing body.",
  P12_11:"12.11. If a team uses stand-ins, the results will still be valid as using stand-ins is the team's choice.",
  P12_12:"12.12. The company reserves the right to remove activities, markets, and any other products from the website.",
  P12_13:"12.13. A detailed explanation of our sports betting rules can be found on a separate page: Sports Betting Rules.",
  title13:"13. Communication and Notices",
  P13_1:"13.1. All communications and notices you send to us under these terms should be sent using the customer support form on the website.",
  P13_2:"13.2. Unless otherwise specified in these terms, all communications and notices we send to you under these terms shall be posted on the website and/or sent to the registered email address we hold for the relevant customer on our system. The method of such communication shall be determined by us.",
  P13_3:"13.3. All communications and notices sent by you or us under these terms shall be in written English and must be sent or delivered through the registered email address in your account.",
  P13_4:"13.4. We may contact you from time to time via email to provide you with information about betting, unique promotional offers, and other information from QueenG.io. By agreeing to these terms when you register on the website, you consent to receive such emails. You can opt out of receiving such promotional materials from us at any time by changing your account settings.",
  title14:"14. Matters Beyond Our Control",
  P14_1: "Despite our reasonable preventive measures, such as natural disasters; trade or labor disputes; power outages; actions or inactions of any government or authority; telecommunications service interruptions or failures; or any other delays or failures caused by third parties, we shall not be liable for any losses or damages you may suffer. In such circumstances, we reserve the right to cancel or suspend services, without assuming any liability.",
  title15:"15. Liability",
  P15_1: "15.1. To the extent permitted by applicable law, we shall not be liable to you for any reasonably foreseeable losses or damages (direct or indirect) you may suffer if we fail to fulfill our obligations under these terms, unless we breach any obligations imposed on us by law, including if we are negligent and cause death or personal injury. In such cases, if the failure is attributable to: (I) your own fault; (II) third parties unrelated to our performance of these terms (such as issues arising from communication network performance, congestion and connectivity, or the performance of your computer equipment); or (III) any other events that we or they could not foresee or prevent even if we or our suppliers took reasonable precautions.",
  P15_2: "15.2. We strongly advise you to: (I) Ensure the suitability and compatibility of the services with your own computer equipment before use; and (II) Take reasonable precautions to protect yourself from harmful programs or devices, including by installing antivirus software.",
  title16: "16. Underage gambling",
  P16_1: "16.1. If we suspect that you are currently under the age of 18 or below the age of 18 (or below the legal age applicable to you in your jurisdiction), your account will be suspended (locked) to prevent you from further betting or withdrawing from your account when you make any bets through this service. We will then investigate the matter, including whether you are betting on behalf of or representing persons under the age of 18 (or below the legal age applicable to you in your jurisdiction). If we find that you: (a) currently; (b) are under the age of 18 or below the legal age applicable to you at the relevant time; (c) are betting as an agent for persons under the age of 18 or below the applicable legal age: all winnings currently or to be credited to your account will be retained; all winnings obtained through betting via the service while underage must be paid to us as required (if you fail to comply with this provision, we will seek to recover all costs associated with recovering such amounts); and/or any non-winnings deposited into your account will be either returned to you or retained by us until you reach the age of 18. We reserve the right to deduct transaction fees from refund amounts, including transaction fees for deposits into your QueenG.io account insured by us.",
  P16_2: "16.2. If you are over the age of 18 but you are betting in a jurisdiction where the designated legal betting age is higher than 18, and you are below the legal minimum age in that jurisdiction, then this condition also applies to you.",
  P16_3: "16.3. If we suspect that you have violated the provisions of this clause or attempted to rely on them for fraud, we reserve the right to take any necessary action to investigate the matter, including notifying the relevant law enforcement agencies.",
  title17: "17. Anti-fraud policy",
  P17_1: "17.1. QueenG prohibits and refuses to use the service for any form of illegal activity, including money laundering, terrorism financing, or violation of trade sanctions. Accounts suspected of being used for money laundering may be frozen, and transactions may be suspended until the risk department completes all necessary procedures. According to QueenG's AML policy, all cryptocurrency deposits must meet basic turnover requirements. Turnover means that all users must wager 100% of the upcoming deposit for gaming bets or 300% for sports bets before withdrawals can be approved. This means that if your deposit is $300 worth of ETH, for example, you should wager at least $300 in ETH (for gaming activities) or at least $900 in ETH (for sports betting) before withdrawals can be approved. This rule applies to the cryptocurrency balance of each user. The QueenG risk department is entitled to block every suspicious transaction to prevent money laundering. User accounts may be investigated by the risk department until all requirements are met, and payments under investigation will be suspended.",
  P17_2:"17.2. Know Your Customer (KYC) QueenG reserves the right to request any KYC documents at any time when necessary. QueenG reserves the right to restrict services, deposits, or withdrawals until the identity is fully verified, or for any other reasons determined by the QueenG risk department. QueenG defines a 7-day initial response as qualifying in the KYC process. The entire process should be completed within 30 days after the risk department's initial response via email to risk{'@'}QueenG.io. If there is suspicion of fraud, and if the user refuses to provide the information requested by the risk department or the qualifying timeline has passed, QueenG reserves the right to permanently close the user's account access.",
  P17_3: "17.3. We will seek criminal and contractual sanctions against any customer involved in fraud, dishonesty, or criminal behavior. In case of any suspicion, we will withhold any funds from the customer. Customers shall identify and be responsible for paying us all costs, expenses, or losses incurred or suffered by us (including any direct, indirect, or consequential losses, loss of profits, loss of business, and loss of reputation) directly or indirectly caused by the customer's fraud, dishonesty, or criminal behavior.",
  title18: "18. Intellectual property rights",
  P18_1: "18.1. Any unauthorized use of our name and logo may result in legal action against you.",
  P18_2: "18.2. Between us and you, we are the sole owners of the rights to the service, our technology, software, and business systems ('Systems'), as well as our odds. You may not use your profile for your own commercial interests (such as selling your status updates to advertisers); when choosing a nickname for your account, we reserve the right to delete or revoke it if we deem it appropriate.",
  P18_3:"18.3. You may not use our URLs, trademarks, trade names, and/or commercial appearance, logos ('Marks'), and/or our odds for any products or services that do not belong to us in any way that may confuse customers or disparage us in the public or in any way.",
  P18_4: "18.4. Unless expressly provided in these terms, we and our licensors do not grant you any express or implied rights, licenses, ownership, or interests in the Systems or Marks, and by us and our licensors. You agree not to use any automatic or manual devices to monitor or replicate web pages or content in the service. Any unauthorized use or reproduction may result in legal action against you.",
  title19: "19. Your license",
  P19_1: "19.1. Subject to compliance with these terms and your compliance with these terms, we grant you a non-exclusive, limited, non-transferable, and non-sublicensable license to access and use the Service solely for your personal non-commercial purposes. If we terminate the agreement with you under these terms, your license from us will terminate.",
  P19_2: "19.2. Except for your own content, you may not modify, publish, transmit, transfer, sell, reproduce, upload, post, distribute, perform, display, create derivative works, or otherwise exploit the Service and/or any content therein or the software contained therein in any manner unless expressly permitted in these terms or on the website. You may not modify or alter any information or content on the service or provided to you in connection with the service, merge with other data, or publish in any form, including, for example, screen or database scraping, intended to collect, store, reorganize, or manipulate such information or content.",
  P19_3: "19.3. Your non-compliance with these terms may also infringe upon our or third-party intellectual property and other rights, which may subject you to civil and/or criminal liability.",
  title20: "20. Your Conduct and Security",
  P20_1:"20.1. To protect and safeguard all our customers, it is strictly prohibited to post any content on the Service and engage in any illegal, inappropriate, or unwelcome behavior related to the Service and/or Service ('Prohibited Conduct').",
  P20_2: "20.2. If you engage in Prohibited Conduct, or if we determine in our sole discretion that you have engaged in Prohibited Conduct, your account and/or your access to or use of the Service may be terminated immediately without further notice. Other customers, third parties, law enforcement agencies, and/or we may take legal action against you for your prohibited behavior.",
  P20_3: "20.3. Prohibited Conduct includes but is not limited to accessing or using the Service to:",
  P20_4: "Promote or share information that you know to be false, misleading, or illegal;",
  P20_5: "Engage in any illegal or unlawful activities, such as but not limited to facilitating or promoting any criminal activities or enterprises, infringing on the privacy or other rights of other customers or any other third party, or engaging in any activities involving the creation or dissemination of computer viruses;",
  P20_6: "Harm minors in any way;",
  P20_7: "Transmit or provide any content that is illegal, harmful, threatening, abusive, harassing, defamatory, misleading, vulgar, obscene, lewd, violent, hateful, or racially or ethnically offensive;",
  P20_8: "Transmit or provide any content that you are not authorized to provide under any law, contract, or fiduciary relationship, including but not limited to any content that infringes on third-party copyrights, trademarks, or other intellectual property rights and ownership;",
  P20_9: "Transmit or provide any content or materials containing any software viruses or other computer or programming code (including HTML) designed to interrupt, destroy, or alter the functionality of the Service, its demonstration, or any other website, computer software, or hardware;",
  P20_10: "Interfere with, disrupt, or reverse engineer the Service in any way, including but not limited to intercepting, simulating, or redirecting the communication protocols we use, creating or using cheating, modifying, or hacking software designed to modify the Service, or using any software to intercept or collect information from or through the Service;",
  P20_11: "Use any robot or other automated mechanism to retrieve or index any information from the Service;",
  P20_12: "Engage in any activities or actions, determined solely by us and entirely in our discretion, that result in or may result in other customers being defrauded or deceived;",
  P20_13: "Transmit or provide any unsolicited or unauthorized advertising or spam, such as but not limited to junk mail, instant messages, 'spam' chain letters, pyramid schemes, or other forms of solicitation;",
  P20_14: "Create accounts on the website in an automated manner or under false or fraudulent pretenses;",
  P20_15: "Impersonating other customers or any other third party;",
  P20_16:"Exploit cheating and unfair advantages, including system malfunctions and errors, as well as using robots (automated players) or exploiting 'errors'",
  P20_17: "Engage in criminal activities, including money laundering and other criminal intrusions.",
  P20_18:"20.4. If you discover or suspect players cheating, colluding, or engaging in fraudulent activities, please notify our representative immediately. The company reserves the right to terminate games or activities in each of the above situations as well as in any other situation to prevent fraud.",
  p20_19:"20.5. The above list of prohibited behaviors is not exhaustive, and we may modify it at any time or from time to time. We reserve the right to investigate and take all such actions as we, in our sole discretion, deem appropriate or necessary in such circumstances, including but not limited to removing posts from the service and/or terminating the account of any customer, and taking action against any customer or third party, whether or not notified, who directly or indirectly participates or knowingly allows any third party to participate in prohibited behavior, directly or indirectly.",
  title21: "21. Links to Other Websites",
  P21_1:"The service may contain links to third-party websites that are not maintained or affiliated with us, and over which we have no control. Such links to these websites are provided solely for the convenience of customers, and we do not investigate, monitor, or check their accuracy or completeness. The inclusion of links to such websites does not imply any endorsement by us of the linked websites or their content or their owners, nor any affiliation therewith. We cannot control or accept any responsibility for the availability and accuracy, completeness, accessibility, and usefulness thereof. Therefore, when accessing such websites, we recommend that you take usual precautions when accessing new websites, including reviewing their privacy policies and terms of use.",
  title22: "22. Complaints",
  P22_1: "22.1. If you have any concerns or questions regarding these terms, you should contact our customer service department through the link on the website and use your registered email address for all communications with us. If a user has a complaint, you should send an email regarding the dispute to our customer service team at support{'@'}QueenG.io.",
  P22_2:"22.2. Despite the provisions above, we do not assume any responsibility towards you or any third party in responding to any complaints we receive or taking action on them.",
  P22_3: "22.3. If a customer is dissatisfied with the settlement of a bet, the customer should provide detailed information about their complaint to our customer service department. We will make reasonable efforts to respond to such inquiries within a few days (in any event, we intend to respond to all such inquiries within 28 days of receipt).",
  P22_4: "22.4. Disputes must be raised within fourteen (14) days from the date of the decision regarding the bet. Claims will not be honored after this deadline. Customers are responsible for all transactions on their accounts.",
  P22_5: "22.5. If a dispute arises between you and us, our customer service department will attempt to reach a mutually acceptable solution. If our customer service department cannot reach a mutually acceptable solution with you, the matter will be escalated to our management.",
  P22_6: "22.6. If all efforts to resolve a dispute to the satisfaction of the customer fail, the customer has the right to lodge a complaint with our licensing authority, Gaming Services Provider NV.",
  title23: "23. Assignment",
  P23_1: "Without our prior written consent, you may not assign these terms or any rights or obligations under this agreement, and such consent will not be unreasonably withheld. We may, without your consent, assign all or any part of our rights and obligations under this agreement to any third party, provided that such third party is capable of providing services substantially similar in quality by posting written notice on the service.",
  title24: "24. Severability",
  P24_1: "If any competent authority deems any provision of these terms unenforceable or invalid, the relevant provision shall be modified to make it enforceable to the maximum extent permitted by applicable law in accordance with the original intent. The validity and enforceability of the remaining provisions of these terms shall not be affected.",
  title25: "25. Breach of These Terms",
  P25_1: "25.1. Without limiting our other remedies, if we reasonably believe that you have breached any material term of these terms, we may suspend or terminate your account and refuse to continue providing services to you without prior notice in any case. However, notice will be promptly provided to you regarding any such actions taken.",
  P25_2: "25.2. You agree to fully indemnify, defend, and hold harmless QueenG and its shareholders, directors, agents, and employees from and against all claims, demands, liabilities, damages, losses, costs, and expenses, including legal fees and any other expenses, resulting from: (i) your full or partial breach of this agreement; (ii) your violation of any law or any third-party rights; (iii) your use of the services.",
  title26: "26. General provisions",
  P26_1: "26.1. Term of Agreement. These terms shall remain fully effective as long as you access or use the services or are a customer or visitor to the website. These terms shall continue to be effective even after termination of your account for any reason.",
  P26_2: "26.2. Gender. Words importing the singular include the plural and vice versa, words importing the masculine gender include the feminine and neuter genders and vice versa, and words importing persons include individuals, partnerships, associations, trusts, unincorporated organizations, and corporations.",
  P26_3: "26.3. Waiver. Our waiver of any provision of these terms or conditions or any breach or threatened breach thereof, whether by conduct or otherwise, shall not be effective or binding upon us unless made in writing and signed by us, and shall be limited to the specific breach waived unless otherwise provided in the written waiver. Our failure at any time to enforce any provision of these terms shall not be construed as a waiver of such provision or our right to enforce such provision at any other time.",
  P26_4: "26.4. Acknowledgment. Your subsequent access or use of the services shall constitute your acknowledgment that you have read, understood, and agreed to each and every provision of these terms. Accordingly, you hereby irrevocably waive any future dispute, claim, demand, or action contrary to anything in these terms.",
  P26_5: "26.5. Language. If there are discrepancies between the English version of these rules and any other language version, the English version shall prevail.",
  P26_6: "26.6. Governing Law. These terms shall be governed by the laws of Curaçao, and you unconditionally and irrevocably submit to the exclusive jurisdiction of the courts of Curaçao to settle any disputes arising out of or in connection with the creation, validity, effect, interpretation, or performance of, or the legal relationships established by, these terms or any other matter arising from or in connection with these terms, and you irrevocably waive any objection which you may have at any time to the laying of venue of any proceedings in such courts, or any claim that any such proceedings have been brought in an inconvenient forum, or that such courts do not have jurisdiction. Nothing in these terms shall limit the right of QueenG to take proceedings against you in any other court of competent jurisdiction.",
  P26_7: "26.7. Entire Agreement. These terms constitute the entire agreement between you and us regarding your access to and use of the services, and supersede all prior agreements and communications, whether oral or written, concerning the subject matter of this agreement.",
}
