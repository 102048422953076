// 个人中心的翻译
export default {
  myGame:  "내 게임",
  totalWin:  "총 승리",
  totalBets:  "총 베팅 수",
  betsToday:  "오늘의 베팅 횟수",
  betsWeek:  "이번 주 베팅 횟수",
  myBonus:  "나의 배당금",
  pledgeDividends:  "배당 공약",
  providePOOL:  "POOL 유동성을 제공하고, 파트너가 되어 각 상금풀의 배당금을 누려보세요",
  dividendYesterday:  "어제 배당금",
  accumulatedDividends:  "누적 배당금",
  myMining:  "나의 채굴",
  gameMining:  "게임 마이닝",
  participate:  "게임을 플레이하고 매일 채굴에 참여하며 투표권을 갖습니다",
  miningYesterday:  "어제의 채굴",
  cumulativeExcavation:  "누적 발굴",
  miningYesterdayChu:  "어제 파냈어요",
  nearly:  "지난 7일 동안의 채굴",
  totalMining:  "총 채굴",
  settlementDate:  "결제 날짜",
  gameAmount:  "오늘의 게임 금액",
  numberGames:  "게임 수",
  miningShare:  "채굴 공유",
  miningIncome:  "채굴 이익",
  details:  "xx 세부정보",
  return:  "반품",
  viewDetails:  "세부 정보보기",
  yesterdayEarnings:  "어제 수입",
  earningsRecentDays:  "지난 7일 동안의 수입",
  totalIncome:  "총 수익",
  settlementLP:  "정착 LP",
  dividends:  "피제수",
  operation:  "작동하다",

}
