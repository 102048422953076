// 三个游戏及我的投注的翻译
export default {
  pledge: "Depósito",
  yawan:  "Oshe",
  bettingAmount:  "valor da aposta",
  highestWin:  "Maior vitória",
  castOnce:  "Vote 1 vez",
  castFive:  "Vote 5 vezes",
  excavatedOnce:  "Espera-se que um investimento resulte em mineração",
  bigWinner:  "grande vencedor",
  mostFortunate:  "o mais sortudo",
  rulesGame:  "regras do jogo",
  player:  "jogador",
  betting:  "aposta",
  multiple:  "múltiplo",
  bonus:  "bônus",
  placeBet:  "fazer uma aposta",
  result:  "resultado",
  earnings:  "renda",
  bettingTime:  "Tempo de Liquidação",
  bettingDirection:  "Direção de apostas",
  aboutToBegin:  "Prestes a começar",
  excavatedTurningOnce:  "Estima-se que você possa desenterrá-lo depois de transferi-lo",
  drawTheLottery:  "Sorteio de loteria!",
  latestPrice:  "Último preço",
  realTime:  "tempo real",
  oneMinute:  "1 minuto",
  fifteenMinutes:  "15 minutos",
  guessTheRise:  "adivinhe a ascensão",
  guessTheFall:  "adivinhe a queda",
  bettingPrice:  "preço de aposta",
  settlementInProgress:  "Liquidação em andamento",
  game:  "jogo",
  return:  "retornar",
  theRise:  "ascender",
  theFall:  "cair",
  openNote:  "aposta aberta",
  selected:  "escolhido",
  pleaseLoginFirst:  "por favor faça login primeiro",
  enterCorrectAmount:  "Por favor, insira o valor correto da aposta",
  creditLow:  "Saldo insuficiente",
  maximumWorthTokens:  "A aposta máxima é de 100.000 tokens de valor USDT",
  congratulationsWinning:  "Parabéns pela vitória",
  winningLottery:  "Parabéns pela vitória",
  didnnotWinPrize:  "Desculpe, não ganhei",
}
