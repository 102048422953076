import axios from "axios";
import store from "../store.js";
import { message } from "./message.js";

const $axios = axios.create({
  baseURL: process.env.VUE_APP_SERVICE,
  headers: {
    "Content-Type": "application/json",
  },
});

// 添加请求拦截器
$axios.interceptors.request.use(function (config) {
  if (store.state.token?.accessToken) {
    config.headers.authToken = store.state.token.accessToken
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
// 添加响应拦截器
$axios.interceptors.response.use(
  function (response) {
    if (response.status === 200 && response?.data?.code == 0) {
      if (response.data?.count || response.data?.total) {
        return {
          total: response.data?.count || response.data?.total,
          list: response.data.data || response.data.result
        }
      } else {
        return response.data.data || response.data.result;
      }
    } else {
      if (response?.data?.ip) {
        return response.data;
      }
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default $axios
