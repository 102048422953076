// 帮助-隐私政策的翻译
export default {
  title:  "Chính sách bảo mật",
  p1:  "Chúng tôi có quyền thay đổi Chính sách quyền riêng tư này bất cứ lúc nào và bạn sẽ được cập nhật kịp thời. Nếu bạn muốn đảm bảo rằng mình biết về những thay đổi mới nhất, chúng tôi khuyên bạn nên truy cập trang này thường xuyên.",
  p2:  "Nếu bạn có thêm câu hỏi hoặc cần thêm thông tin về chính sách quyền riêng tư của chúng tôi, vui lòng liên hệ với chúng tôi.",
  p3:  "Chính sách quyền riêng tư này chỉ áp dụng cho các hoạt động trực tuyến của chúng tôi và có hiệu lực đối với khách truy cập trang web của chúng tôi liên quan đến thông tin họ đã chia sẻ và/hoặc thu thập trong QueenG. Chính sách quyền riêng tư này không áp dụng cho bất kỳ thông tin nào được thu thập ngoại tuyến hoặc thông qua các kênh khác ngoài kênh này.' trang mạng." ,
  p4_h:  "đồng ý",
  p5:  "Chúng tôi yêu cầu bạn đồng ý xử lý thông tin của bạn cho các mục đích cụ thể và bạn có quyền rút lại sự đồng ý của mình bất cứ lúc nào. Tuy nhiên, bằng cách sử dụng trang web của chúng tôi, bạn đồng ý với chính sách quyền riêng tư của chúng tôi và đồng ý với các điều khoản của nó." ,
  p6_h:  "Chúng tôi thu thập dữ liệu người dùng nào",
  p7:  "Dữ liệu cá nhân' sẽ được hiểu là bất kỳ thông tin nào liên quan đến một người được nhận dạng hoặc có thể nhận dạng; một thể nhân có thể nhận dạng là người có thể được nhận dạng, trực tiếp hoặc gián tiếp, đặc biệt bằng cách tham chiếu đến thông tin như tên, dữ liệu vị trí, email, số nhận dạng trực tuyến hoặc một số nhận dạng cụ thể cho một hoặc nhiều yếu tố cụ thể về bản sắc thể chất, sinh lý, di truyền, tinh thần, kinh tế, văn hóa hoặc xã hội của cá nhân đó.",
  p8:  "Chúng tôi có thể xử lý dữ liệu cho phép chúng tôi liên hệ với bạn ('Dữ liệu liên hệ'). Dữ liệu liên hệ có thể bao gồm tên, địa chỉ email của bạn. Nguồn Dữ liệu liên hệ là biểu mẫu đăng ký, biểu mẫu liên hệ của chúng tôi và những thông tin được cung cấp trong Thông tin tài khoản của bạn.",
  p9:  "Chúng tôi có thể xử lý dữ liệu tài khoản người dùng Trang web của bạn ('Dữ liệu tài khoản'). Dữ liệu tài khoản có thể bao gồm số nhận dạng tài khoản, tên, địa chỉ email, ngày tạo và sửa đổi tài khoản, cài đặt Trang web. Nguồn dữ liệu tài khoản chính là bạn;Một số yếu tố của dữ liệu tài khoản có thể được tạo bởi trang web của chúng tôi.",
  p10:  "Chúng tôi có thể xử lý dữ liệu kỹ thuật và tài chính, dữ liệu sử dụng'. Điều này có nghĩa là khi bạn truy cập trang web của chúng tôi và/hoặc sử dụng dịch vụ của chúng tôi, chúng tôi có thể thu thập thông tin kỹ thuật và tài chính về bạn, có thể bao gồm: địa chỉ IP, ngôn ngữ, ngày đăng ký, ngày đăng nhập, các chi nhánh được liên kết, thông tin thống kê liên quan đến chi nhánh (nguồn lưu lượng truy cập của bạn vào trang web của chúng tôi, dữ liệu về lượt xem và số lần nhấp vào tài liệu quảng cáo của chúng tôi), ngày và Khoảng thời gian tự loại trừ, ngày và thời gian ngừng hoạt động, số lượng và thời gian không thành công số lần đăng nhập, số dư tài khoản, tiền thưởng tài khoản, tiền thật của tài khoản, ngày giao dịch, giá trị giao dịch, số nhận dạng giao dịch, tổng tiền thưởng, tổng tiền thưởng, tổng tiền gửi, tổng tiền gửi, số lần rút Tổng số tiền rút, tổng số tiền rút, số tiền cần rút, số số lần rút tiền được thực hiện, số tiền rút sai, số lần rút sai, tỷ lệ gửi/rút tiền, tổng thu nhập,",
  p11:  "Chúng tôi có thể xử lý thông tin có trong hoặc liên quan đến bất kỳ thông tin liên lạc nào mà bạn gửi cho chúng tôi hoặc chúng tôi gửi cho bạn ('dữ liệu liên lạc'). Dữ liệu liên lạc có thể bao gồm nội dung của thông tin liên lạc và siêu dữ liệu liên quan đến thông tin liên lạc. Chúng tôi có thể tạo siêu dữ liệu liên quan đến thông tin liên lạc được thực hiện bằng biểu mẫu liên hệ.",
  p12_h:  "Lý do chúng tôi thu thập dữ liệu của bạn",
  p13:  "QueenG sẽ chỉ thu thập thông tin cá nhân của bạn bằng các phương tiện hợp pháp và công bằng chứ không phải theo cách xâm phạm để vận hành hoạt động kinh doanh của mình với tư cách là nhà cái cá cược trực tuyến được cấp phép cho các mục đích sau:",
  p14:  "Để xác thực Biết khách hàng của bạn (KYC).",
  p15:  "Nếu bạn muốn đăng ký nhận thông tin tiếp thị của QueenG.",
  p16:  "Xử lý đơn đăng ký của bạn để trở thành thành viên của QueenG hoặc bất kỳ tên miền phụ được lưu trữ nào.",
  p17:  "Để cung cấp và cải thiện dịch vụ cho bạn với tư cách là thành viên.",
  p18:  "Để xác định bạn là thành viên và xác minh danh tính của bạn vì mục đích bảo mật cũng như tuân thủ các nghĩa vụ pháp lý của chúng tôi.",
  p19:  "Duy trì tài khoản thành viên của bạn.",
  p20:  "Nâng cấp và nâng cao trải nghiệm của bạn trên Trang web hoặc trên thiết bị của bạn hoặc tùy chỉnh hay phát triển thông tin, dịch vụ hoặc sản phẩm để đáp ứng nhu cầu của bạn, có thể bao gồm nghiên cứu thị trường và tiến hành các chương trình khuyến mãi.",
  p21:  "Tạo dữ liệu tổng hợp về các thành viên thông qua phân tích nhân khẩu học, thực hiện phân tích thống kê cơ sở dữ liệu để cung cấp cho các bên liên quan tiềm năng và hiện tại, đồng thời cho phép hoạt động kinh doanh của QueenG hiệu quả hơn. Chúng tôi cũng sử dụng dữ liệu này để phân tích xem có tồn tại nhiều tài khoản người dùng hay không.",
  p22:  "Để trả lời câu hỏi, nhận xét hoặc yêu cầu của bạn.",
  p23:  "Tuân thủ các nghĩa vụ theo hợp đồng, pháp lý và luật định của QueenG.",
  p24:  "Hãy thực hiện hành động thích hợp nếu QueenG có lý do để nghi ngờ rằng hoạt động bất hợp pháp hoặc hành vi sai trái có tính chất nghiêm trọng đã, đang hoặc có thể liên quan đến các chức năng và hoạt động của chúng tôi.",
  p25:  "Thiết lập, thực hiện hoặc bảo vệ mọi khiếu nại pháp lý.",
  p26:  "Dữ liệu cá nhân của bạn sẽ không được tiết lộ cho bên thứ ba trừ khi luật pháp yêu cầu. Dữ liệu cá nhân có thể được tiết lộ cho các đối tác kinh doanh hoặc nhà cung cấp hoặc nhà cung cấp dịch vụ của QueenG vì họ có thể chịu trách nhiệm về một số phần nhất định trong chức năng hoặc hoạt động tổng thể của trang web. Nhân viên của QueenG có thể truy cập dữ liệu cá nhân của bạn để thực hiện nhiệm vụ của họ và cung cấp cho bạn sự hỗ trợ và dịch vụ tốt nhất có thể. Bạn đồng ý với việc tiết lộ như vậy.",
  p27:  "QueenG sử dụng thông tin người dùng cho mục đích tiếp thị. Tuy nhiên, chúng tôi tôn trọng quyền riêng tư của người dùng. Nếu người dùng không muốn nhận bất kỳ tài liệu quảng cáo nào, họ có thể chọn tùy chọn đó khi đăng ký hoặc hủy đăng ký bất kỳ lúc nào.",
  p28_h: "tệp nhật ký",
  p29:  "QueenG tuân theo quy trình tiêu chuẩn sử dụng tệp nhật ký. Các tệp này ghi lại khách truy cập bất cứ khi nào họ truy cập trang web. Thông tin được thu thập bởi tệp nhật ký bao gồm địa chỉ Giao thức Internet (IP), loại trình duyệt, Nhà cung cấp dịch vụ Internet (ISP), ngày và giờ , các trang giới thiệu/thoát và có thể cả số lần nhấp chuột. Chúng không liên quan đến bất kỳ thông tin nhận dạng cá nhân nào. Mục đích của thông tin là phân tích xu hướng, quản lý trang web, theo dõi chuyển động của người dùng trên trang web và thu thập thông tin nhân khẩu học." ,
  p31_h:  "Việc sử dụng cookie của chúng tôi",
  p32:  "Chúng tôi có thể đặt và truy cập một số Cookie nhất định trên máy tính hoặc thiết bị của bạn. Cookie là một phần thông tin dưới dạng một tệp văn bản rất nhỏ được đặt trên ổ cứng của người dùng Internet. Nó được tạo bởi máy chủ trang web, được tạo ra bởi máy chủ trang web.' về cơ bản chạy một máy tính trang web. Cookie chứa thông tin do máy chủ đặt và có sẵn cho máy chủ bất cứ khi nào người dùng truy cập trang web.",
  p33:  "Bằng cách sử dụng trang web của chúng tôi, bạn đồng ý với việc sử dụng và lưu trữ cookie trên máy tính hoặc thiết bị của mình. Nếu bạn không muốn trang web của chúng tôi sử dụng cookie trên máy tính hoặc thiết bị của bạn, bạn có thể tắt và xóa cookie bất kỳ lúc nào thông qua cài đặt trình duyệt của mình .Để biết thêm thông tin về cookie cũng như cách vô hiệu hóa và đồng bằng chúng, nhưng xin lưu ý rằng nếu bạn từ chối hoặc xóa cookie, một số phần trên trang web của chúng tôi có thể không hoạt động bình thường và chúng tôi không thể cung cấp cho bạn một số dịch vụ nhất định.",
  p34:  "Cookie có thể là cookie 'liên tục' hoặc cookie 'phiên': cookie liên tục sẽ được trình duyệt web lưu trữ và sẽ vẫn có hiệu lực cho đến ngày hết hạn đã đặt, trừ khi người dùng xóa trước ngày hết hạn; mặt khác, Cookie phiên hết hạn vào cuối phiên người dùng, khi đóng trình duyệt web.",
  p35:  "Chúng tôi sử dụng các cookie sau:",
  p36:  "Cookie 'cần thiết' giúp trang web có thể sử dụng được bằng cách kích hoạt các chức năng cơ bản như điều hướng trang và truy cập vào các khu vực an toàn của trang web. Nếu không có các cookie này, trang web sẽ không hoạt động bình thường.",
  p37:  "Cookie tùy chọn cho phép trang web ghi nhớ thông tin, chẳng hạn như khu vực bạn đang ở, thông tin này sẽ thay đổi cách hoạt động hoặc giao diện của trang web.",
  p38:  "Cookie thống kê giúp trang web hiểu cách khách truy cập tương tác với trang web bằng cách thu thập và báo cáo thông tin ẩn danh.",
  p39:  "Cookie 'bảo mật' được sử dụng như một phần của các biện pháp bảo mật nhằm bảo vệ tài khoản người dùng, bao gồm ngăn chặn việc sử dụng gian lận thông tin đăng nhập cũng như bảo vệ trang web và dịch vụ của chúng tôi.",
  p40:  "Cookie xác thực và hiện diện được sử dụng để nhận dạng bạn khi bạn truy cập và duyệt trang web của chúng tôi, đồng thời giúp chúng tôi xác định xem bạn có đăng nhập vào trang web của chúng tôi hay không.",
  p41:  "Cookie'Tiếp thị' được sử dụng để theo dõi khách truy cập trên các trang web. Mục đích của chúng là hiển thị quảng cáo có liên quan và thu hút người dùng cá nhân và do đó có giá trị hơn đối với nhà xuất bản.",
  p42:  "Chúng tôi sử dụng cả cookie của bên thứ nhất và bên thứ ba. Cookie của bên thứ nhất do chúng tôi đặt trực tiếp và chỉ chúng tôi sử dụng. Chúng tôi sử dụng cookie để hỗ trợ và cải thiện trải nghiệm của bạn trên trang web cũng như để cung cấp và cải thiện các sản phẩm và dịch vụ của chúng tôi . ",
  p43:  "Bằng cách sử dụng trang web của chúng tôi, bạn cũng có thể nhận được một số cookie của bên thứ ba trên máy tính hoặc thiết bị của mình. Cookie của bên thứ ba là những cookie được đặt bởi các trang web, dịch vụ và/hoặc các bên không phải là chúng tôi. Ngoài ra, chúng tôi sử dụng Dịch vụ phân tích, cũng sử dụng cookie. Phân tích trang web đề cập đến một bộ công cụ được sử dụng để thu thập và phân tích số liệu thống kê sử dụng, cho phép chúng tôi hiểu rõ hơn cách mọi người sử dụng trang web.",
  p44:  "Trang web này sử dụng Google Analytics, một dịch vụ phân tích trang web được cung cấp bởi Google, Inc. ('Google'). Google Analytics sử dụng cookie để giúp trang web phân tích cách người dùng sử dụng trang web. Thông tin do cookie tạo ra về việc bạn sử dụng trang web của chúng tôi (bao gồm địa chỉ IP của bạn) sẽ được truyền tới Google và được lưu trữ trên các máy chủ ở Hoa Kỳ. Google sẽ sử dụng thông tin này để phân tích việc bạn sử dụng trang web, biên soạn báo cáo về hoạt động trang web cho chúng tôi và thực hiện các dịch vụ khác liên quan đến việc sử dụng trang web và Internet việc sử dụng. Google cũng có thể chuyển thông tin này cho các bên thứ ba theo yêu cầu của pháp luật hoặc khi các bên thứ ba đó xử lý dữ liệu thay mặt cho Google.",
  p45_h:  "Bảo vệ và bảo mật dữ liệu",
  p46:  "QueenG cam kết bảo vệ dữ liệu của bạn và giữ bí mật. QueenG đã làm mọi thứ có thể để ngăn chặn hành vi trộm cắp dữ liệu, truy cập và tiết lộ trái phép bằng cách triển khai công nghệ và phần mềm mới nhất, giúp chúng tôi bảo vệ tất cả thông tin chúng tôi thu thập trực tuyến.",
  p47_h:  "Chính sách quyền riêng tư của bên thứ ba",
  p48:  "Chính sách quyền riêng tư của chúng tôi không áp dụng cho các nhà quảng cáo hoặc trang web khác. Do đó, chúng tôi khuyên bạn nên xem lại chính sách quyền riêng tư tương ứng của các máy chủ quảng cáo bên thứ ba này để biết thêm chi tiết. Chính sách này có thể bao gồm các thông lệ của họ về cách chọn không tham gia một số tùy chọn nhất định và Hướng dẫn. Bạn có thể chọn tắt cookie thông qua các tùy chọn trình duyệt cá nhân của mình. Để biết thông tin chi tiết hơn về quản lý cookie với các trình duyệt web cụ thể, bạn có thể tìm thấy thông tin này tại các trang web tương ứng của trình duyệt.",
  p49_h:  "truyền dữ liệu",
  p50:  "Chúng tôi duy trì các máy chủ trên khắp thế giới và thông tin của bạn có thể được xử lý trên các máy chủ bên ngoài quốc gia nơi bạn cư trú. Luật bảo vệ dữ liệu khác nhau tùy theo quốc gia và một số luật cung cấp sự bảo vệ nhiều hơn các luật khác. Bất kể thông tin của bạn nằm ở đâu. Dù chúng tôi xử lý ở đâu, chúng tôi đều áp dụng các biện pháp bảo vệ tương tự được mô tả trong chính sách này. Chúng tôi cũng tuân thủ một số khung pháp lý nhất định liên quan đến việc truyền dữ liệu. Ủy ban Châu Âu đã xác định rằng một số quốc gia nhất định ngoài Khu vực Kinh tế Châu Âu (EEA) cung cấp biện pháp bảo vệ đầy đủ cho dữ liệu cá nhân. Đối với việc truyền dữ liệu từ EEA đối với các quốc gia khác, chẳng hạn như Hoa Kỳ, chúng tôi tuân thủ khung pháp lý thiết lập mức độ bảo vệ tương đương như luật của Liên minh Châu Âu. Khi nhận được đơn khiếu nại chính thức bằng văn bản, chúng tôi sẽ liên hệ với người khiếu nại để phản hồi.",
  p51_h:  "Lưu giữ và xóa dữ liệu cá nhân",
  p52:  "Các chính sách và thủ tục của chúng tôi được thiết kế để giúp đảm bảo rằng chúng tôi tuân thủ các nghĩa vụ pháp lý của mình liên quan đến việc lưu giữ và xóa dữ liệu cá nhân. Dữ liệu cá nhân mà chúng tôi xử lý cho bất kỳ mục đích nào sẽ không được lưu giữ lâu hơn mức cần thiết cho mục đích đó hoặc các mục đích đó.' Bất kể bất kỳ điều khoản nào khác, chúng tôi có thể lưu giữ dữ liệu cá nhân của bạn khi điều này là cần thiết để tuân thủ nghĩa vụ pháp lý mà chúng tôi phải tuân theo hoặc để bảo vệ lợi ích sống còn của bạn hoặc lợi ích sống còn của một thể nhân khác.",
  p53_h:  "Quyền lợi của bạn",
  p54:  "Nếu bạn là cư dân Châu Âu, bạn có quyền truy cập dữ liệu cá nhân mà chúng tôi lưu giữ về bạn và yêu cầu sửa, cập nhật hoặc xóa dữ liệu cá nhân của bạn.",
  p55:  "Đặc biệt, bạn có quyền thực hiện những điều sau:",
  p56:  "Rút lại sự đồng ý của bạn bất kỳ lúc nào. Nếu trước đây bạn đã đồng ý cho phép xử lý dữ liệu cá nhân của mình thì bạn có quyền rút lại sự đồng ý của mình.",
  p57:  "Phản đối việc xử lý dữ liệu của bạn. Bạn có quyền phản đối việc xử lý dữ liệu của mình nếu việc xử lý dựa trên cơ sở pháp lý mà không có sự đồng ý.",
  p58:  "Quyền truy cập vào dữ liệu của bạn. Bạn có quyền tìm hiểu xem dữ liệu của mình có đang được xử lý hay không, nhận thông tin tiết lộ về các khía cạnh nhất định của quá trình xử lý và nhận bản sao của dữ liệu đang được xử lý.",
  p59:  "Xác minh và yêu cầu chỉnh sửa. Bạn có quyền xác minh tính chính xác của dữ liệu của mình và yêu cầu cập nhật hoặc sửa dữ liệu đó.",
  p60:  "Hạn chế xử lý dữ liệu của bạn. Trong một số trường hợp nhất định, bạn có quyền hạn chế xử lý dữ liệu của mình. Trong trường hợp này, chúng tôi sẽ không xử lý dữ liệu của bạn cho bất kỳ mục đích nào ngoài mục đích lưu trữ.",
  p61:  "Xóa hoặc xóa dữ liệu cá nhân của bạn. Trong một số trường hợp nhất định, bạn có quyền yêu cầu chúng tôi xóa dữ liệu của mình.",
  p62:  "Quyền di chuyển dữ liệu. Bạn có quyền yêu cầu chúng tôi chuyển dữ liệu mà chúng tôi đã thu thập cho một tổ chức khác hoặc trực tiếp cho bạn, theo những điều kiện nhất định.",
  p63:  "Gửi khiếu nại. Bạn có quyền khiếu nại với cơ quan bảo vệ dữ liệu có thẩm quyền. Ngoài ra, nếu bạn là cư dân Châu Âu, chúng tôi lưu ý rằng chúng tôi đang xử lý dữ liệu cá nhân của bạn để thực hiện hợp đồng mà chúng tôi có thể có với bạn , hoặc để theo đuổi các mục đích của chúng tôi ở trên Các lợi ích kinh doanh hợp pháp được liệt kê. Theo CCPA, Quyền riêng tư (Không bán thông tin cá nhân của tôi) và các quyền khác, người tiêu dùng ở California có quyền:",
  p64:  "Yêu cầu các doanh nghiệp thu thập dữ liệu cá nhân về người tiêu dùng tiết lộ các danh mục và phần dữ liệu cá nhân cụ thể mà doanh nghiệp đã thu thập về người tiêu dùng.",
  p65:  "Yêu cầu doanh nghiệp xóa mọi dữ liệu cá nhân mà doanh nghiệp đã thu thập về người tiêu dùng.",
  p66:  "Được yêu cầu bởi các doanh nghiệp bán dữ liệu cá nhân của người tiêu dùng, không phải việc bán dữ liệu cá nhân của người tiêu dùng. Nếu bạn muốn thực hiện bất kỳ quyền nào trong số này, vui lòng liên hệ với chúng tôi theo địa chỉ support{'@'}QueenG.io",
  p67_h:  "Hạn chế thu thập dữ liệu cá nhân của bạn",
  p68:  "Tại một số thời điểm, bạn có thể muốn hạn chế việc sử dụng và thu thập dữ liệu cá nhân của mình. Bạn có thể thực hiện việc này bằng cách thực hiện như sau: Khi bạn điền vào biểu mẫu trên trang web, nếu bạn không muốn thông tin cá nhân của mình bị tiết lộ, vui lòng đảm bảo kiểm tra xem có hộp nào bạn có thể bỏ chọn không.",
  p69:  "QueenG sẽ không thuê, bán hoặc phân phối thông tin cá nhân của bạn cho bất kỳ bên thứ ba nào trừ khi chúng tôi nhận được sự cho phép của bạn. Chúng tôi có thể làm như vậy nếu luật pháp buộc chúng tôi phải làm như vậy. Nếu bạn đồng ý với Chính sách quyền riêng tư này, chúng tôi sẽ cung cấp cho bạn quyền sử dụng của bạn. thông tin cá nhân khi gửi tài liệu quảng cáo.",
  p70_h:  "Thay đổi chính sách bảo mật của chúng tôi",
  p71:  "Đôi khi, chúng tôi có thể thay đổi Chính sách quyền riêng tư này (ví dụ: nếu luật thay đổi). Mọi thay đổi sẽ được đăng ngay trên trang web của chúng tôi và bạn sẽ được coi là đã chấp nhận các điều khoản của Chính sách quyền riêng tư trong lần sử dụng đầu tiên của bạn. trang web của bạn theo những thay đổi. Chúng tôi khuyên bạn nên xem lại trang này thường xuyên để cập nhật. Nếu bạn không đồng ý với bất kỳ thay đổi nào, chúng tôi khuyên bạn nên liên hệ với bộ phận hỗ trợ khách hàng để làm rõ hoặc đóng tài khoản của bạn."
}
