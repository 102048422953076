// 日常任务的翻译
export default {
  manyRewards: "众多奖励都在",
  theDailyTask: "日常任务",
  getIt: "中等你来拿！",
  moreRewards: "下注越多 奖励越多",
  completeGame: "选择并完成每日游戏任务，让您在QueenG收获更多的精彩！",
  distributed: "日常任务分布在不同类型的游戏中。完成它们即可获得奖励！",
  dailyTask: "每日任务倒计时：",
  bonusCompleting: "完成所有任务额外奖励：",
  getMany: "领取",
  winDice1: "在骰子押单胜出30次",
  winDice2: "在骰子押和胜出30次",
  leopard: "在骰子押出一次豹子",
  winTwice: "使用相同的幸运号码在骰子中连胜x2 两次",
  roulette1: "在X2中玩30次轮盘",
  roulette2: "在X3中玩20次轮盘",
  roulette3: "在X5中玩15次轮盘",
  roulette4: "在X50中玩20次轮盘",
  guess1: "猜涨跌次数达30次",
  guess2: "猜涨猜对20次",
  guess3: "猜跌猜对20次",
  guess4: "猜跌连续猜对5次",
  collectCompletion: "完成后点击领取",
  notCompleted: "还未完成任务",
  rewardsReceived: "已领取过奖励",
  SuccessfullyReceived: "领取成功"
}
