// 返佣的翻译
export default {
  rebateProgram: "Rebate program",
  usersShare: "As users share invitation links, invitees connect through the link and actively play games on the platform, the inviter earns passive earnings. The more players you invite - the higher the earnings you earn.",
  usingTheReferralLinks: "Using the referral link generated by the platform, it will be recorded and tracked how the users you invite come and what winnings you receive from their bets.",
  enterInvitation: "Enter the invitation page",
  commissionRebate: "Net profit rebate",
  inviteeWinsBet: "Every time the invitee wins a bet, 3% of the invitee's winnings will be paid to you immediately.",
  platformBetting:  "Rebate on placed bets",
  inviteeLoses: "Every time the invitee loses a bet, the platform will take 3% of the bet and pay it to you.",
  miningCommission: "Mining rebate",
  miningRebate: "Each time the invitee gains earnings from mining, you will receive a corresponding mining commission of 3%."
}
