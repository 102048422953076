// 帮助-游戏的翻译
export default {
  title:  "เกม",
  p1:  "QueenG ได้รวบรวมเกมที่ได้รับความนิยมมากที่สุดในแวดวงคาสิโนและพัฒนาภายในองค์กร คุณสามารถใช้สกุลเงินดิจิทัลชั้นนำเพื่อวางเดิมพันในเกมได้โดยตรง ปัจจุบันผู้ใช้สามารถเพลิดเพลินกับเกมที่ออกแบบมาเฉพาะตัว เช่น การทอยลูกเต๋า การเดาการขึ้นและลง และวงล้อใหญ่ นอกจากนี้เรายังจะเปิดตัวเกมคาสิโนยอดนิยมอีกมากมายในอนาคต!",
  p2:  "อัตราต่อรองที่เกี่ยวข้องกับแต่ละเกมจะแตกต่างกัน แต่จำนวนเงินเดิมพันขั้นต่ำสูงสุดจะกำหนดไว้ที่: $1000000-$1",
  title1:  "1) ทอยลูกเต๋า",
  p3:  "Crap เป็นเกมลูกเต๋าจีนโบราณที่ผู้เล่นเดิมพันผลการหมุนลูกเต๋าสามลูกที่หมุนอยู่ในกรง",
  p4:  "เมื่อเกมเริ่มต้นผู้เล่นเลือกทิศทางขนาดของพื้นที่วางเดิมพันหรือจำนวนแต้มที่กำหนด หลังจากเดิมพันเสร็จสิ้น ลูกเต๋าจะเริ่มหมุน หลังจากการหมุนเสร็จสิ้น แพลตฟอร์มจะแสดงผลลัพธ์และการออก รางวัล",
  p5:  "แตกต่างจากลูกเต๋าแบบดั้งเดิม เกมลูกเต๋า GAMES จะทำให้กระบวนการเดิมพัน ลูกเต๋าสุ่ม การชดเชยการชนะเป็นอัตโนมัติโดยสมบูรณ์ และการประมวลผลเงินทุน/รางวัลผ่านสัญญาอันชาญฉลาดโดยไม่ต้องมีส่วนร่วมจากบุคคลที่สาม ซึ่งเร็วกว่าการเดิมพันคาสิโนแบบดั้งเดิมที่มีอยู่ มีประสิทธิภาพและยุติธรรมมากกว่ามาก ",
  subtitle1:  "1.1) สั่งซื้อ",
  p6:  "มีพื้นที่วางเดิมพันเป็นแถวอยู่ใต้ลูกเต๋าซึ่งแบ่งเป็นช่องเล็กๆ 6 ช่อง ในแถวนี้คุณเดิมพันว่าหมายเลขใดจะปรากฏหลังจากหมุนลูกเต๋า หากหนึ่งในสามลูกเต๋าแสดงหมายเลขที่คุณเดิมพันแล้ว เงินรางวัลของคุณคือ 1 ต่อ 2 หากลูกเต๋าสองในสามลูกแสดงหมายเลขที่คุณเดิมพัน เงินรางวัลของคุณคือ 1 ต่อ 11 หากลูกเต๋าสามในสามลูกแสดงหมายเลขที่คุณเดิมพัน เงินรางวัลของคุณคือ 1 ถึง 151",
  subtitle2:  "1.2) การเดิมพันเสมอ",
  p7:  "ในพื้นที่เสมอ การเดิมพันของคุณคือผลรวมของตัวเลขหลังจากหมุนลูกเต๋าสามลูก “ใหญ่” หมายถึงผลรวมของตัวเลขคือ 11 ถึง 17 “เล็ก” หมายถึงผลรวมของตัวเลขคือ 4 ถึง 10 ถ้า ตัวเลขหลังหมุนเป็นสามถ้าแต้มเท่ากันก็เป็นของ “เสือดาว” แล้วทั้งใหญ่และเล็กแพ้ อัตราต่อรองใหญ่และเล็กคือ 2 ต่อ 1 และอัตราต่อรองเสือดาวคือ 25 ต่อ 1 ",
  title2:  "2) เดาการขึ้นหรือลง",
  p8:  "ขึ้นอยู่กับสภาวะตลาดของสกุลเงินกระแสหลักทั้งสาม ผู้ใช้สามารถเลือกที่จะเป็นกระทิงหรือหมี และคาดการณ์การขึ้นและลงของคู่การซื้อขายที่เลือกได้ภายใน 15 วินาที หลังจากวางเดิมพันในทิศทางเดียว ตลาดจะกระโดดขึ้น 15 วินาที จะมีการนับถอยหลัง หลังจาก 15 วินาที จะมีการเปรียบเทียบราคาขึ้นลง หากทายถูก ราคาจะขึ้นหรือลงทันที คืนเงินหลังการชำระราคา",
  p9:  "เกี่ยวกับการวางเดิมพัน:",
  p10:  "หลังจากวิเคราะห์และตรวจสอบตลาดแล้ว ให้กำหนดจำนวนเงินเดิมพันแล้วคลิกปุ่มเดาขึ้น/เดาลง หากผลการเดาถูกต้องคุณจะได้รับโบนัส 2 เท่า หากผลการทายผิดมีเพียงจำนวนเงินเดิมพันเท่านั้นที่จะ จะถูกหักออก หากจำนวนเงินที่เพิ่มขึ้นหรือลดลงเป็นศูนย์ จะถือเป็นเสมอ และจะได้รับเงินเดิมพันคืน",
  title3:  "3) จานเสียงขนาดใหญ่",
  p11:  "วงล้อใหญ่เป็นเกมการพนันทั่วไปในคาสิโน วงล้อมี 54 ช่องที่สามารถเลือกได้และสี่สีมีสัดส่วนที่แตกต่างกัน สีม่วงมี 26 บล็อกและราคาต่อรองคือ 2 ครั้ง สีแดงมี 17 บล็อกและอัตราต่อรอง เป็น 3 เท่า สีเขียวคือ 10 ชิ้น อัตราต่อรองคือ 5 เท่า สีเหลืองคือ 1 ชิ้น อัตราต่อรองคือ 50 เท่า",
  p12:  "กำหนดจำนวนเงินเดิมพัน หลังจากเลือกสีเดิมพันแล้ว วงล้อรูเล็ตจะหมุน หลังจากวงล้อรูเล็ตหยุดหมุนสีของบริเวณที่เลือกจะเป็นสีลอตเตอรี่ หลังจากจับสลากแล้วการชำระจะเสร็จสิ้นทันทีและ โบนัสจะถูกแจกจ่าย;"
}
