import dayjs from 'dayjs'
import 'dayjs/locale/zh'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
dayjs.extend(relativeTime)
dayjs.extend(utc)
export default {
  install(Vue) {
    Vue.config.globalProperties.$dayjs = dayjs
  }
}