// 首页的翻译
export default {
  qGTHasHeenAwarded: "已奖励QGT",
  homePage: "首页",
  lasVegas: "赌城",
  throwTheDice: "投骰子",
  roulette: "轮盘",
  guessTheRiseAndFall: "猜涨跌",
  slotMachine: "老虎机",
  aloneGame: "单机小游戏",
  arcade: "街机",
  pledge: "质押",
  commissionBack: "返佣",
  noviceMission: "新手任务",
  qGTTokens: "QGT代币",
  helpCenter: "帮助中心",

  games: '游戏',
  user: '用户',
  payout: '下注',

  gamesTypeAll: '全部',
  gamesType1: '赌城',
  gamesType2: '老虎机',
  gamesType3: '单机小游戏',
  gamesType4: '街机',
  gamesType5: '体育赛事',
  gamesType1msg: '客户可以根据自己的想法选择在任何其他门上下注.',
  gamesType2msg: '插入硬币后，拉下杠杆，它就会开始旋转.',
  gamesType3msg: '插入硬币后，拉下杠杆，它就会开始旋转.',
  gamesType4msg: '插入硬币后，拉下杠杆，它就会开始旋转.',
  gamesType5msg: '体育赛事即将来临.',
  gamesRecordText: '游戏记录',

  theOriginalGame: "独创游戏",
  miningMode: "即挖矿模式",
  miningRewards: "下注越多 奖励越多 挖矿奖励实时结算",
  qGTCanBeDugToday: "可挖矿即将来临",
  highestEarnedMultiplier: "体育赛事即将来临",
  gamesPlayed: "已开展的游戏",
  gamesPlayedNew: "最近常玩的游戏",
  highestWin: "最高赢取",
  participant: "参与者",
  canBeDugToday: "今日可挖",
  highReturn: "高回报",
  highYield: "高收益",

  signIn: "登录",
  registration: "注册",
  purse: "钱包",
  search: "搜索",
  hideOneBalance: "隐藏0余额",
  displayUSD: "显示美元",
  personalCenter: "个人中心",
  invite: "复制邀请链接",
  inviteCopy: "邀请链接复制成功",
  setUp: "设置",
  Language: "语言设置",
  LanguageZh: "繁体",
  LanguageEn: "English",
  logOutAccount: "退出帐号"
};
