// 帮助-Ido质押的翻译
export default {
  title: "Juramento",
  p1:  "Os usuários detêm e prometem QGT LP para fornecer liquidez para a plataforma. Ao se tornarem apoiadores da plataforma, eles também podem usufruir dos direitos de parceiro da plataforma. A cada 24 horas, 20% do lucro total do pool de apostas da plataforma será distribuído a cada usuário de staking. . ",
  p2:  "Esse valor será distribuído na moeda digital ganha pelo pool de apostas da plataforma, ou seja, a moeda das fichas de apostas.",
  title1:  "1) Como funciona o bônus de patrocinador?",
  p3:  "O pool de promessas é liquidado a cada 24 horas, e 20% do lucro total da plataforma do dia anterior será distribuído a todos os apoiadores.",
  p4:  "Além do mais, simplesmente apostando um LP líquido, você pode obter enormes ganhos em todas as criptomoedas da plataforma: USDT, ETH, BTC, BNB, TRX e muito mais.",
  p5:  "Na página de apoio, você pode visualizar os ganhos diários estimados e os ganhos estimados de staking.",
  img1:  "",
  p6:  "Insira números nos campos e veja os benefícios projetados em diferentes cenários.",
  p7:  "Quanto mais liquidez você aposta, mais você ganha.",
  title2:  "2) Extraia mais QGT para aumentar a receita",
  p8:  "20% dos lucros diários da plataforma serão divididos entre todos os apoiadores. Você pode ver os detalhes dos dividendos na página do apoiador e na central pessoal.",
  p9:  "No entanto, quanto mais apoiadores do QueenG, menos dividendos você recebe. Aumente seus ganhos apostando mais para extrair tokens QGT e apostar liquidez na plataforma! Expanda a liquidez QGT em todas as moedas disponíveis todos os dias, prometa obter grandes recompensas!"
}
