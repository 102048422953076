// 帮助-Qa的翻译
export default {
  title: "Q&A",
  title1: "1）My withdrawal hasn't arrived yet?",
  p1_1: "Why your withdrawal still hasn't arrived in your wallet.",
  p1_2: "There are several reasons why your withdrawal did not arrive in your wallet, the most common reason being an unconfirmed transaction.",
  p1_3: "Each transaction must be acknowledged before it is credited to the destination address. The number of confirmations required depends on wallet to wallet. When it comes to Bitcoin, some wallets require only one confirmation, while others require more than three. These confirmations take time.",
  p1_4: "If the withdrawal amount is large, they may get stuck and need to be handled manually by our administrator.",
  p1_5: "Note: Even manual withdrawals are processed almost instantly, you don't need to worry, just be patient.",
  title2: "2) What is betting on mining",
  p2_1: "The total amount of QGT is 8 billion, 50% of which is used for betting rewards, that is, playing games is mining, and this part is reduced every year. Users can enjoy the dividends of the mining earnings of the previous day every day, and the more they bet, The more you enjoy, the more you gain.",
  p2_2: "The advantage of playing and mining is that even if users incur losses in the game, they can still get higher profits by selling QGT. At the same time, QGT is also online on external exchanges at the same time, and users can transfer and circulate freely.",
  title3: "3) When will the commission and bonus be paid?",
  p3_1: "The bonus commissions and supporter dividend for referrals rebates are settled once a day, and the rewards for the previous day will be distributed after 0:00 UTC the next day;",
  p3_2: "The issuance of mining rewards is the same;",
  title4: "4) Can I deposit digital coins through BEP2 or Tron network?",
  p4: "You can check the supported network and currency through the recharge area of the wallet, if not, it does not support recharge;",
  title5: "5) About handling fees and withdrawal fees",
  p5_1: "The platform deducts 0.3% of the betting fee each time you win a prize, but not when you lose.",
  p5_2: "For each withdrawal, the platform will charge the corresponding withdrawal service fee according to the current network rate."
}
