// 三个游戏及我的投注的翻译
export default {
  pledge: "押单",
  yawan: "押和",
  bettingAmount: "投注金额",
  highestWin: "最高赢取",
  castOnce: "投1次",
  castFive: "投5次",
  excavatedOnce: "投一次预计可挖",
  bigWinner: "大赢家",
  mostFortunate: "最幸运",
  rulesGame: "游戏规则",
  player: "玩家",
  betting: "投注",
  multiple: "倍数",
  bonus: "奖金",
  placeBet: "下注",
  result: "结果",
  earnings: "收益",
  bettingTime: "结算时间",
  bettingDirection: "投注方向",
  aboutToBegin: "即将开始",
  excavatedTurningOnce: "转一次预计可挖",
  drawTheLottery: "开奖！",
  latestPrice: "最新价",
  realTime: "实时",
  oneMinute: "1分钟",
  fifteenMinutes: "15分钟",
  guessTheRise: "猜涨",
  guessTheFall: "猜跌",
  bettingPrice: "下注价",
  settlementInProgress: "结算中",
  game: "游戏",
  return: "返回",
  theRise: "涨",
  theFall: "跌",
  openNote: "开注",
  selected: "已选择",
  pleaseLoginFirst: "请先登录",
  enterCorrectAmount: "请输入正确的投注金额",
  creditLow: "余额不足",
  maximumWorthTokens: "最大投注100000USDT价值代币",
  congratulationsWinning: "恭喜您抽中",
  winningLottery: "恭喜您中奖",
  didnnotWinPrize: "很遗憾未中奖",
}
