// 个人中心的翻译
export default {
  myGame:"My Games",
  totalWin:"Total Winnings",
  totalBets:"Total Bets",
  betsToday:"Number of Bets Today",
  betsWeek:"Number of Bets This Week",
  myBonus: "My bonus",
  StakeDividends:"Staking Dividends",
  providePOOL:"Provide POOL Liquidity, Become a Partner, and Enjoy Dividends from Each Pool",
  dividendYesterday: "Yesterday's dividend",
  accumulatedDividends: "Accumulated dividends",
  myMining: "My mining",
  gameMining: "Game mining",
  participate:"Play games to participate in daily mining and have voting rights",
  miningYesterday: "Mining yesterday",
  cumulativeExcavation:"Total Mined",
  miningYesterdayChu:"Mined Yesterday",
  nearly:"Mining in the Last 7 Days",
  totalMining: "Total mining",
  settlementDate: "Settlement date",
  gameAmount:"Daily Gaming Volume",
  numberGames:"Number of Games Played",
  miningShare: "Mining share",
  miningearnings:"Mining Earnings",
  details:"Details of xx",
  return: "Return",
  viewDetails:"View Details",
  yesterdayEarnings: "Yesterday's earnings",
  earningsRecentDays:"Earnings in the Last 7 Days",
  totalearnings:"Total Earnings",
  settlementLP:"LP Settlement",
  dividends: "Dividends",
  operation:"Action"

}
