// 登录注册的翻译
export default {
  emailOrUserName: "e-mel atau Nama pengguna",
  password:  "kata laluan",
  signIn:  "Log masuk",
  registration:  "daftar",
  emailOrUsernameNotExist:  "E-mel atau nama pengguna tidak wujud",
  passwordError:  "Salah kata laluan",
  loginSuccessful:  "log masuk berjaya",
  email:  "E-mel",
  userName:  "nama pengguna",
  invitationCode:  "Kod Jemputan",
  termsServiceConfirmation:  "Mengklik kotak semak menunjukkan bahawa anda telah membaca dan mengesahkan",
  service:  "terma dan syarat",
  mailboxExists:  "E-mel sudah wujud",
  userNameExists:  "Nama pengguna sudah wujud",
  incorrectMailbox:  "Format e-mel tidak betul",
  incorrectPasswords:  "Kata laluan mestilah tidak kurang daripada 6 aksara panjang dan mesti mengandungi huruf dan titik perpuluhan",
  invitationCodeNotExist:  "Kod jemputan tidak wujud",
  registrationSuccessful:  "pendaftaran berjaya",
  pleaseCheckEmail:  "Sila semak e-mel anda",
  pleaseEnterEmailAddress:  "Sila masukkan alamat e-mel anda",
  enterUserNameOrEmail:  "Sila masukkan nama pengguna atau alamat e-mel anda",
  pleaseInputPassword:  "Sila masukkan kata laluan",
  verificationCode:  "Kod pengesahan",
  sendVerificationCode:  "Hantar kod pengesahan",
  mailSent:  "Mesej telah dihantar",
  enterVerificationCode:  "sila masukkan kod pengesahan",
  enterUserName:  "sila masukkan nama pengguna",
  agreeConditions:  "Sila bersetuju dengan terma dan syarat",
  forgetPassword:  'lupa kata laluan',
  forgetEmail:  'E-mel',
  forgetCode:  'Kod pengesahan',
  forgetLogin:  'Pergi ke log masuk',
  forgetConfirm:  'sahkan',
  forgetErrorEmail:  'Sila isikan alamat e-mel anda',
  forgetErrorVerificationCode:  'Sila isikan kod pengesahan',
  forgetErrorSuccess:  'Tetapan semula kata laluan berjaya',

}
