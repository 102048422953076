// 帮助-隐私政策的翻译
export default {
  title: "개인 정보 정책",
  p1:  "우리는 언제든지 이 개인정보 보호정책을 변경할 권리를 보유하며 귀하는 즉시 업데이트될 것입니다. 최신 변경 사항을 알고 싶다면 이 페이지를 자주 방문하는 것이 좋습니다.",
  p2:  "추가 질문이 있거나 개인 정보 보호 정책에 대한 추가 정보가 필요한 경우 언제든지 문의해 주시기 바랍니다.",
  p3:  "본 개인정보 보호정책은 당사의 온라인 활동에만 적용되며 QueenG에서 공유 및/또는 수집한 정보와 관련하여 당사 웹사이트 방문자에게 유효합니다. 본 개인정보 보호정책은 오프라인이나 본 사이트 이외의 채널을 통해 수집된 정보에는 적용되지 않습니다. 웹사이트." ,
  p4_h:  "동의하다",
  p5:  "우리는 특정 목적을 위한 귀하의 정보 처리에 대한 귀하의 동의를 요청하며, 귀하는 언제든지 동의를 철회할 권리가 있습니다. 그러나 당사 웹사이트를 사용함으로써 귀하는 당사의 개인정보 보호정책과 해당 약관에 동의하는 것으로 간주됩니다." ,
  p6_h:  "우리는 어떤 사용자 데이터를 수집합니까?",
  p7:  "개인 데이터'는 식별되거나 식별 가능한 개인과 관련된 모든 정보로 이해됩니다. 식별 가능한 자연인은 특히 이름, 위치 데이터, 이메일, 온라인 식별자와 같은 정보를 참조하여 직접 또는 간접적으로 식별될 수 있는 사람입니다. 또는 해당 자연인의 신체적, 생리적, 유전적, 정신적, 경제적, 문화적 또는 사회적 정체성과 관련된 하나 이상의 요소에 대한 식별자입니다.",
  p8:  "당사는 귀하에게 연락할 수 있는 데이터('연락처 데이터')를 처리할 수 있습니다. 연락처 데이터에는 귀하의 이름, 이메일 주소가 포함될 수 있습니다. 연락처 데이터의 출처는 등록 양식, 문의 양식 및 귀하의 계정 정보에 제공된 것입니다.",
  p9:  "당사는 귀하의 웹사이트 사용자 계정 데이터('계정 데이터')를 처리할 수 있습니다. 계정 데이터에는 귀하의 계정 식별자, 이름, 이메일 주소, 계정 생성 및 수정 날짜, 웹사이트 설정이 포함될 수 있습니다. 계정 데이터의 주요 소스는 귀하입니다. 계정 데이터는 당사 웹사이트에서 생성될 수 있습니다.",
  p10:  "당사는 기술 및 재무 데이터, 사용 데이터를 처리할 수 있습니다.' 이는 귀하가 당사 웹사이트를 방문하거나 당사 서비스를 사용할 때 IP 주소, 지역, 등록 날짜, 로그인 날짜, 관련 계열사, 계열사 관련 통계 정보(귀하의 당사 웹사이트 트래픽 소스, 당사 홍보 자료에 대한 귀하의 조회 및 클릭에 대한 데이터), 자체 제외 날짜 및 기간, 블랙아웃 날짜 및 기간, 실패한 횟수 및 시간 로그인 시도, 계정 잔액, 계정 보너스, 계정 실제 자금, 거래 날짜, 거래 값, 거래 식별자, 보너스 총액, 보너스 총액, 입금액 총액, 입금액 총액, 인출 총액 인출 총액, 인출 총액, 인출할 금액, 숫자 출금금액, 출금금액, 출금횟수, 입출금비율, 총수입금액,",
  p11:  "우리는 귀하가 당사에 보내거나 당사가 귀하에게 보내는 통신에 포함되거나 이와 관련된 정보('통신 데이터')를 처리할 수 있습니다. 통신 데이터에는 통신 내용 및 통신과 관련된 메타데이터가 포함될 수 있습니다. 당사는 메타데이터를 생성할 수 있습니다. 문의 양식을 사용한 커뮤니케이션과 관련됩니다.",
  p12_h:  "우리가 귀하의 데이터를 수집하는 이유",
  p13:  "QueenG는 다음 목적을 위해 허가받은 온라인 북메이커로서 사업을 운영하기 위해 침해적인 방식이 아닌 합법적이고 공정한 수단으로만 귀하의 개인 정보를 수집합니다.",
  p14:  "고객 파악(KYC) 인증을 위해.",
  p15:  "QueenG의 마케팅 커뮤니케이션을 구독하고 싶다면.",
  p16:  "QueenG 또는 호스팅된 하위 도메인의 회원이 되려면 신청서를 처리하세요.",
  p17:  "회원으로서 귀하에게 서비스를 제공하고 개선하기 위해.",
  p18:  "귀하를 회원으로 식별하고 보안 목적으로 귀하의 신원을 확인하며 당사의 법적 의무를 준수하기 위해.",
  p19:  "회원 계정을 유지하세요.",
  p20:  "사이트 또는 귀하의 기기에서 귀하의 경험을 업그레이드 및 향상시키거나, 귀하의 필요에 맞게 정보, 서비스 또는 제품을 맞춤화 또는 개발하십시오. 여기에는 시장 조사 및 프로모션 수행이 포함될 수 있습니다.",
  p21:  "인구통계학적 분석을 통해 회원에 대한 집계 데이터를 생성하고, 데이터베이스의 통계적 분석을 수행하여 잠재 및 기존 이해관계자에게 제공하며, QueenG의 비즈니스를 보다 효율적으로 운영할 수 있도록 합니다. 또한 이 데이터를 사용하여 사용자 계정 다중 계정 존재 여부를 분석합니다.",
  p22:  "귀하의 질문, 의견 또는 요청에 응답합니다.",
  p23:  "QueenG의 계약상, 법적, 법적 의무를 준수합니다.",
  p24:  "QueenG가 당사의 기능 및 활동과 관련하여 불법 활동이나 심각한 성격의 위법 행위가 있었거나 참여하고 있거나 참여할 수 있다고 의심할 만한 이유가 있는 경우 적절한 조치를 취하십시오.",
  p25:  "법적 청구권을 확립, 행사 또는 방어합니다.",
  p26:  "귀하의 개인 데이터는 법률에서 요구하지 않는 한 제3자에게 공개되지 않습니다. 웹사이트의 전체 기능이나 운영 중 특정 부분을 담당할 수 있는 QueenG 비즈니스 파트너, 공급업체 또는 서비스 제공업체에 개인 데이터가 공개될 수 있습니다. QueenG 직원 직무를 수행하고 귀하에게 가능한 최고의 지원과 서비스를 제공하기 위해 귀하의 개인정보에 접근할 수 있습니다. 이에 귀하는 그러한 공개에 동의합니다.",
  p27:  "QueenG는 사용자 정보를 마케팅 목적으로 사용합니다. 그러나 당사는 사용자의 개인정보를 존중합니다. 사용자가 프로모션 자료 수신을 원하지 않는 경우 언제든지 등록 또는 구독 취소 시 해당 옵션을 선택할 수 있습니다.",
  p28_h: "로그 파일",
  p29:  "QueenG는 로그 파일을 사용하는 표준 절차를 따릅니다. 이 파일은 방문자가 웹사이트를 방문할 때마다 방문자를 기록합니다. 로그 파일에 의해 수집되는 정보에는 인터넷 프로토콜(IP) 주소, 브라우저 유형, 인터넷 서비스 공급자(ISP), 날짜 및 시간 스탬프, 참조/종료 페이지 및 클릭 수. 이러한 정보는 개인 식별 정보와 연관되지 않습니다. 정보의 목적은 추세를 분석하고, 사이트를 관리하고, 사이트에서 사용자의 이동을 추적하고, 인구통계학적 정보를 수집하는 것입니다." ,
  p31_h:  "당사의 쿠키 사용",
  p32:  "우리는 귀하의 컴퓨터나 장치에 특정 쿠키를 배치하고 이에 액세스할 수 있습니다. 쿠키는 인터넷 사용자의 하드 드라이브에 배치되는 아주 작은 텍스트 파일 형태의 정보 조각입니다. 이는 웹페이지 서버에 의해 생성됩니다. 기본적으로 웹사이트의 컴퓨터를 운영하며, 쿠키란 서버가 설정한 정보로서 이용자가 해당 사이트를 방문할 때마다 서버에 제공되는 정보를 담고 있습니다.",
  p33:  "당사 웹사이트를 사용함으로써 귀하는 귀하의 컴퓨터 또는 장치에서 쿠키를 사용하고 저장하는 데 동의하게 됩니다. 당사 웹사이트가 귀하의 컴퓨터 또는 장치에서 쿠키를 사용하는 것을 원하지 않는 경우, 귀하는 브라우저 설정을 통해 언제든지 쿠키를 비활성화하고 삭제할 수 있습니다. .쿠키에 대한 자세한 내용과 쿠키 비활성화 및 삭제 방법에 대해 알아보십시오. 단, 귀하가 쿠키를 거부하거나 삭제하는 경우 당사 사이트의 일부 기능이 제대로 작동하지 않을 수 있으며 특정 서비스를 귀하에게 제공하지 못할 수도 있다는 점을 유의하시기 바랍니다.",
  p34:  "쿠키는 '영구' 쿠키 또는 '세션' 쿠키일 수 있습니다. 영구 쿠키는 웹 브라우저에 의해 저장되며 만료일 전에 사용자가 삭제하지 않는 한 설정된 만료일까지 유효합니다. 반면, 세션 쿠키는 웹 브라우저가 닫힐 때 사용자 세션이 끝나면 만료됩니다.",
  p35:  "우리는 다음 쿠키를 사용합니다:",
  p36:  "'필수' 쿠키는 페이지 탐색 및 웹사이트의 보안 영역에 대한 액세스와 같은 기본 기능을 활성화하여 웹사이트를 사용할 수 있게 만듭니다. 이러한 쿠키가 없으면 웹사이트가 제대로 작동하지 않습니다.",
  p37:  "기본 설정 쿠키를 사용하면 웹사이트가 귀하가 있는 지역과 같은 정보를 기억하여 웹사이트의 동작이나 모양을 변경할 수 있습니다.",
  p38:  "통계 쿠키는 익명으로 정보를 수집하고 보고함으로써 방문자가 웹사이트와 상호 작용하는 방식을 웹사이트가 이해하는 데 도움이 됩니다.",
  p39:  "'보안' 쿠키는 로그인 자격 증명의 사기성 사용을 방지하고 당사 웹사이트와 서비스를 보호하는 등 사용자 계정을 보호하는 데 사용되는 보안 조치의 요소로 사용됩니다.",
  p40:  "인증 및 존재 쿠키는 귀하가 당사 웹사이트를 방문하고 검색할 때 귀하를 식별하고 귀하가 당사 웹사이트에 로그인했는지 여부를 확인하는 데 사용됩니다.",
  p41:  "'마케팅' 쿠키는 웹사이트 전체에서 방문자를 추적하는 데 사용됩니다. 쿠키의 목적은 개별 사용자에게 관련성이 있고 관심을 끌 수 있는 광고를 표시하여 게시자에게 더 가치 있는 광고를 표시하는 것입니다.",
  p42:  "저희는 제1자 쿠키와 제3자 쿠키를 모두 사용합니다. 제1자 쿠키는 당사가 직접 배치하며 당사에서만 사용합니다. 당사는 쿠키를 사용하여 귀하의 웹사이트 경험을 촉진하고 개선하며 제품과 서비스를 제공 및 개선합니다. . ",
  p43:  "당사의 웹사이트를 사용함으로써 귀하는 귀하의 컴퓨터나 장치에서 특정 제3자 쿠키를 받을 수도 있습니다. 제3자 쿠키는 당사가 아닌 웹사이트, 서비스 및/또는 당사자가 배치한 쿠키입니다. 또한 당사는 분석 서비스를 사용합니다. 쿠키를 사용합니다. 웹사이트 분석은 사용 통계를 수집하고 분석하는 데 사용되는 도구 세트를 말하며 이를 통해 사람들이 웹사이트를 어떻게 사용하는지 더 잘 이해할 수 있습니다.",
  p44:  "이 웹사이트는 Google, Inc.('Google')에서 제공하는 웹 분석 서비스인 Google Analytics를 사용합니다. Google Analytics는 쿠키를 사용하여 웹사이트가 사용자의 웹사이트 사용 방식을 분석하도록 돕습니다. 쿠키에 의해 생성된 정보는 귀하의 당사 웹사이트 사용에 관한 것입니다. (귀하의 IP 주소 포함)은 Google로 전송되어 미국 서버에 저장됩니다. Google은 이 정보를 사용하여 귀하의 웹사이트 사용을 분석하고 웹사이트 활동에 대한 보고서를 작성하며 웹사이트 사용 및 인터넷과 관련된 추가 서비스를 수행합니다. Google은 법에 따라 요구되거나 해당 제3자가 Google을 대신하여 데이터를 처리하는 경우 이 정보를 제3자에게 전송할 수도 있습니다.",
  p45_h:  "데이터 보호 및 보안",
  p46:  "QueenG는 귀하의 데이터를 보호하고 기밀을 유지하기 위해 최선을 다하고 있습니다. QueenG는 온라인에서 수집하는 모든 정보를 보호하는 데 도움이 되는 최신 기술과 소프트웨어를 구현하여 데이터 도난, 무단 액세스 및 공개를 방지하기 위해 최선을 다했습니다.",
  p47_h:  "제3자 개인정보 보호정책",
  p48:  "당사의 개인정보 보호정책은 다른 광고주나 웹사이트에는 적용되지 않습니다. 따라서 자세한 내용은 이러한 제3자 광고 서버의 해당 개인정보 보호정책을 검토하는 것이 좋습니다. 여기에는 특정 옵션을 선택 해제하는 방법 및 지침. 귀하는 개별 브라우저 옵션을 통해 쿠키를 비활성화하도록 선택할 수 있습니다. 특정 웹 브라우저의 쿠키 관리에 대한 자세한 내용은 브라우저의 해당 웹사이트에서 확인할 수 있습니다.",
  p49_h:  "데이터 전송",
  p50:  "우리는 전 세계에 서버를 유지하고 있으며 귀하의 정보는 귀하가 거주하는 국가 밖의 서버에서 처리될 수 있습니다. 데이터 보호법은 국가마다 다르며 일부는 다른 국가보다 더 많은 보호를 제공합니다. 귀하의 정보가 어디에 있든 우리가 처리하는 곳마다 적용됩니다. 본 정책에 설명된 것과 동일한 보호 조치가 적용됩니다. 당사는 또한 데이터 전송과 관련된 특정 법적 체계도 준수합니다. 유럽 위원회는 유럽 경제 지역(EEA) 외부의 특정 국가가 개인 데이터에 대한 적절한 보호를 제공한다고 결정했습니다. EEA에서는 미국 등 다른 국가에 대해 EU 법률과 동등한 수준의 보호를 설정하는 법적 체계를 준수합니다. 공식적인 서면 불만 사항이 접수되면 이에 대응하여 불만 제기자에게 연락할 것입니다.",
  p51_h:  "개인정보의 보유 및 삭제",
  p52:  "당사의 정책 및 절차는 개인 데이터의 보관 및 삭제와 관련된 법적 의무를 준수하도록 돕기 위해 고안되었습니다. 어떤 목적으로든 당사가 처리하는 개인 데이터는 해당 목적에 필요한 것보다 더 오랫동안 보관되지 않습니다. . 다른 조항에도 불구하고 당사는 당사가 준수해야 하는 법적 의무를 준수하거나 귀하의 중요한 이익 또는 다른 자연인의 중요한 이익을 보호하기 위해 필요한 경우 귀하의 개인 데이터를 보유할 수 있습니다.",
  p53_h:  "귀하의 권리",
  p54:  "귀하가 유럽 거주자인 경우 당사가 보유하고 있는 귀하의 개인 데이터에 액세스하고 귀하의 개인 데이터를 수정, 업데이트 또는 삭제하도록 요청할 권리가 있습니다.",
  p55:  "특히 귀하는 다음을 수행할 권리가 있습니다:",
  p56:  "언제든지 동의를 철회하십시오. 이전에 개인 데이터 처리에 동의한 경우, 귀하는 동의를 철회할 권리가 있습니다.",
  p57:  "귀하의 데이터 처리에 반대하십시오. 귀하는 동의 없이 법적 근거에 따라 처리하는 경우 데이터 처리에 반대할 권리가 있습니다.",
  p58:  "귀하의 데이터에 대한 액세스. 귀하는 귀하의 데이터가 처리되고 있는지 여부를 확인하고, 처리의 특정 측면에 대한 공개를 얻고, 처리 중인 데이터의 사본을 얻을 권리가 있습니다.",
  p59:  "확인하고 수정을 요구합니다. 귀하는 데이터의 정확성을 확인하고 업데이트 또는 수정을 요청할 권리가 있습니다.",
  p60:  "귀하의 데이터 처리 제한. 특정 상황에서 귀하는 귀하의 데이터 처리를 제한할 권리가 있습니다. 이 경우 당사는 저장 이외의 다른 목적으로 귀하의 데이터를 처리하지 않습니다.",
  p61:  "귀하의 개인 데이터를 삭제하거나 삭제하십시오. 특정 상황에서 귀하는 당사에 데이터 삭제를 요청할 권리가 있습니다.",
  p62:  "데이터 이동성에 대한 권리. 귀하는 당사가 수집한 데이터를 특정 조건 하에서 다른 조직으로 또는 귀하에게 직접 전송하도록 요청할 권리가 있습니다.",
  p63:  "불만 사항을 제출하십시오. 귀하는 관할 데이터 보호 당국에 청구를 제기할 권리가 있습니다. 또한 귀하가 유럽 거주자인 경우 당사는 귀하와 체결할 수 있는 계약 이행을 위해 귀하의 개인 데이터를 처리하고 있음을 인지합니다. , 또는 나열된 적법한 사업 이익 위에 당사의 목적을 추구하기 위해 CCPA, 개인 정보 보호(내 개인 정보를 판매하지 않음) 및 기타 권리에 따라 캘리포니아 소비자는 다음과 같은 권리를 갖습니다.",
  p64:  "소비자에 대한 개인 데이터를 수집하는 기업은 기업이 소비자에 대해 수집한 개인 데이터의 범주와 특정 부분을 공개해야 합니다.",
  p65:  "기업이 소비자에 대해 수집한 개인 데이터를 삭제하도록 요구합니다.",
  p66:  "소비자의 개인 데이터 판매가 아닌 소비자의 개인 데이터를 판매하는 기업에서 요청한 것입니다. 이러한 권리를 행사하려면 지원팀{'@'}QueenG.io에 문의하세요.",
  p67_h:  "귀하의 개인정보 수집을 제한하세요",
  p68:  "어느 시점에서 귀하는 개인 데이터의 사용 및 수집을 제한하고 싶을 수도 있습니다. 다음을 수행하여 이를 수행할 수 있습니다. 웹 사이트에서 양식을 작성할 때 개인 정보 공개를 원하지 않는 경우, 선택을 취소할 수 있는 상자가 있는지 꼭 확인하세요.",
  p69:  "QueenG는 귀하의 동의를 얻지 않는 한 귀하의 개인 정보를 제3자에게 임대, 판매 또는 배포하지 않습니다. 법률이 강제하는 경우 그렇게 할 수 있습니다. 귀하가 본 개인정보 보호정책에 동의하는 경우, 당사는 귀하에게 다음과 같은 정보를 제공할 것입니다. 홍보물 발송 시 개인정보를 기재합니다.",
  p70_h:  "개인 정보 보호 정책 변경",
  p71:  "당사는 본 개인정보 보호정책을 수시로 변경할 수 있습니다(예: 법률이 변경되는 경우). 모든 변경 사항은 당사 웹사이트에 즉시 게시되며 귀하가 처음으로 개인정보 보호정책을 수락한 것으로 간주됩니다. 변경 사항에 따라 웹사이트를 방문하세요. 이 페이지를 정기적으로 검토하여 최신 상태를 유지하는 것이 좋습니다. 변경 사항에 동의하지 않는 경우 고객 지원팀에 문의하여 설명을 확인하거나 계정을 폐쇄하는 것이 좋습니다."
}
