// 个人中心的翻译
export default {
  myGame:  "เกมของฉัน",
  totalWin:  "ชัยชนะทั้งหมด",
  totalBets:  "จำนวนการเดิมพันทั้งหมดที่วาง",
  betsToday:  "จำนวนเดิมพันวันนี้",
  betsWeek:  "จำนวนการเดิมพันในสัปดาห์นี้",
  myBonus:  "เงินปันผลของฉัน",
  pledgeDividends:  "จำนำเงินปันผล",
  providePOOL:  "มอบสภาพคล่องของ POOL มาเป็นหุ้นส่วนและเพลิดเพลินไปกับเงินปันผลจากกองรางวัลแต่ละรางวัล",
  dividendYesterday:  "เงินปันผลเมื่อวาน",
  accumulatedDividends:  "เงินปันผลสะสม",
  myMining:  "การขุดของฉัน",
  gameMining:  "การขุดเกม",
  participate:  "เล่นเกมและมีส่วนร่วมในการขุดรายวันและมีสิทธิออกเสียง",
  miningYesterday:  "การขุดเมื่อวานนี้",
  cumulativeExcavation:  "การขุดสะสม",
  miningYesterdayChu:  "ขุดออกมาเมื่อวาน",
  nearly:  "การขุดใน 7 วันที่ผ่านมา",
  totalMining:  "การขุดทั้งหมด",
  settlementDate:  "วันที่ชำระบัญชี",
  gameAmount:  "จำนวนเกมวันนี้",
  numberGames:  "จำนวนเกม",
  miningShare:  "ส่วนแบ่งการขุด",
  miningIncome:  "กำไรจากการขุด",
  details:  "รายละเอียด xx",
  return:  "กลับ",
  viewDetails:  "ดูรายละเอียด",
  yesterdayEarnings:  "รายได้เมื่อวาน",
  earningsRecentDays:  "รายได้ใน 7 วันที่ผ่านมา",
  totalIncome:  "รายได้รวม",
  settlementLP:  "นิคมแอลพี",
  dividends:  "เงินปันผล",
  operation:  "ดำเนินงาน",
}
