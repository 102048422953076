function createMessage(type, content) {
  let messageBox = document.getElementById('message-box');
  if (!messageBox) {
    messageBox = document.createElement('div')
    messageBox.id = "message-box"
    window.document.body.appendChild(messageBox);

    const messageOne = document.createElement('div')
    messageOne.className = `message-one ${type}`
    messageOne.innerHTML = `
      <img class="message-icon" src="/common/${type}.png" />
      <div class="message-content">${content}</div>
    `
    messageBox.appendChild(messageOne);
    setTimeout(() => {
      messageOne.remove()
    }, 3000);
  } else {
    const messageOne = document.createElement('div')
    messageOne.className = `message-one ${type}`
    messageOne.innerHTML = `
      <img class="message-icon" src="/common/${type}.png" />
      <div class="message-content">${content}</div>
    `
    messageBox.appendChild(messageOne);
    setTimeout(() => {
      messageOne.remove()
    }, 3000);
  }

};
const message = {
  success(content) {
    createMessage('success', content)
  },
  error(content) {
    createMessage('error', content)
  },
}
export default {
  install(Vue) {
    Vue.config.globalProperties.$message = message;
  },
};
export { message }