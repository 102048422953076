import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4f91a4d6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "contentArea"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_my_header = _resolveComponent("my-header");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_my_header), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])])], 64);
}