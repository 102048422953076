export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    style: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    }
  }
};