// 首页的翻译
export default {
  qGTHasHeenAwarded:  "QGT đã được trao giải",
  homePage:  "trang đầu",
  lasVegas:  "Sòng bạc",
  throwTheDice:  "ném xúc xắc",
  roulette:  "cò quay",
  guessTheRiseAndFall:  "đoán tăng hay giảm",
  slotMachine:  "May đanh bạc",
  aloneGame:  "Trò chơi nhỏ một người chơi",
  arcade:  "giải trí",
  pledge:  "Lời hứa",
  commissionBack:  "hạ giá",
  noviceMission:  "Nhiệm vụ mới",
  qGTTokens:  "Mã thông báo QGT",
  helpCenter:  "Trung tâm trợ giúp",
  games:  'trò chơi',
  user:  'người dùng',
  payout:  'Đặt cược',
  gamesTypeAll:  'tất cả',
  gamesType1:  'Sòng bạc',
  gamesType2:  'May đanh bạc',
  gamesType3:  'Trò chơi nhỏ độc lập',
  gamesType4:  'giải trí',
  gamesType5:  'Sự kiện thể thao',
  gamesType1msg:  'Khách hàng có thể chọn đặt cược vào bất kỳ cửa nào khác theo ý muốn của mình.',
  gamesType2msg:  'Sau khi nhét đồng xu vào, hãy kéo cần gạt và nó sẽ bắt đầu quay.',
  gamesType3msg:  'Sau khi nhét đồng xu vào, hãy kéo cần gạt và nó sẽ bắt đầu quay.',
  gamesType4msg:  'Sau khi nhét đồng xu vào, hãy kéo cần gạt và nó sẽ bắt đầu quay.',
  gamesType5msg:  'Các sự kiện thể thao sắp diễn ra.',
  gamesRecordText:  'Kỷ lục trò chơi',
  theOriginalGame:  "trò chơi gốc",
  miningMode:  "tức là chế độ khai thác",
  miningRewards:  "Bạn đặt cược càng nhiều, bạn sẽ nhận được càng nhiều phần thưởng và phần thưởng khai thác sẽ được giải quyết theo thời gian thực",
  qGTCanBeDugToday:  "Khai thác đang đến",
  highestEarnedMultiplier:  "Các sự kiện thể thao đang đến",
  gamesPlayed:  "Đã chơi trò chơi",
  gamesPlayedNew:  "Trò chơi tôi thường chơi gần đây",
  highestWin:  "Chiến thắng cao nhất",
  participant:  "Người tham gia",
  canBeDugToday:  "Hôm nay bạn có thể đào",
  highReturn:  "Lợi nhuận cao",
  highYield:  "Thu nhập cao",
  signIn:  "Đăng nhập",
  registration:  "đăng ký",
  purse:  "cái ví",
  search:  "tìm kiếm",
  hideOneBalance:  "Ẩn số dư 0",
  displayUSD:  "Hiển thị đô la",
  personalCenter:  "Trung tâm cá nhân",
  invite:  "Sao chép liên kết lời mời",
  inviteCopy:  "Đã sao chép liên kết lời mời thành công",
  setUp:  "cài đặt",
  Language:  "cài đặt ngôn ngữ",
  LanguageZh:  "Truyền thống Trung Quốc",
  LanguageEn:  "Tiếng Anh",
  logOutAccount:  "Thoát tài khoản"

};
