// 个人中心的翻译
export default {
  myGame:  "Meu jogo",
  totalWin:  "Total de vitórias",
  totalBets:  "Número total de apostas feitas",
  betsToday:  "Número de apostas de hoje",
  betsWeek:  "Número de apostas esta semana",
  myBonus:  "Meu dividendo",
  pledgeDividends:  "Dividendo de penhor",
  providePOOL:  "Forneça liquidez ao POOL, torne-se sócio e aproveite os dividendos de cada premiação",
  dividendYesterday:  "Dividendo de ontem",
  accumulatedDividends:  "Dividendos cumulativos",
  myMining:  "Minha Mineração",
  gameMining:  "Mineração de jogos",
  participate:  "Jogue e participe da mineração diária e tenha direito a voto",
  miningYesterday:  "Mineração de ontem",
  cumulativeExcavation:  "Escavação cumulativa",
  miningYesterdayChu:  "Desenterrado ontem",
  nearly:  "Mineração nos últimos 7 dias",
  totalMining:  "Mineração Total",
  settlementDate:  "Data de liquidação",
  gameAmount:  "Valor do jogo de hoje",
  numberGames:  "Número de jogos",
  miningShare:  "Compartilhamento de Mineração",
  miningIncome:  "Lucro da Mineração",
  details:  "xx detalhes",
  return:  "retornar",
  viewDetails:  "Ver detalhes",
  yesterdayEarnings:  "Ganhos de ontem",
  earningsRecentDays:  "Ganhos nos últimos 7 dias",
  totalIncome:  "Rendimento total",
  settlementLP:  "Liquidação LP",
  dividends:  "dividendo",
  operation:  "operar",
}
