// 帮助-Qa的翻译
export default {
  title:  "ปัญหาทั่วไป",
  title1:  "1) การถอนเงินของฉันยังมาไม่ถึงใช่หรือไม่",
  p1_1:  "เหตุใดการถอนเงินของคุณจึงยังไม่เข้ากระเป๋าเงินของคุณ",
  p1_2:  "มีสาเหตุหลายประการที่ทำให้การถอนเงินของคุณไม่เข้ากระเป๋าเงินของคุณ สาเหตุที่พบบ่อยที่สุดคือการทำธุรกรรมที่ไม่ได้รับการยืนยัน",
  p1_3:  "ทุกธุรกรรมจะต้องได้รับการยืนยันก่อนที่จะโอนไปยังที่อยู่ปลายทาง จำนวนการยืนยันที่ต้องการขึ้นอยู่กับกระเป๋าสตางค์แต่ละใบ เมื่อพูดถึง Bitcoin กระเป๋าเงินบางใบต้องการเพียงการยืนยัน 1 ครั้ง ในขณะที่บางใบต้องการการยืนยัน 3+ ครั้ง การยืนยันเหล่านี้จะใช้เวลาบางอย่าง ระยะเวลา",
  p1_4:  "หากจำนวนเงินที่ถอนออกมามาก อาจติดขัดและต้องมีการดำเนินการด้วยตนเองจากผู้ดูแลระบบของเรา",
  p1_5:  "หมายเหตุ: แม้แต่การถอนเงินด้วยตนเองก็ยังได้รับการประมวลผลเกือบจะในทันที คุณไม่จำเป็นต้องกังวล เพียงแค่อดทนรอ",
  title2:  "2) การเดิมพันและการขุดคืออะไร",
  p2_1:  "ยอดรวมของ QGT คือ 8 พันล้าน โดย 50% ใช้สำหรับรางวัลการเดิมพัน นั่นคือ การเล่นเกมคือการขุด และส่วนนี้จะลดลงทุกปี ผู้ใช้สามารถเพลิดเพลินกับเงินปันผลของรายได้จากการขุดของวันก่อนหน้าได้ทุกวัน วัน และยิ่งพวกเขาเดิมพันมากเท่าไร ยิ่งเพลิดเพลินมากเท่าไรก็ยิ่งได้รับมากขึ้นเท่านั้น",
  p2_2:  "ข้อได้เปรียบของการเล่นและการขุดคือแม้ว่าผู้ใช้จะขาดทุนในเกม พวกเขายังสามารถได้รับผลกำไรที่สูงขึ้นจากการขาย QGT ในเวลาเดียวกัน QGT ยังออนไลน์บนการแลกเปลี่ยนภายนอกในเวลาเดียวกัน และผู้ใช้สามารถถ่ายโอนและ หมุนเวียนอย่างเสรี",
  title3:  "3) จะมีการจ่ายค่าคอมมิชชั่นและเงินปันผลเมื่อใด",
  p3_1:  "ค่าคอมมิชชั่นโบนัสและเงินปันผลของผู้สนับสนุนสำหรับการคืนเงินคำเชิญจะถูกตัดสินวันละครั้ง และรางวัลสำหรับวันก่อนหน้าจะแจกจ่ายหลังเวลา 00:00 UTC ของวันถัดไป",
  p3_2:  "การออกรางวัลการขุดจะเหมือนกัน",
  title4:  "4) ฉันสามารถฝากเหรียญดิจิทัลผ่านเครือข่าย BEP2 หรือ Tron ได้หรือไม่",
  p4_1:  "คุณสามารถตรวจสอบเครือข่ายและสกุลเงินที่รองรับได้ผ่านพื้นที่เติมเงินกระเป๋าสตางค์ หากคุณไม่เห็น แสดงว่ายังไม่รองรับการเติมเงิน",
  title5:  "5) เกี่ยวกับค่าธรรมเนียมการจัดการและค่าธรรมเนียมการถอน",
  p5_1:  "ทุกครั้งที่คุณชนะโบนัสจากการเดิมพัน แพลตฟอร์มจะหักค่าธรรมเนียมการเดิมพัน 0.3% แต่จะไม่ถูกใช้เมื่อคุณแพ้เกม",
  p5_2:  "สำหรับการถอนเงินแต่ละครั้ง แพลตฟอร์มจะเรียกเก็บค่าธรรมเนียมการถอนเงินตามอัตราเครือข่ายปัจจุบัน"
}
