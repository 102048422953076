// 设置的翻译
export default {
  routine: "常规",
  emailAddress: "电邮地址",
  changeEmail: "更换电子邮箱",
  cellPhoneNumber: "手机号",
  areaCode: "区号",
  preservation: "保存",
  originalMailbox: "原邮箱验证码",
  mailbox: "原邮箱",
  sendVerificationCode: "发送验证码",
  nextStep: "下一步",
  newEmailAddress: "新邮箱地址",
  confirmReplacement: "确认更换",
  originalPhoneCode: "原手机验证码",
  originalPhone: "原手机号",
  newPhoneNumber: "新手机号",
  oldPassword: "旧密码",
  newPassword: "新密码",
  confirmNewPassword: "确认新密码",
  oldPasswordError: "旧密码错误",
  pleaseOldPassword: "请输入旧密码",
  pleaseNewPassword: "请输入新密码",
  password: "密码",
  changePhone: "更换手机号",
  savePhone: "保存手机号",
  phoneNumber: "已向手机号",
  resend: "重新发送",
  mailSending: "邮件发送频率过快",
  enterCorrectCode: "请输入正确的验证码",
  checkEmail: "请检查电子邮箱",
  sendingSucceeded: "发送成功",
  mailboxSucceeded: "更换邮箱成功",
  savingSucceeded: "保存成功",
  enterConfirmationPassword: "请输入确认密码",
  passwordsTwiceInconsistent: "两次输入密码不一致",
  passwordSuccessfully: "密码修改成功"
}
