// 支持者的翻译
export default {
  todayDividend: "Dự kiến ​​hôm nay sòng bạc sẽ trả cổ tức",
  supplyYourLP:  "#Cung cấp LP của bạn để trở thành đối tác nền tảng và chia sẻ hoa hồng với nền tảng",
  supplyQGT:  "Cung cấp QGT",
  dividendsExpected:  "Cổ tức dự kiến ​​sẽ được phân phối",
  networkCosts:  "Phí mạng ước tính",
  pledge:  "Lời hứa",
  pledgedQGT:  "QGT cam kết",
  quantity:  "Số lượng",
  unlock:  "mở khóa",
  dividendsPaid:  "Cổ tức được chia",
  settlementDate:  "Ngày thanh toán",
  settlementQGT:  "Giải quyết QGT",
  dividends:  "cổ tức",
  operation:  "vận hành",
  viewTheDetails:   "Xem chi tiết",
  pledged:  "Đã cam kết",
  unlockQuantity:  "Số lượng đã mở khóa",
  correctBalance:  "Vui lòng nhập số dư chính xác",
  correctNumber:  "Vui lòng nhập đúng số",
  insufficientPledged:  "Số lượng cam kết không đủ",
  unlockSucceeded:  "Mở khóa thành công"
}
