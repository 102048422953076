// 帮助-Qgt代币的翻译
export default {
  title: "Visão geral do token QGT",
  title1: "1) Mineração",
  p1:  "A mineração é um sistema de mineração de moeda que explora QGT diariamente, fazendo apostas. QGT é um token de incentivo para o compartilhamento de ações do QueenG e um ambiente de jogo saudável. Os tokens QGT podem ser extraídos jogando e apostando. Suas apostas Quanto mais você aposta - mais QGT você é meu. Minerar mais QGT significa retornos diários constantes.",
  p2:  "⚠️ Para reduzir o peso da mineração e garantir seu correto processo, a aposta mínima para minerar QGT é de $ 1 (em qualquer moeda)",
  title2:  "2) Transação",
  p3_1:  "A plataforma lançará gradualmente as transações e pools de capital da QGT em vários níveis superiores, como: uniswap, justswap e ApeSwap",
  p3_2:  "A plataforma também apoiará diretamente a troca de QGT, e as transações internas serão a maneira mais rápida e fácil de comprar QGT.",
  p4:  "Torne-se um sortudo proprietário de QGT ou troque seus tokens QGT por outras criptomoedas.",
  title3:  "3) uso de QGT",
  subtitle1:  "3.1) Compromisso",
  p5:  "Ao apostar a liquidez do QGT (no módulo de apoiador), os usuários podem usufruir de dividendos e obter parte dos lucros da plataforma.",
  p6:  "A cada 24 horas, o pool de penhores liberará 20% do lucro total da plataforma para dividendos, ou seja, a receita diária da plataforma em diversas moedas será distribuída aos provedores de liquidez QGT na forma de dividendos. – mais lucros de dividendos você obtém! Lucre com todos os ganhos da plataforma!",
  subtitle2:  "3.2) Transação",
  p7: "Compre e venda tokens QGT nas bolsas TOP e você também pode manter tokens QGT para investir e se beneficiar. ",
  subtitle3:  "3.3) Jogos de azar",
  p8:  "Os tokens QGT podem ser usados ​​em todos os jogos do cassino e suas funções de aposta são as mesmas de outras moedas; a aposta mínima no jogo é 1 QGT.",
  title4:  "4) Destruição do QGT",
  p9:  "QueenG realiza destruição mensal de tokens QGT para apoiar o desenvolvimento saudável da economia monetária e do ecossistema. A destruição planejada aumenta o valor do token nativo QGT e mantém um equilíbrio entre detentores e desenvolvedores para apoiar o modelo de distribuição de tokens e o crescimento constante dos pagamentos de apostas para detentores de tokens. Os tokens são queimados mensalmente."
}
