import bus from '../../plugins/eventBus.js';
import LoginModal from '../LoginModal.vue';
import MySwitch from '../MySwitch.vue';
import Wallet from '../Wallet.vue';
export default {
  components: {
    LoginModal,
    Wallet,
    MySwitch
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  created() {
    bus.on('register', data => {
      this.formShow = true;
      this.formType = 0;
      if (data) {
        this.invitedId = Number(data);
      }
    });
    bus.on('login', () => {
      this.formShow = true;
      this.formType = 1;
    });
    if (location.search && location.search.slice(1).split('=')[1]) {
      this.formShow = true;
      this.invitedId = location.search.slice(1).split('=')[1];
    }
    this.languageTxtMeth();
  },
  watch: {
    '$store.state.Lang'() {
      this.languageTxtMeth();
    }
  },
  data() {
    return {
      formType: 0,
      formShow: false,
      showZeroCurrency: false,
      wallShow: false,
      invitedId: 0,
      searchText: '',
      languageTxt: ''
    };
  },
  computed: {
    balanceList() {
      return this.$store.getters.balanceList.filter(item => {
        return item.coinName.includes(this.searchText);
      });
    }
  },
  methods: {
    getInviteCode() {
      this.$http.post('/userManage/getInvitationCode').then(res => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', window.location.protocol + '//' + document.domain + '?invited_code=' + res.invitation_code);
        input.select();
        if (document.execCommand('copy')) {
          this.$message.success(this.$t("index.inviteCopy"));
        }
        document.body.removeChild(input);
      });
    },
    fixed(num) {
      if (num < 0.1) {
        return '0';
      } else {
        return String(num).replace(/^(.*\..{4}).*$/, '$1');
      }
    },
    showForm(type) {
      this.formType = type;
      this.formShow = true;
    },
    changeZeroShow() {
      this.showZeroCurrency = !this.showZeroCurrency;
    },
    changeDollerShow() {
      this.$store.commit('changeDollerShow', !this.$store.state.showDoller);
    },
    changeCurrency(currency) {
      this.$store.commit('changeCurrency', {
        code: currency.coinType,
        typeName: currency.coinName
      });
    },
    async logout() {
      this.$store.commit('logout');
      if (this.$store.state.connected) {
        await this.$web3ModalPlugin.disconnect();
      }
    },
    languageSwitch(lang, txt) {
      this.$store.commit('changeLang', lang);
      this.$i18n.locale = lang;
      this.languageTxt = txt;
    },
    languageTxtMeth() {
      if (this.$store.state.Lang == 'zh') {
        this.languageTxt = '中文';
      } else if (this.$store.state.Lang == 'th') {
        this.languageTxt = 'แบบไทย';
      } else if (this.$store.state.Lang == 'kr') {
        this.languageTxt = '한국인';
      } else if (this.$store.state.Lang == 'vn') {
        this.languageTxt = 'Tiếng Việt';
      } else if (this.$store.state.Lang == 'pt') {
        this.languageTxt = 'Português';
      } else if (this.$store.state.Lang == 'ma') {
        this.languageTxt = 'Melayu';
      } else {
        this.languageTxt = 'English';
      }
    }
  }
};