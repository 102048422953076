// 钱包的翻译
export default {
  deposit: "Deposit",
  withdrawal: "Withdrawal",
  other:"Others",
  recordDrawing:"Deposit and Withdrawal Records",
  network: "Network",
  tokenContract: "Token Contract Address:",
  your: "Your",
  depositAddress: "deposit address",
  minimumDeposit:"Minimum Deposit Amount",
  lowerAmounts:", amounts lower than this will not be credited or refunded.",
  sendOnly: "Please only send",
  toAddress:"to this address, requiring 1 confirmation for deposit to be credited.",
  yourWalletAddress: "Your wallet address",
  enterAddress:"Enter Address",
  quantity:"Amount",
  balance: "Balance",
  enterAmount:"Enter Amount",
  whole:"All",
  minimumWithdrawalAmount: "The minimum withdrawal amount is",
  transactionCosts:"Transaction Fee: - Processing Time - 0-2 hours. Network confirmations are not included,",
  additionalTime:"additional time may be required.",
  sendingAddress: "Sending address",
  accountArrivalTime:"Estimated Arrival Time",
  accountStates: "status",
  accountStatesTxt_1: "Rejected",
  accountStatesTxt0:"Pending Review",
  accountStatesTxt1:"Approved",
  accountStatesTxt2:"Transferred",
  withdrawalAddress: "Withdrawal address",
  serviceCharge:"Fee",
  pleaseEnterAddress: "Please enter the address",
  extract:"Withdraw",
  copySucceeded:"Copy Successful",
  enterCorrectBalance: "Please enter the correct balance",
  runningLow:"Insufficient Balance",
  withdrawalSucceeded:"Withdrawal Successful",
  otherCoinName: 'Currency name',
  otherAmount:"Amount",
  otherType: "Type",
  otherStatus: "Status",
  otherStatus1: "invalid",
  otherStatus2: "incomplete",
  otherStatus3: "Completed",
}
