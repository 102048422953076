// 个人中心的翻译
export default {
  myGame: "我的游戏",
  totalWin: "总赢取",
  totalBets: "总下注次",
  betsToday: "今日下注次数",
  betsWeek: "本周下注次数",
  myBonus: "我的分红",
  pledgeDividends: "质押分红",
  providePOOL: "提供POOL流动性 成为合伙人 享受每个奖池的分红",
  dividendYesterday: "昨日分红",
  accumulatedDividends: "累计分红",
  myMining: "我的挖矿",
  gameMining: "游戏挖矿",
  participate: "玩游戏参与每日挖矿 拥有投票权",
  miningYesterday: "昨日挖矿",
  cumulativeExcavation: "累计挖出",
  miningYesterdayChu: "昨日挖出",

  nearly: "近7天挖矿",
  totalMining: "总挖矿",
  settlementDate: "结算日期",
  gameAmount: "当日游戏额",
  numberGames: "游戏次数",
  miningShare: "挖矿份额",
  miningIncome: "挖矿收益",
  details: "xx明细",
  return: "返回",
  viewDetails: "查看明细",
  yesterdayEarnings: "昨日收益",
  earningsRecentDays: "近7天收益",
  totalIncome: "总收益",
  settlementLP: "结算LP",
  dividends: "分红",
  operation: "操作",
}
