// 帮助-Qgt代币的翻译
export default {
  title: "QGT 토큰 개요",
  title1: "1) 채굴",
  p1:  "마이닝은 베팅을 통해 매일 QGT를 채굴하는 통화 마이닝 시스템입니다. QGT는 QueenG 지분 공유와 건전한 게임 환경을 위한 인센티브 토큰입니다. QGT 토큰은 게임 플레이와 베팅을 통해 채굴할 수 있습니다. 베팅을 많이 할수록 - 더 많은 것을 얻을 수 있습니다. QGT를 채굴하세요. 더 많은 QGT를 채굴한다는 것은 꾸준한 일일 수익을 의미합니다.",
  p2:  "⚠️ 채굴 부담을 줄이고 올바른 프로세스를 보장하기 위해 QGT를 채굴하기 위한 최소 지분은 $1(모든 통화)입니다.",
  title2:  "2) 거래",
  p3_1:  "플랫폼은 uniswap, justswap 및 ApeSwap과 같은 다양한 최상위 수준에서 QGT의 거래 및 자본 풀을 점차적으로 시작할 것입니다.",
  p3_2:  "플랫폼은 QGT의 교환도 직접 지원할 것이며 내부 거래는 QGT를 구매하는 가장 빠르고 쉬운 방법이 될 것입니다.",
  p4:  "행운의 QGT 소유자가 되거나 QGT 토큰을 다른 암호화폐로 교환하세요.",
  title3:  "3) QGT 사용법",
  subtitle1:  "3.1) 서약",
  p5:  "(서포터 모듈에) QGT 유동성을 스테이킹함으로써 사용자는 배당금을 즐기고 플랫폼 수익의 일부를 얻을 수 있습니다.",
  p6:  "24시간마다 서약 풀은 플랫폼 총 수익의 20%를 배당금으로 공개합니다. 즉, 플랫폼의 다양한 통화 일일 수입이 배당금 형태로 QGT 유동성 공급자에게 분배됩니다. 귀하가 서약한 QGT 유동성이 많을수록 – 더 많은 배당 수익을 얻을 수 있습니다! 플랫폼에서 발생하는 모든 수익에서 수익을 얻으세요!",
  subtitle2:  "3.2) 거래",
  p7: "TOP 거래소에서 QGT 토큰을 사고 팔 수 있으며, QGT 토큰을 보유하여 투자하고 혜택을 누릴 수도 있습니다. ",
  subtitle3:  "3.3) 도박",
  p8:  "QGT 토큰은 카지노의 모든 게임에서 사용할 수 있으며 베팅 기능은 다른 통화와 동일합니다. 게임의 최소 베팅 금액은 1 QGT입니다.",
  title4:  "4) QGT의 파괴",
  p9:  "QueenG는 통화 경제와 생태계의 건전한 발전을 지원하기 위해 매월 QGT 토큰 폐기를 실시합니다. 계획된 폐기는 QGT 기본 토큰의 가치를 높이고 보유자와 개발자 간의 균형을 유지하여 토큰 배포 모델과 스테이킹 지불금의 지속적인 성장을 지원합니다. 토큰 보유자. 토큰은 매월 소각됩니다."

}
