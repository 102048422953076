// 日常任务的翻译
export default {
  manyRewards: "Many rewards are there",
  theDailyTask:  "daily tasks",
  getIt: "Wait for you to get it!",
  moreRewards: "The more you bet, the greater the rewards",
  completeGame: "Choose and complete daily game tasks to gain more excitement in QueenG!",
  distributed: "Daily tasks are distributed across different types of games. Complete them and get rewards!",
  dailyTask: "Daily task countdown:",
  bonusCompleting: "Additional rewards for completing all tasks:",
  getMany: "Receive",
  winDice1: "Bet on dice and win 30 times",
  winDice2: "Bet on a draw at dice and win 30 times",
  leopard: "The dice throw out once with triple matching number",
  winTwice: "Win x2 twice in a row in the dice using the same lucky number",
  roulette1: "Play Roulette 30 times in X2",
  roulette2: "Play Roulette 20 times in X3",
  roulette3: "Play Roulette 15 times in X5",
  roulette4: "Play Roulette 20 times in X50",
  guess1:  "Guess the rise or fall up to 30 times",
  guess2:  "Guess rise correctly for 20 times.",
  guess3:  "Guess fall correctly for 20 times",
  guess4: "Guess the fall five times in a row.",
  collectCompletion: "Click to receive after completion",
  notCompleted: "The task has not been completed yet",
  rewardsReceived: "Rewards have been received",
  SuccessfullyReceived: "Successfully received"
}
