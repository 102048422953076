// 帮助-隐私政策的翻译
export default {
  title:  "Política de Privacidade",
  p1:  "Reservamo-nos o direito de alterar esta Política de Privacidade a qualquer momento, e você será prontamente atualizado. Se quiser ter certeza de que está ciente das alterações mais recentes, recomendamos que visite esta página com frequência.",
  p2:  "Se você tiver dúvidas adicionais ou precisar de mais informações sobre nossa política de privacidade, não hesite em nos contatar.",
  p3:  "Esta Política de Privacidade aplica-se apenas às nossas atividades online e é válida para visitantes do nosso site no que diz respeito às informações que compartilharam e/ou coletaram no QueenG. Esta Política de Privacidade não se aplica a quaisquer informações coletadas offline ou através de canais diferentes deste. local na rede Internet." ,
  p4_h:  "concordar",
  p5:  "Pedimos o seu consentimento para o processamento de suas informações para fins específicos, e você tem o direito de retirar seu consentimento a qualquer momento. No entanto, ao usar nosso site, você concorda com nossa política de privacidade e com seus termos." ,
  p6_h:  "Quais dados do usuário coletamos",
  p7:  "Entende-se por “dados pessoais” qualquer informação relativa a uma pessoa singular identificada ou identificável; é considerada identificável uma pessoa singular que possa ser identificada, direta ou indiretamente, nomeadamente por referência a informações como nome, dados de localização, email, identificador online ou um identificador específico de um ou mais fatores específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular.",
  p8:  "Podemos processar dados que nos permitem entrar em contato com você ('Dados de Contato'). Os Dados de Contato podem incluir seu nome, endereço de e-mail. As fontes dos Dados de Contato são nosso formulário de registro, formulário de contato e aqueles fornecidos nas Informações de sua conta.",
  p9:  "Podemos processar os dados da sua conta de usuário do site ('Dados da conta'). Os dados da conta podem incluir o identificador da sua conta, nome, endereço de e-mail, datas de criação e modificação da conta, configurações do site. A fonte principal dos dados da conta é você; alguns elementos de os dados da conta podem ser gerados pelo nosso site.",
  p10:  "Podemos processar dados técnicos e financeiros, dados de uso'.Isso significa que quando você visita nosso site e/ou usa nossos serviços, podemos coletar informações técnicas e financeiras sobre você, que podem incluir: endereço IP, localidade, data de registro, data de login, afiliados associados, informações estatísticas relacionadas aos afiliados (a fonte de seu tráfego para nosso site, dados sobre suas visualizações e cliques em nossos materiais promocionais), datas e período de autoexclusão, data e período de blackout, número e hora de falha tentativas de login, saldo da conta, bônus da conta, fundos de dinheiro real da conta, data da transação, valor da transação, identificador da transação, total de bônus, total de bônus, total de depósito, total de depósito, retiradas Quantidade total de retiradas, total de retiradas, valor a ser retirado, número de saques a serem feitos, valor de saque errado, contagem de saques errada, proporção de depósito/saque, receita total",
  p11:  "Podemos processar informações contidas ou relacionadas a qualquer comunicação que você nos envie ou que lhe enviemos ('dados de comunicação'). Os Dados de Comunicação podem incluir o conteúdo da comunicação e os metadados associados à comunicação. Podemos gerar metadados relacionadas com comunicações efetuadas através de formulários de contacto.",
  p12_h:  "Por que coletamos seus dados",
  p13: "A QueenG apenas recolherá as suas informações pessoais por meios legais e justos e não de forma intrusiva para operar o seu negócio como casa de apostas online licenciada para os seguintes fins:",
  p14:  "Para autenticação Know Your Customer (KYC).",
  p15:  "Se você gostaria de assinar as comunicações de marketing da QueenG.",
  p16:  "Processe sua inscrição para se tornar membro do QueenG ou de qualquer subdomínio hospedado.",
  p17:  "Para fornecer e melhorar os serviços para você como membro.",
  p18:  "Para identificá-lo como membro e verificar sua identidade para fins de segurança e para cumprir nossas obrigações legais.",
  p19:  "Mantenha sua conta de membro.",
  p20:  "Atualize e aprimore sua experiência no Site ou em seu dispositivo, ou personalize ou desenvolva informações, serviços ou produtos para atender às suas necessidades, o que pode incluir pesquisas de mercado e realização de promoções.",
  p21:  "Criar dados agregados sobre os membros por meio de análise demográfica, realizar análises estatísticas do banco de dados para fornecer às partes interessadas potenciais e existentes e permitir uma operação mais eficiente dos negócios do QueenG. Também usamos esses dados para analisar se existem contas de usuários múltiplas contas.",
  p22:  "Em resposta às suas perguntas, comentários ou solicitações.",
  p23:  "Cumprir as obrigações contratuais, legais e estatutárias da QueenG.",
  p24:  "Tomar as medidas apropriadas se o QueenG tiver motivos para suspeitar que atividades ilegais ou má conduta de natureza grave foram, estão sendo ou podem estar envolvidas em conexão com nossas funções e atividades.",
  p25:  "Estabelecer, exercer ou defender qualquer reivindicação legal.",
  p26:  "Seus dados pessoais não serão divulgados a terceiros, a menos que exigido por lei. Os dados pessoais podem ser divulgados a parceiros de negócios ou fornecedores ou prestadores de serviços da QueenG, pois eles podem ser responsáveis ​​por certas partes da funcionalidade geral ou operação do site. Funcionários da QueenG poderá aceder aos seus dados pessoais para desempenhar as suas funções e prestar-lhe a melhor assistência e serviço possível. Você consente com tal divulgação.",
  p27:  "QueenG usa informações do usuário para fins de marketing. No entanto, respeitamos a privacidade de nossos usuários. Se os usuários não desejarem receber nenhum material promocional, eles podem selecionar tal opção ao se registrar ou cancelar a assinatura a qualquer momento.",
  p28_h:  "arquivo de log",
  p29:  "QueenG segue um procedimento padrão de uso de arquivos de log. Esses arquivos registram os visitantes sempre que eles visitam o site. As informações coletadas pelos arquivos de log incluem endereços de protocolo de Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), carimbo de data e hora, páginas de referência/saída e, possivelmente, o número de cliques. Eles não estão associados a nenhuma informação de identificação pessoal. O objetivo das informações é analisar tendências, administrar o site, rastrear o movimento dos usuários no site e coletar informações demográficas. " ,
  p31_h:  "Nosso uso de cookies",
  p32:  "Podemos colocar e acessar determinados Cookies em seu computador ou dispositivo. Um cookie é uma informação na forma de um arquivo de texto muito pequeno que é colocado no disco rígido de um usuário da Internet. É gerado por um servidor de páginas da web, que basicamente executa o computador de um site. Os cookies contêm informações que são definidas por um servidor e ficam disponíveis para o servidor sempre que um usuário visita o site.",
  p33:  "Ao utilizar nosso site, você concorda com o uso e armazenamento de cookies em seu computador ou dispositivo. Se você não deseja que nosso site use cookies em seu computador ou dispositivo, você pode desativar e excluir cookies a qualquer momento através das configurações do seu navegador .Para obter mais informações sobre cookies e como desativá-los e delta-los, mas observe que se você rejeitar ou excluir cookies, algumas partes do nosso site poderão não funcionar corretamente e poderemos não ser capazes de fornecer determinados serviços.",
  p34:  "Os cookies podem ser cookies 'persistentes' ou cookies de 'sessão': um cookie persistente será armazenado por um navegador da Web e permanecerá válido até a data de expiração definida, a menos que seja excluído pelo usuário antes da data de expiração; por outro lado, Os cookies de sessão expiram no final da sessão do usuário, quando o navegador da web é fechado.",
  p35:  "Utilizamos os seguintes cookies:",
  p36:  "Os cookies 'necessários' tornam o site utilizável, permitindo funções básicas, como navegação na página e acesso a áreas seguras do site. Sem esses cookies, o site não funcionará corretamente.",
  p37:  "Os cookies de preferências permitem que um site se lembre de informações, como a região em que você está, que alteram o comportamento ou a aparência do site.",
  p38:  "Os cookies estatísticos ajudam o site a entender como os visitantes interagem com o site, coletando e relatando informações anonimamente.",
  p39:  "Os cookies de 'segurança' são usados ​​como um elemento das medidas de segurança usadas para proteger as contas dos usuários, incluindo a prevenção do uso fraudulento de credenciais de login e a proteção do nosso site e serviços.",
  p40:  "Os cookies de autenticação e presença são usados ​​para identificá-lo quando você visita e navega em nosso site e nos ajudam a determinar se você está conectado ao nosso site.",
  p41:  "Os cookies de' marketing 'são usados ​​para rastrear visitantes em sites. Sua finalidade é exibir publicidade que seja relevante e envolvente para o usuário individual e, portanto, mais valiosa para os editores.",
  p42:  "Usamos cookies primários e de terceiros. Os cookies primários são colocados diretamente por nós e usados ​​apenas por nós. Usamos cookies para facilitar e melhorar sua experiência no site e para fornecer e melhorar nossos produtos e serviços . ",
  p43:  "Ao usar nosso site, você também pode receber determinados cookies de terceiros em seu computador ou dispositivo. Cookies de terceiros são aqueles colocados por sites, serviços e/ou terceiros que não nós. Além disso, usamos serviços analíticos, que também usam cookies. A análise do site refere-se a um conjunto de ferramentas utilizadas para coletar e analisar estatísticas de uso, permitindo-nos entender melhor como as pessoas usam o site.",
  p44:  "Este site usa o Google Analytics, um serviço de análise da web fornecido pela Google, Inc. ('Google'). O Google Analytics usa cookies para ajudar o site a analisar como os usuários usam o site. As informações geradas pelo cookie sobre o uso do nosso site (incluindo o seu endereço IP) serão transmitidos ao Google e armazenados em servidores nos Estados Unidos. O Google usará essas informações para analisar seu uso do site, compilar relatórios sobre a atividade do site para nós e realizar outros serviços relacionados ao uso do site e da Internet. uso. O Google também pode transferir essas informações a terceiros quando exigido por lei ou quando esses terceiros processam os dados em nome do Google.",
  p45_h:  "Protegendo e protegendo dados",
  p46:  "QueenG está empenhada em proteger seus dados e mantê-los confidenciais. QueenG tem feito tudo o que pode para evitar roubo de dados, acesso não autorizado e divulgação, implementando a mais recente tecnologia e software, o que nos ajuda a proteger todas as informações que coletamos online.",
  p47_h:  "Política de Privacidade de Terceiros",
  p48:  "Nossa Política de Privacidade não se aplica a outros anunciantes ou sites. Portanto, recomendamos que você revise as respectivas políticas de privacidade desses servidores de anúncios de terceiros para obter mais detalhes. Ela pode incluir suas práticas sobre como cancelar certas opções e Instruções. Você pode optar por desativar os cookies através das opções individuais do seu navegador. Para saber informações mais detalhadas sobre o gerenciamento de cookies com navegadores específicos, elas podem ser encontradas nos respectivos sites dos navegadores.",
  p49_h:  "transmissão de dados",
  p50:  "Mantemos servidores em todo o mundo e suas informações podem ser processadas em servidores fora do país em que você reside. As leis de proteção de dados variam de acordo com o país e algumas fornecem mais proteção do que outras. Não importa onde suas informações residam. Onde quer que processemos, aplicamos as mesmas salvaguardas descritas nesta política. Também cumprimos determinadas estruturas legais relacionadas às transferências de dados. A Comissão Europeia determinou que certos países fora do Espaço Econômico Europeu (EEE) forneçam proteção adequada para dados pessoais. Para a transferência de dados do EEE para outros países, como os Estados Unidos, cumprimos uma estrutura jurídica que estabelece um nível de proteção equivalente ao da legislação da UE. Quando recebermos uma reclamação formal por escrito, entraremos em contato com o reclamante em resposta.",
  p51_h:  'Retenção e eliminação de dados pessoais',
  p52:  "Nossas políticas e procedimentos são projetados para ajudar a garantir o cumprimento de nossas obrigações legais em relação à retenção e exclusão de dados pessoais. Os dados pessoais que processamos para qualquer finalidade não serão mantidos por mais tempo do que o necessário para essa finalidade ou para essas finalidades. . Sem prejuízo de qualquer outra disposição, poderemos conservar os seus dados pessoais sempre que tal seja necessário para o cumprimento de uma obrigação legal a que estejamos sujeitos, ou para proteger os seus interesses vitais ou os interesses vitais de outra pessoa singular.",
  p53_h:  "Seus direitos",
  p54:  "Se você é residente na Europa, tem o direito de acessar os dados pessoais que mantemos sobre você e de solicitar que seus dados pessoais sejam corrigidos, atualizados ou excluídos.",
  p55:  "Em particular, você tem o direito de fazer o seguinte:",
  p56:  "Retire o seu consentimento a qualquer momento. Se tiver consentido previamente com o tratamento dos seus dados pessoais, tem o direito de retirar o seu consentimento.",
  p57:  "Opor-se ao processamento dos seus dados. Você tem o direito de se opor ao processamento dos seus dados se o processamento for baseado em uma base legal sem consentimento.",
  p58:  "Acesso aos seus dados. Você tem o direito de saber se seus dados estão sendo processados, de obter divulgações sobre certos aspectos do processamento e de obter uma cópia dos dados que estão sendo processados.",
  p59:  "Verificar e solicitar retificação. Você tem o direito de verificar a veracidade dos seus dados e solicitar que eles sejam atualizados ou corrigidos.",
  p60:  "Restrição do tratamento dos seus dados. Em determinadas circunstâncias, tem o direito de restringir o tratamento dos seus dados. Neste caso, não processaremos os seus dados para qualquer outra finalidade que não seja o armazenamento.",
  p61:  "Exclua ou de outra forma exclua seus dados pessoais. Em determinadas circunstâncias, você tem o direito de nos solicitar a exclusão de seus dados.",
  p62:  "O direito à portabilidade dos dados. Você tem o direito de solicitar que transfiramos os dados que coletamos para outra organização, ou diretamente para você, sob certas condições.",
  p63:  "Apresente uma reclamação. Você tem o direito de apresentar uma reclamação junto à autoridade competente de proteção de dados. Além disso, se você for residente europeu, observamos que estamos processando seus dados pessoais para a execução de um contrato que possamos ter com você , ou de outra forma para perseguir nossos objetivos acima, interesses comerciais legítimos listados. De acordo com a CCPA, privacidade (não vender minhas informações pessoais) e outros direitos, os consumidores da Califórnia têm o direito de:",
  p64:  "Exigir que as empresas que coletam dados pessoais sobre os consumidores divulguem as categorias e os dados pessoais específicos que a empresa coletou sobre os consumidores.",
  p65:  "Exigir que as empresas excluam quaisquer dados pessoais que a empresa tenha coletado sobre os consumidores.",
  p66:  "Solicitado por empresas que vendem dados pessoais de consumidores, não pela venda de dados pessoais de consumidores. Se desejar exercer algum desses direitos, entre em contato conosco pelo suporte{'@'}QueenG.io",
  p67_h:  "Restringir a recolha dos seus dados pessoais",
  p68:  "Em algum momento, você pode querer restringir o uso e a coleta de seus dados pessoais. Você pode fazer isso da seguinte forma: Ao preencher um formulário no site, se não quiser que suas informações pessoais sejam divulgadas, por favor, certifique-se de verificar se há uma caixa que você pode desmarcar.",
  p69:  "QueenG não alugará, venderá ou distribuirá suas informações pessoais a terceiros, a menos que obtenhamos sua permissão. Poderemos fazê-lo se a lei nos obrigar a fazê-lo. Se você concordar com esta Política de Privacidade, forneceremos a você Use seu informações pessoais ao enviar materiais promocionais.",
  p70_h:  "Alterações na nossa política de privacidade",
  p71:  "Podemos alterar esta Política de Privacidade de tempos em tempos (por exemplo, se a lei mudar). Quaisquer alterações serão imediatamente publicadas em nosso site e será considerado que você aceitou os termos da Política de Privacidade na primeira utilização do site após as alterações. Recomendamos que você revise esta página regularmente para se manter atualizado. Se você não concordar com quaisquer alterações, recomendamos que você entre em contato com o suporte ao cliente para esclarecimentos ou feche sua conta de outra forma."
}
