// 登录注册的翻译
export default {
  emailOrUserName:  "อีเมล์หรือชื่อผู้ใช้",
  password:  "รหัสผ่าน",
  signIn:  "เข้าสู่ระบบ",
  registration:  "ลงทะเบียน",
  emailOrUsernameNotExist:  "ไม่มีอีเมลหรือชื่อผู้ใช้",
  passwordError:  "รหัสผ่านผิด",
  loginSuccessful:  "เข้าสู่ระบบสำเร็จ",
  email:  "อีเมล",
  userName:  "ชื่อผู้ใช้",
  invitationCode:  "รหัสการเชิญ",
  termsServiceConfirmation:  "การคลิกช่องทำเครื่องหมายแสดงว่าคุณได้อ่านและยืนยันแล้ว",
  service:  "ข้อกำหนดและเงื่อนไข",
  mailboxExists:  "มีอีเมลอยู่แล้ว",
  userNameExists:  "ชื่อผู้ใช้อยู่แล้ว",
  incorrectMailbox:  "รูปแบบอีเมลไม่ถูกต้อง",
  incorrectPasswords:  "รหัสผ่านต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร และต้องมีตัวอักษรและจุดทศนิยม",
  invitationCodeNotExist:  "ไม่มีรหัสคำเชิญ",
  registrationSuccessful:  "ความสำเร็จในการลงทะเบียน",
  pleaseCheckEmail:  "กรุณาตรวจสอบอีเมลของคุณ",
  pleaseEnterEmailAddress:  "กรุณากรอกอีเมลของคุณ",
  enterUserNameOrEmail:  "กรุณากรอกชื่อผู้ใช้หรือที่อยู่อีเมลของคุณ",
  pleaseInputPassword:  "กรุณากรอกรหัสผ่าน",
  verificationCode:  "รหัสยืนยัน",
  sendVerificationCode:  "ส่งรหัสยืนยัน",
  mailSent:  "ส่งข้อความแล้ว",
  enterVerificationCode:  "กรุณากรอกรหัสยืนยัน",
  enterUserName:  "กรุณากรอกชื่อผู้ใช้",
  agreeConditions:  "โปรดยอมรับข้อกำหนดและเงื่อนไข",
  forgetPassword:  'ลืมรหัสผ่าน',
  forgetEmail:  'อีเมล',
  forgetCode:  'รหัสยืนยัน',
  forgetLogin:  'ไปที่เข้าสู่ระบบ',
  forgetConfirm:  'ยืนยัน',
  forgetErrorEmail:  'กรุณากรอกที่อยู่อีเมลของคุณ',
  forgetErrorVerificationCode:  'กรุณากรอกรหัสยืนยัน',
  forgetErrorSuccess:  'รีเซ็ตรหัสผ่านสำเร็จ'
}
