// 邀请的翻译
export default {
  instantly1:  "Dapatkan Gundam sekarang",
  instantly2:  "3%",
  instantly3:  "jumlah yang tinggi",
  instantly4:  "Rebat Keuntungan",
  invitationCode:  "Kod Jemputan",
  copy:  "salinan",
  share:  "kongsi",
  invited:  "Dijemput",
  commissionReturned:  "Komisen telah dikembalikan",
  commissionAccumulated:  "Rebat telah terkumpul",
  date:  "Tarikh",
  commissionRebate:  "Rebat rebat untung bersih",
  bettingGoldRebate:  "Rebat pada pertaruhan",
  miningCommission:  "Rebat perlombongan",
  total:  "jumlah",
  operation:  "beroperasi",
  viewDetails:  "Lihat butiran",
  goodFriends:  "Kawan",
  entryTime:  "masa ketibaan",
  howRebate:  "Bagaimana untuk mendapatkan komisen daripada rakan-rakan",
  inviteFriends:  "menjemput kawan-kawan",
  shareLink:  "Kongsi pautan dengan rakan anda, dan rakan anda akan log masuk ke platform melalui pautan",
  friendsLogIn:  "Log Masuk Rakan",
  friendsToPlatform:  "Selepas rakan anda log masuk ke platform, cas semula dan sertai permainan",
  giveOutRewards:  "mengedarkan ganjaran",
  friendsPlayGames:  "Rakan bermain permainan di platform dan menjemput orang ramai untuk mendapatkan rebat keuntungan",
  tip1:  "1. Jemput rakan anda untuk menyertai platform, dan anda boleh mendapat sehingga 3% daripada rebat yuran pengendalian.",
  step1:  "Langkah pertama: Pergi ke halaman jemput rakan dan kongsi poster atau pautan jemputan anda dengan rakan anda.",
  step2:  "Langkah 2: Rakan log masuk ke platform melalui pautan jemputan, dan rakan boleh menerima ganjaran komisen dengan menyertai permainan.",
  step3:  "Langkah 3: Pendapatan yang dijana oleh rakan bermain permainan di platform, pendapatan perlombongan dan pertaruhan rakan yang memenangi platform semuanya akan dikembalikan kepada anda dalam perkadaran tertentu.",
  tip2:  "2. Ganjaran rebat akan dijelaskan kepada anda dalam masa nyata dan diedarkan ke dompet akaun anda.",
  tip3:  "3. Tiada had atas bilangan jemputan. Lebih ramai kawan yang anda jemput, lebih banyak rebat yang anda akan terima. Setiap rakan boleh menikmati rebat secara kekal. Teruskan dan jemput lebih ramai kawan!",
  scanningCode1:  "Imbas kod untuk menerima",
  scanningCode2:  "Gundam",
  scanningCode3:  "ganjaran perlombongan WCC 3%",
  longPress:  "Tekan lama untuk mengenal pasti kod QR",
  enteringThePage:  "Selepas memasuki halaman, klik Daftar untuk menyertai permainan dengan segera",
  savePicture:  "simpan Gambar",
  rewardRules:  "Peraturan Ganjaran",
  copySucceeded:  "Berjaya menyalin",
  hiQGT:  "Hai, selamat datang ke platform permainan FUN3.0",
  airdropBenefits:  "",
  clickGetIt:  "Daftar dan jemput rakan bermain permainan untuk menikmati komisen tinggi dan ganjaran perlombongan",
  receive:  "Mulakan pendaftaran"
}
