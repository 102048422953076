export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    switchOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      menuListShow: true,
      languageList: [{
        name: '香港',
        value: 'zh'
      }, {
        name: 'USA',
        value: 'en'
      }]
    };
  },
  computed: {
    chosedLanguage() {
      return this.languageList.filter(item => {
        return item.value == this.$store.state.Lang;
      })[0];
    }
  },
  methods: {
    // gameJump (gameType) {
    //   this.$httpOutside.post('/cc88/getGameUrl', {gameType}).then(res => {
    //     window.open(res);
    //   })
    // },
    toggleMenuList() {
      this.menuListShow = !this.menuListShow;
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('changeLang', lang);
      this.$refs.language.blur();
    }
  }
};