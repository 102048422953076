// 帮助-Qa的翻译
export default {
  title: "일반적인 문제",
  title1:  "1) 출금이 아직 도착하지 않았나요?",
  p1_1:  "인출금이 아직 지갑에 도착하지 않은 이유는 무엇입니까?",
  p1_2:  "출금 금액이 지갑에 도착하지 않는 데에는 여러 가지 이유가 있으며, 가장 일반적인 이유는 확인되지 않은 거래입니다.",
  p1_3:  "모든 거래는 대상 주소에 입금되기 전에 확인되어야 합니다. 필요한 확인 횟수는 지갑마다 다릅니다. 비트코인의 경우 일부 지갑에서는 1번의 확인만 필요하고 다른 지갑에서는 3번 이상의 확인이 필요합니다. 이러한 확인에는 일정 시간이 걸립니다. 시간이 얼마 남지 않았습니다.",
  p1_4:  "인출 금액이 클 경우 중단되어 관리자의 수동 처리가 필요할 수 있습니다.",
  p1_5:  "참고: 수동 인출도 거의 즉시 처리되므로 걱정할 필요가 없습니다. 인내심을 가지세요.",
  title2:  "2) 배팅과 마이닝이란 무엇인가",
  p2_1:  "QGT의 총량은 80억개이며, 그 중 50%는 베팅 보상, 즉 게임을 하는 것이 채굴이며, 이 부분은 매년 감소하고 있습니다. 사용자는 매일 전날의 채굴 수익에 대한 배당금을 누릴 수 있습니다. 더 많이 베팅할수록 더 많이 즐길수록 더 많은 것을 얻게 됩니다.",
  p2_2:  "플레이와 채굴의 장점은 사용자가 게임에서 손실을 입더라도 QGT를 판매하면 더 높은 수익을 얻을 수 있다는 것입니다. 동시에 QGT는 외부 거래소에도 동시에 온라인 상태이며 사용자는 전송 및 전송이 가능합니다. 자유롭게 순환하세요.",
  title3:  "3) 수수료와 배당금은 언제 지급되나요?",
  p3_1:  "초대 리베이트에 대한 보너스 커미션과 서포터 배당금은 1일 1회 정산되며, 전날 보상은 다음 날 0:00 UTC 이후에 지급됩니다.",
  p3_2:  "마이닝 보상 발행은 동일합니다;",
  title4:  "4) BEP2 또는 Tron 네트워크를 통해 디지털 코인을 입금할 수 있나요?",
  p4_1:  "지갑 충전 구역을 통해 지원되는 네트워크 및 통화를 확인할 수 있습니다. 표시되지 않으면 아직 충전이 지원되지 않는 것입니다.",
  title5:  "5) 취급수수료 및 출금수수료에 대하여",
  p5_1:  "베팅을 통해 보너스를 얻을 때마다 플랫폼은 0.3%의 베팅 수수료를 공제하지만 게임에서 패배할 경우에는 사용되지 않습니다.",
  p5_2:  "각 인출에 대해 플랫폼은 현재 네트워크 요율에 따라 해당 인출 수수료를 청구합니다."
}
