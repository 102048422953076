// 帮助-隐私政策的翻译
export default {
  title: "Privacy policy",
  p1: "We reserve the right to change this Privacy Policy at any time, and you will be promptly updated. If you want to ensure you are aware of the latest changes, we recommend that you visit this page frequently.",
  p2: "If you have additional questions or need more information about our privacy policy, please feel free to contact us.",
  p3: "This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in QueenG. This Privacy Policy does not apply to any information collected offline or through channels other than this website.",
  p4_h: "agree",
  p5: "We ask for your consent to the processing of your information for specific purposes, and you have the right to withdraw your consent at any time. However, by using our website, you hereby agree to our privacy policy and agree to its terms.",
  p6_h: "What user data do we collect",
  p7: "'Personal data' shall be understood as any information relating to an identified or identifiable person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to information such as name, location data, email, online identifier or an identifier specific to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.",
  p8: "We may process data that enables us to contact you ('Contact Data'). Contact Data may include your name, email address. The sources of Contact Data are our registration form, contact form and those provided in your account Information.",
  p9: "We may process your Website user account data ('Account Data'). Account Data may include your account identifier, name, email address, account creation and modification dates, Website settings. The primary source of Account Data is you ;Some elements of the account data may be generated by our website.",
  p10: "We may process technical and financial data, usage data'. This means that when you visit our website and/or use our services, we may collect technical and financial information about you, which may include : IP address, locale, registration date, login date, associated affiliates, affiliate-related statistical information (the source of your traffic to our website, data on your views and clicks on our promotional materials), self-exclusion dates and Period, blackout date and period, number and time of failed login attempts, account balance, account bonus, account real money funds, transaction date, transaction value, transaction identifier, bonus total, bonus total, deposit total, deposit total, withdrawals Total amount of withdrawals, total withdrawals, amount to be withdrawn, number of withdrawals to be made, wrong withdrawal amount, wrong withdrawal count, deposit/withdrawal ratio, total earnings",
  p11: "We may process information contained in or relating to any communication that you send to us or that we send to you ('communication data'). Communication Data may include the content of the communication and metadata associated with the communication. We may Generate metadata related to communications made using contact forms.",
  p12_h: "Why do we collect your data",
  p13: "QueenG will only collect your personal information by lawful and fair means and not in an intrusive manner to operate its business as a licensed online bookmaker for the following purposes:",
  p14: "For know-your-customer (KYC) authentication.",
  p15: "If you would like to subscribe to QueenG's marketing newsletter.",
  p16: "Process your application to become a member of QueenG or any hosted subdomain.",
  p17: "To provide and improve services to you as a member.",
  p18: "Identify you as a member and verify your identity for security purposes and comply with our legal obligations.",
  p19: "Maintain your membership account.",
  p20: "Upgrade and enhance your experience within the site or on the device, or customize or develop information, services or products to meet your needs, which may include market research and conducting promotions.",
  p21: "Statistical analysis of the database by creating summary data about members through demographic analysis to be available to potential and existing stakeholders and to allow for more efficient operation of QueenG's business. We also use this data to analyze whether a user account has multiple accounts.",
  p22: "In response to your questions, comments or requests.",
  p23: "Comply with QueenG's contractual, legal and statutory obligations.",
  p24: "Take appropriate action if QueenG has reason to suspect that illegal activity or misconduct of a serious nature has been, is being or may be engaged in in connection with our functions and activities.",
  p25: "Establish, exercise or defend any legal claim.",
  p26: "Your personal data will not be disclosed to third parties unless required by law. As QueenG business partners or suppliers or service providers may be responsible for some parts of the overall functionality or operations of the website, personal data may be disclosed to them. QueenG employees have access to your personal data in order to perform their duties and provide you with the best help and services. You hereby consent to such disclosure.",
  p27: "QueenG uses user information for marketing purposes. However, we respect the privacy of our users. If users do not wish to receive any promotional materials, they can select such an option when registering or unsubscribe at any time.",
  p28_h: "The log file",
  p29: "QueenG follows a standard procedure of using log files. These files record visitors whenever they visit the website. The information collected by log files includes Internet Protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp , referring/exit pages, and possibly the number of clicks. These are not associated with any personally identifiable information. The purpose of the information is to analyze trends, administer the site, track users' movement on the site, and gather demographic information.",
  p31_h: "Our use of cookies",
  p32: "We may place and access certain Cookies on your computer or device. A cookie is a piece of information in the form of a very small text file that is placed on an Internet user's hard drive. It is generated by a web page server, which basically runs a website computer. Cookies contain information that is set by a server and is available to the server whenever a user visits the site.",
  p33: "By using our website, you agree to the use and storage of cookies on your computer or device. If you do not want our website to use cookies on your computer or device, you can disable and delete cookies at any time through browser Settings. For more information about cookies and how to disable and delta, please note that if you reject or delete cookies, some parts of our website may not function properly and we may not be able to provide you with certain services.",
  p34: "Cookies can be either 'persistent' cookies or 'session' cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; on the other hand, Session cookies expire at the end of the user session, when the web browser is closed.",
  p35: "We use the following cookies:",
  p36: '"Necessary" cookies help make websites usable by enabling basic features such as page navigation and access to secure areas of the website. Without these cookies, the website will not function properly.',
  p37: 'The Preferences cookie enables a website to remember information that changes the way a website behaves or looks, such as where you are located.',
  p38: '"Statistics" cookies help websites understand how visitors interact with the site by anonymously collecting and reporting information.',
  p39:'"Secure" cookies are used as an element of the security measures used to protect user accounts, including preventing the fraudulent use of login credentials and protecting our website and services.',
  p40:'The "Authentication and Status" cookie is used to identify you when you visit our website and browse our website and to help us determine if you are logged into our website.',
  p41: '"Marketing" cookies are used to track visitors across websites. The aim is to show ads that are relevant and engaging to individual users, and thus more valuable to publishers.',
  p42: "We use both first-party and third-party cookies. The first-party Cookie is placed directly by us for our use only. We use cookies to promote and improve your experience of the website and to provide and improve our products and services.",
  p43: "By using our website, you may also receive certain third-party cookies on your computer or device. Third-party cookies are those placed by websites, services and/or other parties other than us. In addition, we use analytics services, which also use cookies. Website analytics refers to a set of tools used to collect and analyze usage statistics, allowing us to better understand how people use websites.",
  p44: `The site uses Google Analytics, a web analytics service provided by Google Inc. (" Google "). Google Analytics uses cookies to help websites analyze how users are using websites. The information generated by cookies about your use of our site, including your IP address, will be transferred to Google and stored on servers in the United States. Google will use this information to analyze your use of the site, compile website activity reports for us, and perform further services related to site use and Internet use. Google may also transfer this information to third parties when required by law or when third parties process the data on Google's behalf.`,
  p45_h: "Protect and secure data",
  p46: "QueenG is committed to protecting your data and keeping it confidential. QueenG has done its best to prevent data theft, unauthorised access and disclosure by implementing the latest technology and software, which helps us protect all the information we collect online.",
  p47_h: "Third Party Privacy Policy",
  p48: "Our privacy policy does not apply to other advertisers or websites. Therefore, we recommend that you review the respective privacy policies of these third-party AD servers for more detailed information. It may include their practices and instructions on how to opt out of certain options. You can choose to disable cookies through your personal browser option. More detailed information about cookie management for a particular web browser can be found on the browsers' respective websites.",
  p49_h: "The data transfer",
  p50: "We maintain servers around the world and your information may be processed on servers outside your country/region of residence. Data protection laws vary by country/region, with some providing more protection than others. Regardless of where your information is processed, we apply the same protections described in this policy. We also comply with certain legal frameworks related to data transfer. The European Commission has established that certain countries/territories outside the European Economic Area (EEA) adequately protect personal data. To transfer data from the EEA to other countries/regions, such as the US, we comply with a legal framework that establishes the same level of protection as EU law. When we receive a formal written complaint, we contact the complainant in response.",
  p51_h: "Retention and deletion of personal data",
  p52: "Our policies and procedures are designed to help ensure that we comply with our legal obligations relating to the retention and deletion of personal data. Personal data processed by us for any purpose shall not be kept longer than is necessary for that purpose or for those purposes. Notwithstanding other provisions, we may retain your personal data in order to comply with our legal obligations or to protect your vital interests or the vital interests of other natural persons.",
  p53_h: "Your rights",
  p54: "If you are a European resident, you have the right to access the personal data we hold about you and request that your personal data be corrected, updated or deleted.",
  p55: "In particular, you have the right to do the following:",
  p56: "Withdraw your consent at any time. If you have previously consented to the processing of your personal data, you have the right to withdraw your consent.",
  p57: "Object to processing your data. If the processing is done on a legal basis without consent, you have the right to object to the processing of your data.",
  p58: "Access your data. You have the right to know if data is being processed, to obtain disclosure about certain aspects of processing, and to obtain a copy of the data being processed.",
  p59: "Verify and seek corrective action. You have the right to verify the accuracy of your data and request that it be updated or corrected.",
  p60: "Limit the processing of your data. In some cases, you have the right to limit the processing of your data. In this case, we will not process your data for any purpose other than storage.",
  p61: "Delete or otherwise delete your personal data. Under certain circumstances, you have the right to ask us to delete your data.",
  p62: "Right to data portability. You have the right to request that we transfer the data we collect to another organization, or directly to you, under certain conditions.",
  p63: "File a complaint. You have the right to file a claim with the competent data protection authority. In addition, if you are a resident of Europe, it has come to our attention that we are processing your personal data to fulfill a contract we may have entered into with you, or otherwise pursue our legitimate business interests listed above. Under the CCPA, privacy (Don't Sell My Personal Information), and other rights, California consumers have the right to:",
  p64: "Companies that collect personal data about consumers are required to disclose the types and specific fragments of personal data that companies collect about consumers.",
  p65: "Require businesses to delete any personal data that businesses have collected about consumers.",
  p66: "Ask companies that sell consumers' personal data, not consumers' personal data. If you would like to exercise any of these rights, please contact us in the following ways to support {'@'}queeng.io",
  p67_h: "Limit the collection of your personal data",
  p68: "At some point, you may want to limit the use and collection of your personal data. You can do this by doing the following: When you fill out a form on the website, make sure to check if there is a box you can uncheck if you don't want to give out your personal information.",
  p69: "QueenG will not rent, sell or distribute your personal information to any third party unless it has your permission. If the law forced us to, we might. If you agree to this Privacy Policy, we will use your personal information when we need to send you promotional materials.",
  p70_h: "Changes to our privacy policy",
  p71: "We may change this Privacy Policy from time to time (for example, if the law changes). Any changes will be immediately posted on our website and you will be deemed to have accepted the terms of the Privacy Policy when you use the site for the first time after the change. We recommend that you check this page regularly to keep up to date. If you do not agree to any changes, we recommend that you contact customer support to clarify or otherwise close your account."
}
