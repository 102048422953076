// 设置的翻译
export default {
  routine: "convencional",
  emailAddress:  "endereço de email",
  changeEmail:  "Mude o endereço de email",
  cellPhoneNumber:  "Número de telefone",
  areaCode:  "Código de área",
  preservation:  "salvar",
  originalMailbox:  "Código de verificação de e-mail original",
  mailbox:  "e-mail original",
  sendVerificationCode:  "Enviar o código de verificação",
  nextStep:  "Próxima Etapa",
  newEmailAddress:  "Novo Endereço de Email",
  confirmReplacement:  "Confirmar substituição",
  originalPhoneCode:  "Código de verificação original do celular",
  originalPhone:  "Número de telemóvel original",
  newPhoneNumber:  "Novo número de celular",
  oldPassword:  "Senha Antiga",
  newPassword:  "Nova Senha",
  confirmNewPassword:  "Confirme a nova senha",
  oldPasswordError:  "A senha antiga está errada",
  pleaseOldPassword:  "Por favor, digite sua senha antiga",
  pleaseNewPassword:  "Por favor, insira uma nova senha",
  password:  "senha",
  changePhone:  "Alterar número de celular",
  savePhone:  "Salvar número de telefone",
  phoneNumber:  "O número do celular foi enviado",
  resend:  "Reenviar",
  mailSending:  "Os e-mails são enviados com muita frequência",
  enterCorrectCode:  "Por favor, insira o código de verificação correto",
  checkEmail:  "Por favor verifique seu email",
  sendingSucceeded:  "Enviado com sucesso",
  mailboxSucceeded:  "E-mail alterado com sucesso",
  savingSucceeded:  "Salvo com sucesso",
  enterConfirmationPassword:  "Por favor, digite a senha de confirmação",
  passwordsTwiceInconsistent:  "As senhas inseridas duas vezes são inconsistentes",
  passwordSuccessfully:  "Redefinição de senha concluída"

}
