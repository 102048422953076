// 帮助-关于的翻译
export default {
  aboutQueenG: "About QueenG",
  p1:  "QueenG is an iGaming platform based on BSC. While retaining traditional gambling habits, it is completely decentralized, ensuring the fairness and openness of the game process, providing all users with a unique gaming experience, a simple and easy-to-use interface and operation path, and making the experience more enjoyable and optimized.",
  p2: "Supports multiple blockchain wallet connection logins, assets are controlled by the user's own wallet, and user earnings is settled to the wallet instantly, which is absolutely safe and reliable.",
  p3: "The QueenG platform is divided into two parts: gambling and sports. Gambling includes traditional casino games such as dice and slot machines, and sports includes betting on various events. In the early stage, it will be original games, and then it will be connected to various game suppliers and partners, and will be launched online through cooperation within the platform.",
  major: "Four major benefits for users",
  tip1: "1）Pledge income",
  detail1: "Users who Stake the liquidity of the platform currency can enjoy platform earnings dividends.",
  tip2: "2) Deposit income",
  detail2:  "After the platform provides various farm products and fixed deposit digital currencies, the corresponding annualized earnings can be obtained after the deposit expires.",
  tip3: "3) Mining income",
  detail3: "User games are mining. The more games you play, the more platform currency mining rewards you can get.",
  tip4: "4) Rebate income",
  detail4:  "Users share the invitation links, invitees register and actively play games on the platform, and inviters will receive passive earnings.",
}
