// 帮助-隐私政策的翻译
export default {
  title: "隐私政策",
  p1: "我们保留随时更改本隐私政策的权利，您将及时得到更新。如果您想确保了解最新的变化，我们建议您经常访问此页面。",
  p2: "如果您有其他问题或需要有关我们隐私政策的更多信息，请随时与我们联系。",
  p3: "本隐私政策仅适用于我们的在线活动，对于我们网站的访问者在 QueenG共享和/或收集的信息有效。本隐私政策不适用于线下或通过本网站以外的渠道收集的任何信息。",
  p4_h: "同意",
  p5: "我们要求您同意为特定目的处理您的信息，并且您有权随时撤回您的同意。但是，通过使用我们的网站，您在此同意我们的隐私政策并同意其条款。",
  p6_h: "我们收集哪些用户数据",
  p7: "“个人数据”应理解为与已识别或可识别的人有关的任何信息；可识别的自然人是可以直接或间接识别的人，特别是通过参考诸如姓名、位置数据、电子邮件、在线标识符或特定于身体、生理、遗传的一个或多个因素的标识符，该自然人的精神、经济、文化或社会身份。",
  p8: "我们可能会处理使我们能够与您取得联系的数据（“联系数据”）。联系数据可能包括您的姓名、电子邮件地址。联系数据的来源是我们的注册表、联系表和您帐户中提供的信息。",
  p9: "我们可能会处理您的网站用户帐户数据（“帐户数据”）。帐户数据可能包括您的帐户标识符、姓名、电子邮件地址、帐户创建和修改日期、网站设置。帐户数据的主要来源是您；帐户数据的某些元素可能由我们的网站生成。",
  p10: "我们可能会处理与“技术和财务数据、使用数据”。这意味着，当您访问我们的网站和/或使用我们的服务时，我们可能会收集有关您的技术和财务信息，其中可能包括：IP 地址、区域设置、注册日期、登录日期、关联的关联公司、关联公司相关的统计信息（您访问我们网站的流量来源、您对我们宣传材料的浏览和点击的数据）、自我排除日期和期间、禁止日期和期间、登录尝试失败次数和时间、账户余额、账户奖金、账户真实货币资金、交易日期、交易价值、交易标识符、赠金总额、赠金总额、存款总额、存款总额、提款总额、提款总额、待提款金额、待提款次数、错误提款金额、错误提款计数，存款/取款比率，总收入，",
  p11: "我们可能会处理您发送给我们或我们发送给您的任何通信中包含或与之相关的信息（“通信数据”）。通信数据可以包括通信内容和与通信相关联的元数据。我们可能会生成与使用联系表格进行的通信相关的元数据。",
  p12_h: "为什么我们收集您的数据",
  p13: "QueenG 将仅通过合法和公平的方式收集您的个人信息，而不是以侵入性的方式作为获得许可的在线博彩博彩公司运营其业务，用于以下目的：",
  p14: "用于了解您的客户 (KYC) 身份验证。",
  p15: "如果您想订阅 QueenG 的营销通讯。",
  p16: "处理您的申请以成为 QueenG 或任何托管子域的成员。",
  p17: "为您作为会员提供和改进服务。",
  p18: "将您识别为会员并出于安全目的验证您的身份并遵守我们的法律义务。",
  p19: "维护您的会员帐户。",
  p20: "升级和增强您在网站内或设备上的体验，或定制或开发信息、服务或产品以满足您的需求，其中可能包括市场研究和进行促销。",
  p21: "通过人口统计分析创建有关会员的汇总数据，对数据库进行统计分析，以提供给潜在和现有的利益相关者，并允许更有效地运营 QueenG 的业务。我们还使用这些数据来分析用户帐户是否存在多帐户。",
  p22: "回应您的问题、评论或请求。",
  p23: "遵守 QueenG 的合同、法律和法定义务。",
  p24: "如果 QueenG 有理由怀疑已经、正在或可能从事与我们的职能和活动相关的非法活动或严重性质的不当行为，则采取适当的行动。",
  p25: "建立、行使或捍卫任何法律主张。",
  p26: "除非法律要求，否则您的个人数据不会透露给第三方。由于 QueenG 业务合作伙伴或供应商或服务提供商可能对网站整体功能或运营的某些部分负责，因此可能会向他们披露个人数据。QueenG 的员工可以访问您的个人数据，以履行其职责并为您提供最好的帮助和服务。您特此同意此类披露。",
  p27: "QueenG 将用户信息用于营销目的。但是，我们尊重用户的隐私。如果用户不希望收到任何宣传材料，他们可以在注册时选择此类选项或随时取消订阅。",
  p28_h: "日志文件",
  p29: "QueenG 遵循使用日志文件的标准程序。这些文件在访问者访问网站时记录他们。日志文件收集的信息包括互联网协议 (IP) 地址、浏览器类型、互联网服务提供商 (ISP)、日期和时间戳、引用/退出页面以及可能的点击次数。这些与任何可识别个人身份的信息无关。信息的目的是分析趋势、管理网站、跟踪用户在网站上的活动以及收集人口统计信息。",
  p31_h: "我们对 Cookie 的使用",
  p32: "我们可能会在您的计算机或设备上放置和访问某些 Cookie。Cookie 是放置在互联网用户硬盘上的非常小的文本文件形式的一条信息。它由网页服务器生成，该服务器基本上是运行网站的计算机。Cookies 包含的信息是由服务器设置的，只要用户访问该站点，该服务器就可以使用这些信息。",
  p33: "通过使用我们的网站，您同意在您的计算机或设备上使用和存储 Cookie。如果您不希望我们的网站在您的计算机或设备上使用 Cookie，您可以随时通过浏览器设置停用和删除 Cookie。有关 Cookie 以及如何禁用和 delta 的更多信息,但请注意，如果您拒绝或删除 Cookie，我们网站的某些部分可能无法正常运行，并且我们可能无法为您提供某些服务。",
  p34: "Cookie 可以是“持久性”cookie 或“会话”cookie：持久性 cookie 将由网络浏览器存储，并在其设定的到期日之前一直有效，除非用户在到期日之前删除；另一方面，会话 cookie 将在用户会话结束时，即 Web 浏览器关闭时过期。",
  p35: "我们使用以下 cookie：",
  p36: "“必要的”cookie 通过启用页面导航和访问网站安全区域等基本功能来帮助网站可用。没有这些 cookie，网站将无法正常运行。",
  p37: "“首选项”cookie 使网站能够记住改变网站行为或外观方式的信息，例如您所在的地区。",
  p38: "“统计” cookie 通过匿名收集和报告信息，帮助网站了解访问者如何与网站互动。",
  p39: "“安全” cookie 被用作用于保护用户帐户的安全措施的一个元素，包括防止欺诈性使用登录凭据和保护我们的网站和服务。",
  p40: "“身份验证和状态”cookie 用于在您访问我们的网站和浏览我们的网站时识别您的身份，并帮助我们确定您是否登录了我们的网站。",
  p41: "“营销”cookie 用于跨网站跟踪访问者。其目的是展示与个人用户相关且引人入胜的广告，从而对发布商更有价值。",
  p42: "我们同时使用第一方和第三方 Cookie。第一方 Cookie 是我们直接放置的，仅供我们使用。我们使用 Cookie 来促进和改善您对网站的体验，并提供和改进我们的产品和服务。",
  p43: "通过使用我们的网站，您还可能在您的计算机或设备上收到某些第三方 Cookie。第三方 Cookie 是由网站、服务和/或我们以外的其他方放置的那些。此外，我们使用分析服务，这些服务也使用 Cookie。网站分析是指一组用于收集和分析使用统计数据的工具，使我们能够更好地了解人们如何使用网站。",
  p44: "该网站使用谷歌分析，一种由谷歌公司（“谷歌”）提供的网络分析服务。谷歌分析使用 Cookie 来帮助网站分析用户如何使用网站。由 Cookie 生成的关于您使用我们网站的信息（包括您的 IP 地址）将被传输到谷歌并存储在美国的服务器上。谷歌将使用这些信息来分析您对网站的使用，为我们编制网站活动报告，并执行与网站使用和互联网使用相关的进一步服务。Google 也可能会在法律要求或第三方代表 Google 处理这些数据的情况下将此信息传输给第三方。",
  p45_h: "保护和保护数据",
  p46: "QueenG 致力于保护您的数据并对其保密。QueenG 已尽其所能通过实施最新技术和软件来防止数据盗窃、未经授权的访问和披露，这有助于我们保护我们在线收集的所有信息。",
  p47_h: "第三方隐私政策",
  p48: "我们的隐私政策不适用于其他广告商或网站。因此，我们建议您查阅这些第三方广告服务器各自的隐私政策以获取更多详细信息。它可能包括他们关于如何选择退出某些选项的做法和说明。您可以通过您的个人浏览器选项选择禁用 cookie。要了解有关特定网络浏览器的 cookie 管理的更多详细信息，可以在浏览器各自的网站上找到。",
  p49_h: "数据传输",
  p50: "我们在世界各地维护服务器，您的信息可能会在您居住国家/地区以外的服务器上处理。数据保护法因国家/地区而异，有些提供比其他更多的保护。无论您的信息在何处处理，我们都会采用本政策中描述的相同保护措施。我们还遵守与数据传输相关的某些法律框架。欧盟委员会已确定欧洲经济区 (EEA) 以外的某些国家/地区充分保护个人数据。要将数据从 EEA 传输到其他国家/地区，例如美国，我们遵守与欧盟法律建立同等保护级别的法律框架。当我们收到正式的书面投诉时，我们会联系投诉人作为回应。",
  p51_h: "保留和删除个人数据",
  p52: "我们的政策和程序旨在帮助确保我们遵守与保留和删除个人数据有关的法律义务。我们为任何目的处理的个人数据的保存时间不得超过该目的或这些目的所需的时间。尽管有其他规定，我们可能会保留您的个人数据，前提是为了遵守我们所承担的法律义务，或为了保护您的切身利益或其他自然人的切身利益。",
  p53_h: "您的权利",
  p54: "如果您是欧洲居民，您有权访问我们持有的关于您的个人数据，并要求更正、更新或删除您的个人数据。",
  p55: "特别是，您有权执行以下操作：",
  p56: "随时撤回您的同意。如果您之前已同意处理您的个人数据，您有权撤回同意。",
  p57: "反对处理您的数据。如果处理是在未经同意的法律基础上进行的，您有权反对处理您的数据。",
  p58: "访问您的数据。您有权了解是否正在处理数据，获得有关处理某些方面的披露，并获得正在处理的数据的副本。",
  p59: "核实并寻求整改。您有权验证您的数据的准确性并要求对其进行更新或更正。",
  p60: "限制处理您的数据。在某些情况下，您有权限制对您的数据的处理。在这种情况下，我们不会出于存储以外的任何目的处理您的数据。",
  p61: "删除或以其他方式删除您的个人数据。在某些情况下，您有权要求我们删除您的数据。",
  p62: "数据可移植性的权利。您有权要求我们在某些条件下将我们收集的数据传输给另一个组织，或直接传输给您。",
  p63: "提出投诉。您有权向主管数据保护机构提出索赔。此外，如果您是欧洲居民，我们注意到我们正在处理您的个人数据以履行我们可能与您签订的合同，或以其他方式追求我们上面列出的合法商业利益。根据 CCPA、隐私权（请勿出售我的个人信息）以及其他权利，加州消费者有权：",
  p64: "要求收集消费者个人数据的企业披露企业收集的有关消费者的个人数据的类别和具体片段。",
  p65: "要求企业删除企业收集的有关消费者的任何个人数据。",
  p66: "要求出售消费者个人数据的企业，而不是出售消费者个人数据。如果您想行使这些权利中的任何一项，请通过以下方式与我们联系支持{'@'}QueenG.io",
  p67_h: "限制收集您的个人数据",
  p68: "在某些时候，您可能希望限制对您的个人数据的使用和收集。您可以通过执行以下操作来实现此目的：当您在网站上填写表格时，如果您不想透露您的个人信息，请确保检查是否有一个可以不选中的框。",
  p69: "除非获得您的许可，否则 QueenG 不会将您的个人信息出租、出售或分发给任何第三方。如果法律强迫我们，我们可能会这样做。如果您同意本隐私政策，我们将在需要向您发送宣传资料时使用您的个人信息。",
  p70_h: "更改我们的隐私政策",
  p71: "我们可能会不时更改本隐私政策（例如，如果法律发生变化）。任何更改将立即发布在我们的网站上，并且您将被视为在更改后首次使用本网站时已接受隐私政策的条款。我们建议您定期查看此页面以保持最新状态。如果您不同意任何更改，我们建议您联系客户支持以澄清或以其他方式关闭您的帐户。"
}
