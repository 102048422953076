const Index = () => import('./view/Index.vue')
const Casino = () => import('./view/gamesType/Casino.vue')
const SlotMachine = () => import('./view/gamesType/SlotMachine.vue')
const AloneGame = () => import('./view/gamesType/AloneGame.vue')
const Arcade = () => import('./view/gamesType/Arcade.vue')
const Sport = () => import('./view/gamesType/Sport.vue')

const Dice = () => import('./view/games/Dice.vue')
const Turntable = () => import('./view/games/Turntable.vue')
const Guess = () => import('./view/games/Guess.vue')



const Rebate = () => import('./view/rebate/Index.vue')
const RebateInvite = () => import('./view/rebate/Invite.vue')
const Invited = () => import('./view/rebate/Invited.vue')
const Ido = () => import('./view/Ido.vue')
const Task = () => import('./view/Task.vue')
const HelpAbout = () => import('./view/help/About.vue')
const HelpQgt = () => import('./view/help/Qgt.vue')
const HelpIdo = () => import('./view/help/Ido.vue')
const HelpGames = () => import('./view/help/Games.vue')
const HelpAccount = () => import('./view/help/Account.vue')
const HelpTermsService = () => import('./view/help/TermsService.vue')
const HelpPrivacyPolicy = () => import('./view/help/PrivacyPolicy.vue')
const HelpQa = () => import('./view/help/Qa.vue')
const User = () => import('./view/user/Index.vue')
const GameList = () => import('./view/user/GameList.vue')
const BonusList = () => import('./view/user/BonusList.vue')
const BonusDetail = () => import('./view/user/BonusDetail.vue')
const MiningList = () => import('./view/user/MiningList.vue')
const Setting = () => import('./view/setting/Index.vue')
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Index, name: 'Index' },
    { path: '/casino', component: Casino, name: 'Casino' },
    { path: '/slotMachine', component: SlotMachine, name: 'SlotMachine' },
    { path: '/aloneGame', component: AloneGame, name: 'AloneGame' },
    { path: '/arcade', component: Arcade, name: 'Arcade' },
    { path: '/sport', component: Sport, name: 'Sport' },


    { path: '/dice', component: Dice, name: 'Dice' },
    { path: '/turntable', component: Turntable, name: 'Turntable' },
    { path: '/guess', component: Guess, name: 'Guess' },






    { path: '/rebate', component: Rebate, name: 'Rebate', },
    { path: '/rebate/Invite', component: RebateInvite, name: 'RebateInvite', },
    { path: '/invited/:id', component: Invited, name: 'Invited', },
    { path: '/ido', component: Ido, name: 'Ido' },
    { path: '/task', component: Task, name: 'Task' },
    { path: '/helpCenter/about', component: HelpAbout, name: 'HelpAbout' },
    { path: '/helpCenter/qgt', component: HelpQgt, name: 'HelpQgt' },
    { path: '/helpCenter/ido', component: HelpIdo, name: 'HelpIdo' },
    { path: '/helpCenter/games', component: HelpGames, name: 'HelpGames' },
    { path: '/helpCenter/account', component: HelpAccount, name: 'HelpAccount' },
    { path: '/helpCenter/termsService', component: HelpTermsService, name: 'HelpTermsService' },
    { path: '/helpCenter/privacyPolicy', component: HelpPrivacyPolicy, name: 'HelpPrivacyPolicy' },
    { path: '/helpCenter/qa', component: HelpQa, name: 'HelpQa' },
    { path: '/user/index', component: User, name: 'User' },
    { path: '/user/gameList', component: GameList, name: 'GameList' },
    { path: '/user/bonusList', component: BonusList, name: 'BonusList' },
    { path: '/user/bonusDetail', component: BonusDetail, name: 'BonusDetail' },
    { path: '/user/miningList', component: MiningList, name: 'MiningList' },
    { path: '/setting/index', component: Setting, name: 'Setting' },
  ],
})

export default router