// 帮助-Qa的翻译
export default {
  title: "masalah biasa",
  title1:  "1) Pengeluaran saya belum sampai lagi?",
  p1_1:  "Mengapa pengeluaran anda masih belum masuk ke dalam dompet anda.",
  p1_2:  "Terdapat beberapa sebab mengapa pengeluaran anda tidak masuk ke dalam dompet anda, sebab yang paling biasa ialah transaksi yang tidak disahkan.",
  p1_3:  "Setiap transaksi mesti disahkan sebelum dikreditkan ke alamat destinasi. Bilangan pengesahan yang diperlukan bergantung dari dompet ke dompet. Apabila ia berkaitan dengan Bitcoin, sesetengah dompet hanya memerlukan 1 pengesahan manakala yang lain memerlukan 3+ Pengesahan. Pengesahan ini akan mengambil masa tertentu jumlah masa.",
  p1_4:  "Jika jumlah pengeluaran adalah besar, mereka mungkin tersekat dan memerlukan pemprosesan manual oleh pentadbir kami.",
  p1_5:  "Nota: Walaupun pengeluaran manual diproses hampir serta-merta, anda tidak perlu risau, hanya bersabar.",
  title2:  "2) Apa itu pertaruhan dan perlombongan",
  p2_1:  "Jumlah keseluruhan QGT ialah 8 bilion, 50% daripadanya digunakan untuk ganjaran pertaruhan, iaitu bermain permainan adalah melombong, dan bahagian ini dikurangkan setiap tahun. Pengguna boleh menikmati dividen pendapatan perlombongan hari sebelumnya setiap hari, dan lebih banyak mereka bertaruh, Lebih banyak anda menikmati, lebih banyak anda mendapat.",
  p2_2:  "Kelebihan bermain dan melombong ialah walaupun pengguna mengalami kerugian dalam permainan, mereka masih boleh mendapatkan keuntungan yang lebih tinggi dengan menjual QGT. Pada masa yang sama, QGT juga berada dalam talian di bursa luaran pada masa yang sama, dan pengguna boleh memindahkan dan beredar dengan bebas.",
  title3:  "3) Bilakah komisen dan dividen akan dibayar?",
  p3_1:  "Komisen bonus dan dividen penyokong untuk rebat jemputan diselesaikan sekali sehari, dan ganjaran untuk hari sebelumnya akan diagihkan selepas 0:00 UTC pada hari berikutnya;",
  p3_2:  "Pengeluaran ganjaran perlombongan adalah sama;",
  title4:  "4) Bolehkah saya mendepositkan syiling digital melalui rangkaian BEP2 atau Tron?",
  p4_1:  "Anda boleh menyemak rangkaian dan mata wang yang disokong melalui kawasan cas semula dompet. Jika anda tidak melihatnya, cas semula belum disokong lagi;",
  title5:  "5) Mengenai yuran pengendalian dan yuran pengeluaran",
  p5_1:  "Setiap kali anda memenangi bonus dengan pertaruhan, platform akan memotong 0.3% yuran pertaruhan, tetapi ia tidak akan digunakan apabila anda kalah dalam permainan;",
  p5_2:  "Untuk setiap pengeluaran, platform akan mengenakan bayaran pengeluaran yang sepadan berdasarkan kadar rangkaian semasa;"
}
