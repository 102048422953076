// 支持者的翻译
export default {
  todayDividend: "คาดว่าคาสิโนจะจ่ายเงินปันผลในวันนี้",
  supplyYourLP:  "#จัดหา LP ของคุณเพื่อเป็นพันธมิตรแพลตฟอร์มและแบ่งปันค่าคอมมิชชั่นกับแพลตฟอร์ม",
  supplyQGT:  "จัดหา QGT",
  dividendsExpected:  "คาดว่าจะมีการจ่ายเงินปันผล",
  networkCosts:  "ค่าบริการเครือข่ายโดยประมาณ",
  pledge:  "จำนำ",
  pledgedQGT:  "QGT ให้คำมั่น",
  quantity:  "ปริมาณ",
  unlock:  "ปลดล็อค",
  dividendsPaid:  "การจ่ายเงินปันผล",
  settlementDate:  "วันที่ชำระบัญชี",
  settlementQGT:  "การชำระบัญชี QGT",
  dividends:  "เงินปันผล",
  operation:  "ดำเนินงาน",
  viewTheDetails:   "ดูรายละเอียด",
  pledged:  "คำมั่นสัญญา",
  unlockQuantity:  "ปริมาณที่ปลดล็อค",
  correctBalance:  "กรุณากรอกยอดคงเหลือที่ถูกต้อง",
  correctNumber:  "กรุณากรอกหมายเลขให้ถูกต้อง",
  insufficientPledged:  "ปริมาณจำนำไม่เพียงพอ",
  unlockSucceeded:  "ปลดล็อคเรียบร้อยแล้ว"
}
