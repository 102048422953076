// 首页的翻译
export default {
  qGTHasHeenAwarded: "QGT foi premiado",
  homePage:  "primeira página",
  lasVegas:  "Cassino",
  throwTheDice:  "jogar os dados",
  roulette:  "roleta",
  guessTheRiseAndFall:  "adivinhe a ascensão ou queda",
  slotMachine:  "Máquina caça-níqueis",
  aloneGame:  "Minijogo para um jogador",
  arcade:  "videogames",
  pledge:  "Juramento",
  commissionBack:  "desconto",
  noviceMission:  "Tarefa de iniciante",
  qGTTokens:  "Token QGT",
  helpCenter:  "Centro de ajuda",
  games:  'jogo',
  user:  'do utilizador',
  payout:  'Fazer uma aposta',
  gamesTypeAll:  'todos',
  gamesType1:  'Cassino',
  gamesType2:  'Máquina caça-níqueis',
  gamesType3:  'Minijogo independente',
  gamesType4:  'videogames',
  gamesType5:  'Evento esportivo',
  gamesType1msg:  'Os clientes podem optar por fazer apostas em qualquer outra porta conforme sua vontade.',
  gamesType2msg:  'Depois de inserir a moeda, puxe a alavanca e ela começará a girar.',
  gamesType3msg:  'Depois de inserir a moeda, puxe a alavanca e ela começará a girar.',
  gamesType4msg:  'Depois de inserir a moeda, puxe a alavanca e ela começará a girar.',
  gamesType5msg:  'Eventos esportivos chegarão em breve.',
  gamesRecordText:  'Recorde do jogo',
  theOriginalGame:  "jogo original",
  miningMode:  "ou seja, modo de mineração",
  miningRewards:  "Quanto mais você apostar, mais recompensas receberá, e as recompensas de mineração serão liquidadas em tempo real",
  qGTCanBeDugToday:  "A mineração está chegando",
  highestEarnedMultiplier:  "Eventos esportivos estão chegando",
  gamesPlayed:  "Jogos jogados",
  gamesPlayedNew:  "Jogos que jogo com frequência recentemente",
  highestWin:  "Maior vitória",
  participant:  "Participante",
  canBeDugToday:  "Você pode cavar hoje",
  highReturn:  "Alto retorno",
  highYield:  "Alta renda",
  signIn:  "Conecte-se",
  registration:  "registro",
  purse:  "carteira",
  search:  "procurar",
  hideOneBalance:  "Ocultar saldo 0",
  displayUSD:  "Mostrar dólares",
  personalCenter:  "Centro Pessoal",
  invite:  "Copiar link do convite",
  inviteCopy:  "Link de convite copiado com sucesso",
  setUp:  "configurar",
  Language:  "configurações de linguagem",
  LanguageZh:  "Chinês tradicional",
  LanguageEn:  "Inglês",
  logOutAccount:  "Sair da conta"
};
