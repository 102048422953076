import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router'
import modal from './components/Modal.vue'
import MyInput from './components/MyInput.vue'
import MyButton from './components/MyButton.vue'
import MyTabs from './components/MyTabs.vue'
import MyPageSize from './components/MyPageSize.vue'
import MyToggle from './components/MyToggle.vue'
import MyEmpty from './components/MyEmpty.vue'
import http from './plugins/http.js'
import message from './plugins/message.js'
import store from './store.js'
import dayjs from './plugins/dayjs.js'
import web3 from './plugins/web3modal.js'
import i18n from "./i18n/index";
const app = createApp(App)

app.component('Modal', modal)
app.component('MyInput', MyInput)
app.component('MyButton', MyButton)
app.component('MyTabs', MyTabs)
app.component('MyPageSize', MyPageSize)
app.component('MyToggle', MyToggle)
app.component('MyEmpty', MyEmpty)
app.use(router)
app.use(http);
app.use(store)
app.use(message)
app.use(dayjs)
app.use(web3)
app.use(i18n)
app.mount('#app')
