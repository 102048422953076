// 三个游戏及我的投注的翻译
export default {
  pledge:  "Deposit",
  yawan:  "Oshe",
  bettingAmount:  "jumlah pertaruhan",
  highestWin:  "Kemenangan Tertinggi",
  castOnce:  "Undi 1 kali",
  castFive:  "Undi 5 kali",
  excavatedOnce:  "Satu pelaburan dijangka menghasilkan perlombongan",
  bigWinner:  "pemenang besar",
  mostFortunate:  "yang paling bertuah",
  rulesGame:  "peraturan permainan",
  player:  "pemain",
  betting:  "taruhan",
  multiple:  "pelbagai",
  bonus:  "bonus",
  placeBet:  "letak pertaruhan",
  result:  "hasil",
  earnings:  "pendapatan",
  bettingTime:  "Masa Penyelesaian",
  bettingDirection:  "Arah pertaruhan",
  aboutToBegin:  "Hampir untuk bermula",
  excavatedTurningOnce:  "Dianggarkan anda boleh menggalinya sebaik sahaja anda memindahkannya",
  drawTheLottery:  "Cabutan loteri!",
  latestPrice:  "Harga terkini",
  realTime:  "masa sebenar",
  oneMinute:  "1 minit",
  fifteenMinutes:  "15 minit",
  guessTheRise:  "teka kenaikan",
  guessTheFall:  "teka jatuh",
  bettingPrice:  "harga pertaruhan",
  settlementInProgress:  "Penyelesaian sedang dijalankan",
  game:  "permainan",
  return:  "kembali",
  theRise:  "bangkit",
  theFall:  "jatuh",
  openNote:  "pertaruhan terbuka",
  selected:  "dipilih",
  pleaseLoginFirst:  "sila log masuk dahulu",
  enterCorrectAmount:  "Sila masukkan jumlah pertaruhan yang betul",
  creditLow:  "Baki tidak mencukupi",
  maximumWorthTokens:  "Pertaruhan maksimum ialah 100,000 token nilai USDT",
  congratulationsWinning:  "Tahniah kerana menang",
  winningLottery:  "Tahniah atas kemenangan anda",
  didnnotWinPrize:  "Maaf saya tidak menang",

}
