// 邀请的翻译
export default {
  instantly1:  "รับกันดั้มเดี๋ยวนี้",
  instantly2:  "3%",
  instantly3:  "จำนวนเงินสูง",
  instantly4:  "ส่วนลดกำไร",
  invitationCode:  "รหัสการเชิญ",
  copy:  "สำเนา",
  share:  "แบ่งปัน",
  invited:  "เชิญ",
  commissionReturned:  "ได้รับค่าคอมมิชชั่นคืนแล้ว",
  commissionAccumulated:  "ส่วนลดสะสมแล้ว",
  date:  "วันที่",
  commissionRebate:  "ส่วนลดเงินคืนกำไรสุทธิ",
  bettingGoldRebate:  "ส่วนลดการเดิมพัน",
  miningCommission:  "ส่วนลดการขุด",
  total:  "ทั้งหมด",
  operation:  "ดำเนินงาน",
  viewDetails:  "ดูรายละเอียด",
  goodFriends:  "เพื่อน",
  entryTime:  "เวลาถึง",
  howRebate:  "วิธีรับค่าคอมมิชชั่นจากเพื่อน",
  inviteFriends:  "เชิญเพื่อน ๆ",
  shareLink:  "แชร์ลิงก์กับเพื่อนของคุณ แล้วเพื่อนของคุณจะเข้าสู่แพลตฟอร์มผ่านลิงก์",
  friendsLogIn:  "เข้าสู่ระบบเพื่อน",
  friendsToPlatform:  "หลังจากที่เพื่อนของคุณเข้าสู่ระบบแพลตฟอร์มแล้ว ให้เติมเงินและเข้าร่วมในเกม",
  giveOutRewards:  "แจกรางวัล",
  friendsPlayGames:  "เพื่อนเล่นเกมบนแพลตฟอร์มและเชิญผู้คนให้รับเงินคืน",
  tip1:  "1. เชิญเพื่อนของคุณให้เข้าร่วมแพลตฟอร์ม และคุณจะได้รับส่วนลดค่าธรรมเนียมการจัดการสูงสุดถึง 3%",
  step1:  "ขั้นตอนที่หนึ่ง: ไปที่หน้าเชิญเพื่อนและแชร์โปสเตอร์หรือลิงก์คำเชิญของคุณกับเพื่อน ๆ",
  step2:  "ขั้นตอนที่ 2: เพื่อนเข้าสู่ระบบแพลตฟอร์มผ่านลิงก์คำเชิญ และเพื่อน ๆ จะได้รับรางวัลค่าคอมมิชชันจากการเข้าร่วมในเกม",
  step3:  "ขั้นตอนที่ 3: รายได้ที่สร้างโดยเพื่อนที่เล่นเกมบนแพลตฟอร์ม รายได้จากการขุด และแพลตฟอร์มที่ชนะการเดิมพันของเพื่อน จะถูกคืนให้คุณในสัดส่วนที่แน่นอน",
  tip2:  "2. รางวัลเงินคืนจะถูกชำระให้กับคุณแบบเรียลไทม์และแจกจ่ายไปยังกระเป๋าเงินในบัญชีของคุณ",
  tip3:  "3. ไม่มีการจำกัดจำนวนคำเชิญ ยิ่งเชิญเพื่อนมากเท่าไร คุณก็จะได้รับส่วนลดมากขึ้นเท่านั้น เพื่อนแต่ละคนสามารถเพลิดเพลินกับส่วนลดอย่างถาวร เชิญเพื่อนเพิ่มได้เลย!",
  scanningCode1:  "สแกนรหัสเพื่อรับ",
  scanningCode2:  "กันดั้ม",
  scanningCode3:  "รางวัลการขุด WCC 3%",
  longPress:  "กดแบบยาวเพื่อระบุรหัส QR",
  enteringThePage:  "หลังจากเข้าสู่หน้าเพจแล้วให้กดลงทะเบียนเพื่อเข้าร่วมเกมทันที",
  savePicture:  "บันทึกรูปภาพ",
  rewardRules:  "กฎการให้รางวัล",
  copySucceeded:  "คัดลอกสำเร็จ",
  hiQGT:  "สวัสดี ยินดีต้อนรับสู่แพลตฟอร์มเกม FUN3.0",
  airdropBenefits:  "",
  clickGetIt:  "ลงทะเบียนและเชิญเพื่อนมาเล่นเกมเพื่อเพลิดเพลินกับค่าคอมมิชชั่นสูงและรางวัลการขุด",
  receive:  "เริ่มการลงทะเบียน",
}
