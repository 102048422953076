// 钱包的翻译
export default {
  deposit:  "deposit",
  withdrawal:  "Mengeluarkan",
  other:  "lain",
  recordDrawing:  "Rekod Deposit dan Pengeluaran",
  network:  "rangkaian",
  tokenContract:  "Alamat kontrak token:",
  your:  "Anda",
  depositAddress:  "Alamat Deposit",
  minimumDeposit:  "Jumlah deposit minimum",
  lowerAmounts:  ",Jumlah yang lebih rendah tidak akan dikreditkan atau dikembalikan.",
  sendOnly:  "Sila hantar sahaja",
  toAddress:  "Ke alamat ini, anda perlu mendapatkan 1 pengesahan untuk deposit dikreditkan.",
  yourWalletAddress:  "Alamat dompet anda",
  enterAddress:  "Masukkan alamat",
  quantity:  "kuantiti",
  balance:  "Seimbang",
  enterAmount:  "Masukkan jumlah",
  whole:  "semua",
  minimumWithdrawalAmount:  "Jumlah pengeluaran minimum ialah",
  transactionCosts:  "Yuran transaksi: - Masa yang dibelanjakan - 0-2 jam. Pengesahan rangkaian tidak disertakan,",
  additionalTime:  "Ia mungkin mengambil sedikit masa tambahan.",
  sendingAddress:  "alamat menghantar",
  accountArrivalTime:  "Masa ketibaan",
  accountStates:  "negeri",
  accountStatesTxt_1:  "Ditolak",
  accountStatesTxt0:  "Untuk disemak",
  accountStatesTxt1:  "Diaudit",
  accountStatesTxt2:  "Sudah tiba",
  withdrawalAddress:  "Alamat pengeluaran",
  serviceCharge:  "yuran pengendalian",
  pleaseEnterAddress:  "Sila masukkan alamat",
  extract:  "ekstrak",
  copySucceeded:  "Berjaya menyalin",
  enterCorrectBalance:  "Sila masukkan baki yang betul",
  runningLow:  "Baki tidak mencukupi",
  withdrawalSucceeded:  "Pengeluaran berjaya",
  otherCoinName:  'Nama mata wang',
  otherAmount:  'jumlah',
  otherType:  'jenis',
  otherStatus:  'negeri',
  otherStatus1:  'tidak sah',
  otherStatus2:  'dibatalkan',
  otherStatus3:  'selesai',
}
