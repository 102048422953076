// 帮助-游戏的翻译
export default {
  title: "게임",
  p1:  "QueenG는 카지노 현장에서 가장 인기 있는 게임을 모아 자체 개발했습니다. TOP 암호화폐를 사용하여 게임에 직접 배팅할 수 있습니다. 현재 사용자는 주사위 굴리기, 상승 및 하강 추측 등 독특한 디자인의 게임을 즐길 수 있습니다. , 그리고 큰 바퀴. 앞으로 더 많은 인기 카지노 게임도 출시할 예정입니다!",
  p2:  "각 게임에 해당하는 배당률은 다르지만 최대-최소 베팅 금액은 $1000000-$1로 고정되어 있습니다.",
  title1:  "1) 주사위를 굴린다",
  p3:  "크랩(Crap)은 플레이어가 새장에서 3개의 주사위를 돌려 결과에 돈을 거는 고대 중국의 주사위 게임입니다.",
  p4:  "게임이 시작되면 플레이어는 배팅 영역의 크기 방향이나 특정 포인트 수를 선택합니다. 배팅이 완료되면 주사위가 회전하기 시작합니다. 회전이 완료되면 플랫폼이 결과를 표시하고 발행합니다. 보상.",
  p5:  "기존 주사위와 달리 GAMES 주사위 게임은 제3자의 참여 없이 스마트 계약을 통해 베팅, 무작위 주사위, 당첨 보상, 자금/보상 처리 과정을 완전히 자동화합니다. 이는 기존의 전통적인 카지노 베팅보다 빠릅니다. 훨씬 더 효율적이고 공정합니다. .",
  subtitle1:  "1.1) 주문하기",
  p6:  "주사위 아래에는 6개의 작은 셀로 나누어진 베팅 영역 행이 있습니다. 이 행에서 주사위 회전 후 표시될 숫자에 베팅합니다. 세 개의 주사위 중 하나에 베팅한 숫자가 표시되면 귀하의 승률은 1 대 2입니다. 3개의 주사위 중 2개가 베팅한 숫자를 나타내면 귀하의 승률은 1 대 11입니다. 3개의 주사위 중 3개가 베팅한 숫자를 나타내면 귀하의 승률은 1 대 151입니다.",
  subtitle2:  "1.2) 무승부에 베팅",
  p7:  "동점 영역에서 베팅은 세 개의 주사위를 돌린 후의 숫자의 합입니다. '큰'은 숫자의 합이 11~17임을 의미하고, '소'는 숫자의 합이 4~10임을 의미합니다. 스핀 후의 숫자는 3입니다. 포인트가 동일하면 '표범'에 속하며 크고 작은 모두 패배합니다. 크고 작은 확률은 2:1, 표범 확률은 25:1입니다. ",
  title2:  "2) 상승 또는 하락 추측",
  p8:  "세 가지 주류 통화의 시장 상황을 기반으로 사용자는 강세 또는 약세를 선택할 수 있으며, 선택한 거래 쌍의 상승 및 하락을 15초 안에 예측할 수 있습니다. 한 방향으로 베팅한 후 15초 동안 시장 점프 카운트다운이 시작됩니다. 15초 후 가격 상승 및 하락을 비교합니다. 추측이 맞으면 가격이 즉시 상승 또는 하락합니다. 정산 후 리베이트합니다.",
  p9:  "베팅 정보:",
  p10:  "시장을 분석 및 확인한 후 배팅 금액을 설정하고 추측/추측 버튼을 클릭하세요. 추측 결과가 맞으면 해당 보너스를 2배로 받고, 추측 결과가 틀리면 배팅한 금액만 지급됩니다. 차감되며, 증가 또는 감소 금액이 0인 경우 무승부가 되며 베팅 금액은 환불됩니다.",
  title3:  "3) 큰 턴테이블",
  p11:  "큰 바퀴는 카지노에서 흔히 볼 수 있는 도박 게임입니다. 바퀴에는 선택할 수 있는 영역이 54개 있고 4가지 색상의 비율이 다릅니다. 보라색에는 블록이 26개 있고 확률은 2배, 빨간색에는 블록이 17개 있고 확률은 2배입니다. 은 3배 ;녹색은 10개, 확률은 5배, 노란색은 1개, 확률은 50배;",
  p12:  "베팅 금액을 설정하세요. 베팅 색상을 선택하면 룰렛 휠이 회전합니다. 룰렛 휠 회전이 멈춘 후 선택한 영역의 색상이 복권 색상이 됩니다. 추첨이 이루어진 후 즉시 정산이 완료되며, 보너스가 분배될 것입니다;"
}
