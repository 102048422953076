export default {
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => []
    },
    row: {
      type: Number,
      default: 10
    }
  },
  methods: {
    changTab(index) {
      this.$emit('update:modelValue', index);
      this.$emit('change', index);
    }
  }
};