// 帮助-Qa的翻译
export default {
  title: "常见问题",
  title1: "1）我的提款还没到账？",
  p1_1: "为什么您的提款仍未到达您的钱包。",
  p1_2: "您的提款没有到达您的钱包有几个原因，最常见的原因是未经确认的交易。",
  p1_3: "每笔交易在记入目标地址之前都必须得到确认。所需的确认数量取决于钱包到钱包。当涉及到比特币时，一些钱包只需要 1 次确认，而另一些钱包则需要 3 次以上的确认。这些确认都需要一定的时间。",
  p1_4: "如果提款金额很大，他们可能会卡住，需要我们的管理员手动处理。",
  p1_5: "注意：即使是手动提款也几乎可以立即处理，您无需担心，请耐心等待。",
  title2: "2）什么是下注即挖矿",
  p2_1: "QGT总量为80亿枚，用其中50％用于投注奖励，也就是玩游戏即挖矿，并且该部分每年减放。用户每天都可以享受前一日的挖矿收益分红，下注越多享受收益越多。",
  p2_2: "玩一把即挖矿的好处在于，用户即使在游戏当中产生亏损，也可以通过卖出QGT获得更高的收益。同时QGT也在外部交易所同时上线，用户可以自由转账与流通。",
  title3: "3）佣金和分红什么时候发？",
  p3_1: "邀请返佣的奖励佣金和支持者分红每日结算一次，为次日UTC0点后发放前一日的奖励；",
  p3_2: "挖矿奖励的发放也是同样的；",
  title4: "4）我可以通过 BEP2 或 Tron 网络存入数字币吗？",
  p4_1: "你可以通过钱包充值区域，查看支持的网络及币种，如未见则暂不支持充值；",
  title5: "5）关于手续费及提款费用",
  p5_1: "每次下注赢取奖金后，平台会扣取0.3%的下注手续费，而输了游戏时则不用；",
  p5_2: "每次提款是，平台将根据当前网路费率，收取对应提款手续费；"
}
