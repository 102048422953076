// 三个游戏及我的投注的翻译
export default {
  pledge:  "Bet Odds",
  yawan:  "Bet Draw",
  bettingAmount: "Betting amount",
  highestWin:  "Highest winning",
  castOnce: "Bet 1 time",
  castFive: "Bet 5 times",
  excavatedOnce: "The estimated mining reward for betting once is:",
  bigWinner: "Big winner",
  mostFortunate: "Most fortunate",
  rulesGame: "Rules of the game",
  player: "Player",
  betting: "Betting",
  multiple: "Multiple",
  bonus: "Bonus",
  placeBet: "Place a bet",
  result: "Result",
  earnings: "Earnings",
  bettingTime: "Settlement time",
  bettingDirection: "Betting direction",
  aboutToBegin: "It's about to begin",
  excavatedTurningOnce:  "It is estimated that it can be mined after turning once.",
  drawTheLottery: "Lottery draw!",
  latestPrice: "Latest price",
  realTime: "Real time",
  oneMinute: "1 minute",
  fifteenMinutes: "15 minutes",
  guessTheRise: "Guess the rise",
  guessTheFall: "Guess the fall",
  bettingPrice: "Betting price",
  settlementInProgress: "Settlement in progress",
  game: "Game",
  return: "Return",
  theRise: "Rise",
  theFall: "Fall",
  openNote: "open bet",
  selected: "Selected",
  pleaseLoginFirst: "Please login first",
  enterCorrectAmount: "Please enter the correct bet amount",
  creditLow: "Insufficient balance",
  maximumWorthTokens: "The maximum bet is 100,000 USDT value tokens",
  congratulationsWinning: "Congratulations on winning",
  winningLottery: "Congratulations on winning the lottery",
  didnnotWinPrize: "Unfortunately, you did not win this time."
}
