// 返佣的翻译
export default {
  rebateProgram:  "Program Rebat",
  usersShare:  "Apabila pengguna berkongsi pautan jemputan, orang yang dijemput menyambung melalui pautan dan bermain permainan secara aktif di platform, pengundang memperoleh pendapatan pasif. Semakin ramai pemain yang anda jemput - semakin tinggi pendapatan yang anda perolehi.",
  usingTheReferralLinks:  "Menggunakan pautan rujukan yang dijana oleh platform, ia akan direkodkan dan dijejaki bagaimana pengguna yang anda jemput datang dan apakah kemenangan yang anda terima daripada pertaruhan mereka.",
  enterInvitation:  "Masukkan halaman jemputan",
  commissionRebate:  "Rebat Untung Bersih",
  inviteeWinsBet:  "Setiap kali jemputan memenangi pertaruhan, 3% daripada kemenangan jemputan akan dibayar kepada anda serta-merta.",
  platformBetting:  "Rebat wang pertaruhan platform",
  inviteeLoses:  "Setiap kali jemputan kalah taruhan, platform akan mengambil 3% daripada pertaruhan dan membayarnya kepada anda.",
  miningCommission:  "Rebat perlombongan",
  miningRebate:  "Setiap kali jemputan memperoleh pendapatan daripada perlombongan, anda akan menerima komisen perlombongan yang sepadan sebanyak 3%."
}
