// 帮助-关于的翻译
export default {
  aboutQueenG: "퀸G 소개",
  p1:  "QueenG는 BSC를 기반으로 한 iGaming 플랫폼입니다. 전통적인 도박 습관을 유지하면서 완전히 분산되어 게임 프로세스의 공정성과 개방성을 보장하고 모든 사용자에게 독특한 게임 경험을 제공합니다. 간단하고 사용하기 쉬운 인터페이스 그리고 작업 경로는 최선을 다하는 경험을 만들 것입니다.",
  p2:  "다중 블록체인 지갑 연결 로그인을 지원하고, 자산은 사용자 자신의 지갑에 의해 통제되며, 사용자 수입은 즉시 지갑에 정산되므로 절대적으로 안전하고 신뢰할 수 있습니다.",
  p3:  "QueenG 플랫폼은 도박과 스포츠 두 부분으로 나누어집니다. 도박에는 주사위, 슬롯머신 등 전통적인 카지노 게임이 포함되고, 스포츠에는 다양한 이벤트에 대한 배팅이 포함됩니다. 초기에는 독창적인 게임이 될 것이며, 이후에는 다양한 게임 공급업체 및 파트너와 연결되어 플랫폼 내에서 협력적으로 출시될 예정입니다. .",
  major:  "사용자의 4가지 주요 이점",
  tip1:  "1) 담보소득",
  detail1:  "플랫폼 화폐의 유동성을 약속한 사용자는 플랫폼 소득 배당을 누릴 수 있습니다.",
  tip2:  "2) 예금소득",
  detail2:  "플랫폼에서 다양한 농산물과 정기 예금 디지털 통화를 제공한 후 예치 기간 만료 시 해당 연간 수익을 얻을 수 있습니다.",
  tip3:  "3) 채굴소득",
  detail3:  "사용자 게임은 채굴입니다. 더 많은 게임을 플레이할수록 더 많은 플랫폼 통화 채굴 보상을 얻을 수 있습니다.",
  tip4:  "4) 리베이트 수입",
  detail4:  "사용자는 초대 링크를 공유하고, 초대받은 사람은 플랫폼에 등록하고 적극적으로 게임을 하며, 초대한 사람은 소극적인 수입을 받게 됩니다.",
}
