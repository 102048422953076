// 三个游戏及我的投注的翻译
export default {
  pledge:  "Tiền gửi",
  yawan:  "Oshe",
  bettingAmount:  "số tiền đặt cược",
  highestWin:  "Chiến thắng cao nhất",
  castOnce:  "Bình chọn 1 lần",
  castFive:  "Bỏ phiếu 5 lần",
  excavatedOnce:  "Một khoản đầu tư dự kiến ​​sẽ mang lại kết quả là khai thác",
  bigWinner:  "người chiến thắng lớn",
  mostFortunate:  "Người may mắn nhất",
  rulesGame:  "luật chơi",
  player:  "người chơi",
  betting:  "cá cược",
  multiple:  "nhiều",
  bonus:  "thưởng",
  placeBet:  "đặt cược",
  result:  "kết quả",
  earnings:  "thu nhập",
  bettingTime:  "Thời gian giải quyết",
  bettingDirection:  "Hướng cá cược",
  aboutToBegin:  "Về để bắt đầu",
  excavatedTurningOnce:  "Người ta ước tính rằng bạn có thể đào nó sau khi chuyển nó",
  drawTheLottery:  "Xổ số rút thăm!",
  latestPrice:  "Giá mới nhất",
  realTime:  "thời gian thực",
  oneMinute:  "1 phút",
  fifteenMinutes:  "15 phút",
  guessTheRise:  "đoán mức tăng",
  guessTheFall:  "đoán mùa thu",
  bettingPrice:  "giá đặt cược",
  settlementInProgress:  "Đang giải quyết",
  game:  "trò chơi",
  return:  "trở lại",
  theRise:  "tăng lên",
  theFall:  "ngã",
  openNote:  "đặt cược mở",
  selected:  "được chọn",
  pleaseLoginFirst:  "vui lòng đăng nhập trước",
  enterCorrectAmount:  "Vui lòng nhập đúng số tiền đặt cược",
  creditLow:  "Thiếu cân bằng",
  maximumWorthTokens:  "Mức đặt cược tối đa là 100.000 token giá trị USDT",
  congratulationsWinning:  "Chúc mừng chiến thắng",
  winningLottery:  "Chúc mừng chiến thắng của bạn",
  didnnotWinPrize:  "Xin lỗi tôi đã không thắng",

}
