// 帮助-Qgt代币的翻译
export default {
  title: "Gambaran Keseluruhan Token QGT",
  title1: "1) Perlombongan",
  p1:  "Perlombongan ialah sistem perlombongan mata wang yang melombong QGT setiap hari dengan meletakkan pertaruhan. QGT ialah token insentif untuk perkongsian ekuiti QueenG dan persekitaran permainan yang sihat. Token QGT boleh dilombong dengan bermain permainan dan pertaruhan. Pertaruhan anda Semakin banyak anda mempertaruhkan - semakin banyak QGT anda milik anda. Menambang lebih banyak QGT bermakna pulangan harian yang stabil.",
  p2:  "⚠️ Untuk mengurangkan beban perlombongan dan memastikan proses yang betul, pegangan minimum untuk melombong QGT ialah $1 (dalam mana-mana mata wang)",
  title2:  "2) Transaksi",
  p3_1:  "Platform ini akan melancarkan transaksi dan kumpulan modal QGT secara beransur-ansur di pelbagai peringkat teratas, seperti: uniswap, justswap dan ApeSwap",
  p3_2:  "Platform ini juga akan secara langsung menyokong pertukaran QGT, dan transaksi dalaman akan menjadi cara terpantas dan termudah untuk membeli QGT.",
  p4:  "Jadilah pemilik QGT yang bertuah, atau tukar token QGT anda dengan mata wang kripto yang lain.",
  title3:  "3) Penggunaan QGT",
  subtitle1:  "3.1) Ikrar",
  p5:  "Dengan mempertaruhkan kecairan QGT (dalam modul penyokong), pengguna boleh menikmati dividen dan memperoleh sebahagian daripada keuntungan platform.",
  p6:  "Setiap 24 jam, kumpulan ikrar akan mengeluarkan 20% daripada jumlah keuntungan platform untuk dividen, iaitu, pendapatan harian platform dalam pelbagai mata wang akan diagihkan kepada penyedia kecairan QGT dalam bentuk dividen. Kecairan QGT yang dicagarkan anda Lebih banyak jantina – lebih banyak keuntungan dividen yang anda dapat! Untung daripada semua pendapatan daripada platform!",
  subtitle2:  "3.2) Transaksi",
  p7: "Beli dan jual token QGT di bursa TOP, dan anda juga boleh memegang token QGT untuk melabur dan mendapat manfaat. ",
  subtitle3:  "3.3) Perjudian",
  p8:  "Token QGT boleh digunakan dalam semua permainan di kasino, dan fungsi pertaruhannya adalah sama seperti mata wang lain; pertaruhan minimum dalam permainan - 1 QGT.",
  title4:  "4) Pemusnahan QGT",
  p9:  "QueenG menjalankan pemusnahan token QGT bulanan untuk menyokong pembangunan sihat ekonomi mata wang dan ekosistem. Pemusnahan terancang meningkatkan nilai token asli QGT dan mengekalkan keseimbangan antara pemegang dan pembangun untuk menyokong model Pengedaran token dan pertumbuhan berterusan pembayaran taruhan untuk pemegang token. Token dibakar setiap bulan."
}
