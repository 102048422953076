// 帮助-关于的翻译
export default {
  aboutQueenG:  "เกี่ยวกับควีนจี",
  p1:  "QueenG เป็นแพลตฟอร์ม iGaming ที่ใช้ BSC ในขณะที่ยังคงรักษานิสัยการเล่นการพนันแบบดั้งเดิมไว้ แต่ก็มีการกระจายอำนาจอย่างสมบูรณ์ ทำให้มั่นใจในความยุติธรรมและการเปิดกว้างของกระบวนการเกม และมอบประสบการณ์การเล่นเกมที่ไม่เหมือนใครให้กับผู้ใช้ทุกคน อินเทอร์เฟซที่เรียบง่ายและใช้งานง่าย และเส้นทางการดำเนินงานจะทำให้ประสบการณ์ของคุณทำให้ดีที่สุด",
  p2:  "รองรับการเข้าสู่ระบบการเชื่อมต่อกระเป๋าเงินบล็อคเชนหลายรายการ ทรัพย์สินจะถูกควบคุมโดยกระเป๋าเงินของผู้ใช้เอง และรายได้ของผู้ใช้จะถูกชำระเข้ากระเป๋าเงินทันที ซึ่งปลอดภัยและเชื่อถือได้อย่างแน่นอน",
  p3:  "แพลตฟอร์ม QueenG แบ่งออกเป็นสองส่วน: การพนันและกีฬา การพนันรวมถึงเกมคาสิโนแบบดั้งเดิมเช่นลูกเต๋าและสล็อตแมชชีน และกีฬารวมถึงการเดิมพันในเหตุการณ์ต่าง ๆ ในระยะแรกมันจะเป็นเกมดั้งเดิมและจากนั้นก็จะเป็น เชื่อมต่อกับซัพพลายเออร์และพันธมิตรเกมต่างๆ และจะเปิดตัวความร่วมมือภายในแพลตฟอร์ม",
  major:  "ประโยชน์หลักสี่ประการของผู้ใช้",
  tip1:  "1) รายได้จำนำ",
  detail1:  "ผู้ใช้ที่ให้คำมั่นสัญญาเรื่องสภาพคล่องของสกุลเงินแพลตฟอร์มสามารถเพลิดเพลินกับเงินปันผลรายได้ของแพลตฟอร์ม",
  tip2:  "2) รายได้จากเงินฝาก",
  detail2:  "หลังจากที่แพลตฟอร์มให้บริการผลิตภัณฑ์ฟาร์มที่หลากหลายและสกุลเงินดิจิทัลสำหรับการฝากประจำ คุณจะได้รับผลตอบแทนเป็นรายปีที่สอดคล้องกันเมื่อสิ้นสุดระยะเวลาการฝาก",
  tip3:  "3) รายได้จากการขุด",
  detail3:  "เกมของผู้ใช้กำลังขุด ยิ่งคุณเล่นเกมมากเท่าไร คุณก็จะได้รับรางวัลการขุดสกุลเงินบนแพลตฟอร์มมากขึ้นเท่านั้น",
  tip4:  "4) รายได้ส่วนลด",
  detail4:  "ผู้ใช้แชร์ลิงก์คำเชิญ ผู้ได้รับเชิญลงทะเบียนและเล่นเกมบนแพลตฟอร์ม และผู้เชิญจะได้รับรายได้แบบพาสซีฟ",
}
