import WalletConnectProvider from '@walletconnect/web3-provider'
import detectEthereumProvider from '@metamask/detect-provider'
import Web3Modal from 'web3modal'
import Web3 from 'web3'
import store from '../store'
import pre_abi from '../assets/json/DiceGame_pre.json'
import prod_abi from '../assets/json/DiceGame_prod.json'
import erc20 from '../assets/json/erc20.json'
import fomo3d from '../assets/json/Fomo3dFactory.json'
import newfomo from '../assets/json/Fomo3d.json'
import defi from '../assets/json/Defi.json'

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '9e950c74fe0249fa81e4183913638de2', // 以太坊连接必填
    },
  },
}

const web3Modal = new Web3Modal({
  network: 'mainnet',
  cacheProvider: true,
  providerOptions,
})

const aibObj = {
  pre: pre_abi,
  prod: prod_abi
}
const abi = aibObj[process.env.VUE_APP_GAME_JSON]

class Web3ModalPlugin {
  provider;
  web3;
  web3Modal;
  contract;
  accountContract;
  balanceOfTimer;
  metamaskProvider;
  startPolling;
  refused;
  transactionHash;
  publicWeb3;
  lb2bnbContract;
  lb2busdContract;
  constructor() {
    this.provider = null;
    this.web3 = null;
    this.web3Modal = web3Modal;
    this.contract = null;
    this.fomo3dContract = null;
    this.accountContract = null;
    this.usdtContract = null;
    this.defiContract = null;
    this.startPolling = false;
    this.refused = false;
    this.publicWeb3 = new Web3(Web3.givenProvider)
    // this.metamaskProvider = await detectEthereumProvider()
  }
  async getAccount() {
    const provider = await web3Modal.connect()
    if (provider.show) {
      await provider.toggleModal()
    }
    const web3 = new Web3(provider)
    return { provider, web3, web3Modal }
  }
  async connect() {
    try {
      await this.judgeNetwork()
      const { provider, web3 } = await this.getAccount()
      this.provider = provider
      this.web3 = web3
      await this.subscribeProvider()
      const address = await web3.eth.getAccounts()
      if (address.length > 0) {
        store.commit('changeConnected', address)
        this.accountContract = new this.web3.eth.Contract(erc20.output.abi, process.env.VUE_APP_BASE_CONTRACT);
        this.lb2bnbContract = new this.web3.eth.Contract(erc20.output.abi, process.env.VUE_APP_LB2BNB);
        this.lb2busdContract = new this.web3.eth.Contract(erc20.output.abi, process.env.VUE_APP_LB2USDT);
        this.contract = new this.web3.eth.Contract(abi.abi, process.env.VUE_APP_GAME_CONTRACT);
        this.fomo3dContract = new this.web3.eth.Contract(fomo3d.abi, process.env.VUE_APP_FOMO_CONTRACT);
        this.usdtContract = new this.web3.eth.Contract(erc20.output.abi, process.env.VUE_APP_USDT_CONTRACT);
        this.defiContract = new this.web3.eth.Contract(defi.abi, '0xca2C5968Ef8592506d7181da1a648537B7E21B41');
        this.getBalanceOf()
        // this.approve()
      }
    } catch (error) {
      // console.log(error)
    }
  }

  async disconnect() {
    if (this?.provider?.wc?.killSession) {
      this.provider.wc.killSession()
      this.provider.stop()
      this.provider = null
      this.web3 = null
      await this.web3Modal.clearCachedProvider()
      return
    }
    if (this?.provider?.close) {
      await this.provider.close()
    }
    await this.web3Modal.clearCachedProvider()
    store.commit('changeConnected', [])
    store.commit('changeBalanceOf', null)
    window.localStorage.removeItem('address')
    clearTimeout(this.balanceOfTimer)
  }

  async approve(type, val) {
    console.log('进入 approve')
    let contract, hash;
    switch (type) {
      case 'BNB':
        contract = this.lb2bnbContract;
        hash = process.env.VUE_APP_LB2BNB
        break;
      case 'BUSD':
        contract = this.lb2busdContract;
        hash = process.env.VUE_APP_LB2USDT
        break;
      default:
        break;
    }
    const quota = await contract.methods.allowance(store.state.address, process.env.VUE_APP_LB2BNB).call({
      from: store.state.address,
    })
    if (this.web3.utils.fromWei(quota) > val) {
      return
    }
    await contract.methods.approve(process.env.VUE_APP_GAME_CONTRACT, this.web3.utils.toWei(val, 'ether')).send({
      from: store.state.address,
    }).then(res => {
      console.log('成功')
      console.log(res)
    }).catch(err => {
      console.log('失败')
      console.log(err)
    });
    console.log('结束 approve')
  }

  async subscribeProvider() {
    // 断开连接
    this.provider.on('disconnect', () => {
      console.log('断开连接')
      store.commit('changeConnected', [])
      store.commit('changeBalanceOf', null)
      clearInterval(this.balanceOfTimer)
      this.disconnect()
    })
    // 切换账号
    this.provider.on('accountsChanged', async (accounts) => {
      if (accounts.length > 0) {
        store.commit('changeConnected', accounts)
        // this.approve()
      }
    }
    )
  }
  getBalanceOf() {
    this.getBaseInfo()
    this.balanceOfTimer = setInterval(() => {
      this.getBaseInfo()
    }, 5000);
  }
  // 获取基础信息
  async getBaseInfo() {
    // 获取余额
    this.lb2bnbContract.methods.balanceOf(store.state.address).call().then(res => {
      const format = Number(this.web3.utils.fromWei(res)).toFixed(2)
      store.commit('changeBnbBalanceOf', format)
    })
    this.lb2busdContract.methods.balanceOf(store.state.address).call().then(res => {
      const format = Number(this.web3.utils.fromWei(res)).toFixed(2)
      store.commit('changeBusdBalanceOf', format)
    })
  }
  async judgeNetwork() {
    this.metamaskProvider = await detectEthereumProvider()
    if (this.metamaskProvider) {
      try {
        await this.metamaskProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: Web3.utils.numberToHex(process.env.VUE_APP_NET_ID), // 目标链ID
            },
          ],
        })
      } catch (e) {
        // const code = this.$store.state.isMobileDevice
        //   ? e.data?.originalError?.code
        //   : e.code
        // if (code === 4902) {
        try {
          await this.metamaskProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: Web3.utils.numberToHex(process.env.VUE_APP_NET_ID), // 目标链ID
                chainName: process.env.VUE_APP_NET_TITLE,
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'BNB',
                  decimals: 18,
                },
                rpcUrls: [process.env.VUE_APP_NET_RPC_URL], // 节点
                blockExplorerUrls: [process.env.VUE_APP_BLOCK_EXPLORER_URL],
              },
            ],
          })
        } catch (ee) {
          console.log(264)
          console.log(ee)
          //
        }
        // }
      }
    } else {
      window.open('https://metamask.io/download/')
    }
  }
  async ticketApprove(hash, currentHash) {
    const bnbHash = process.env.VUE_APP_BASE_CONTRACT
    const usdtHash = process.env.VUE_APP_USDT_CONTRACT
    const lb2usdtHash = process.env.VUE_APP_LB2USDT
    const lb2bnbHash = process.env.VUE_APP_LB2BNB
    let contract;
    if (currentHash.toLowerCase() == bnbHash.toLowerCase()) {
      contract = this.accountContract
    }
    if (currentHash.toLowerCase() == usdtHash.toLowerCase()) {
      contract = this.usdtContract
    }
    if (currentHash.toLowerCase() == lb2usdtHash.toLowerCase()) {
      contract = this.lb2busdContract
    }
    if (currentHash.toLowerCase() == lb2bnbHash.toLowerCase()) {
      contract = this.lb2bnbContract
    }
    const quota = await contract.methods.allowance(store.state.address, hash).call({
      from: store.state.address,
    })
    if (this.web3.utils.fromWei(quota) > 10000) {
      return
    }
    await contract.methods.approve(hash, this.web3.utils.toWei('9999999999', 'ether')).send({
      from: store.state.address,
    })
  }
  async getPoolTime(hash) {
    const newContract = new this.publicWeb3.eth.Contract(newfomo.abi, hash);
    const result = await newContract.methods._endtime().call()
    return result
  }
  async supplyChain(type, val) {
    console.log('进入 supplyChain')
    await this.approve(type, val);
    console.log('approve 完毕2')
    let hash;
    switch (type) {
      case 'BNB':
        hash = process.env.VUE_APP_LB2BNB
        break;
      case 'BUSD':
        hash = process.env.VUE_APP_LB2USDT
        break;
      default:
        break;
    }
    console.log(3)
    const result = await this.defiContract.methods.deposit(hash, this.web3.utils.toWei(val)).send({
      from: store.state.address
    })
    console.log('结束 supplyChain')
  }
  async confirmUnlock(type, val) {
    console.log(type, val)
    let hash;
    switch (type) {
      case 'BNB':
        hash = process.env.VUE_APP_LB2BNB
        break;
      case 'BUSD':
        hash = process.env.VUE_APP_LB2USDT
        break;
      default:
        break;
    }
    const result = await this.defiContract.methods.withdraw(hash, this.web3.utils.toWei(val)).send({
      from: store.state.address
    })
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$web3 = Web3
    app.config.globalProperties.$web3Modal = web3Modal
    // app.config.globalProperties.$getAccount = getAccount
    // app.config.globalProperties.$provider = detectEthereumProvider
    // app.config.globalProperties.$connect = connect
    app.config.globalProperties.$web3ModalPlugin = new Web3ModalPlugin()
  }
}
