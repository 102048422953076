// 钱包的翻译
export default {
  deposit: "depósito",
  withdrawal:  "Cancelamento",
  other:  "outro",
  recordDrawing:  "Registros de Depósitos e Retiradas",
  network:  "rede",
  tokenContract:  "Endereço do contrato de token:",
  your:  "Seu",
  depositAddress:  "Endereço de Depósito",
  minimumDeposit:  "Valor mínimo de depósito",
  lowerAmounts:  ",Valores inferiores não serão creditados ou reembolsados.",
  sendOnly:  "Por favor, envie apenas",
  toAddress:  "Para este endereço, você precisa obter 1 confirmação para que o depósito seja creditado.",
  yourWalletAddress:  "Endereço da sua carteira",
  enterAddress:  "Insira o endereço",
  quantity:  "quantidade",
  balance:  "Equilíbrio",
  enterAmount:  "Insira o valor",
  whole:  "todos",
  minimumWithdrawalAmount:  "O valor mínimo de retirada é",
  transactionCosts:  "Taxa de transação: - Tempo gasto - 0-2 horas. Confirmação de rede não incluída,",
  additionalTime:  "Pode levar algum tempo extra.",
  sendingAddress:  "endereço de envio",
  accountArrivalTime:  "Tempo de chegada",
  accountStates:  "estado",
  accountStatesTxt_1:  "Rejeitado",
  accountStatesTxt0:  "A ser revisado",
  accountStatesTxt1:  "Auditado",
  accountStatesTxt2:  "Chegado",
  withdrawalAddress:  "Endereço de retirada",
  serviceCharge:  "taxa de manuseio",
  pleaseEnterAddress:  "Por favor, insira o endereço",
  extract:  "extrair",
  copySucceeded:  "Copiar com sucesso",
  enterCorrectBalance:  "Por favor, insira o saldo correto",
  runningLow:  "Saldo insuficiente",
  withdrawalSucceeded:  "Retirada bem-sucedida",
  otherCoinName:  'Nome da moeda',
  otherAmount:  'quantia',
  otherType:  'tipo',
  otherStatus:  'estado',
  otherStatus1:  'inválido',
  otherStatus2:  'desfeito',
  otherStatus3:  'concluído',
}
