// 邀请的翻译
export default {
  instantly1:  "Nhận ngay bây giờ",
  instantly2:  "3%",
  instantly3:  "số tiền cao",
  instantly4:  "Chiết khấu lợi nhuận",
  invitationCode:  "Mã mời",
  copy:  "sao chép",
  share:  "chia sẻ",
  invited:  "Được mời",
  commissionReturned:  "Hoa hồng đã được trả lại",
  commissionAccumulated:  "Giảm giá đã được tích lũy",
  date:  "ngày",
  commissionRebate:  "Giảm giá chiết khấu lợi nhuận ròng",
  bettingGoldRebate:  "Hoàn tiền khi đặt cược",
  miningCommission:  "Giảm giá khai thác",
  total:  "tổng cộng",
  operation:  "vận hành",
  viewDetails:  "Xem chi tiết",
  goodFriends:  "Bạn",
  entryTime:  "thời gian đến",
  howRebate:  "Cách nhận hoa hồng từ bạn bè",
  inviteFriends:  "mời bạn bè",
  shareLink:  "Chia sẻ liên kết với bạn bè của bạn và bạn bè của bạn sẽ đăng nhập vào nền tảng thông qua liên kết",
  friendsLogIn:  "Đăng nhập bạn bè",
  friendsToPlatform:  "Sau khi bạn của bạn đăng nhập vào nền tảng, hãy nạp tiền và tham gia trò chơi",
  giveOutRewards:  "phân phối phần thưởng",
  friendsPlayGames:  "Bạn bè chơi trò chơi trên nền tảng và mời mọi người nhận tiền hoàn lại",
  tip1:  "1. Mời bạn bè của bạn tham gia nền tảng và bạn có thể nhận được khoản giảm giá lên tới 3% phí xử lý.",
  step1:  "Bước một: Đi tới trang mời bạn bè và chia sẻ áp phích hoặc liên kết lời mời của bạn với bạn bè.",
  step2:  "Bước 2: Bạn bè đăng nhập vào nền tảng thông qua liên kết lời mời và bạn bè có thể nhận phần thưởng hoa hồng bằng cách tham gia trò chơi.",
  step3:  "Bước 3: Thu nhập do bạn bè chơi trò chơi trên nền tảng tạo ra, thu nhập khai thác và tiền cược của bạn bè thắng trên nền tảng đều sẽ được trả lại cho bạn theo một tỷ lệ nhất định.",
  tip2:  "2. Phần thưởng giảm giá sẽ được thanh toán cho bạn theo thời gian thực và được phân bổ vào ví tài khoản của bạn.",
  tip3:  "3. Không có giới hạn trên về số lượng lời mời. Bạn mời càng nhiều bạn bè, bạn sẽ nhận được càng nhiều giảm giá. Mỗi người bạn có thể tận hưởng giảm giá vĩnh viễn. Hãy tiếp tục và mời thêm bạn bè!",
  scanningCode1:  "Quét mã để nhận",
  scanningCode2:  "Gundam",
  scanningCode3:  "Phần thưởng khai thác WCC 3%",
  longPress:  "Nhấn và giữ để xác định mã QR",
  enteringThePage:  "Sau khi vào trang nhấn Đăng ký để tham gia chơi ngay",
  savePicture:  "lưu hình ảnh",
  rewardRules:  "Quy tắc khen thưởng",
  copySucceeded:  "Sao chép thành công",
  hiQGT:  "Xin chào, chào mừng bạn đến với nền tảng trò chơi FUN3.0",
  airdropBenefits:  "",
  clickGetIt:  "Đăng ký và mời bạn bè chơi trò chơi để hưởng hoa hồng cao và phần thưởng khai thác",
  receive:  "Bắt đầu đăng ký"

}
