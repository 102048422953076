// 返佣的翻译
export default {
  rebateProgram:  "Chương trình giảm giá",
  usersShare:  "Khi người dùng chia sẻ liên kết lời mời, người được mời kết nối thông qua liên kết và tích cực chơi trò chơi trên nền tảng, người mời sẽ kiếm được thu nhập thụ động. Bạn mời càng nhiều người chơi - thu nhập bạn kiếm được càng cao.",
  usingTheReferralLinks:  "Bằng cách sử dụng liên kết giới thiệu do nền tảng tạo ra, liên kết này sẽ được ghi lại và theo dõi cách người dùng mà bạn mời đến cũng như số tiền thắng bạn nhận được từ việc đặt cược của họ.",
  enterInvitation:  "Nhập trang lời mời",
  commissionRebate:  "Giảm giá lợi nhuận ròng",
  inviteeWinsBet:  "Mỗi khi người được mời thắng cược, 3% số tiền thắng cược của người được mời sẽ được trả cho bạn ngay lập tức.",
  platformBetting:  "Nền tảng hoàn tiền cá cược",
  inviteeLoses:  "Mỗi khi người được mời thua cược, nền tảng sẽ lấy 3% số tiền đặt cược và trả cho bạn.",
  miningCommission:  "Giảm giá khai thác",
  miningRebate:  "Mỗi khi người được mời kiếm được thu nhập từ việc khai thác, bạn sẽ nhận được khoản hoa hồng khai thác tương ứng là 3%."
}
