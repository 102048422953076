// 帮助-游戏的翻译
export default {
  title: "Game",
  p1: "QueenG has assembled the most popular games in the casino scene and developed them in-house to make direct gaming bets more convenient by using TOP cryptocurrencies. Currently, users can enjoy uniquely designed games such as dice rolling, guessing the rise and fall, and the big wheel. We will also launch more popular casino games in the near future!",
  p2: "The odds corresponding to each game are different, but the maximum-minimum bet amount is fixed at: $1000000-$1",
  title1: "1）Roll the dice",
  p3: "Rolling dice is an ancient Chinese dice game where players bet on the outcome of the three dice rolling inside a cage.",
  p4: "At the beginning of the game, players choose the size direction or specific number of the betting area. After placing bets, the dice will start spinning. Once the spinning ends, the platform will display the results and distribute rewards.",
  p5: "Different from traditional dice rolling, dice games are fully automated through smart contracts to execute processes such as placing bets, random dice rolling, awarding prizes, and handling funds/rewards without any third-party involvement. This is much more efficient and fair compared to existing traditional casino betting methods.",
  subtitle1: "1.1) Betting on odds",
  p6: "There is a row of betting areas below the dice that are divided into 6 small compartments. In this row, you bet on which number will be displayed after the dice spin. If one of the three dice shows the number you bet on, then your Your winnings are 1 to 2. If two of the three dice show the number you bet, your winnings are 1 to 11. If three of the three dice show the number you bet, your winnings are It's 1 to 151.",
  subtitle2: "1.2) Betting on draw",
  p7:  'In the Tie area, you bet on the sum of the numbers of the three rolls of the dice. "Big" is when the sum of the exponents is 11 to 17; "Small" means the sum of the exponents is 4 to 10. If the three points of the rotated number being placed are the same, then it belongs to the "leopard", then both big and small loses. Big and small odds are 2 to 1, and the odds of leopards are 25 to 1.',
  title2:  "2) Guess the rise or fall",
  p8: "Based on the market conditions of the three mainstream currencies, users can choose to be bullish or bearish, and predict the rise and fall of the selected trading pair in 15 seconds. After placing a bet in one direction, a 15-second market jump countdown will be entered. After 15 seconds, the price rise and fall will be compared. If the guess is correct, the price will rise or fall immediately. Rebate after settlement.",
  p9: "About placing bets:",
  p10: "After analyzing and checking the market, set the bet amount and click the Guess Up/Guess Down button. If the guess result is correct, you will get 2 times the corresponding bonus; if the guess result is wrong, only the amount bet will be deducted; if the rise or fall amount is zero , then it is a draw and the bet will be refunded;",
  title3: "3) Big wheel",
  p11: "The big wheel is a common gambling game in casinos. The wheel has 54 areas that can be selected, and the four colors have different proportions; purple has 26 blocks, and the odds are 2 times; red has 17 blocks, and the odds are 3 times ;Green is 10 blocks, the odds are 5 times; yellow is 1 block, the odds are 50 times;",
  p12: "Set the bet amount. After selecting the bet color, the roulette wheel will rotate. After the roulette wheel stops rotating, the color of the selected area will be the lottery color. After the lottery is drawn, the settlement will be completed immediately and the bonus will be distributed;"
}
