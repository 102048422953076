// 帮助-Qgt代币的翻译
export default {
  title:  "Tổng quan về mã thông báo QGT",
  title1:  "1) Khai thác mỏ",
  p1:  "Khai thác là một hệ thống khai thác tiền tệ khai thác QGT hàng ngày bằng cách đặt cược. QGT là mã thông báo khuyến khích chia sẻ vốn sở hữu của QueenG và môi trường chơi trò chơi lành mạnh. Mã thông báo QGT có thể được khai thác bằng cách chơi trò chơi và cá cược. Đặt cược của bạn Càng đặt cược nhiều - càng nhiều QGT là của bạn. Khai thác nhiều QGT hơn có nghĩa là lợi nhuận ổn định hàng ngày.",
  p2:  "⚠️ Để giảm bớt gánh nặng khai thác và đảm bảo quy trình chính xác, số tiền đặt cược tối thiểu để khai thác QGT là 1 USD (bằng bất kỳ loại tiền tệ nào)",
  title2:  "2) Giao dịch",
  p3_1:  "Nền tảng này sẽ dần dần triển khai các giao dịch và nhóm vốn của QGT ở nhiều cấp độ cao nhất khác nhau, chẳng hạn như: uniswap, justswap và ApeSwap",
  p3_2:  "Nền tảng này cũng sẽ trực tiếp hỗ trợ trao đổi QGT và các giao dịch nội bộ sẽ là cách nhanh nhất và dễ dàng nhất để mua QGT.",
  p4:  "Trở thành chủ sở hữu QGT may mắn hoặc đổi mã thông báo QGT của bạn lấy các loại tiền điện tử khác.",
  title3:  "3) Việc sử dụng QGT",
  subtitle1:  "3.1) Cam kết",
  p5:  "Bằng cách đặt cược thanh khoản QGT (trong mô-đun hỗ trợ), người dùng có thể được hưởng cổ tức và nhận được một phần lợi nhuận của nền tảng.",
  p6:  "Cứ sau 24 giờ, nhóm cầm cố sẽ giải phóng 20% ​​tổng lợi nhuận của nền tảng để chia cổ tức, nghĩa là thu nhập hàng ngày của nền tảng bằng nhiều loại tiền tệ khác nhau sẽ được phân phối cho các nhà cung cấp thanh khoản QGT dưới dạng cổ tức. – bạn càng nhận được nhiều lợi nhuận cổ tức! Lợi nhuận từ tất cả thu nhập từ nền tảng!",
  subtitle2:  "3.2) Giao dịch",
  p7:  "Mua và bán mã thông báo QGT trên các sàn giao dịch TOP và bạn cũng có thể giữ mã thông báo QGT để đầu tư và hưởng lợi.",
  subtitle3:  "3.3) Cờ bạc",
  p8:  "Mã thông báo QGT có thể được sử dụng trong tất cả các trò chơi trong sòng bạc và chức năng cá cược của chúng giống như các loại tiền tệ khác; mức đặt cược tối thiểu trong trò chơi - 1 QGT.",
  title4:  "4) Tiêu diệt QGT",
  p9:  "QueenG tiến hành tiêu hủy mã thông báo QGT hàng tháng để hỗ trợ sự phát triển lành mạnh của nền kinh tế tiền tệ và hệ sinh thái. Việc tiêu hủy theo kế hoạch sẽ làm tăng giá trị của mã thông báo gốc QGT và duy trì sự cân bằng giữa chủ sở hữu và nhà phát triển để hỗ trợ mô hình phân phối mã thông báo và tăng trưởng liên tục các khoản thanh toán đặt cược cho chủ sở hữu mã thông báo. Mã thông báo được đốt hàng tháng."
}
