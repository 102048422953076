// 邀请的翻译
export default {
  instantly1: "立即获得高达",
  instantly2: "3%",
  instantly3: "的高额",
  instantly4: "收益返佣",
  invitationCode: "邀请码",
  copy: "复制",
  share: "分享",
  invited: "已邀请",
  commissionReturned: "已返佣",
  commissionAccumulated: "已累计返佣",
  date: "日期",
  commissionRebate: "净盈利返佣返佣",
  bettingGoldRebate: "下注金返佣",
  miningCommission: "挖矿返佣",
  total: "总计",
  operation: "操作",
  viewDetails: "查看明细",
  goodFriends: "好友",
  entryTime: "入驻时间",
  howRebate: "如何获得好友返佣",
  inviteFriends: "邀请好友",
  shareLink: "将链接分享好友，好友通过链接登录平台",
  friendsLogIn: "好友登录",
  friendsToPlatform: "好友登录平台后，充值并参与游戏",
  giveOutRewards: "发放奖励",
  friendsPlayGames: "好友在平台玩游戏，邀请人获得收益返佣",
  tip1: "1.邀请您的好友加入平台，您可获得最高3%的手续费返佣。",
  step1: "第一步：进入邀请好友页面，将您的海报或者邀请链接分享给好友。",
  step2: "第二步：好友通过邀请链接登录平台，好友参与游戏即可获得返佣奖励。",
  step3: "第三步：好友在平台内玩游戏产生的收入、挖矿收益以及平台赢取好友下注金后都将以一定比例返佣给您。",
  tip2: "2.返佣奖励实时结算给您, 发放到账户钱包内。",
  tip3: "3.邀请数量不设上限，邀请好友越多，您获得的返佣收益越多，每个好友可永久享受返佣收益，快去邀请更多好友吧！",
  scanningCode1: "扫码领取",
  scanningCode2: "高达",
  scanningCode3: "3%WCC挖矿奖励",
  longPress: "长按识别二维码",
  enteringThePage: "进入页面后,点击注册即刻参与游戏",
  savePicture: "保存图片",
  rewardRules: "奖励规则",
  copySucceeded: "复制成功",
  hiQGT: "Hi,欢迎进入FUN3.0游戏平台",
  airdropBenefits: "",
  clickGetIt: "注册并邀请好友进行游戏可享受高额返佣与挖矿奖励",
  receive: "开始注册"
}
