// 设置的翻译
export default {
  routine:  "thông thường",
  emailAddress:  "địa chỉ email",
  changeEmail:  "Thay đổi địa chỉ email",
  cellPhoneNumber:  "Số điện thoại",
  areaCode:  "mã vùng",
  preservation:  "cứu",
  originalMailbox:  "Mã xác minh email gốc",
  mailbox:  "email gốc",
  sendVerificationCode:  "Gửi mã xác minh",
  nextStep:  "Bước tiếp theo",
  newEmailAddress:  "Địa chỉ thư điện tử mới",
  confirmReplacement:  "Xác nhận thay thế",
  originalPhoneCode:  "Mã xác minh điện thoại di động gốc",
  originalPhone:  "Số điện thoại di động gốc",
  newPhoneNumber:  "Số điện thoại di động mới",
  oldPassword:  "Mật khẩu cũ",
  newPassword:  "Mật khẩu mới",
  confirmNewPassword:  "Xác nhận mật khẩu mới",
  oldPasswordError:  "Mật khẩu cũ sai",
  pleaseOldPassword:  "Vui lòng nhập mật khẩu cũ của bạn",
  pleaseNewPassword:  "Vui lòng nhập mật khẩu mới",
  password:  "mật khẩu",
  changePhone:  "Thay đổi số điện thoại di động",
  savePhone:  "Lưu số điện thoại",
  phoneNumber:  "Số điện thoại di động đã được gửi",
  resend:  "Gửi lại",
  mailSending:  "Email được gửi quá thường xuyên",
  enterCorrectCode:  "Vui lòng nhập mã xác minh chính xác",
  checkEmail:  "Vui lòng kiểm tra email của bạn",
  sendingSucceeded:  "Gửi thành công",
  mailboxSucceeded:  "Email đã thay đổi thành công",
  savingSucceeded:  "Đã lưu thành công",
  enterConfirmationPassword:  "Vui lòng nhập mật khẩu xác nhận",
  passwordsTwiceInconsistent:  "Mật khẩu được nhập hai lần không nhất quán",
  passwordSuccessfully:  "Đặt lại mật khẩu hoàn tất"
}
