import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["helpMenu", {
      close: !$props.switchOpen
    }])
  }, [_createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/about'
    }]),
    to: "/helpCenter/about"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpAbout.aboutQueenG")), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/qgt'
    }]),
    to: "/helpCenter/qgt"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpQgt.title")), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/ido'
    }]),
    to: "/helpCenter/ido"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpIdo.title")), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/games'
    }]),
    to: "/helpCenter/games"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpGames.title")), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/account'
    }]),
    to: "/helpCenter/account"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpAccount.title")), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/termsService'
    }]),
    to: "/helpCenter/termsService"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpTermsService.title")), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/privacyPolicy'
    }]),
    to: "/helpCenter/privacyPolicy"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpPrivacyPolicy.title")), 1)]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_router_link, {
    class: _normalizeClass(["menu", {
      active: _ctx.$route.fullPath == '/helpCenter/qa'
    }]),
    to: "/helpCenter/qa"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("helpQa.title")), 1)]),
    _: 1
  }, 8, ["class"])], 2);
}