// 登录注册的翻译
export default {
  emailOrUserName: "Email or User Name",
  password: "Password",
  signIn:"Login",
  registration:"Register",
  emailOrUsernameNotExist: "Email or username does not exist",
  passwordError:"Password incorrect.",
  loginSuccessful: "Login successful",
  email: "Email",
  userName: "Username",
  invitationCode: "Invitation code",
  termsServiceConfirmation:"Clicking the checkbox indicates that you have read and agreed to the",
  service:"Terms and Conditions.",
  mailboxExists:"Email already exists.",
  userNameExists:"Username already exists.",
  incorrectMailbox:"The email format is incorrect.",
  incorrectPasswords: "Passwords must be at least 6 digits in length and must contain letters and a decimal point",
  invitationCodeNotExist: "The invitation code does not exist",
  registrationSuccessful: "Registration successful",
  pleaseCheckEmail: "Please check your email",
  pleaseEnterEmailAddress:"Please enter your email.",
  enterUserNameOrEmail:"Please enter your username or email.",
  pleaseInputPassword:"Please enter your password.",
  verificationCode: "Verification Code",
  sendVerificationCode: "Send verification code",
  mailSent:"Email sent.",
  enterVerificationCode: "Please enter the verification code",
  enterUserName:"Please enter your username.",
  agreeConditions: "Please agree to the terms and conditions",
  forgetPassword:"Forget Password",
  forgetEmail: 'Email',
  forgetCode:"Verification Code",
  forgetLogin:"Go to Login",
  forgetConfirm: 'Confirm',
  forgetErrorEmail:"Please fill in your email.",
  forgetErrorVerificationCode: 'Please fill in the verification code',
  forgetErrorSuccess: 'Password reset successful',

}
