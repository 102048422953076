export default {
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    rowList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    chosedRow() {
      return this.rowList.filter(item => {
        return item.value == this.modelValue;
      })[0];
    }
  },
  methods: {
    changeRow(row) {
      this.$emit('update:modelValue', row.value);
      this.$emit('change', row.value);
      this.$refs.dropDown.blur();
    }
  }
};