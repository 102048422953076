// 登录注册的翻译
export default {
  emailOrUserName: "email ou nome de usuário",
  password:  "senha",
  signIn:  "Conecte-se",
  registration:  "registro",
  emailOrUsernameNotExist:  "E-mail ou nome de usuário não existe",
  passwordError:  "senha incorreta",
  loginSuccessful:  "login bem sucedido",
  email:  "E-mail",
  userName:  "nome de usuário",
  invitationCode:  "Código de Convite",
  termsServiceConfirmation:  "Clicar na caixa de seleção indica que você leu e confirmou",
  service:  "termos e Condições",
  mailboxExists:  "E-mail já existe",
  userNameExists:  "O nome de usuário já existe",
  incorrectMailbox:  "O formato do e-mail está incorreto",
  incorrectPasswords:  "A senha não deve ter menos de 6 caracteres e deve conter letras e pontos decimais",
  invitationCodeNotExist:  "O código de convite não existe",
  registrationSuccessful:  "Registro bem sucedido",
  pleaseCheckEmail:  "Por favor verifique seu email",
  pleaseEnterEmailAddress:  "Por favor, indique o seu endereço de e-mail",
  enterUserNameOrEmail:  "Por favor, digite seu nome de usuário ou endereço de e-mail",
  pleaseInputPassword:  "Por favor, digite a senha",
  verificationCode:  "Código de verificação",
  sendVerificationCode:  "Enviar o código de verificação",
  mailSent:  "Mensagem enviada",
  enterVerificationCode:  "por favor insira o código de verificação",
  enterUserName:  "por favor insira o nome de usuário",
  agreeConditions:  "Por favor concorde com os termos e condiçoes",
  forgetPassword:  'esqueci a senha',
  forgetEmail:  'E-mail',
  forgetCode:  'Código de verificação',
  forgetLogin:  'Vá para fazer login',
  forgetConfirm:  'confirme',
  forgetErrorEmail:  'Por favor, preencha seu endereço de e-mail',
  forgetErrorVerificationCode:  'Por favor, preencha o código de verificação',
  forgetErrorSuccess:  'Redefinição de senha bem-sucedida',
}
