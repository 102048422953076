import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e2182168"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tabs"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["tab", {
        active: $props.modelValue == index
      }]),
      key: index,
      onClick: $event => $options.changTab(index)
    }, _toDisplayString(item), 11, _hoisted_2);
  }), 128))]);
}