// 日常任务的翻译
export default {
  manyRewards: "Banyak pahala ada",
  theDailyTask:  "tugasan harian",
  getIt:  "Anda boleh datang dan dapatkannya nanti!",
  moreRewards:  "Semakin banyak anda bertaruh, semakin besar ganjarannya",
  completeGame:  "Pilih dan selesaikan tugasan permainan harian untuk mendapatkan lebih banyak keseronokan dalam QueenG!",
  distributed:  "Tugas harian diagihkan merentasi pelbagai jenis permainan. Selesaikan mereka dan dapatkan ganjaran!",
  dailyTask:  "kira detik tugas harian:",
  bonusCompleting:  "Ganjaran tambahan untuk menyelesaikan semua tugasan:",
  getMany:  "menerima",
  winDice1:  "Taruh pada dadu untuk menang 30 kali",
  winDice2:  "Taruh pada seri pada mata dadu dan menang 30 kali",
  leopard:  "Dau membuang seekor harimau bintang",
  winTwice:  "Gunakan nombor bertuah yang sama dua kali berturut-turut x2 dalam dadu",
  roulette1:  "Main rolet 30 kali dalam X2",
  roulette2:  "Main Roulette 20 kali dalam X3",
  roulette3:  "Main Roulette 15 kali dalam X5",
  roulette4:  "Main Roulette 20 kali dalam X50",
  guess1:  "teka harga naik atau turun 30 kali",
  guess2:  "Teka dengan betul 20 kali",
  guess3:  "Teka betul 20 kali",
  guess4:  "Teka jatuh dengan betul 5 kali berturut-turut",
  collectCompletion:  "Klik untuk menerima selepas selesai",
  notCompleted:  "Tugas belum selesai lagi",
  rewardsReceived:  "Sudah menerima ganjaran",
  SuccessfullyReceived:  "Terima dengan jayanya"
}
