// 设置的翻译
export default {
  routine: "Routine",
  emailAddress: "Email address",
  changeEmail: "Change email",
  cellPhoneNumber: "Cell phone number",
  areaCode: "Area code",
  preservation:"Save",
  originalMailbox:"Existing Email Verification Code",
  mailbox:"Existing Email",
  sendVerificationCode:"Send Verification Code",
  nextStep:"Next",
  newEmailAddress: "New email address",
  confirmReplacement:"Confirm Change",
  originalPhoneCode:"Existing Phone Verification Code",
  originalPhone:"Existing Phone Number",
  newPhoneNumber: "New phone number",
  oldPassword: "Old password",
  newPassword: "New password",
  confirmNewPassword:"Confirm New Password",
  oldPasswordError:"Old Password Incorrect",
  pleaseOldPassword:"Please Enter Old Password",
  pleaseNewPassword:"Please Enter New Password",
  password: "Password",
  changePhone: "Change phone number",
  savePhone: "Save mobile phone number",
  phoneNumber: "Sent to mobile phone number",
  resend: "Resend",
  mailSending:"Email Sending Frequency Too Fast",
  enterCorrectCode: "Please enter the correct verification code",
  checkEmail: "Please check your email",
  sendingSucceeded:"Sent Successfully",
  mailboxSucceeded:"Email Change Successful",
  savingSucceeded:"Saved Successfully",
  enterConfirmationPassword: "Please enter the confirmation password",
  passwordsTwiceInconsistent:"The passwords entered twice do not match.",
  passwordSuccessfully:"Password changed successfully."
}
