// 个人中心的翻译
export default {
  myGame:  "permainan saya",
  totalWin:  "Jumlah Kemenangan",
  totalBets:  "Jumlah pertaruhan yang diletakkan",
  betsToday:  "Bilangan pertaruhan hari ini",
  betsWeek:  "Bilangan pertaruhan minggu ini",
  myBonus:  "Dividen saya",
  pledgeDividends:  "Gadai dividen",
  providePOOL:  "Sediakan kecairan POOL, menjadi rakan kongsi dan nikmati dividen daripada setiap kumpulan hadiah",
  dividendYesterday:  "Dividen semalam",
  accumulatedDividends:  "Dividen terkumpul",
  myMining:  "Perlombongan Saya",
  gameMining:  "Perlombongan Permainan",
  participate:  "Main permainan dan mengambil bahagian dalam perlombongan harian dan mempunyai hak mengundi",
  miningYesterday:  "Perlombongan semalam",
  cumulativeExcavation:  "Penggalian kumulatif",
  miningYesterdayChu:  "Digali semalam",
  nearly:  "Melombong dalam 7 hari yang lalu",
  totalMining:  "Jumlah Perlombongan",
  settlementDate:  "Tarikh penyelesaian",
  gameAmount:  "Jumlah Permainan Hari Ini",
  numberGames:  "Bilangan permainan",
  miningShare:  "Saham Perlombongan",
  miningIncome:  "Keuntungan Perlombongan",
  details:  "butiran xx",
  return:  "kembali",
  viewDetails:  "Lihat butiran",
  yesterdayEarnings:  "Pendapatan semalam",
  earningsRecentDays:  "Pendapatan dalam 7 hari lalu",
  totalIncome:  "Jumlah hasil",
  settlementLP:  "LP Penyelesaian",
  dividends:  "dividen",
  operation:  "beroperasi",
}
