import MyHeader from './components/layout/MyHeader.vue';
import LeftMenu from './components/layout/LeftMenu.vue';
import MyLayout from './components/layout/MyLayout.vue';
import httpOutside from "@/plugins/httpOutside";
export default {
  components: {
    MyHeader,
    LeftMenu,
    MyLayout
  },
  data() {
    return {
      timer: null
    };
  },
  methods: {
    getCurrencyList() {
      this.$http.get('/wallet-manager/anonymous/currency_list').then(res => {
        this.$store.commit('setCurrencyList', res);
        this.$store.commit('changeCurrency', res[0]);
      });
    },
    getRate() {
      this.$http.get('/wallet-manager/anonymous/exchange_rate').then(res => {
        this.$store.commit('setRate', res);
      });
    },
    getUserBalance() {
      this.$http.post('/wallet-manager/games/search/asset').then(res => {
        this.$store.commit('setBalance', res);
      });
    },
    getAddress() {
      this.$http.get('/wallet-manager/games/userAddress').then(res => {
        this.$store.commit('setUserAddress', res);
      });
    },
    startTimer() {
      this.getAddress();
      this.getUserBalance();
      this.timer = setInterval(() => {
        this.getUserBalance();
      }, 5000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    getTotalDig() {
      this.$http.post('/gamescenter-server/anonymous/games/search/totleminiting').then(res => {
        this.$store.commit('setTotalDig', res.GameTotleM);
      });
    }
  },
  created() {
    httpOutside.get("https://api.ipify.org?format=json").then(resIp => {
      httpOutside.get(`https://ipinfo.io/${resIp.ip}/json`).then(resInfo => {
        let navLang = '';
        if (resInfo.country == 'CN' || resInfo.country == 'HK' || resInfo.country == 'MO' || resInfo.country == 'TW') {
          navLang = 'zh';
        } else if (resInfo.country == 'TH') {
          navLang = 'th';
        } else if (resInfo.country == 'KR') {
          navLang = 'kr';
        } else if (resInfo.country == 'VN') {
          navLang = 'vn';
        } else if (resInfo.country == 'PT' || resInfo.country == 'BR') {
          navLang = 'pt';
        } else if (resInfo.country == 'MY' || resInfo.country == 'BN' || resInfo.country == 'SG') {
          navLang = 'ma';
        }
        this.$store.commit('changeLang', navLang);
        this.$i18n.locale = navLang;
      });
    });

    // this.$i18n.locale = this.$store.state.Lang;
    this.getCurrencyList();
    this.getRate();
    setInterval(() => {
      this.getRate();
    }, 1000 * 60);
    if (this.$store.state.userInfo.ID) {
      this.startTimer();
    }
    this.getTotalDig();
  },
  watch: {
    '$store.state.userInfo'(obj) {
      if (obj.ID) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    }
  }
};