// 帮助-Qgt代币的翻译
export default {
  title:"QGT Token Overview",
  title1:"1）mining",
  p1: "Mining is a currency mining system that mines QGT daily by placing bets. QGT is an incentive token for QueenG equity sharing and a healthy gaming environment. QGT tokens can be mined by playing games and betting. Your bets The more you stake - the more QGT you mine. Mining more QGT means steady daily returns.",
  p2: "⚠️ To reduce the burden of mining and ensure its correct process, the minimum stake to mine QGT is $1 (in any currency)",
  title2: "2) trading",
  p3_1: "The platform will continue to launch QGT trading and capital pools in various top tier platforms, such as UnisWAp, JustSwap and ApeSwap",
  p3_2: "The platform will also directly support the exchange of QGT, and internal transactions will be the fastest and easiest way to purchase QGT.",
  p4: "Become a lucky QGT owner, or exchange your QGT tokens for other cryptocurrencies.",
  title3: "3) QGT usage",
  subtitle1: "3.1) the pledge",
  p5: "By pledging QGT liquidity (in the Supporter module), users can enjoy a share of the platform's profits.",
  p6: "Every 24 hours, the pledge pool will release 20% of the platform's total profits for dividends, that is, the platform's daily earnings in various currencies will be paid to QGT liquidity providers in the form of dividends. The more QGT liquidity you pledge - the more dividend profits you earn! Profit from all the revenue on the platform!",
  subtitle2: "3.2) trading",
  p7:'Trading QGT tokens on the TOP exchange can also benefit from holding QGT tokens investment.',
  subtitle3: "3.3) gambling",
  p8: "QGT tokens can be used for all games in the casino, and the bet function is the same as other currencies; Minimum bet for the game - 1 QGT.",
  title4: "4) Destruction of QGT",
  p9: "To support the healthy development of the monetary economy and ecosystem, QueenG conducts QGT token burns every month. Planned burns increase the value of the QGT native tokens, maintaining balance between holders and developers, to support the continuous growth of token distribution models and Staking expenditures for token holders. Tokens are burned once every month."
}
