// 支持者的翻译
export default {
  todayDividend: "오늘 카지노에서 배당금을 지급할 것으로 예상된다",
  supplyYourLP:  "#플랫폼 파트너가 되어 플랫폼과 수수료를 공유하려면 LP를 공급하세요",
  supplyQGT:  "QGT 공급",
  dividendsExpected:  "배당금은 분배될 것으로 예상됩니다",
  networkCosts:  "예상 네트워크 요금",
  pledge:  "약속",
  pledgedQGT:  "QGT 약속",
  quantity:  "수량",
  unlock:  "터놓다",
  dividendsPaid:  "배당금 분배",
  settlementDate:  "결제 날짜",
  settlementQGT:  "정산 QGT",
  dividends:  "피제수",
  operation:  "작동하다",
  viewTheDetails:   "세부 정보보기",
  pledged:  "약속",
  unlockQuantity:  "잠금 해제된 수량",
  correctBalance:  "올바른 잔액을 입력해주세요",
  correctNumber:  "올바른 숫자를 입력해주세요",
  insufficientPledged:  "약정 수량 부족",
  unlockSucceeded:  "성공적으로 잠금 해제되었습니다."

}
