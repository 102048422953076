import QrcodeVue from 'qrcode.vue';
import WalletModal from "./WalletModal.vue";
export default {
  components: {
    WalletModal,
    'qrcode-vue': QrcodeVue
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: 0,
      search: '',
      chosedNet: 'bsc',
      chosedCoin: 1,
      chosedCoinCode: '02',
      chosedCoinName: 'USDT',
      address: '',
      num: '',
      recordType: 0,
      list1: [],
      list2: [],
      list3: [],
      total: 0,
      totalPage: 0,
      page: 1
    };
  },
  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    currencyList() {
      return this.$store.state.currencyList.filter(item => {
        return item.net === this.chosedNet && item.typeName.toUpperCase().includes(this.search.toUpperCase());
      });
    },
    netList() {
      return Array.from(new Set(this.$store.state.currencyList.map(item => {
        return item.net;
      })));
    },
    chosedCoinBalance() {
      return this.$store.state.balanceList.filter(item => {
        return item.coinName == this.chosedCoinName.toLocaleLowerCase();
      })[0].balance;
    }
  },
  methods: {
    changeType(type) {
      if (type == 2 && this.type != type) {
        this.recordType = 0;
        this.list1 = [];
        this.page = 1;
        this.totalPage = 0;
        this.getList1();
      }
      this.type = type;
    },
    changeCurrency(item) {
      this.chosedCoin = item.ID;
      this.chosedCoinCode = item.code;
      this.chosedCoinName = item.typeName.toUpperCase();
      this.$refs.coinBox.blur();
    },
    changeNet(name) {
      if (this.chosedNet == name.toLocaleLowerCase()) {
        return;
      }
      this.chosedNet = name.toLocaleLowerCase();
      const chosedCoin = this.$store.state.currencyList.filter(item => {
        return item.net === name;
      })[0];
      this.chosedCoin = chosedCoin.ID;
      this.chosedCoinCode = chosedCoin.code;
      this.chosedCoinName = chosedCoin.typeName.toUpperCase();
      this.$refs.netBox.blur();
    },
    copy(text) {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', text);
      input.select();
      if (document.execCommand('copy')) {
        this.$message.success(this.$t("wallet.copySucceeded"));
      }
      document.body.removeChild(input);
    },
    draw() {
      if (!this.address) {
        this.$message.error(this.$t("wallet.pleaseEnterAddress"));
        return;
      }
      const reg = /^(\d*|\d+\.\d{1,2})$/;
      if (!reg.test(this.num)) {
        this.$message.error(this.$t("wallet.enterCorrectBalance"));
        return;
      }
      if (Number(this.num) > this.chosedCoinBalance) {
        this.$message.error(this.$t("wallet.runningLow"));
        return;
      }
      this.$http.post('/wallet-manager/games/withdraw', {
        Net: this.chosedNet,
        Amount: this.num,
        coinType: this.chosedCoinCode,
        ToAddress: this.address
      }).then(() => {
        this.num = '';
        this.address = '';
        this.$message.success(this.$t("wallet.withdrawalSucceeded"));
      });
    },
    changeRecordType(type) {
      this.recordType = type;
      this.list1 = [];
      this.list2 = [];
      this.list3 = [];
      this.page = 1;
      this.totalPage = 0;
      if (type == 0) {
        this.getList1();
      } else if (type == 1) {
        this.getList2();
      } else if (type == 2) {
        this.getList3();
      }
    },
    getList1() {
      this.$http.get('/wallet-manager/games/recharge', {
        params: {
          page: this.page,
          pageSize: 5
        }
      }).then(res => {
        this.list1 = res.list;
        this.total = res.total;
        this.totalPage = Math.ceil(res.total / 5);
      });
    },
    getList2() {
      this.$http.get('/wallet-manager/games/withdraw', {
        params: {
          page: this.page,
          pageSize: 5
        }
      }).then(res => {
        this.list2 = res.list;
        this.total = res.total;
        this.totalPage = Math.ceil(res.total / 5);
      });
    },
    getList3() {
      this.$http.get('/wallet-manager/games/otherTrade', {
        params: {
          page: this.page,
          pageSize: 5
        }
      }).then(res => {
        this.list3 = res.list;
        this.total = res.total;
        this.totalPage = Math.ceil(res.total / 5);
      });
    },
    turnPage(num) {
      this.page = this.page + num;
      if (this.recordType == 0) {
        this.getList1();
      } else if (this.recordType == 1) {
        this.getList2();
      } else if (this.recordType == 2) {
        this.getList3();
      }
    }
  }
};