export default {
  props: {
    modelValue: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      rowList: [10, 20, 50]
    };
  },
  methods: {
    changeRow(row) {
      this.$emit('update:modelValue', row);
      this.$emit('change', row);
      this.$refs.dropDown.blur();
    }
  }
};