// 设置的翻译
export default {
  routine:  "konvensional",
  emailAddress:  "alamat emel",
  changeEmail:  "Tukar alamat emel",
  cellPhoneNumber:  "Nombor telefon",
  areaCode:  "kod kawasan",
  preservation:  "simpan",
  originalMailbox:  "Kod pengesahan e-mel asal",
  mailbox:  "e-mel asal",
  sendVerificationCode:  "Hantar kod pengesahan",
  nextStep:  "Langkah seterusnya",
  newEmailAddress:  "Alamat Email Baru",
  confirmReplacement:  "Sahkan penggantian",
  originalPhoneCode:  "Kod pengesahan telefon mudah alih asal",
  originalPhone:  "Nombor telefon bimbit asal",
  newPhoneNumber:  "Nombor mudah alih baharu",
  oldPassword:  "Kata laluan lama",
  newPassword:  "Kata laluan baharu",
  confirmNewPassword:  "Sahkan kata laluan baharu",
  oldPasswordError:  "Kata laluan lama salah",
  pleaseOldPassword:  "Sila masukkan kata laluan lama anda",
  pleaseNewPassword:  "Sila masukkan kata laluan baharu",
  password:  "kata laluan",
  changePhone:  "Tukar nombor telefon bimbit",
  savePhone:  "Simpan nombor telefon",
  phoneNumber:  "Nombor telefon bimbit telah dihantar",
  resend:  "Hantar semula",
  mailSending:  "E-mel dihantar terlalu kerap",
  enterCorrectCode:  "Sila masukkan kod pengesahan yang betul",
  checkEmail:  "Sila semak e-mel anda",
  sendingSucceeded:  "Berjaya dihantar",
  mailboxSucceeded:  "E-mel berjaya ditukar",
  savingSucceeded:  "Berjaya disimpan",
  enterConfirmationPassword:  "Sila masukkan kata laluan pengesahan",
  passwordsTwiceInconsistent:  "Kata laluan yang dimasukkan dua kali tidak konsisten",
  passwordSuccessfully:  "Tetapan semula kata laluan selesai"
}
