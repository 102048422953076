// 登录注册的翻译
export default {
  emailOrUserName: "电子邮箱或用户名",
  password: "密码",
  signIn: "登录",
  registration: "注册",
  emailOrUsernameNotExist: "电子邮箱或用户名不存在",
  passwordError: "密码错误",
  loginSuccessful: "登录成功",
  email: "电子邮箱",
  userName: "用户名",
  invitationCode: "邀请码",
  termsServiceConfirmation: "点击复选框即表示您已阅读并确认",
  service: "条款与条件",
  mailboxExists: "邮箱已存在",
  userNameExists: "用户名已存在",
  incorrectMailbox: "邮箱格式不正确",
  incorrectPasswords: "密码长度不得低于6位，且需包含字母和小数点",
  invitationCodeNotExist: "邀请码不存在",
  registrationSuccessful: "注册成功",
  pleaseCheckEmail: "请检查电子邮箱",
  pleaseEnterEmailAddress: "请输入电子邮箱",
  enterUserNameOrEmail: "请输入用户名或邮箱",
  pleaseInputPassword: "请输入密码",
  verificationCode: "验证码",
  sendVerificationCode: "发送验证码",
  mailSent: "邮件已发送",
  enterVerificationCode: "请输入验证码",
  enterUserName: "请输入用户名",
  agreeConditions: "请同意条款与条件",
  forgetPassword: '忘记密码',
  forgetEmail: '电子邮箱',
  forgetCode: '验证码',
  forgetLogin: '去登录',
  forgetConfirm: '确认',
  forgetErrorEmail: '请填写电子邮箱',
  forgetErrorVerificationCode: '请填写验证码',
  forgetErrorSuccess: '密码重置成功',
}
