// 三个游戏及我的投注的翻译
export default {
  pledge:  "เงินฝาก",
  yawan:  "โอเช",
  bettingAmount:  "จำนวนเงินเดิมพัน",
  highestWin:  "ชัยชนะสูงสุด",
  castOnce:  "โหวต 1 ครั้ง",
  castFive:  "โหวต 5 ครั้ง",
  excavatedOnce:  "การลงทุนเพียงครั้งเดียวคาดว่าจะส่งผลให้เกิดการขุด",
  bigWinner:  "ผู้ชนะที่ยิ่งใหญ่",
  mostFortunate:  "โชคดีที่สุด",
  rulesGame:  "กฎของเกม",
  player:  "ผู้เล่น",
  betting:  "เดิมพัน",
  multiple:  "หลายรายการ",
  bonus:  "โบนัส",
  placeBet:  "วางเดิมพัน",
  result:  "ผลลัพธ์",
  earnings:  "รายได้",
  bettingTime:  "เวลาชำระหนี้",
  bettingDirection:  "ทิศทางการเดิมพัน",
  aboutToBegin:  "กำลังจะเริ่ม",
  excavatedTurningOnce:  "คาดว่าคุณจะสามารถขุดมันได้เมื่อคุณโอนมัน",
  drawTheLottery:  "หวยออก!",
  latestPrice:  "ราคาล่าสุด",
  realTime:  "เรียลไทม์",
  oneMinute:  "1 นาที",
  fifteenMinutes:  "15 นาที",
  guessTheRise:  "เดาการเพิ่มขึ้น",
  guessTheFall:  "เดาการตก",
  bettingPrice:  "ราคาเดิมพัน",
  settlementInProgress:  "อยู่ระหว่างดำเนินการชำระหนี้",
  game:  "เกม",
  return:  "กลับ",
  theRise:  "ลุกขึ้น",
  theFall:  "ตก",
  openNote:  "เปิดเดิมพัน",
  selected:  "เลือก",
  pleaseLoginFirst:  "กรุณาเข้าสู่ระบบก่อน",
  enterCorrectAmount:  "กรุณากรอกจำนวนเงินเดิมพันให้ถูกต้อง",
  creditLow:  "ยอดเงินไม่เพียงพอ",
  maximumWorthTokens:  "เดิมพันสูงสุดคือโทเค็นมูลค่า 100,000 USDT",
  congratulationsWinning:  "ขอแสดงความยินดีกับชัยชนะ",
  winningLottery:  "ขอแสดงความยินดีกับชัยชนะของคุณ",
  didnnotWinPrize:  "ขอโทษทีไม่ชนะ"
}
