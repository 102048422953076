// 帮助-关于的翻译
export default {
  aboutQueenG: "Giới thiệu về QueenG",
  p1:  "QueenG là một nền tảng iGaming dựa trên BSC. Mặc dù vẫn giữ thói quen cờ bạc truyền thống nhưng nó hoàn toàn phi tập trung, đảm bảo tính công bằng và cởi mở của quy trình trò chơi, đồng thời mang lại trải nghiệm chơi trò chơi độc đáo cho tất cả người dùng. Giao diện đơn giản và dễ sử dụng và đường dẫn hoạt động sẽ giúp trải nghiệm phát huy hết khả năng của bạn.",
  p2:  "Hỗ trợ nhiều lần đăng nhập kết nối ví blockchain, tài sản được kiểm soát bởi ví của chính người dùng và thu nhập của người dùng được chuyển vào ví ngay lập tức, điều này hoàn toàn an toàn và đáng tin cậy.",
  p3:  "Nền tảng QueenG được chia thành hai phần: cờ bạc và thể thao. Cờ bạc bao gồm các trò chơi sòng bạc truyền thống như xúc xắc và máy đánh bạc, còn thể thao bao gồm đặt cược vào các sự kiện khác nhau. Trong giai đoạn đầu, nó sẽ là các trò chơi nguyên gốc, và sau đó sẽ là được kết nối với nhiều nhà cung cấp và đối tác trò chơi khác nhau và sẽ được ra mắt hợp tác trong nền tảng. .",
  major:  "Bốn lợi ích chính của người dùng",
  tip1:  "1) Thu nhập cầm cố",
  detail1:  "Người dùng cam kết tính thanh khoản của tiền tệ nền tảng có thể được hưởng cổ tức thu nhập nền tảng.",
  tip2:  "2) Thu nhập từ tiền gửi",
  detail2:  "Sau khi nền tảng cung cấp nhiều sản phẩm nông nghiệp khác nhau và tiền kỹ thuật số tiền gửi có kỳ hạn, bạn có thể nhận được tiền lãi hàng năm tương ứng khi hết thời hạn gửi.",
  tip3:  "3) Thu nhập khai thác",
  detail3:  "Trò chơi của người dùng đang khai thác. Bạn càng chơi nhiều trò chơi, bạn càng nhận được nhiều phần thưởng khai thác tiền tệ trên nền tảng hơn.",
  tip4:  "4) Thu nhập chiết khấu",
  detail4:  "Người dùng chia sẻ liên kết lời mời, người được mời đăng ký và tích cực chơi trò chơi trên nền tảng và người mời sẽ nhận được thu nhập thụ động.",
}
