// 帮助-Ido质押的翻译
export default {
  title:  "จำนำ",
  p1:  "ผู้ใช้ถือและให้คำมั่นสัญญากับ QGT LP เพื่อจัดหาสภาพคล่องให้กับแพลตฟอร์ม เมื่อเป็นผู้สนับสนุนแพลตฟอร์ม พวกเขายังสามารถเพลิดเพลินกับสิทธิ์ของพันธมิตรแพลตฟอร์ม ทุก ๆ 24 ชั่วโมง 20% ของกำไรรวมของกลุ่มการเดิมพันแพลตฟอร์มจะถูกแจกจ่ายให้กับผู้ใช้ที่เดิมพันแต่ละคน . ",
  p2:  "จำนวนเงินนี้จะถูกแจกจ่ายในสกุลเงินดิจิทัลที่ได้รับจากกลุ่มการเดิมพันของแพลตฟอร์ม นั่นคือสกุลเงินชิปเดิมพัน",
  title1:  "1) โบนัสผู้สนับสนุนทำงานอย่างไร",
  p3:  "กลุ่มคำมั่นสัญญาจะถูกตัดสินทุกๆ 24 ชั่วโมง และ 20% ของกำไรแพลตฟอร์มทั้งหมดจากวันก่อนหน้าจะถูกแจกจ่ายให้กับผู้สนับสนุนทุกคน",
  p4:  "ยิ่งกว่านั้น เพียงแค่วางเดิมพัน LP ที่มีสภาพคล่องเพียงอันเดียว คุณก็สามารถรับรายได้มหาศาลจากสกุลเงินดิจิทัลทั้งหมดของแพลตฟอร์ม: USDT, ETH, BTC, BNB, TRX และอื่นๆ",
  p5:  "ในหน้าผู้สนับสนุน คุณสามารถดูรายได้รายวันโดยประมาณและรายรับจากการปักหลักโดยประมาณ",
  img1:  "",
  p6:  "ป้อนตัวเลขลงในช่องและดูผลประโยชน์ที่คาดการณ์ไว้ภายใต้สถานการณ์ต่างๆ",
  p7:  "ยิ่งคุณเดิมพันสภาพคล่องมากเท่าไร คุณก็ยิ่งได้รับมากขึ้นเท่านั้น",
  title2:  "2) ขุด QGT เพิ่มเพื่อเพิ่มรายได้",
  p8:  "20% ของผลกำไรรายวันของแพลตฟอร์มจะถูกแบ่งปันให้กับผู้สนับสนุนทั้งหมด คุณสามารถดูรายละเอียดการจ่ายเงินปันผลได้ในหน้าผู้สนับสนุนและศูนย์ส่วนบุคคล",
  p9:  "อย่างไรก็ตาม ยิ่งผู้สนับสนุน QueenG มากเท่าไร เงินปันผลก็จะยิ่งน้อยลงเท่านั้น เพิ่มรายได้ของคุณโดยการวางเดิมพันมากขึ้นเพื่อขุดโทเค็น QGT และสภาพคล่องของการเดิมพันบนแพลตฟอร์ม! ขยายสภาพคล่องของ QGT ในสกุลเงินที่มีอยู่ทั้งหมดทุกวัน ให้คำมั่นว่าจะได้รับรางวัลมหาศาล!"
}
