// 支持者的翻译
export default {
  todayDividend:  "Adalah dijangka bahawa kasino akan membayar dividen hari ini",
  supplyYourLP:  "#Bekalkan LP anda untuk menjadi rakan kongsi platform dan kongsi komisen dengan platform",
  supplyQGT:  "Bekalan QGT",
  dividendsExpected:  "Dividen dijangka akan diagihkan",
  networkCosts:  "Anggaran caj rangkaian",
  pledge:  "Ikrar",
  pledgedQGT:  "QGT berikrar",
  quantity:  "kuantiti",
  unlock:  "buka kunci",
  dividendsPaid:  "Dividen diagihkan",
  settlementDate:  "Tarikh penyelesaian",
  settlementQGT:  "Penyelesaian QGT",
  dividends:  "dividen",
  operation:  "beroperasi",
  viewTheDetails:   "Lihat butiran",
  pledged:  "Berjanji",
  unlockQuantity:  "Kuantiti tidak berkunci",
  correctBalance:  "Sila masukkan baki yang betul",
  correctNumber:  "Sila masukkan nombor yang betul",
  insufficientPledged:  "Kuantiti yang dicagarkan tidak mencukupi",
  unlockSucceeded:  "Berjaya dibuka"
}
