// 帮助-Ido质押的翻译
export default {
  title: "Ikrar",
  p1:  "Pengguna memegang dan mengikrarkan QGT LP untuk menyediakan kecairan untuk platform. Apabila menjadi penyokong platform, mereka juga boleh menikmati hak rakan kongsi platform. Setiap 24 jam, 20% daripada jumlah keuntungan kumpulan pertaruhan platform akan diagihkan kepada setiap pengguna yang mempertaruhkan . ",
  p2:  "Jumlah ini akan diagihkan dalam mata wang digital yang diperoleh oleh kumpulan pertaruhan platform, iaitu mata wang cip pertaruhan.",
  title1:  "1) Bagaimanakah bonus penyokong berfungsi?",
  p3:  "Kolam ikrar dijelaskan setiap 24 jam, dan 20% daripada jumlah keuntungan platform dari hari sebelumnya akan diagihkan kepada semua penyokong.",
  p4:  "Apatah lagi, dengan hanya mempertaruhkan satu LP cair, anda boleh memperoleh pendapatan yang besar merentas semua mata wang kripto platform: USDT, ETH, BTC, BNB, TRX dan banyak lagi.",
  p5:  "Pada halaman penyokong, anda boleh melihat anggaran pendapatan harian dan anggaran pendapatan taruhan.",
  img1:  "",
  p6:  "Masukkan nombor dalam medan dan lihat faedah yang diunjurkan di bawah senario yang berbeza.",
  p7:  "Lebih banyak kecairan yang anda pertaruhkan, lebih banyak yang anda perolehi.",
  title2:  "2) Lombong lebih banyak QGT untuk menambah pendapatan",
  p8:  "20% daripada keuntungan harian platform akan dikongsi di kalangan semua penyokong. Anda boleh melihat butiran dividen di halaman penyokong dan pusat peribadi.",
  p9:  "Namun, semakin ramai penyokong QueenG, semakin sedikit dividen yang anda terima. Tingkatkan pendapatan anda dengan mempertaruhkan lebih banyak untuk melombong token QGT dan kecairan pegangan pada platform! Kembangkan kecairan QGT dalam semua mata wang yang tersedia setiap hari , berikrar untuk mendapatkan ganjaran yang besar!"
}
