// 支持者的翻译
export default {
  todayDividend: "Expected dividend of Casino today",
  supplyYourLP: "# Supply your LP to become a platform partner and share commissions with the platform",
  supplyQGT: "Supply QGT",
  dividendsExpected:"Estimated dividends",
  networkCosts:"Estimated network fees",
  Stake:"Staking",
  StakedQGT:"QGT staked",
  quantity:"Amount",
  unlock: "Unlock",
  dividendsPaid:"Dividends distributed",
  settlementDate: "Settlement date",
  settlementQGT: "Settlement QGT",
  dividends: "Dividends",
  operation: "Operation",
  viewTheDetails:"View Details",
  Staked:"Staked",
  unlockQuantity:"Amount to unlock",
  correctBalance: "Please enter the correct balance",
  correctNumber:"Please enter a valid number",
  insufficientStaked:"Insufficient staked amount",
  unlockSucceeded:"Unlock successful"
}
