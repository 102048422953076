// 帮助-Ido质押的翻译
export default {
  title: "Stake",
  p1: "Users hold and Stake QGT LP to provide liquidity for the platform. When becoming a platform supporter, they can also enjoy platform partner rights. Every 24 hours, 20% of the total profit of the platform betting pool will be distributed to each staking user.",
  p2: "This amount will be distributed in the digital currency earned by the platform's betting pool, that is, the betting chip currency.",
  title1:  "1）How does the dividends work?",
  p3: "The staking pool settles once every 24 hours, distributing 20% of the previous day's total platform profit to all supporters.",
  p4: "What's more, by simply staking one liquid LP, you can earn huge earnings across all the platform's cryptocurrencies: USDT, ETH, BTC, BNB, TRX, and more.",
  p5: "On the Supporters' page, you can view estimated daily earnings and estimated staking earnings.",
  img1: "",
  p6: "Enter numbers in the fields and see projected benefits under different scenarios.",
  p7: "The more liquidity you stake, the more you earn.",
  title2: "2) Mine more QGT to increase earnings",
  p8: "20% of the platform's daily profits will be shared among all supporters. You can view the dividend details on the supporter page and personal hub.",
  p9: "However, the more supporters of QueenG, the less dividends you receive. Increase your earnings by staking more to mine QGT tokens and stake liquidity on the platform! Expand QGT liquidity in all available currencies every day , Stake to get huge rewards!"
}
