// 日常任务的翻译
export default {
  manyRewards: "Có nhiều phần thưởng",
  theDailyTask:  "công việc hàng ngày",
  getIt:  "Bạn có thể đến và lấy nó sau!",
  moreRewards:  "Đặt cược càng nhiều, phần thưởng càng lớn",
  completeGame:  "Chọn và hoàn thành nhiệm vụ trò chơi hàng ngày để có thêm hứng thú trong QueenG!",
  distributed:  "Nhiệm vụ hàng ngày được phân bổ cho nhiều loại trò chơi khác nhau. Hãy hoàn thành chúng và nhận phần thưởng!",
  dailyTask:  "Đếm ngược nhiệm vụ hàng ngày:",
  bonusCompleting:  "Phần thưởng bổ sung khi hoàn thành tất cả nhiệm vụ:",
  getMany:  "nhận được",
  winDice1:  "Đặt cược vào xúc xắc để thắng 30 lần",
  winDice2:  "Đặt cược vào kết quả hòa và thắng 30 lần",
  leopard:  "Con xúc xắc ném ra một con báo",
  winTwice:  "Sử dụng cùng một số may mắn hai lần liên tiếp x2 trong xúc xắc",
  roulette1:  "Chơi roulette 30 lần trong X2",
  roulette2:  "Chơi Roulette 20 lần trong X3",
  roulette3:  "Chơi Roulette 15 lần trong X5",
  roulette4:  "Chơi Roulette 20 lần trong X50",
  guess1:  "đoán giá lên hoặc xuống 30 lần",
  guess2:  "Đoán đúng 20 lần",
  guess3:  "Đoán đúng 20 lần",
  guess4:  "Đoán đúng cú ngã 5 lần liên tiếp",
  collectCompletion:  "Click để nhận sau khi hoàn thành",
  notCompleted:  "Nhiệm vụ vẫn chưa hoàn thành",
  rewardsReceived:  "Đã nhận được phần thưởng",
  SuccessfullyReceived:  "Nhận thành công"

}
