// 帮助-Ido质押的翻译
export default {
  title: "약속",
  p1:  "사용자는 플랫폼에 유동성을 제공하기 위해 QGT LP를 보유하고 서약합니다. 플랫폼 지지자가 되면 플랫폼 파트너 권리도 누릴 수 있습니다. 24시간마다 플랫폼 베팅 풀의 총 수익의 20%가 각 스테이킹 사용자에게 분배됩니다. . ",
  p2:  "이 금액은 플랫폼의 베팅 풀에서 얻은 디지털 통화, 즉 베팅 칩 통화로 분배됩니다.",
  title1:  "1) 후원자 보너스는 어떻게 작동하나요?",
  p3:  "서약풀은 24시간마다 정산되며, 전날 플랫폼 전체 수익의 20%가 모든 서포터들에게 분배됩니다.",
  p4:  "또한 유동 LP 1개만 스테이킹하면 USDT, ETH, BTC, BNB, TRX 등 플랫폼의 모든 암호화폐에서 막대한 수익을 얻을 수 있습니다.",
  p5:  "후원자 페이지에서는 예상 일일 수익과 예상 스테이킹 수익을 확인할 수 있습니다.",
  img1:  "",
  p6:  "필드에 숫자를 입력하고 다양한 시나리오에서 예상되는 이점을 확인하세요.",
  p7:  "더 많은 유동성을 보유할수록 더 많은 수익을 얻을 수 있습니다.",
  title2:  "2) 더 많은 QGT를 채굴하여 수입을 늘리세요",
  p8:  "플랫폼 일일 수익의 20%는 모든 서포터들에게 공유됩니다. 배당 내역은 서포터 페이지와 개인센터에서 확인하실 수 있습니다.",
  p9:  "그러나 QueenG의 지지자가 많을수록 받는 배당금은 줄어듭니다. QGT 토큰을 채굴하고 플랫폼에 유동성을 스테이킹하기 위해 더 많이 스테이킹하여 수입을 늘리십시오! 매일 사용 가능한 모든 통화로 QGT 유동성을 확장하고 엄청난 보상을 받을 것을 약속하세요!"
}
