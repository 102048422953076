export default {
  props: {
    value: [String, Number],
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    error: {
      type: String,
      default: ""
    },
    buttonText: {
      type: [String, Number],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPassword: false
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  },
  emits: ['update:error'],
  methods: {
    switchShowPassword() {
      this.showPassword = !this.showPassword;
    },
    input() {
      this.$emit('update:value', this.inputValue);
      this.$emit('update:error', '');
    },
    clickButton() {
      this.$emit('buttonClick');
    }
  }
};