// 首页的翻译
export default {
  qGTHasHeenAwarded:  "QGT ได้รับรางวัลแล้ว",
  homePage:  "หน้าแรก",
  lasVegas:  "คาสิโน",
  throwTheDice:  "โยนลูกเต๋า",
  roulette:  "รูเล็ต",
  guessTheRiseAndFall:  "คาดเดาการขึ้นหรือลง",
  slotMachine:  "สล็อตแมชชีน",
  aloneGame:  "มินิเกมผู้เล่นคนเดียว",
  arcade:  "อาร์เคด",
  pledge:  "จำนำ",
  commissionBack:  "ส่วนลด",
  noviceMission:  "งานสามเณร",
  qGTTokens:  "โทเค็น QGT",
  helpCenter:  "ศูนย์ช่วยเหลือ",
  games:  'เกม',
  user:  'ผู้ใช้',
  payout:  'วางเดิมพัน',
  gamesTypeAll:  'ทั้งหมด',
  gamesType1:  'คาสิโน',
  gamesType2:  'สล็อตแมชชีน',
  gamesType3:  'มินิเกมแบบสแตนด์อโลน',
  gamesType4:  'อาร์เคด',
  gamesType5:  'กิจกรรมกีฬา',
  gamesType1msg:  'ลูกค้าสามารถเลือกที่จะวางเดิมพันที่ประตูอื่นได้ตามต้องการ',
  gamesType2msg:  'หลังจากใส่เหรียญแล้ว ให้ดึงคันโยกแล้วมันจะเริ่มหมุน',
  gamesType3msg:  'หลังจากใส่เหรียญแล้ว ให้ดึงคันโยกแล้วมันจะเริ่มหมุน',
  gamesType4msg:  'หลังจากใส่เหรียญแล้ว ให้ดึงคันโยกแล้วมันจะเริ่มหมุน',
  gamesType5msg:  'งานกีฬากำลังจะมาเร็วๆ นี้',
  gamesRecordText:  'บันทึกเกม',
  theOriginalGame:  "เกมต้นฉบับ",
  miningMode:  "นั่นคือโหมดการขุด",
  miningRewards:  "ยิ่งคุณเดิมพันมากเท่าไร คุณจะได้รับรางวัลมากขึ้นเท่านั้น และรางวัลการขุดจะถูกตัดสินแบบเรียลไทม์",
  qGTCanBeDugToday:  "การขุดกำลังมา",
  highestEarnedMultiplier:  "การแข่งขันกีฬากำลังจะมา",
  gamesPlayed:  "เล่นเกม",
  gamesPlayedNew:  "เกมที่ฉันเล่นบ่อยเมื่อเร็ว ๆ นี้",
  highestWin:  "ชัยชนะสูงสุด",
  participant:  "ผู้เข้าร่วม",
  canBeDugToday:  "วันนี้คุณขุดได้",
  highReturn:  "ผลตอบแทนสูง",
  highYield:  "รายได้สูง",
  signIn:  "เข้าสู่ระบบ",
  registration:  "ลงทะเบียน",
  purse:  "กระเป๋าสตางค์",
  search:  "ค้นหา",
  hideOneBalance:  "ซ่อนยอดคงเหลือ 0",
  displayUSD:  "แสดงดอลลาร์",
  personalCenter:  "ศูนย์ส่วนบุคคล",
  invite:  "คัดลอกลิงก์คำเชิญ",
  inviteCopy:  "คัดลอกลิงก์คำเชิญเรียบร้อยแล้ว",
  setUp:  "ติดตั้ง",
  Language:  "ตั้งค่าภาษา",
  LanguageZh:  "จีนดั้งเดิม",
  LanguageEn:  "ภาษาอังกฤษ",
  logOutAccount:  "ออกจากบัญชี"
};
