import HelpMenu from './HelpMenu.vue';
import MyHeader from './MyHeader.vue';
import LeftMenu from './LeftMenu.vue';
export default {
  components: {
    HelpMenu,
    MyHeader,
    LeftMenu
  },
  data() {
    return {};
  },
  methods: {}
};