// 钱包的翻译
export default {
  deposit:  "tiền gửi",
  withdrawal:  "Rút tiền",
  other:  "khác",
  recordDrawing:  "Hồ sơ gửi tiền và rút tiền",
  network:  "mạng",
  tokenContract:  "Địa chỉ hợp đồng mã thông báo:",
  your:  "Của bạn",
  depositAddress:  "Địa chỉ gửi tiền",
  minimumDeposit:  "Số tiền gửi tối thiểu",
  lowerAmounts:  ",Số tiền thấp hơn sẽ không được ghi có hoặc hoàn lại.",
  sendOnly:  "Vui lòng chỉ gửi",
  toAddress:  "Đến địa chỉ này, bạn cần nhận được 1 xác nhận để khoản tiền gửi được ghi có.",
  yourWalletAddress:  "Địa chỉ ví của bạn",
  enterAddress:  "Nhập địa chỉ",
  quantity:  "Số lượng",
  balance:  "Sự cân bằng",
  enterAmount:  "Nhập số tiền",
  whole:  "tất cả",
  minimumWithdrawalAmount:  "Số tiền rút tối thiểu là",
  transactionCosts:  "Phí giao dịch: - Thời gian sử dụng - 0-2 giờ. Không bao gồm xác nhận mạng,",
  additionalTime:  "Có thể mất thêm thời gian.",
  sendingAddress:  "địa chỉ gửi",
  accountArrivalTime:  "Thời gian đến",
  accountStates:  "tình trạng",
  accountStatesTxt_1:  "Vật bị loại bỏ",
  accountStatesTxt0:  "Để được xem xét",
  accountStatesTxt1:  "Đã kiểm toán",
  accountStatesTxt2:  "Tới nơi",
  withdrawalAddress:  "Địa chỉ rút tiền",
  serviceCharge:  "phí xử lý",
  pleaseEnterAddress:  "Vui lòng nhập địa chỉ",
  extract:  "trích xuất",
  copySucceeded:  "Sao chép thành công",
  enterCorrectBalance:  "Vui lòng nhập số dư chính xác",
  runningLow:  "Thiếu cân bằng",
  withdrawalSucceeded:  "Rút tiền thành công",
  otherCoinName:  'Tên tiền tệ',
  otherAmount:  'số lượng',
  otherType:  'kiểu',
  otherStatus:  'tình trạng',
  otherStatus1:  'không hợp lệ',
  otherStatus2:  'hoàn tác',
  otherStatus3:  'hoàn thành',
}
