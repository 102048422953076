// 帮助-Qa的翻译
export default {
  title:  "vấn đề thường gặp",
  title1:  "1) Khoản tiền rút của tôi vẫn chưa đến?",
  p1_1:  "Tại sao số tiền rút của bạn vẫn chưa về ví của bạn.",
  p1_2:  "Có một số lý do khiến số tiền rút của bạn không đến ví của bạn, lý do phổ biến nhất là giao dịch chưa được xác nhận.",
  p1_3:  "Mọi giao dịch phải được xác nhận trước khi được ghi có vào địa chỉ đích. Số lượng xác nhận cần thiết tùy thuộc vào từng ví. Khi nói đến Bitcoin, một số ví chỉ yêu cầu 1 xác nhận trong khi những ví khác yêu cầu 3+ Xác nhận. Những xác nhận này sẽ mất một khoảng thời gian nhất định khoảng thời gian.",
  p1_4:  "Nếu số tiền rút lớn, chúng có thể bị kẹt và yêu cầu quản trị viên của chúng tôi xử lý thủ công.",
  p1_5:  "Lưu ý: Ngay cả việc rút tiền thủ công cũng được xử lý gần như ngay lập tức, bạn không cần phải lo lắng, chỉ cần kiên nhẫn.",
  title2:  "2) Cá cược và khai thác là gì",
  p2_1:  "Tổng số QGT là 8 tỷ, 50% trong số đó được dùng để làm phần thưởng cá cược, tức là chơi trò chơi là khai thác và phần này được giảm hàng năm. Người dùng có thể tận hưởng cổ tức từ thu nhập khai thác của ngày hôm trước mỗi ngày.' ngày, và họ càng đặt cược nhiều, Bạn càng thích, bạn càng kiếm được nhiều.",
  p2_2:  "Ưu điểm của việc chơi và khai thác là ngay cả khi người dùng bị thua lỗ trong trò chơi, họ vẫn có thể nhận được lợi nhuận cao hơn bằng cách bán QGT. Đồng thời, QGT cũng trực tuyến trên các sàn giao dịch bên ngoài và người dùng có thể chuyển và lưu thông tự do.",
  title3:  "3) Khi nào hoa hồng và cổ tức sẽ được trả?",
  p3_1:  "Tiền thưởng hoa hồng và cổ tức của người hỗ trợ cho các khoản giảm giá lời mời được thanh toán mỗi ngày một lần và phần thưởng cho ngày hôm trước sẽ được phân phối sau 0:00 UTC ngày hôm sau;",
  p3_2:  "Việc phát hành phần thưởng khai thác là như nhau;",
  title4:  "4) Tôi có thể gửi tiền kỹ thuật số qua mạng BEP2 hoặc Tron không?",
  p4_1:  "Bạn có thể kiểm tra các mạng và loại tiền được hỗ trợ thông qua khu vực nạp tiền vào ví. Nếu bạn không thấy thì nghĩa là việc nạp tiền chưa được hỗ trợ;",
  title5:  "5) Về phí xử lý và phí rút tiền",
  p5_1:  "Mỗi khi bạn giành được tiền thưởng bằng cách đặt cược, nền tảng sẽ khấu trừ 0,3% phí cá cược, nhưng số tiền này sẽ không được sử dụng khi bạn thua trò chơi;",
  p5_2:  "Đối với mỗi lần rút tiền, nền tảng sẽ tính phí rút tiền tương ứng dựa trên tỷ giá mạng hiện tại;"
}
