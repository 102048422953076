// 返佣的翻译
export default {
  rebateProgram:  "โปรแกรมส่วนลด",
  usersShare:  "เมื่อผู้ใช้แชร์ลิงก์คำเชิญ ผู้ได้รับเชิญเชื่อมต่อผ่านลิงก์และเล่นเกมบนแพลตฟอร์ม ผู้เชิญจะได้รับรายได้แบบพาสซีฟ ยิ่งคุณเชิญผู้เล่นมากเท่าไร รายได้ก็จะยิ่งมากขึ้นเท่านั้น",
  usingTheReferralLinks:  "การใช้ลิงก์อ้างอิงที่สร้างโดยแพลตฟอร์ม จะถูกบันทึกและติดตามว่าผู้ใช้ที่คุณเชิญมาอย่างไร และเงินรางวัลที่คุณได้รับจากการเดิมพันของพวกเขา",
  enterInvitation:  "เข้าสู่หน้าคำเชิญ",
  commissionRebate:  "ส่วนลดกำไรสุทธิ",
  inviteeWinsBet:  "ทุกครั้งที่ผู้ได้รับเชิญชนะการเดิมพัน 3% ของเงินรางวัลของผู้ได้รับเชิญจะจ่ายให้กับคุณทันที",
  platformBetting:  "แพลตฟอร์มคืนเงินเดิมพัน",
  inviteeLoses:  "ทุกครั้งที่ผู้ได้รับเชิญแพ้เดิมพัน แพลตฟอร์มจะรับ 3% ของเดิมพันและจ่ายเงินให้กับคุณ",
  miningCommission:  "ส่วนลดการขุด",
  miningRebate:  "ทุกครั้งที่ผู้ได้รับเชิญได้รับรายได้จากการขุด คุณจะได้รับค่าคอมมิชชันการขุด 3% ที่สอดคล้องกัน"
}
