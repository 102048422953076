// 返佣的翻译
export default {
  rebateProgram: "返佣计划",
  usersShare: "用户分享邀请链接、被邀请人通过链接连接并在平台上积极玩游戏，邀请人都会获得被动收入。您邀请的玩家越多 - 您获得的收入就越高。",
  usingTheReferralLinks: "使用平台生成的推荐链接，将记录并跟踪您邀请的用户是如何来的，以及您从他们的投注中获得什么奖金。",
  enterInvitation: "进入邀请页",
  commissionRebate: "净盈利返佣",
  inviteeWinsBet: "每次受邀人赢得投注后，受邀人盈利部分的3%将立即支付给您。",
  platformBetting: "平台下注金返佣",
  inviteeLoses: "每次受邀人赌输后，平台抽取下注金的3%支付给您。",
  miningCommission: "挖矿返佣",
  miningRebate: "每次受邀人挖矿获得收益后，您将会获得3%对应的挖矿返佣。"
}
