// 返佣的翻译
export default {
  rebateProgram:  "Programa de descontos",
  usersShare:  "À medida que os usuários compartilham links de convite, os convidados se conectam por meio do link e jogam ativamente na plataforma, o autor do convite ganha uma renda passiva. Quanto mais jogadores você convidar, maior será a renda que você ganha.",
  usingTheReferralLinks:  "Através do link de indicação gerado pela plataforma, será registrado e rastreado como chegam os usuários que você convida e quais ganhos você recebe com suas apostas.",
  enterInvitation:  "Entre na página de convite",
  commissionRebate:  "Desconto de Lucro Líquido",
  inviteeWinsBet:  "Cada vez que o convidado ganhar uma aposta, 3% dos ganhos do convidado serão pagos a você imediatamente.",
  platformBetting:  "Desconto em dinheiro para apostas na plataforma",
  inviteeLoses:  "Cada vez que o convidado perder uma aposta, a plataforma pegará 3% da aposta e pagará para você.",
  miningCommission:  "Desconto de mineração",
  miningRebate:  "Cada vez que o convidado obtiver receita com a mineração, você receberá uma comissão de mineração correspondente de 3%."
}
