// 钱包的翻译
export default {
  deposit: "存款",
  withdrawal: "提款",
  other: "其他",
  recordDrawing: "充提记录",
  network: "网络",
  tokenContract: "代币合约地址:",
  your: "您的",
  depositAddress: "存款地址",
  minimumDeposit: "最低存款金额",
  lowerAmounts: ",较低的金额将不会被记入或退款。",
  sendOnly: "请只发送",
  toAddress: "到此地址，需获1个确认让存款到账。",
  yourWalletAddress: "您的钱包地址",
  enterAddress: "输入地址",
  quantity: "数量",
  balance: "余额",
  enterAmount: "输入金额",
  whole: "全部",
  minimumWithdrawalAmount: "最低提款金额为",
  transactionCosts: "交易费用：-    所用时间 -0-2小时。网络确认不包括在内，",
  additionalTime: "可能需要一些额外的时间。",
  sendingAddress: "发送地址",
  accountArrivalTime: "到账时间",
  accountStates: "状态",
  accountStatesTxt_1: "已驳回",
  accountStatesTxt0: "待审核",
  accountStatesTxt1: "已审核",
  accountStatesTxt2: "已到账",
  withdrawalAddress: "提款地址",
  serviceCharge: "手续费",
  pleaseEnterAddress: "请输入地址",
  extract: "提取",
  copySucceeded: "复制成功",
  enterCorrectBalance: "请输入正确余额",
  runningLow: "余额不足",
  withdrawalSucceeded: "提款成功",
  otherCoinName: '币种名称',
  otherAmount: '金额',
  otherType: '类型',
  otherStatus: '状态',
  otherStatus1: '无效',
  otherStatus2: '未完成',
  otherStatus3: '已完成',
}
