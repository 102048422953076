import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "task",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.close && $options.close(...args))
  }, [_createElementVNode("div", {
    class: "modal",
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [_createElementVNode("img", {
    src: "/common/close.png",
    alt: "",
    class: "close",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.close && $options.close(...args))
  }), _renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 512)), [[_vShow, $props.modelValue]]);
}