// 繁体中文

import transform from "./simplified2traditional.js";
const CN = require.context("./modules/zh-CN/", true, /\.(js)$/);
const HK = require.context("./modules/zh-HK/", true, /\.(js)$/);
const _ = require("lodash");

const language = {};
CN.keys().forEach((filename) => {
  language[filename.replace("./", "").replace(".js", "")] =
    CN(filename).default;
});
const traditional = transform(language);
HK.keys().forEach((filename) => {
  const name = filename.replace("./", "").replace(".js", "");
  const content = HK(filename).default;
  traditional[name] = _.merge(traditional[name], content);
});

export default traditional;
