// 日常任务的翻译
export default {
  manyRewards: "보상이 많아요",
  theDailyTask:  "일일 작업",
  getIt:  "나중에 오셔서 받아가셔도 됩니다!",
  moreRewards:  "베팅을 많이 할수록 보상도 커집니다",
  completeGame:  "QueenG에서 더 많은 즐거움을 얻으려면 일일 게임 작업을 선택하고 완료하세요!",
  distributed:  "일일 작업은 다양한 유형의 게임에 분산되어 있습니다. 완료하고 보상을 받으세요!",
  dailyTask:  "일일 작업 카운트다운:",
  bonusCompleting:  "모든 작업 완료에 대한 추가 보상:",
  getMany:  "받다",
  winDice1:  "30번 승리하려면 주사위에 베팅하세요",
  winDice2:  "주사위 무승부에 베팅하고 30번 승리하세요",
  leopard:  "주사위는 표범을 던진다",
  winTwice:  "같은 행운의 숫자를 두 번 연속으로 사용하세요 x2 주사위",
  roulette1:  "X2에서 룰렛을 30번 플레이하세요",
  roulette2:  "X3에서 룰렛을 20번 플레이하세요",
  roulette3:  "X5에서 룰렛을 15번 플레이하세요",
  roulette4:  "X50에서 룰렛을 20번 플레이하세요",
  guess1:  "가격이 30번 오르거나 내릴지 추측해 보세요",
  guess2:  "20번 맞히세요",
  guess3:  "20번 맞췄어요",
  guess4:  "5번 연속으로 넘어진 것을 맞히세요",
  collectCompletion:  "완료 후 받기를 클릭하세요",
  notCompleted:  "작업이 아직 완료되지 않았습니다",
  rewardsReceived:  "이미 보상을 받았습니다",
  SuccessfullyReceived:  "성공적으로 수신되었습니다"

}
