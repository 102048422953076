// 帮助-隐私政策的翻译
export default {
  title:  "Dasar Privasi",
  p1:  "Kami berhak untuk menukar Dasar Privasi ini pada bila-bila masa, dan anda akan dikemas kini dengan segera. Jika anda ingin memastikan anda mengetahui tentang perubahan terkini, kami mengesyorkan agar anda melawat halaman ini dengan kerap.",
  p2:  "Jika anda mempunyai soalan tambahan atau memerlukan maklumat lanjut tentang dasar privasi kami, sila hubungi kami.",
  p3:  "Dasar Privasi ini hanya terpakai untuk aktiviti dalam talian kami dan sah untuk pelawat ke tapak web kami berkenaan dengan maklumat yang mereka kongsi dan/atau kumpulkan dalam QueenG. Dasar Privasi ini tidak terpakai kepada sebarang maklumat yang dikumpul di luar talian atau melalui saluran selain daripada ini laman web." ,
  p4_h:  "setuju",
  p5:  "Kami meminta persetujuan anda untuk memproses maklumat anda untuk tujuan tertentu, dan anda mempunyai hak untuk menarik balik kebenaran anda pada bila-bila masa. Walau bagaimanapun, dengan menggunakan laman web kami, anda dengan ini bersetuju dengan dasar privasi kami dan bersetuju dengan syaratnya." ,
  p6_h:  "Apakah data pengguna yang kami kumpulkan",
  p7:  "Data peribadi' hendaklah difahami sebagai sebarang maklumat yang berkaitan dengan orang yang dikenal pasti atau yang boleh dikenal pasti; orang yang boleh dikenal pasti ialah orang yang boleh dikenal pasti, secara langsung atau tidak langsung, khususnya dengan merujuk kepada maklumat seperti nama, data lokasi, e-mel, pengecam dalam talian atau pengecam khusus kepada satu atau lebih faktor khusus untuk identiti fizikal, fisiologi, genetik, mental, ekonomi, budaya atau sosial orang semula jadi itu.",
  p8:  "Kami mungkin memproses data yang membolehkan kami menghubungi anda ('Data Hubungan'). Data Hubungan mungkin termasuk nama, alamat e-mel anda. Sumber Data Hubungan ialah borang pendaftaran kami, borang hubungan dan yang disediakan dalam Maklumat akaun anda.",
  p9:  "Kami mungkin memproses data akaun pengguna Laman Web anda ('Data Akaun'). Data Akaun mungkin termasuk pengecam akaun anda, nama, alamat e-mel, tarikh penciptaan dan pengubahsuaian akaun, tetapan Laman web. Sumber utama Data Akaun ialah anda ;Beberapa elemen data akaun mungkin dijana oleh tapak web kami.",
  p10:  "Kami mungkin memproses data teknikal dan kewangan, data penggunaan'. Ini bermakna apabila anda melawati tapak web kami dan/atau menggunakan perkhidmatan kami, kami mungkin mengumpul maklumat teknikal dan kewangan tentang anda, yang mungkin termasuk: alamat IP, tempat, tarikh pendaftaran, tarikh log masuk, ahli gabungan berkaitan, maklumat statistik berkaitan ahli gabungan (sumber trafik anda ke tapak web kami, data pada paparan dan klik anda pada bahan promosi kami), tarikh dan Tempoh pengecualian diri, tarikh dan tempoh pemadaman, bilangan dan masa gagal percubaan log masuk, baki akaun, bonus akaun, akaun dana wang sebenar, tarikh transaksi, nilai transaksi, pengecam transaksi, jumlah bonus, jumlah bonus, jumlah deposit, jumlah deposit, pengeluaran Jumlah pengeluaran, jumlah pengeluaran, jumlah yang perlu dikeluarkan, nombor pengeluaran yang perlu dibuat, jumlah pengeluaran yang salah, kiraan pengeluaran yang salah, nisbah deposit/pengeluaran, jumlah pendapatan,",
  p11:  "Kami mungkin memproses maklumat yang terkandung dalam atau berkaitan dengan mana-mana komunikasi yang anda hantar kepada kami atau yang kami hantar kepada anda ('data komunikasi'). Data Komunikasi mungkin termasuk kandungan komunikasi dan metadata yang berkaitan dengan komunikasi. Kami mungkin Menjana metadata berkaitan dengan komunikasi yang dibuat menggunakan borang hubungan.",
  p12_h:  "Mengapa kami mengumpul data anda",
  p13:  "QueenG hanya akan mengumpul maklumat peribadi anda dengan cara yang sah dan adil dan bukan dengan cara yang mengganggu untuk mengendalikan perniagaannya sebagai pembuat taruhan dalam talian berlesen untuk tujuan berikut:",
  p14:  "Untuk pengesahan Mengenal Pelanggan Anda (KYC).",
  p15:  "Jika anda ingin melanggan komunikasi pemasaran QueenG.",
  p16:  "Proses permohonan anda untuk menjadi ahli QueenG atau mana-mana subdomain yang dihoskan.",
  p17:  "Untuk menyediakan dan menambah baik perkhidmatan kepada anda sebagai ahli.",
  p18:  "Untuk mengenal pasti anda sebagai ahli dan mengesahkan identiti anda untuk tujuan keselamatan dan untuk mematuhi kewajipan undang-undang kami.",
  p19:  "Kekalkan akaun keahlian anda.",
  p20:  "Tingkatkan dan tingkatkan pengalaman anda dalam Laman atau pada peranti anda, atau sesuaikan atau bangunkan maklumat, perkhidmatan atau produk untuk memenuhi keperluan anda, yang mungkin termasuk penyelidikan pasaran dan menjalankan promosi.",
  p21:  "Buat data agregat tentang ahli melalui analisis demografi, lakukan analisis statistik pangkalan data untuk diberikan kepada pihak berkepentingan yang berpotensi dan sedia ada, dan membolehkan operasi perniagaan QueenG yang lebih cekap. Kami juga menggunakan data ini untuk menganalisis sama ada akaun pengguna Berbilang akaun wujud.",
  p22:  "Sebagai respons kepada soalan, komen atau permintaan anda.",
  p23:  "Mematuhi obligasi kontrak, undang-undang dan undang-undang QueenG.",
  p24:  "Ambil tindakan yang sewajarnya jika QueenG mempunyai sebab untuk mengesyaki bahawa aktiviti haram atau salah laku yang serius telah, sedang atau mungkin terlibat berkaitan dengan fungsi dan aktiviti kami.",
  p25:  "Menubuhkan, melaksanakan atau mempertahankan sebarang tuntutan undang-undang.",
  p26:  "Data peribadi anda tidak akan didedahkan kepada pihak ketiga melainkan dikehendaki oleh undang-undang. Data peribadi mungkin didedahkan kepada rakan kongsi perniagaan atau pembekal atau penyedia perkhidmatan QueenG kerana mereka mungkin bertanggungjawab ke atas bahagian tertentu dari keseluruhan fungsi atau operasi tapak web . Pekerja QueenG boleh mengakses data peribadi anda untuk menjalankan tugas mereka dan memberi anda bantuan dan perkhidmatan yang terbaik. Anda dengan ini bersetuju dengan pendedahan tersebut.",
  p27:  "QueenG menggunakan maklumat pengguna untuk tujuan pemasaran. Walau bagaimanapun, kami menghormati privasi pengguna kami. Jika pengguna tidak mahu menerima sebarang bahan promosi, mereka boleh memilih pilihan sedemikian apabila mendaftar atau berhenti melanggan pada bila-bila masa.",
  p28_h: "fail log",
  p29:  "QueenG mengikut prosedur standard menggunakan fail log. Fail ini merekodkan pelawat setiap kali mereka melawat tapak web. Maklumat yang dikumpul oleh fail log termasuk alamat Protokol Internet (IP), jenis pelayar, Pembekal Perkhidmatan Internet (ISP), cap tarikh dan masa , merujuk/keluar halaman, dan mungkin bilangan klik. Ini tidak dikaitkan dengan sebarang maklumat yang boleh dikenal pasti secara peribadi. Tujuan maklumat adalah untuk menganalisis arah aliran, mentadbir tapak, menjejaki pergerakan pengguna di tapak dan mengumpul maklumat demografi." ,
  p31_h:  "Penggunaan kuki kami",
  p32:  "Kami mungkin meletakkan dan mengakses Kuki tertentu pada komputer atau peranti anda. Kuki ialah sekeping maklumat dalam bentuk fail teks yang sangat kecil yang diletakkan pada pemacu keras pengguna Internet. Ia dihasilkan oleh pelayan halaman web, yang pada asasnya menjalankan komputer tapak web. Kuki mengandungi maklumat yang ditetapkan oleh pelayan dan tersedia kepada pelayan apabila pengguna melawat tapak.",
  p33:  "Dengan menggunakan tapak web kami, anda bersetuju dengan penggunaan dan penyimpanan kuki pada komputer atau peranti anda. Jika anda tidak mahu tapak web kami menggunakan kuki pada komputer atau peranti anda, anda boleh menyahaktifkan dan memadam kuki pada bila-bila masa melalui tetapan penyemak imbas anda .Untuk maklumat lanjut tentang kuki dan cara untuk melumpuhkan serta mengecilkannya, tetapi sila ambil perhatian bahawa jika anda menolak atau memadamkan kuki, sesetengah bahagian tapak kami mungkin tidak berfungsi dengan baik dan kami mungkin tidak dapat menyediakan perkhidmatan tertentu kepada anda.",
  p34:  "Kuki boleh sama ada kuki 'berterusan' atau kuki 'sesi': kuki berterusan akan disimpan oleh penyemak imbas web dan akan kekal sah sehingga tarikh luput yang ditetapkan, melainkan dipadamkan oleh pengguna sebelum tarikh luput; sebaliknya, Kuki sesi tamat tempoh pada akhir sesi pengguna, apabila penyemak imbas web ditutup.",
  p35:  "Kami menggunakan kuki berikut:",
  p36:  "Kuki 'Perlu' menjadikan tapak web boleh digunakan dengan mendayakan fungsi asas seperti navigasi halaman dan akses ke kawasan selamat tapak web. Tanpa kuki ini, tapak web tidak akan berfungsi dengan baik.",
  p37:  "Kuki pilihan membolehkan tapak web mengingati maklumat, seperti wilayah yang anda berada, yang mengubah cara tapak web berkelakuan atau kelihatan.",
  p38:  "Kuki statistik membantu tapak web memahami cara pelawat berinteraksi dengan tapak web dengan mengumpul dan melaporkan maklumat secara awanama.",
  p39:  "Kuki 'Keselamatan' digunakan sebagai elemen langkah keselamatan yang digunakan untuk melindungi akaun pengguna, termasuk menghalang penggunaan penipuan bukti kelayakan log masuk dan melindungi tapak web dan perkhidmatan kami.",
  p40:  "Kuki Pengesahan dan Kehadiran digunakan untuk mengenal pasti anda apabila anda melawat dan menyemak imbas laman web kami dan membantu kami menentukan sama ada anda log masuk ke tapak web kami.",
  p41:  "Kuki 'Pemasaran' digunakan untuk menjejak pelawat merentas tapak web. Tujuannya adalah untuk memaparkan pengiklanan yang relevan dan menarik kepada pengguna individu dan oleh itu lebih berharga kepada penerbit.",
  p42:  "Kami menggunakan kedua-dua kuki pihak pertama dan pihak ketiga. Kuki pihak pertama diletakkan terus oleh kami dan hanya digunakan oleh kami. Kami menggunakan kuki untuk memudahkan dan menambah baik pengalaman laman web anda dan untuk menyediakan dan menambah baik produk dan perkhidmatan kami . ",
  p43:  "Dengan menggunakan tapak web kami, anda juga mungkin menerima kuki pihak ketiga tertentu pada komputer atau peranti anda. Kuki pihak ketiga ialah kuki yang diletakkan oleh tapak web, perkhidmatan dan/atau pihak selain kami. Selain itu, kami menggunakan Perkhidmatan analitik, yang juga gunakan kuki. Analitis tapak web merujuk kepada satu set alat yang digunakan untuk mengumpul dan menganalisis statistik penggunaan, membolehkan kami memahami dengan lebih baik cara orang menggunakan tapak web.",
  p44:  "Tapak web ini menggunakan Google Analytics, perkhidmatan analitik web yang disediakan oleh Google, Inc. ('Google'). Google Analytics menggunakan kuki untuk membantu tapak web menganalisis cara pengguna menggunakan tapak web. Maklumat yang dijana oleh kuki tentang penggunaan tapak web kami oleh anda (termasuk alamat IP anda) akan dihantar kepada Google dan disimpan pada pelayan di Amerika Syarikat. Google akan menggunakan maklumat ini untuk menganalisis penggunaan tapak web anda, menyusun laporan mengenai aktiviti tapak web untuk kami dan melaksanakan perkhidmatan selanjutnya yang berkaitan dengan penggunaan tapak web dan Internet penggunaan. Google juga boleh memindahkan maklumat ini kepada pihak ketiga jika dikehendaki berbuat demikian oleh undang-undang, atau di mana pihak ketiga tersebut memproses data bagi pihak Google.",
  p45_h:  "Melindungi dan melindungi data",
  p46:  "QueenG komited untuk melindungi data anda dan merahsiakannya. QueenG telah melakukan segala yang boleh untuk mencegah kecurian data, akses dan pendedahan tanpa kebenaran dengan melaksanakan teknologi dan perisian terkini, yang membantu kami melindungi semua maklumat yang kami kumpulkan dalam talian .",
  p47_h:  "Dasar Privasi Pihak Ketiga",
  p48:  "Dasar Privasi kami tidak terpakai kepada pengiklan atau tapak web lain. Oleh itu, kami mengesyorkan agar anda menyemak dasar privasi masing-masing pelayan iklan pihak ketiga ini untuk mendapatkan butiran lanjut. Ini mungkin termasuk amalan mereka mengenai cara memilih keluar daripada pilihan tertentu dan Arahan. Anda boleh memilih untuk melumpuhkan kuki melalui pilihan penyemak imbas individu anda. Untuk mengetahui maklumat lebih terperinci tentang pengurusan kuki dengan pelayar web tertentu, ia boleh didapati di tapak web pelayar masing-masing.",
  p49_h:  "penghantaran data",
  p50:  "Kami mengekalkan pelayan di seluruh dunia dan maklumat anda mungkin diproses pada pelayan di luar negara tempat anda tinggal. Undang-undang perlindungan data berbeza-beza mengikut negara dan sesetengahnya memberikan lebih perlindungan daripada yang lain. Tidak kira di mana maklumat anda berada Di mana-mana sahaja kami memproses, kami memohon perlindungan yang sama yang diterangkan dalam dasar ini. Kami juga mematuhi rangka kerja undang-undang tertentu yang berkaitan dengan pemindahan data. Suruhanjaya Eropah telah menentukan bahawa negara tertentu di luar Kawasan Ekonomi Eropah (EEA) menyediakan perlindungan yang mencukupi untuk data peribadi. Untuk pemindahan data daripada EEA kepada negara lain, seperti Amerika Syarikat, kami mematuhi rangka kerja undang-undang yang menetapkan tahap perlindungan yang setara dengan undang-undang EU. Apabila kami menerima aduan bertulis rasmi, kami akan menghubungi pengadu sebagai maklum balas.",
  p51_h:  "Pengekalan dan pemadaman data peribadi",
  p52:  "Dasar dan prosedur kami direka bentuk untuk membantu memastikan kami mematuhi kewajipan undang-undang kami berhubung dengan pengekalan dan pemadaman data peribadi. Data peribadi yang kami proses untuk sebarang tujuan tidak boleh disimpan lebih lama daripada yang diperlukan untuk tujuan itu atau tujuan tersebut. . Walau apa pun peruntukan lain , kami mungkin mengekalkan data peribadi anda jika ini perlu untuk pematuhan kewajipan undang-undang yang kami tertakluk kepadanya, atau untuk melindungi kepentingan penting anda atau kepentingan penting orang lain.",
  p53_h:  "Hak Anda",
  p54:  "Jika anda seorang pemastautin Eropah, anda mempunyai hak untuk mengakses data peribadi yang kami pegang tentang anda dan meminta supaya data peribadi anda diperbetulkan, dikemas kini atau dipadamkan.",
  p55:  "Khususnya, anda mempunyai hak untuk melakukan perkara berikut:",
  p56:  "Tarik balik persetujuan anda pada bila-bila masa. Jika sebelum ini anda telah bersetuju dengan pemprosesan data peribadi anda, anda mempunyai hak untuk menarik balik kebenaran anda.",
  p57:  "Bantah pemprosesan data anda. Anda berhak untuk membantah pemprosesan data anda jika pemprosesan adalah berdasarkan undang-undang tanpa persetujuan.",
  p58:  "Akses kepada data anda. Anda mempunyai hak untuk mengetahui sama ada data anda sedang diproses, untuk mendapatkan pendedahan tentang aspek tertentu pemprosesan dan untuk mendapatkan salinan data yang sedang diproses.",
  p59:  "Sahkan dan dapatkan pembetulan. Anda mempunyai hak untuk mengesahkan ketepatan data anda dan meminta ia dikemas kini atau diperbetulkan.",
  p60:  "Sekatan pemprosesan data anda. Dalam keadaan tertentu, anda mempunyai hak untuk menyekat pemprosesan data anda. Dalam kes ini, kami tidak akan memproses data anda untuk sebarang tujuan selain storan.",
  p61:  "Padam atau sebaliknya padamkan data peribadi anda. Dalam keadaan tertentu, anda mempunyai hak untuk meminta kami memadamkan data anda.",
  p62:  "Hak untuk mudah alih data. Anda mempunyai hak untuk meminta kami memindahkan data yang telah kami kumpulkan ke organisasi lain, atau terus kepada anda, di bawah syarat tertentu.",
  p63:  "Failkan aduan. Anda mempunyai hak untuk membuat tuntutan kepada pihak berkuasa perlindungan data yang kompeten. Selain itu, jika anda seorang pemastautin Eropah, kami maklum bahawa kami sedang memproses data peribadi anda untuk melaksanakan kontrak yang mungkin kami ada dengan anda , atau sebaliknya untuk meneruskan tujuan kami di atas Kepentingan Perniagaan Sah Tersenarai. Di bawah CCPA, Privasi (Jangan Jual Maklumat Peribadi Saya), dan hak lain, pengguna California mempunyai hak untuk:",
  p64:  "Memerlukan perniagaan yang mengumpul data peribadi tentang pengguna untuk mendedahkan kategori dan bahagian tertentu data peribadi yang telah dikumpulkan oleh perniagaan tentang pengguna.",
  p65:  "Memerlukan perniagaan untuk memadamkan sebarang data peribadi yang dikumpul oleh perniagaan tentang pengguna.",
  p66:  "Diminta oleh perniagaan yang menjual data peribadi pengguna, bukan penjualan data peribadi pengguna. Jika anda ingin melaksanakan mana-mana hak ini, sila hubungi kami di sokongan{'@'}QueenG.io",
  p67_h:  "Hadkan pengumpulan data peribadi anda",
  p68:  "Pada satu ketika, anda mungkin ingin menyekat penggunaan dan pengumpulan data peribadi anda. Anda boleh melakukan ini dengan melakukan perkara berikut: Apabila anda mengisi borang di tapak web, jika anda tidak mahu maklumat peribadi anda didedahkan, sila Pastikan anda menyemak sama ada terdapat kotak yang anda boleh nyahtanda.",
  p69:  "QueenG tidak akan menyewa, menjual atau mengedarkan maklumat peribadi anda kepada mana-mana pihak ketiga melainkan kami mendapat kebenaran anda. Kami boleh berbuat demikian jika undang-undang memaksa kami berbuat demikian. Jika anda bersetuju dengan Dasar Privasi ini, kami akan menyediakan anda dengan Use your maklumat peribadi semasa menghantar bahan promosi.",
  p70_h:  "Perubahan pada dasar privasi kami",
  p71:  "Kami mungkin menukar Dasar Privasi ini dari semasa ke semasa (contohnya, jika undang-undang berubah). Sebarang perubahan akan segera disiarkan di laman web kami dan anda akan dianggap telah menerima syarat Dasar Privasi pada penggunaan pertama anda tapak web berikutan perubahan. Kami mengesyorkan anda menyemak halaman ini dengan kerap untuk kekal terkini. Jika anda tidak bersetuju dengan sebarang perubahan, kami mengesyorkan agar anda menghubungi sokongan pelanggan untuk penjelasan atau menutup akaun anda."
}
