// 帮助-Ido质押的翻译
export default {
  title:  "Lời hứa",
  p1:  "Người dùng nắm giữ và cam kết QGT LP sẽ cung cấp tính thanh khoản cho nền tảng. Khi trở thành người hỗ trợ nền tảng, họ cũng có thể được hưởng các quyền của đối tác nền tảng. Cứ sau 24 giờ, 20% tổng lợi nhuận của nhóm cá cược nền tảng sẽ được phân phối cho mỗi người dùng đặt cược . ",
  p2:  "Số tiền này sẽ được phân phối bằng loại tiền kỹ thuật số kiếm được từ nhóm cá cược của nền tảng, tức là loại tiền chip cá cược.",
  title1:  "1) Tiền thưởng cho người ủng hộ hoạt động như thế nào?",
  p3:  "Khoản cam kết được thanh toán 24 giờ một lần và 20% tổng lợi nhuận của nền tảng từ ngày hôm trước sẽ được phân phối cho tất cả những người ủng hộ.",
  p4:  "Hơn nữa, chỉ cần đặt cọc một LP lỏng, bạn có thể kiếm được khoản thu nhập khổng lồ trên tất cả các loại tiền điện tử của nền tảng: USDT, ETH, BTC, BNB, TRX, v.v.",
  p5:  "Trên trang hỗ trợ, bạn có thể xem thu nhập ước tính hàng ngày và thu nhập đặt cược ước tính.",
  img1:  "",
  p6:  "Nhập số vào các trường và xem lợi ích dự kiến ​​trong các tình huống khác nhau.",
  p7:  "Bạn càng đặt cược nhiều thanh khoản, bạn càng kiếm được nhiều tiền.",
  title2:  "2) Khai thác thêm QGT để tăng thu nhập",
  p8:  "20% lợi nhuận hàng ngày của nền tảng sẽ được chia cho tất cả những người ủng hộ. Bạn có thể xem chi tiết cổ tức trên trang hỗ trợ và trung tâm cá nhân.",
  p9:  "Tuy nhiên, càng có nhiều người ủng hộ QueenG, bạn càng nhận được ít cổ tức. Hãy tăng thu nhập của bạn bằng cách đặt cược nhiều hơn để khai thác mã thông báo QGT và đặt cược thanh khoản trên nền tảng! Mở rộng tính thanh khoản của QGT bằng tất cả các loại tiền tệ có sẵn mỗi ngày, cam kết nhận được phần thưởng lớn!"
}
