// 三个游戏及我的投注的翻译
export default {
  pledge: "보증금",
  yawan:  "오쉐",
  bettingAmount:  "베팅 금액",
  highestWin:  "최고의 승리",
  castOnce:  "1번 투표하세요",
  castFive:  "5번 투표하세요",
  excavatedOnce:  "한 번의 투자로 채굴이 이뤄질 것으로 예상된다",
  bigWinner:  "큰 승자",
  mostFortunate:  "가장 운이 좋은",
  rulesGame:  "게임 규칙",
  player:  "플레이어",
  betting:  "내기",
  multiple:  "다수의",
  bonus:  "보너스",
  placeBet:  "내기를 걸다",
  result:  "결과",
  earnings:  "소득",
  bettingTime:  "결제 시간",
  bettingDirection:  "베팅 방향",
  aboutToBegin:  "곧 시작됩니다",
  excavatedTurningOnce:  "한 번 옮기면 파낼 수 있을 것으로 추정된다",
  drawTheLottery:  "복권 추첨!",
  latestPrice:  "최신 가격",
  realTime:  "실시간",
  oneMinute:  "1 분",
  fifteenMinutes:  "15 분",
  guessTheRise:  "상승을 추측해 보세요",
  guessTheFall:  "가을 추측",
  bettingPrice:  "베팅 가격",
  settlementInProgress:  "정산 진행 중",
  game:  "게임",
  return:  "반품",
  theRise:  "증가",
  theFall:  "떨어지다",
  openNote:  "오픈 베팅",
  selected:  "선택",
  pleaseLoginFirst:  "먼저 로그인을 해주세요",
  enterCorrectAmount:  "정확한 배팅금액을 입력해주세요",
  creditLow:  "잔액 불충분",
  maximumWorthTokens:  "최대 베팅은 100,000 USDT 가치 토큰입니다",
  congratulationsWinning:  "승리를 축하합니다",
  winningLottery:  "승리를 축하합니다",
  didnnotWinPrize:  "이기지 못해서 미안해요"

}
