// 帮助-游戏的翻译
export default {
  title: "游戏",
  p1: "QueenG集结了赌场场景中最受欢迎的游戏，加以自己内部的开发，可使用 TOP 加密货币直接进行游戏下注。目前，用户可以享受独特设计的游戏有 摇骰子、猜涨跌、大转盘，后续我们还将开展更多的热门赌场游戏！",
  p2: "每个游戏对应的赔率不同，但最大-最小下注额固定为：$1000000-$1",
  title1: "1）摇骰子",
  p3: "投骰子是一种古老的中国骰子游戏，玩家押注三个在笼子中转动的骰子的旋转结果。",
  p4: "游戏开始时 ，玩家选择下注区的大小方向或具体点数。下注完成后，骰子将开始旋转, 旋转结束后平台展示结果并发放奖励。",
  p5: "不同于传统摇骰子，GAMES摇骰子游戏完全通过智能合约自动化执行下注、随机摇骰、中奖赔付以及资金/奖励处理等过程，没有任何第三方参与。这比现有的传统赌场下注要高效和公平的多。",
  subtitle1: "1.1）押单",
  p6: "骰子下方有一排被分割为6小格的下注区域，在这一排，您是押注骰子旋转之后将显示哪一个数字. 如果三个骰子中的一个显示您押的数字, 那么您的赢钱为1 比 2. 如果三个骰子中的两个显示您押的数字, 那么您的赢钱为1比11. 如果三个骰子中的三个显示您押的数字, 那么您的赢钱为1 比 151。",
  subtitle2: "1.2）押和",
  p7: "在押和区域，您下注的是三个骰子旋转后的数字总和。  “大”是指数字总和为11 到17；“小” 是指数字总和为4到10。如果旋转后的数字平面三个点数一致，那么属于“豹子”，大小皆输。大、小的赔率为1赔2，豹子赔率为1赔25。",
  title2: "2）猜涨跌",
  p8: "根据三个主流币种的行情，用户可选择看涨或者看跌，预测15s后选中交易对的涨跌情况，下注一个方向后，进入15s行情跳动倒计时，15s后对比价格涨跌，猜中立刻结算返奖。",
  p9: "关于下注：",
  p10: "分析查看行情后，设置下注额，点击猜涨/猜跌 按钮，若竞猜结果正确，则获得相应2倍的奖金；若竞猜结果错误，仅扣除押注的金额；若涨跌额为零，则为平局，退还下注金；",
  title3: "3）大转盘",
  p11: "大转盘是一种赌场常见的博彩游戏。转盘为54个区域可被选中，四个颜色分别占比不同；紫色为26块，赔率为2倍；红色为17块，赔率为3倍;绿色为10块，赔率为5倍；黄色为1块，赔率为50倍;",
  p12: "设置下注额，选中下注颜色后，轮盘转动，轮盘停止转动后选中区域的颜色为开奖颜色。开奖后立刻完成结算，发放奖金；"
}
