import zh from "./zh-HK";
import en from "./en-US";
import th from "./th";
import kr from "./kr";
import vn from "./vn";
import pt from "./pt";
import ma from "./ma";
import { createI18n } from "vue-i18n";
import store from "../store.js";

let navLang = navigator.language.substring(0, 2);

let local;
if (!["zh", "en", "th", "kr", "vn", "pt", "ma"].includes(navLang)) {
  local = "en";
} else {
  local = navLang;
}
store.commit('changeLang', local)
export default createI18n({
  locale: local,
  fallbackLocale: "zh",
  messages: {
    zh,
    en,
    th,
    kr,
    vn,
    pt,
    ma,
  },
});

// let userCountry = new Intl.DateTimeFormat().resolvedOptions().locale;
// userCountry = userCountry.substring(userCountry.indexOf('-') + 1).toUpperCase();
// if (userCountry == 'CN' || userCountry == 'HK' || userCountry == 'MO' || userCountry == 'TW') {
//   navLang = 'zh';
// } else if (userCountry == 'TH') {
//   navLang = 'th';
// } else if (userCountry == 'KR') {
//   navLang = 'kr';
// } else if (userCountry == 'VN') {
//   navLang = 'vn';
// } else if (userCountry == 'PT' || userCountry == 'BR') {
//   navLang = 'pt';
// } else if (userCountry == 'MY' || userCountry == 'BN' || userCountry == 'SG') {
//   navLang = 'ma';
// }