// 首页的翻译
export default {
  qGTHasHeenAwarded: "QGT telah dianugerahkan",
  homePage:  "muka depan",
  lasVegas:  "Kasino",
  throwTheDice:  "lempar dadu",
  roulette:  "roulette",
  guessTheRiseAndFall:  "teka naik atau turun",
  slotMachine:  "Mesin slot",
  aloneGame:  "Permainan mini pemain tunggal",
  arcade:  "arked",
  pledge:  "Ikrar",
  commissionBack:  "rebat",
  noviceMission:  "Tugas baru",
  qGTTokens:  "Token QGT",
  helpCenter:  "Pusat Bantuan",
  games:  'permainan',
  user:  'pengguna',
  payout:  'Buat pertaruhan',
  gamesTypeAll:  'semua',
  gamesType1:  'Kasino',
  gamesType2:  'Mesin slot',
  gamesType3:  'Permainan mini bersendirian',
  gamesType4:  'arcade',
  gamesType5:  'Acara sukan',
  gamesType1msg:  'Pelanggan boleh memilih untuk meletakkan pertaruhan pada mana-mana pintu lain mengikut kehendak mereka.',
  gamesType2msg:  'Selepas memasukkan syiling, tarik tuil dan ia akan mula berputar.',
  gamesType3msg:  'Selepas memasukkan syiling, tarik tuil dan ia akan mula berputar.',
  gamesType4msg:  'Selepas memasukkan syiling, tarik tuil dan ia akan mula berputar.',
  gamesType5msg:  'Acara sukan akan datang tidak lama lagi.',
  gamesRecordText:  'Rekod Permainan',
  theOriginalGame:  "permainan asal",
  miningMode:  "iaitu mod perlombongan",
  miningRewards:  "Lebih banyak anda bertaruh, lebih banyak ganjaran yang anda akan perolehi, dan ganjaran perlombongan akan diselesaikan dalam masa nyata",
  qGTCanBeDugToday:  "Perlombongan akan datang",
  highestEarnedMultiplier:  "Acara sukan akan datang",
  gamesPlayed:  "Bermain permainan",
  gamesPlayedNew:  "Permainan yang sering saya mainkan baru-baru ini",
  highestWin:  "Kemenangan Tertinggi",
  participant:  "Peserta",
  canBeDugToday:  "Anda boleh menggali hari ini",
  highReturn:  "Pulangan tinggi",
  highYield:  "Pendapatan tinggi",
  signIn:  "Log masuk",
  registration:  "daftar",
  purse:  "dompet",
  search:  "cari",
  hideOneBalance:  "Sembunyikan baki 0",
  displayUSD:  "Tunjukkan dolar",
  personalCenter:  "Pusat Peribadi",
  invite:  "Salin pautan jemputan",
  inviteCopy:  "Pautan jemputan berjaya disalin",
  setUp:  "sediakan",
  Language:  "tetapan bahasa",
  LanguageZh:  "Cina Tradisional",
  LanguageEn:  "Bahasa Inggeris",
  logOutAccount:  "Keluar akaun"
};
