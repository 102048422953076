// 邀请的翻译
export default {
  instantly1:  "지금 건담을 구매하세요",
  instantly2:  "삼%",
  instantly3:  "높은 금액",
  instantly4:  "이익 리베이트",
  invitationCode:  "초대 코드",
  copy:  "복사",
  share:  "공유하다",
  invited:  "초대됨",
  commissionReturned:  "커미션이 반환되었습니다",
  commissionAccumulated:  "리베이트가 누적되었습니다",
  date:  "날짜",
  commissionRebate:  "순이익 리베이트 리베이트",
  bettingGoldRebate:  "베팅에 대한 리베이트",
  miningCommission:  "채굴 리베이트",
  total:  "총",
  operation:  "작동하다",
  viewDetails:  "세부 정보보기",
  goodFriends:  "친구",
  entryTime:  "도착 시간",
  howRebate:  "친구로부터 커미션을 받는 방법",
  inviteFriends:  "친구를 초대",
  shareLink:  "링크를 친구와 공유하면 친구들이 해당 링크를 통해 플랫폼에 로그인하게 됩니다.",
  friendsLogIn:  "친구 로그인",
  friendsToPlatform:  "친구가 플랫폼에 로그인한 후 충전하고 게임에 참여하세요",
  giveOutRewards:  "보상 분배",
  friendsPlayGames:  "친구들은 플랫폼에서 게임을 하고 사람들을 초대하여 수익 리베이트를 얻습니다",
  tip1:  "1. 친구를 플랫폼에 초대하면 수수료를 최대 3%까지 환급받을 수 있습니다.",
  step1:  "1단계: 친구 초대 페이지로 이동하여 포스터나 초대 링크를 친구들과 공유하세요.",
  step2:  "2단계: 친구는 초대 링크를 통해 플랫폼에 로그인하고, 친구는 게임에 참여하여 커미션 보상을 받을 수 있습니다.",
  step3:  "3단계: 플랫폼에서 친구가 게임을 하면서 발생한 수입, 채굴 수입, 친구의 베팅에서 승리한 플랫폼이 모두 일정 비율로 귀하에게 반환됩니다.",
  tip2:  "2. 리베이트 보상은 실시간으로 귀하에게 정산되어 귀하의 계정 지갑으로 분배됩니다.",
  tip3:  "3. 초대 횟수에는 제한이 없습니다. 더 많은 친구를 초대할수록 더 많은 리베이트를 받게 됩니다. 각 친구는 리베이트를 영구적으로 누릴 수 있습니다. 계속해서 더 많은 친구를 초대하세요!",
  scanningCode1:  "수신하려면 코드를 스캔하세요",
  scanningCode2:  "건담",
  scanningCode3:  "3% WCC 채굴 보상",
  longPress:  "QR 코드를 식별하려면 길게 누르세요",
  enteringThePage:  "페이지 진입 후 회원가입을 누르시면 바로 게임에 참여하실 수 있습니다",
  savePicture:  "사진을 저장하다",
  rewardRules:  "보상 규칙",
  copySucceeded:  "복사 성공",
  hiQGT:  "안녕하세요, FUN3.0 게임 플랫폼에 오신 것을 환영합니다",
  airdropBenefits:  "",
  clickGetIt:  "등록하고 친구를 초대해 게임을 플레이하면 높은 수수료와 채굴 보상을 누릴 수 있습니다.",
  receive:  "등록 시작"

}
