// 帮助-Qa的翻译
export default {
  title:  "problema comum",
  title1:  "1) Meu saque ainda não chegou?",
  p1_1:  "Por que seu saque ainda não chegou na sua carteira.",
  p1_2:  "Existem vários motivos pelos quais o seu saque não chegou à sua carteira, sendo o motivo mais comum uma transação não confirmada.",
  p1_3:  "Toda transação deve ser confirmada antes de ser creditada no endereço de destino. O número de confirmações necessárias depende de carteira para carteira. Quando se trata de Bitcoin, algumas carteiras exigem apenas 1 confirmação, enquanto outras exigem 3+ confirmações. Essas confirmações levarão um certo tempo quantidade de tempo.",
  p1_4:  "Se o valor do saque for grande, eles podem ficar presos e exigir processamento manual por nossos administradores.",
  p1_5:  "Observação: mesmo as retiradas manuais são processadas quase instantaneamente, você não precisa se preocupar, apenas seja paciente.",
  title2:  "2) O que são apostas e mineração",
  p2_1:  "O valor total do QGT é de 8 bilhões, dos quais 50% são destinados a recompensas de apostas, ou seja, jogar é mineração, e essa parte é reduzida a cada ano. dia, e quanto mais apostam, Quanto mais você aproveita, mais você ganha.",
  p2_2:  "A vantagem de jogar e minerar é que mesmo que os usuários incorram em perdas no jogo, eles ainda podem obter lucros maiores vendendo QGT. Ao mesmo tempo, o QGT também está online em bolsas externas ao mesmo tempo, e os usuários podem transferir e circular livremente.",
  title3:  "3) Quando serão pagas comissões e dividendos?",
  p3_1:  "As comissões de bônus e dividendos de apoiadores para descontos de convite são liquidadas uma vez por dia, e as recompensas do dia anterior serão distribuídas após 0:00 UTC do dia seguinte;",
  p3_2:  "A emissão de recompensas de mineração é a mesma;",
  title4:  "4) Posso depositar moedas digitais via rede BEP2 ou Tron?",
  p4_1:  "Você pode verificar as redes e moedas suportadas através da área de recarga da carteira. Se não encontrar, a recarga ainda não é suportada;",
  title5:  "5) Sobre taxas de manuseio e taxas de retirada",
  p5_1:  "Cada vez que você ganhar um bônus apostando, a plataforma deduzirá uma taxa de aposta de 0,3%, mas não será usada quando você perder o jogo;",
  p5_2:  "Para cada saque, a plataforma cobrará uma taxa de saque correspondente com base na tarifa atual da rede;"
}
