// 个人中心的翻译
export default {
  myGame:  "trò chơi của tôi",
  totalWin:  "Tổng số tiền thắng",
  totalBets:  "Tổng số cược đã đặt",
  betsToday:  "Số lần đặt cược hôm nay",
  betsWeek:  "Số lần đặt cược trong tuần này",
  myBonus:  "Cổ tức của tôi",
  pledgeDividends:  "Cam kết cổ tức",
  providePOOL:  "Cung cấp tính thanh khoản của POOL, trở thành đối tác và tận hưởng cổ tức từ mỗi nhóm giải thưởng",
  dividendYesterday:  "Cổ tức của ngày hôm qua",
  accumulatedDividends:  "Cổ tức tích lũy",
  myMining:  "Khai thác của tôi",
  gameMining:  "Khai thác trò chơi",
  participate:  "Chơi trò chơi và tham gia khai thác hàng ngày và có quyền biểu quyết",
  miningYesterday:  "Khai thác của ngày hôm qua",
  cumulativeExcavation:  "Đào tích lũy",
  miningYesterdayChu:  "Đã đào ra ngày hôm qua",
  nearly:  "Khai thác trong 7 ngày qua",
  totalMining:  "Khai thác tổng thể",
  settlementDate:  "Ngày thanh toán",
  gameAmount:  "Số tiền trò chơi hôm nay",
  numberGames:  "Số lượng trò chơi",
  miningShare:  "Chia sẻ khai thác",
  miningIncome:  "Lợi nhuận khai thác",
  details:  "chi tiết xx",
  return:  "trở lại",
  viewDetails:  "Xem chi tiết",
  yesterdayEarnings:  "Thu nhập của ngày hôm qua",
  earningsRecentDays:  "Thu nhập trong 7 ngày qua",
  totalIncome:  "Tổng doanh thu",
  settlementLP:  "LP thanh toán",
  dividends:  "cổ tức",
  operation:  "vận hành",

}
