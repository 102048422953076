// 帮助-Qgt代币的翻译
export default {
  title:"QGT代币概述",
  title1:"1）挖矿",
  p1: "挖矿是通过下注每日挖出QGT的一种货币挖矿系统。QGT是 QueenG 权益分享和健康游戏环境的激励代币。QGT 代币可以通过玩游戏和博彩来挖出。您下的赌注越多——您挖出的 QGT 就越多。开采更多 QGT 意味着获得稳定的每日收益。",
  p2: "⚠️ 为了减少挖矿的负担并确保其正确的过程，挖矿 QGT 的最低赌注为 1 美元（任何货币）",
  title2: "2）交易",
  p3_1: "平台会陆续在各个顶级上线 QGT的交易和资金池，如：uniswap、justswap、ApeSwap",
  p3_2: "而平台内部也将直接支持QGT的兑换，届时内部交易是购买 QGT 最快、最简单的方式。",
  p4: "成为幸运的 QGT 所有者，或将您的 QGT 代币兑换成其他加密货币，都是不错的选择。",
  title3: "3）QGT用法",
  subtitle1: "3.1）质押",
  p5: "通过质押 QGT流动性（在支持者模块），用户可以享有分红，获得部分平台利润。",
  p6: "每 24 小时，质押池将释放平台总利润的 20% 用于分红，即平台每日赚取的各币种收益，都将以分红的形式发放给QGT流动性提供者。您质押的  QGT 流动性越多 – 您获得的 分红利润就越多！从平台的所有盈利中获利！",
  subtitle2: "3.2）交易",
  p7:'在 TOP 交易所买卖QGT代币，也可持有QGT代币投资获益。',
  subtitle3: "3.3）赌博",
  p8: "QGT  代币可用于赌城内所有游戏，其下注作用与其他货币相同；游戏的最小赌注 - 1 QGT。",
  title4: "4）QGT 的销毁",
  p9: "QueenG为支持货币经济和生态系统的健康发展，每月进行QGT代币销毁。有计划的销毁增加了 QGT 原生代币的价值，保持了持有者和开发者之间的平衡，以支持代币分配模型和代币持有者的 Staking 支出的不断增长。代币每月销毁一次。"
}
