// 帮助-游戏的翻译
export default {
  title:  "permainan",
  p1:  "QueenG telah mengumpulkan permainan paling popular dalam senario kasino dan membangunkannya secara dalaman. Anda boleh menggunakan mata wang kripto TOP untuk meletakkan pertaruhan secara langsung pada permainan tersebut. Pada masa ini, pengguna boleh menikmati permainan yang direka unik seperti bergolek dadu, meneka naik dan turun , dan roda besar. Kami juga akan melancarkan lebih banyak permainan kasino popular pada masa hadapan!",
  p2:  "Peluang yang sepadan dengan setiap permainan adalah berbeza, tetapi jumlah pertaruhan maksimum-minimum ditetapkan pada: $1000000-$1",
  title1:  "1) Melempar dadu",
  p3:  "Crap ialah permainan dadu Cina purba di mana pemain bertaruh pada hasil putaran tiga dadu yang dipintal dalam sangkar.",
  p4:  "Apabila permainan bermula, pemain memilih arah saiz kawasan pertaruhan atau bilangan mata tertentu. Selepas pertaruhan selesai, dadu akan mula berputar. Selepas penggiliran selesai, platform akan memaparkan keputusan dan keluaran pahala.",
  p5:  "Tidak seperti dadu tradisional, permainan dadu GAMES mengautomasikan sepenuhnya proses pertaruhan, dadu rawak, pampasan kemenangan dan pemprosesan dana/ganjaran melalui kontrak pintar tanpa sebarang penyertaan pihak ketiga. Ini lebih pantas daripada pertaruhan kasino tradisional sedia ada. Jauh lebih cekap dan adil .",
  subtitle1:  "1.1) Buat pesanan",
  p6:  "Terdapat deretan kawasan pertaruhan di bawah dadu yang dibahagikan kepada 6 sel kecil. Dalam baris ini, anda bertaruh pada nombor mana yang akan dipaparkan selepas putaran dadu. Jika salah satu daripada tiga dadu menunjukkan nombor yang anda pertaruhkan, maka anda Kemenangan anda ialah 1 hingga 2. Jika dua daripada tiga dadu menunjukkan nombor yang anda pertaruhkan, kemenangan anda ialah 1 hingga 11. Jika tiga daripada tiga dadu menunjukkan nombor yang anda pertaruhkan, kemenangan anda ialah 1 hingga 151.",
  subtitle2:  "1.2) Pertaruhan untuk seri",
  p7:  "Di kawasan Seri, pertaruhan anda ialah jumlah nombor selepas tiga dadu diputarkan. 'Besar' bermaksud jumlah nombor ialah 11 hingga 17; 'Kecil' bermaksud jumlah nombor ialah 4 hingga 10. Jika nombor selepas putaran ialah tiga Jika mata adalah sama, maka ia adalah milik 'Leopard', dan kedua-dua besar dan kecil kalah. Kemungkinan besar dan kecil ialah 2 berbanding 1, dan kemungkinan harimau bintang ialah 25 berbanding 1. ",
  title2:  "2) Teka naik atau turun",
  p8:  "Berdasarkan keadaan pasaran bagi tiga mata wang arus perdana, pengguna boleh memilih untuk menaik atau menurun, dan meramalkan kenaikan dan kejatuhan pasangan dagangan yang dipilih dalam masa 15 saat. Selepas meletakkan pertaruhan dalam satu arah, lonjakan pasaran 15 saat countdown akan dimasukkan. Selepas 15 saat, kenaikan dan penurunan harga akan dibandingkan. Jika tekaan betul, harga akan naik atau turun serta-merta. Rebat selepas penyelesaian.",
  p9:  "Mengenai meletakkan pertaruhan:",
  p10:  "Selepas menganalisis dan menyemak pasaran, tetapkan amaun pertaruhan dan klik butang Teka Naik/Teka Turun. Jika keputusan tekaan betul, anda akan mendapat 2 kali ganda bonus yang sepadan; jika keputusan tekaan salah, hanya amaun pertaruhan akan ditolak; jika jumlah kenaikan atau pengurangan adalah sifar , ia akan menjadi seri dan pertaruhan akan dikembalikan;",
  title3:  "3) Meja putar besar",
  p11:  "Roda besar ialah permainan perjudian biasa di kasino. Roda mempunyai 54 kawasan yang boleh dipilih, dan empat warna mempunyai perkadaran yang berbeza; ungu mempunyai 26 blok, dan kemungkinan adalah 2 kali; merah mempunyai 17 blok, dan kemungkinan ialah 3 kali ;Hijau ialah 10 keping, kemungkinan adalah 5 kali; kuning ialah 1 keping, kemungkinan adalah 50 kali;",
  p12:  "Tetapkan jumlah pertaruhan. Selepas memilih warna pertaruhan, roda rolet akan berputar. Selepas roda rolet berhenti berputar, warna kawasan yang dipilih akan menjadi warna loteri. Selepas undian undian, penyelesaian akan diselesaikan serta-merta dan bonus akan diedarkan;"
}
