import { createStore } from "vuex";
import { createVuexPersistedState } from "vue-persistedstate";
import web3 from 'web3'
const maxDecimal = (number) => {
  return String(number).replace(/^(.*\..{4}).*$/, '$1')
}
export default createStore({
  state: {
    isLogin: false,
    userInfo: {},
    token: {},
    currencyList: [],
    balanceList: [],
    rate: {},
    chosedCurrency: 'qgt',
    chosedCurrencyCode: '01',
    showDoller: false,
    userAddress: {},
    totalDig: 0,
    Lang: 'zh',
    connected: false,
    address: '',
    walletBalanceOf: {
      bnb: 0,
      busd: 0,
    },
  },
  getters: {
    balanceList(state) {
      if (state.showDoller) {
        return state.balanceList.map(item => {
          const balance = item.balance * Number(state.rate[item.coinName])
          return {
            coinName: item.coinName,
            balance: maxDecimal(balance),
            prefix: '$'
          }
        })
      } else {
        return state.balanceList.map(item => {
          return {
            ...item,
            prefix: ''
          }
        })
      }
    },
    currentBalance(state) {
      if (state.showDoller) {
        return state.balanceList.filter(item => {
          return item.coinName === state.chosedCurrency
        }).map(item => {
          return {
            prefix: '$',
            balance: maxDecimal(Number(item.balance) * Number(state.rate[item.coinName]))
          }
        })[0]
      } else {
        return state.balanceList.filter(item => {
          return item.coinName === state.chosedCurrency
        }).map(item => {
          return {
            prefix: '',
            balance: maxDecimal(Number(item.balance))
          }
        })[0]
      }
    },
  },
  mutations: {
    changeBnbBalanceOf(state, data) {
      state.walletBalanceOf.bnb = data
    },
    changeBusdBalanceOf(state, data) {
      state.walletBalanceOf.busd = data
    },
    changeConnected(state, address) {
      state.connected = address.length > 0
      state.address = address.length > 0 ? address[0] : ''
      if (address.length > 0) {
        window.localStorage.setItem('address', address[0])
      } else {
        window.localStorage.removeItem('address')
      }
    },
    changeLang(state, data) {
      state.Lang = data
    },
    login(state, data) {
      state.userInfo = data.user;
      state.token = data.token;
      state.isLogin = true;
    },
    logout(state) {
      state.userInfo = {};
      state.token = {};
      state.walletBalanceOf.bnb = 0;
      state.walletBalanceOf.busd = 0;
      state.isLogin = false;
    },
    refreshToken(state, token) {
      state.token.accessToken = token;
    },
    setTotalDig(state, num) {
      state.totalDig = num;
    },
    setCurrencyList(state, list) {
      state.currencyList = list;
    },
    changeDollerShow(state, status) {
      state.showDoller = status;
    },
    setRate(state, obj) {
      state.rate = obj;
    },
    setBalance(state, balance) {
      state.balanceList = balance.map(item => {
        return {
          ...item,
          // balance: web3.utils.fromWei(String(item.balance))
        }
      })
    },
    setUserAddress(state, addressObj) {
      state.userAddress = addressObj;
    },
    changeCurrency(state, type) {
      state.chosedCurrency = type.typeName;
      state.chosedCurrencyCode = type.code
    },
    changeShowDoller(state, status) {
      state.showDoller = status;
    },
  },
  actions: {},
  modules: {},
  plugins: [createVuexPersistedState({
    blackList: ['connected', 'address'],
  })]
});
