// 帮助-游戏的翻译
export default {
  title:  "trò chơi",
  p1:  "QueenG đã tập hợp các trò chơi phổ biến nhất trong bối cảnh sòng bạc và phát triển nó nội bộ. Bạn có thể sử dụng các loại tiền điện tử TOP để đặt cược trực tiếp vào các trò chơi. Hiện tại, người dùng có thể thưởng thức các trò chơi được thiết kế độc đáo như tung xúc xắc, đoán tăng giảm và bánh xe lớn. Chúng tôi cũng sẽ ra mắt nhiều trò chơi sòng bạc phổ biến hơn trong tương lai!",
  p2:  "Tỷ lệ cược tương ứng với mỗi trận đấu là khác nhau, nhưng số tiền đặt cược tối đa-tối thiểu được cố định ở mức: $1000000-$1",
  title1:  "1) Đổ xúc xắc",
  p3:  "Crap là một trò chơi xúc xắc cổ xưa của Trung Quốc, trong đó người chơi đặt cược vào kết quả của lần quay ba viên xúc xắc được quay trong lồng.",
  p4:  "Khi trò chơi bắt đầu, người chơi chọn hướng kích thước của khu vực đặt cược hoặc số điểm cụ thể. Sau khi đặt cược xong, xúc xắc sẽ bắt đầu quay. Sau khi quay xong, nền tảng sẽ hiển thị kết quả và phát hành phần thưởng.",
  p5:  "Không giống như xúc xắc truyền thống, trò chơi xúc xắc GAMES tự động hóa hoàn toàn quy trình đặt cược, xúc xắc ngẫu nhiên, trả thưởng khi thắng và xử lý quỹ/phần thưởng thông qua hợp đồng thông minh mà không có sự tham gia của bên thứ ba. Điều này nhanh hơn so với cá cược sòng bạc truyền thống hiện tại. Hiệu quả và công bằng hơn nhiều .",
  subtitle1:  "1.1) Đặt hàng",
  p6:  "Có một dãy khu vực đặt cược phía dưới viên xúc xắc được chia thành 6 ô nhỏ. Ở hàng này bạn đặt cược vào số nào sẽ hiển thị sau khi quay xúc xắc. Nếu một trong 3 viên xúc xắc hiển thị số bạn đặt cược thì sau đó Số tiền thắng của bạn là 1 ăn 2. Nếu hai trong số ba viên xúc xắc hiển thị số bạn đặt cược, số tiền thắng của bạn là 1 ăn 11. Nếu ba trong số ba viên xúc xắc hiển thị số bạn đặt cược, số tiền thắng của bạn là 1 ăn 151.",
  subtitle2:  "1.2) Cược hòa",
  p7:  "Trong ô Hòa, cược của bạn là tổng các số sau khi quay ba viên xúc xắc. 'Lớn' nghĩa là tổng các số từ 11 đến 17; 'Nhỏ' nghĩa là tổng các số từ 4 đến 10. Nếu con số sau khi quay là ba Nếu số điểm bằng nhau thì thuộc về 'Báo', lớn nhỏ đều thua, tỷ lệ lớn nhỏ là 2 ăn 1, tỷ lệ báo hoa mai là 25 ăn 1. ",
  title2:  "2) Đoán tăng hay giảm",
  p8:  "Dựa trên điều kiện thị trường của ba loại tiền tệ chính, người dùng có thể chọn tăng hoặc giảm và dự đoán mức tăng giảm của cặp giao dịch đã chọn trong 15 giây. Sau khi đặt cược theo một hướng, thị trường sẽ nhảy vọt trong 15 giây Đếm ngược sẽ được nhập. Sau 15 giây, giá tăng và giảm sẽ được so sánh. Nếu dự đoán đúng, giá sẽ tăng hoặc giảm ngay lập tức. Giảm giá sau khi thanh toán.",
  p9:  "Về việc đặt cược:",
  p10:  "Sau khi phân tích và kiểm tra thị trường, hãy đặt số tiền đặt cược và nhấp vào nút Đoán Tăng/Đoán xuống. Nếu kết quả đoán đúng, bạn sẽ nhận được gấp 2 lần tiền thưởng tương ứng; nếu kết quả đoán sai, chỉ có số tiền đặt cược sẽ bị trừ; nếu số tiền tăng hoặc giảm bằng 0 thì sẽ hòa và tiền cược sẽ được hoàn lại;",
  title3:  "3) Bàn xoay lớn",
  p11:  "Bánh xe lớn là một trò chơi cờ bạc phổ biến trong sòng bạc. Bánh xe có 54 khu vực có thể lựa chọn và bốn màu có tỷ lệ khác nhau; màu tím có 26 khối và tỷ lệ cược là 2 lần; màu đỏ có 17 khối và tỷ lệ cược là 3 lần;Xanh là 10 quân, tỷ lệ cược là 5 lần; vàng là 1 quân, tỷ lệ cược là 50 lần;",
  p12:  "Đặt số tiền đặt cược. Sau khi chọn màu đặt cược, bánh xe roulette sẽ quay. Sau khi bánh xe roulette ngừng quay, màu của khu vực đã chọn sẽ là màu xổ số. Sau khi rút xổ số, việc giải quyết sẽ hoàn tất ngay lập tức và tiền thưởng sẽ được phân phối;"
}
