// 日常任务的翻译
export default {
  manyRewards:  "Muitas recompensas estão aí",
  theDailyTask:  "tarefas diárias",
  getIt:  "Você pode vir buscá-lo mais tarde!",
  moreRewards:  "Quanto mais você apostar, maior será a recompensa",
  completeGame:  "Escolha e complete as tarefas diárias do jogo para ganhar mais emoção no QueenG!",
  distributed:  "As tarefas diárias são distribuídas em diferentes tipos de jogos. Conclua-as e ganhe recompensas!",
  dailyTask:  "Contagem regressiva de tarefas diárias:",
  bonusCompleting:  "Recompensas adicionais por completar todas as tarefas:",
  getMany:  "receber",
  winDice1:  "Aposte nos dados para ganhar 30 vezes",
  winDice2:  "Aposte no empate nos dados e ganhe 30 vezes",
  leopard:  "Os dados lançam um leopardo",
  winTwice:  "Use o mesmo número da sorte duas vezes seguidas x2 nos dados",
  roulette1:  "Jogue roleta 30 vezes em X2",
  roulette2:  "Jogue Roleta 20 vezes em X3",
  roulette3:  "Jogue Roleta 15 vezes em X5",
  roulette4:  "Jogue Roleta 20 vezes em X50",
  guess1:  "adivinhe o preço para cima ou para baixo 30 vezes",
  guess2:  "Adivinhe corretamente 20 vezes",
  guess3:  "Adivinhe 20 vezes",
  guess4:  "Adivinhe a queda corretamente 5 vezes seguidas",
  collectCompletion:  "Clique para receber após a conclusão",
  notCompleted:  "A tarefa ainda não foi concluída",
  rewardsReceived:  "Já recebi recompensas",
  SuccessfullyReceived:  "Recebido com sucesso"
}
