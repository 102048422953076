export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    invitedId: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (this.invitedId) {
      this.registerForm.invitationCode = String(this.invitedId);
    }
  },
  data() {
    return {
      showPassword: false,
      loginForm: {
        userName: '',
        passward: ''
      },
      loginError: {},
      registerForm: {
        email: '',
        invitationCode: ''
      },
      registerError: {},
      timer: null,
      emailTimer: null,
      emailTime: 0,
      agree: false,
      isShowForget: false,
      forget: {
        email: '',
        verificationCode: ''
      }
    };
  },
  computed: {
    codeText() {
      return this.emailTime == 0 ? this.$t("loginModal.sendVerificationCode") : this.emailTime;
    },
    show: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    formType: {
      get() {
        return this.type;
      },
      set(value) {
        this.$emit('setType', value);
      }
    }
  },
  methods: {
    sendEmail() {
      const reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      if (!reg.test(this.registerForm.email)) {
        this.$message.error(this.$t("loginModal.pleaseCheckEmail"));
        return;
      }
      this.$http.post('/user-auth-service/anonymous/sendEmail', {
        email: this.registerForm.email
      }).then(() => {
        this.startEmailTimer();
      });
    },
    startEmailTimer() {
      this.emailTime = 60;
      this.$message.success(this.$t("loginModal.mailSent"));
      this.emailTimer = setInterval(() => {
        this.emailTime = this.emailTime - 1;
        if (this.emailTime <= 0) {
          clearInterval(this.emailTimer);
        }
      }, 1000);
    },
    checkRegister() {
      let checked = true;
      if (!this.registerForm.email) {
        this.registerError.email = this.$t("loginModal.pleaseEnterEmailAddress");
        checked = false;
      } else {
        const reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
        if (!reg.test(this.registerForm.email)) {
          this.registerError.email = this.$t("loginModal.incorrectMailbox");
          checked = false;
        }
      }
      if (!this.registerForm.vcode) {
        this.registerError.vcode = this.$t("loginModal.enterVerificationCode");
        checked = false;
      }
      if (!this.registerForm.userName) {
        this.registerError.userName = this.$t("loginModal.enterUserName");
        checked = false;
      }
      if (!this.registerForm.passward) {
        this.registerError.passward = this.$t("loginModal.pleaseInputPassword");
        checked = false;
      }
      // if (!this.agree) {
      //   this.$message.error(this.$t("loginModal.agreeConditions"))
      //   checked = false
      // }
      return checked;
    },
    register() {
      if (this.checkRegister()) {
        this.$http.post('/user-auth-service/anonymous/registerUser', this.registerForm).then(res => {
          this.$message.success(this.$t("loginModal.registrationSuccessful"));
          this.$emit('update:modelValue', false);
          this.$store.commit('login', res);
        });
      }
    },
    checkLogin() {
      let checked = true;
      console.log(this.loginForm);
      if (!this.loginForm.userName) {
        this.loginError.userName = this.$t("loginModal.enterUserNameOrEmail");
        checked = false;
      }
      if (!this.loginForm.passward) {
        this.loginError.passward = this.$t("loginModal.pleaseInputPassword");
        checked = false;
      }
      return checked;
    },
    login() {
      if (this.checkLogin()) {
        this.$http.post('/user-auth-service/anonymous/login', this.loginForm).then(res => {
          this.$store.commit('login', res);
          this.$message.success(this.$t("loginModal.loginSuccessful"));
          this.$emit('update:modelValue', false);
        });
      }
    },
    sendEmail2() {
      const reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      if (!reg.test(this.forget.email)) {
        this.$message.error(this.$t("loginModal.pleaseCheckEmail"));
        return;
      }
      this.$http.post('/user-auth-service/anonymous/sendInitPwdEmail', {
        email: this.forget.email
      }).then(() => {
        this.startEmailTimer();
      });
    },
    forgetHandler() {
      if (!this.forget.email) {
        this.$message.error(this.$t("loginModal.forgetErrorEmail"));
        return false;
      }
      if (!this.forget.verificationCode) {
        this.$message.error(this.$t("loginModal.forgetErrorVerificationCode"));
        return false;
      }
      this.$http.post('/user-auth-service/anonymous/initPwdWithCode', this.forget).then(() => {
        this.$message.success(this.$t("loginModal.forgetErrorSuccess"));
        this.forget.email = '';
        this.forget.verificationCode = '';
        this.isShowForget = false;
      });
    }
  }
};