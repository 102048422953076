// 邀请的翻译
export default {
  instantly1:  "Pegue Gundam agora",
  instantly2:  "3%",
  instantly3:  "quantidade elevada",
  instantly4:  "Desconto de lucro",
  invitationCode:  "Código de Convite",
  copy:  "cópia de",
  share:  "compartilhar",
  invited:  "Convidamos",
  commissionReturned:  "A comissão foi devolvida",
  commissionAccumulated:  "Descontos foram acumulados",
  date:  "data",
  commissionRebate:  "Desconto de lucro líquido",
  bettingGoldRebate:  "Desconto em apostas",
  miningCommission:  "Desconto de mineração",
  total:  "total",
  operation:  "operar",
  viewDetails:  "Ver detalhes",
  goodFriends:  "Amigos",
  entryTime:  "tempo de chegada",
  howRebate:  "Como receber comissões de amigos",
  inviteFriends:  "convide amigos",
  shareLink:  "Compartilhe o link com seus amigos, e seus amigos farão login na plataforma através do link",
  friendsLogIn:  "Login de amigo",
  friendsToPlatform:  "Depois que seu amigo fizer login na plataforma, recarregue as baterias e participe do jogo",
  giveOutRewards:  "distribuir recompensas",
  friendsPlayGames:  "Amigos jogam na plataforma e convidam pessoas para obter descontos nos lucros",
  tip1:  "1. Convide seus amigos para aderir à plataforma e você poderá obter até 3% de desconto na taxa de manuseio.",
  step1:  "Etapa um: vá para a página de convite de amigos e compartilhe seu pôster ou link de convite com seus amigos.",
  step2:  "Etapa 2: Os amigos fazem login na plataforma por meio do link de convite e os amigos podem receber recompensas de comissão participando do jogo.",
  step3:  "Etapa 3: A renda gerada por amigos jogando na plataforma, a renda de mineração e as apostas dos amigos vencedores da plataforma serão devolvidas a você em uma determinada proporção.",
  tip2:  "2. As recompensas do desconto serão liquidadas para você em tempo real e distribuídas na carteira da sua conta.",
  tip3:  "3. Não há limite máximo para o número de convites. Quanto mais amigos você convidar, mais descontos receberá. Cada amigo pode aproveitar os descontos permanentemente. Vá em frente e convide mais amigos!",
  scanningCode1:  "Escaneie o código para receber",
  scanningCode2:  "Gundam",
  scanningCode3:  "Recompensa de mineração de 3% do WCC",
  longPress:  "Pressione e segure para identificar o código QR",
  enteringThePage:  "Após entrar na página, clique em Cadastre-se para participar do jogo imediatamente",
  savePicture:  "Salvar foto",
  rewardRules:  "Regras de recompensa",
  copySucceeded:  "Copiar com sucesso",
  hiQGT:  "Olá, bem-vindo à plataforma de jogos FUN3.0",
  airdropBenefits:  "",
  clickGetIt:  "Registre-se e convide amigos para jogar e desfrutar de altas comissões e recompensas de mineração",
  receive:  "Iniciar registro"
}
