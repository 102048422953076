// 日常任务的翻译
export default {
  manyRewards:  "มีรางวัลมากมาย",
  theDailyTask:  "งานประจำวัน",
  getIt:  "คุณสามารถมารับได้ทีหลัง!",
  moreRewards:  "ยิ่งเดิมพันมาก ยิ่งได้รับรางวัลมาก",
  completeGame:  "เลือกและทำภารกิจเกมรายวันให้เสร็จสิ้นเพื่อรับความตื่นเต้นมากขึ้นใน QueenG!",
  distributed:  "งานประจำวันจะกระจายไปตามเกมประเภทต่างๆ ทำภารกิจให้สำเร็จและรับรางวัล!",
  dailyTask:  "นับถอยหลังงานประจำวัน:",
  bonusCompleting:  "รางวัลเพิ่มเติมสำหรับการทำภารกิจทั้งหมดให้เสร็จสิ้น:",
  getMany:  "รับ",
  winDice1:  "เดิมพันลูกเต๋าเพื่อชนะ 30 ครั้ง",
  winDice2:  "เดิมพันเสมอที่ลูกเต๋าและชนะ 30 ครั้ง",
  leopard:  "ลูกเต๋าโยนเสือดาวออกมา",
  winTwice:  "ใช้เลขนำโชคเดียวกันสองครั้งติดต่อกัน x2 ในลูกเต๋า",
  roulette1:  "เล่นรูเล็ต 30 ครั้งใน X2",
  roulette2:  "เล่นรูเล็ต 20 ครั้งใน X3",
  roulette3:  "เล่นรูเล็ต 15 ครั้งใน X5",
  roulette4:  "เล่นรูเล็ต 20 ครั้งใน X50",
  guess1:  "ทายราคาขึ้นหรือลง 30 เท่า",
  guess2:  "ทายถูก 20 ครั้ง",
  guess3:  "ทายถูก 20 ครั้ง",
  guess4:  "ทายการล้มให้ถูก 5 ครั้งติดต่อกัน",
  collectCompletion:  "คลิกเพื่อรับหลังจากเสร็จสิ้น",
  notCompleted: "งานยังไม่เสร็จ",
  rewardsReceived:  "ได้รับรางวัลแล้ว",
  SuccessfullyReceived:  "รับสำเร็จ"
}
